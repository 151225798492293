var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { faChevronDown } from "@faharmony/icons";
import { Icon, Popover } from "@faharmony/components";
import { StyledEmptyBox, StyledMoreAction, StyledTabBar, StyledTabBarContainer, } from "./style";
import { useActiveTabId } from "./helpers";
import AnimatedTabIndicator from "./AnimatedTabIndicator";
import { Tab } from "./Tab";
import { Box, theme } from "@faharmony/theme";
var TabsBar = function (_a) {
    var tabs = _a.tabs, uid = _a.uid, _b = _a.orientation, orientation = _b === void 0 ? "horizontal" : _b, isSummaryView = _a.isSummaryView;
    var tabForms = undefined;
    var tabsArray = [];
    if (tabs && !Array.isArray(tabs)) {
        if ("formMethods" in tabs) {
            tabForms = tabs;
        }
        tabsArray = tabs.tabs;
    }
    else {
        tabsArray = tabs;
    }
    var _c = useActiveTabId(uid, tabsArray, orientation), activeId = _c.activeId, setActiveId = _c.setActiveId;
    var _d = __read(React.useState({}), 2), hiddenItems = _d[0], setHiddenItems = _d[1];
    var formMethods = tabForms === null || tabForms === void 0 ? void 0 : tabForms.formMethods;
    var errorFields = ((formMethods === null || formMethods === void 0 ? void 0 : formMethods.formState.errors) &&
        Object.keys(formMethods === null || formMethods === void 0 ? void 0 : formMethods.formState.errors)) ||
        [];
    var tabRefs = React.useMemo(function () {
        return tabsArray.reduce(function (acc, item) {
            acc[item.id] = React.createRef();
            return acc;
        }, {});
    }, [tabsArray]);
    var _e = __read(React.useState(false), 2), animating = _e[0], setAnimating = _e[1];
    var handleClick = function (id) {
        var _a, _b;
        setAnimating(true);
        (_b = (_a = tabRefs[id]) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
        });
        setActiveId(id);
    };
    var _f = __read(React.useState({
        start: 0,
        end: 0,
    }), 2), errorIndicator = _f[0], setErrorIndicator = _f[1];
    var menuItems = React.useMemo(function () {
        var errorCount = { start: 0, end: 0 };
        var items = Object.keys(hiddenItems)
            .filter(function (id) { return hiddenItems[id] === false; })
            .map(function (id) {
            var _a, _b;
            var tab = tabsArray.find(function (tab) { return tab.id === id; });
            var label = tab === null || tab === void 0 ? void 0 : tab.label;
            var disabled = tab === null || tab === void 0 ? void 0 : tab.disabled;
            var hasError = (errorFields === null || errorFields === void 0 ? void 0 : errorFields.length)
                ? !!((_b = (_a = tabForms === null || tabForms === void 0 ? void 0 : tabForms.fieldNames) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.find(function (fieldName) {
                    return errorFields.includes(fieldName);
                }))
                : false;
            if (hasError) {
                var topTabIndex = Object.keys(hiddenItems).findIndex(function (tabId) { return hiddenItems[tabId] === true; });
                var currentTabIndex = Object.keys(hiddenItems).findIndex(function (tabId) { return tabId === id; });
                if (currentTabIndex < topTabIndex) {
                    errorCount.start = errorCount.start + 1;
                }
                else {
                    errorCount.end = errorCount.end + 1;
                }
            }
            return {
                id: id,
                label: label,
                disabled: disabled,
                color: hasError ? "negative" : "accent",
                onClick: function () { return handleClick(id); },
            };
        });
        setErrorIndicator(errorCount);
        return items;
    }, [hiddenItems, errorFields.length]);
    /**Scroll to the tab if hidden on initial load */
    var _g = __read(React.useState(false), 2), didLoad = _g[0], setDidLoad = _g[1];
    React.useEffect(function () {
        var _a, _b;
        if (!didLoad && hiddenItems[activeId] === false) {
            setAnimating(true);
            (_b = (_a = tabRefs[activeId]) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "nearest",
            });
            setDidLoad(true);
        }
    }, [hiddenItems[activeId], didLoad]);
    return (_jsxs(StyledTabBarContainer, __assign({ orientation: orientation, direction: "column" }, { children: [_jsxs(StyledTabBar, __assign({ className: "tabsBar", orientation: orientation, direction: orientation === "horizontal" ? "row" : "column", isOverflow: !!menuItems.length, errorCount: errorIndicator, activeIndex: tabsArray.findIndex(function (tab) { return tab.id === activeId; }), isSummaryView: isSummaryView }, { children: [tabsArray.map(function (tab) {
                        var _a;
                        return (_jsx(Tab, { hiddenItems: hiddenItems, setHiddenItems: setHiddenItems, tab: tab, ref: tabRefs[tab.id], handleClick: handleClick, active: tab.id === activeId, formMethods: formMethods, fieldNames: ((_a = tabForms === null || tabForms === void 0 ? void 0 : tabForms.fieldNames) === null || _a === void 0 ? void 0 : _a[tab.id]) || [], orientation: orientation }, tab.id));
                    }), _jsx(AnimatedTabIndicator, { orientation: orientation, refs: tabRefs, activeTab: activeId, finishAnimating: function () { return setAnimating(false); }, animating: animating }), menuItems.length === 0 && (_jsx(StyledEmptyBox, { orientation: orientation, className: "noMargin" }))] })), menuItems.length > 0 && (_jsx(StyledMoreAction, __assign({ orientation: orientation }, { children: _jsx(Popover.Menu, __assign({ menuItems: menuItems, trigger: "mouseover", placement: "right-start" }, { children: _jsx(Box, __assign({ width: "100%", justifyContent: "center" }, { children: _jsx(Icon, { icon: faChevronDown, color: !!menuItems.find(function (item) { return item.color === "negative"; })
                                ? theme.color.text.negative
                                : theme.color.text.primary }) })) })) })))] })));
};
export { TabsBar };
