// Defaults
export var baseFontFamily = "Mulish";
export var baseFontSize = 14;
export var convertPxToRem = function (px) {
    if (px === void 0) { px = 0; }
    return px === 0 ? "1rem" : px / baseFontSize + "rem";
};
/** Code variant of Text */
export var code = {
    fontConfig: {
        google: {
            families: ["Fira Code"],
        },
    },
    style: {
        fontSize: convertPxToRem(12),
        fontWeight: "normal",
        lineHeight: convertPxToRem(20),
        letterSpacing: 0,
        textTransform: "none",
    },
};
/** All text-styles except code */
export var text = {
    fontConfig: {
        google: {
            families: ["".concat(baseFontFamily, ":400,700,800,900")],
        },
    },
    h1: {
        fontSize: convertPxToRem(26),
        fontWeight: 800,
        lineHeight: convertPxToRem(36),
        letterSpacing: 0,
        textTransform: "capitalize",
    },
    h2: {
        fontSize: convertPxToRem(22),
        fontWeight: 700,
        lineHeight: convertPxToRem(32),
        letterSpacing: 0,
        textTransform: "none",
    },
    h3: {
        fontSize: convertPxToRem(18),
        fontWeight: 700,
        lineHeight: convertPxToRem(24),
        letterSpacing: 0,
        textTransform: "none",
    },
    h4: {
        fontSize: convertPxToRem(16),
        fontWeight: 700,
        lineHeight: convertPxToRem(24),
        letterSpacing: 0,
        textTransform: "none",
    },
    h5: {
        fontSize: convertPxToRem(baseFontSize),
        fontWeight: 700,
        lineHeight: convertPxToRem(20),
        letterSpacing: "0.5px",
        textTransform: "none",
    },
    h6: {
        fontSize: convertPxToRem(12),
        fontWeight: 700,
        lineHeight: convertPxToRem(16),
        letterSpacing: "0.25px",
        textTransform: "none",
    },
    strong: {
        fontSize: convertPxToRem(12),
        fontWeight: 900,
        lineHeight: convertPxToRem(16),
        letterSpacing: "1px",
        textTransform: "uppercase",
    },
    p: {
        fontSize: convertPxToRem(baseFontSize),
        fontWeight: 400,
        lineHeight: convertPxToRem(20),
        letterSpacing: 0,
        textTransform: "none",
    },
    small: {
        fontSize: convertPxToRem(12),
        fontWeight: 400,
        lineHeight: convertPxToRem(16),
        letterSpacing: 0,
        textTransform: "none",
    },
};
