var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Text } from "@faharmony/components";
import { Center } from "@faharmony/theme";
import { TabsBar } from "./TabBar";
import { tabIdTrigger } from "./helpers";
/**
 * useTabs
 * ---
 * Tabbed navigation used in PageView and SummaryView
 * @jira https://fasolutions.jira.com/browse/HAR-89
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @copyright FA Solutions Oy
 * @version 1.7.0
 * @example
 * const {TabBar, TabPanel} = useTabs("abc", [{ id: "tab0", label: "Tab", content: <div/>}]);
 * <TabBar />
 * <TabPanel />
 */
var useTabs = function (uid, tabs, isSummaryView) {
    if (tabs === void 0) { tabs = []; }
    if (isSummaryView === void 0) { isSummaryView = false; }
    var _a = __read(tabIdTrigger.useValue(), 1), _b = _a[0], activeId = _b === void 0 ? "" : _b;
    var tabPanels;
    var formMethods;
    var orientation = "horizontal";
    if (Array.isArray(tabs)) {
        tabPanels = tabs;
    }
    else {
        tabPanels = tabs.tabs;
        orientation = tabs.orientation || "horizontal";
        if ("formMethods" in tabs) {
            formMethods = tabs.formMethods;
        }
    }
    var TabPanel = React.useCallback(function (props) {
        var _a;
        var activeTab = tabPanels.find(function (_a) {
            var id = _a.id;
            return id === activeId;
        });
        var tabPanelContent = activeTab === null || activeTab === void 0 ? void 0 : activeTab.content;
        (_a = activeTab === null || activeTab === void 0 ? void 0 : activeTab.onActive) === null || _a === void 0 ? void 0 : _a.call(activeTab, activeTab);
        return (_jsx("div", __assign({ style: __assign({ width: "100%", height: "100%" }, props.style) }, { children: tabPanelContent || (_jsx(Center, { children: _jsx(Text, { value: "No tab selected", color: "disabled" }) })) })));
    }, [activeId]);
    var TabBar = React.useCallback(function () { return (_jsx(TabsBar, __assign({}, { tabs: tabs, uid: uid, orientation: orientation, isSummaryView: isSummaryView }))); }, [formMethods === null || formMethods === void 0 ? void 0 : formMethods.formState.errors, orientation]);
    return { TabBar: TabBar, TabPanel: TabPanel, activeTabId: activeId, orientation: orientation };
};
export { useTabs, };
