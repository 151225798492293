var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { proxy } from "valtio";
export var state = proxy({
    modalMode: "closed",
    selectedProcess: undefined,
    activeTask: undefined,
    processPayload: undefined,
    processDefinitions: undefined,
    currentUser: undefined,
    taskManager: {
        global: {
            tasks: [],
            tasksRefetcher: function () {
                console.error("tasksRefetcher not set");
            },
            query: {},
        },
    },
});
export var actions = {
    closeModal: function () {
        state.modalMode = "closed";
        state.selectedProcess = undefined;
        state.activeTask = undefined;
    },
};
export var taskManagerActions = {
    // Updates a task object for all task managers in state based on the provided newTask object.
    updateTask: function (newTask) {
        if (!(newTask === null || newTask === void 0 ? void 0 : newTask.taskId))
            return console.error("Cannot update task without id", newTask);
        var stateAccessorKeys = Object.keys(state.taskManager);
        stateAccessorKeys.forEach(function (key) {
            state.taskManager[key].tasks = state.taskManager[key].tasks.map(function (task) {
                if (task.taskId === newTask.id) {
                    return __assign(__assign({}, task), newTask);
                }
                return task;
            });
        });
    },
    //Remove the completed task from the state and add the next task if any
    completeTask: function (newTask, taskId) {
        var stateAccessorKeys = Object.keys(state.taskManager);
        stateAccessorKeys.forEach(function (key) {
            var _a, _b;
            if (newTask) {
                var workflowDetails = ((_a = state.taskManager[key].tasks) === null || _a === void 0 ? void 0 : _a.length)
                    ? (_b = state.taskManager[key].tasks[0]) === null || _b === void 0 ? void 0 : _b.workflowDetails
                    : undefined;
                var oldTask = state.taskManager[key].tasks.find(function (task) { return task.taskId; });
                if (workflowDetails && oldTask) {
                    /**The instance of oldTask and new task will be the same since they are part of the same flow */
                    newTask.workflowDetails =
                        oldTask === null || oldTask === void 0 ? void 0 : oldTask.workflowDetails;
                    state.taskManager[key].tasks = __spreadArray(__spreadArray([], __read(state.taskManager[key].tasks), false), [
                        newTask,
                    ], false);
                }
            }
            if (taskId) {
                state.taskManager[key].tasks = state.taskManager[key].tasks.filter(function (task) { return task.taskId !== taskId; });
            }
        });
    },
    // Removes a task with a given ID from all task managers in state.
    removeTask: function (taskId) {
        if (!taskId)
            return console.error("Cannot remove task without task id", taskId);
        Object.values(state.taskManager).forEach(function (taskManager) {
            var taskToRemove = taskManager.tasks.find(function (task) { return task.taskId === taskId; });
            if (!taskToRemove)
                return;
            var remainingTasks = taskManager.tasks.filter(function (task) { return task.taskId !== taskId; });
            taskManager.tasks = remainingTasks;
        });
    },
    setLoading: function (stateAccessorKey, loading) {
        state.taskManager[stateAccessorKey].loading = loading;
    },
};
//@ts-ignore
window.TaskManagerState = { state: state };
