var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from "@faharmony/theme";
import { useModals } from "./helpers";
import { ContentView } from "@faharmony/views";
import { Button, Text } from "@faharmony/components";
import { useEffect, useState } from "react";
var Modal = function (modal) {
    var removeModal = useModals().removeModal;
    var handleClose = function (modal) {
        var _a;
        removeModal(modal.id);
        (_a = modal.onClosed) === null || _a === void 0 ? void 0 : _a.call(modal, modal);
    };
    var _a = __read(useState([]), 2), footerActionList = _a[0], setFooterActionList = _a[1];
    var cancelBtnProps = {
        id: "DialogueCancelButton",
        value: modal.cancelText || "Cancel",
        shouldClose: modal.onCancel ? false : true,
        onClick: modal.onCancel,
        variant: modal.cancelButtonVariant || "default",
    };
    var _b = __assign({}, modal).shouldCloseOnConfirm, shouldCloseOnConfirm = _b === void 0 ? true : _b;
    var confirmProps = {
        id: "DialoguConfirmlButton",
        value: modal.confirmText || "OK",
        shouldClose: shouldCloseOnConfirm,
        variant: modal.confirmButtonVariant || "primary",
        onClick: function () { return modal.onConfirm && modal.onConfirm(modal); },
    };
    useEffect(function () {
        var a = modal.footerActions || [];
        if (modal.isConfirm) {
            a = __spreadArray(__spreadArray([cancelBtnProps], __read(a), false), [confirmProps], false);
        }
        setFooterActionList(a.map(function (actions) { return (__assign(__assign({}, actions), { onClick: function () {
                actions.onClick && actions.onClick();
                actions.additionalActions && actions.additionalActions(modal);
                actions.shouldClose && handleClose(modal);
            } })); }) || []);
    }, [modal]);
    return (_jsx(_Fragment, { children: !modal.title ? (typeof modal.content === "function" ? (modal.content({ closeModal: function () { return handleClose(modal); } })) : (modal.content)) : (_jsx(ContentView, { headerTitle: modal.title, headerAdditionalContent: modal.subTitle, onClose: modal.showCloseIcon
                ? function () {
                    return modal.onCancel ? modal.onCancel(modal) : handleClose(modal);
                }
                : undefined, style: { height: modal.height }, children: _jsx(Box, __assign({ direction: "column", style: { padding: modal.noPadding ? "0px" : "8px" } }, { children: typeof modal.content === "string" ? (_jsx(Text, { value: modal.content, variant: "p" })) : modal.content })), footerContent: (footerActionList === null || footerActionList === void 0 ? void 0 : footerActionList.length) ? (_jsx(Box, __assign({ justifyContent: modal.footerButtonPlacement || "space-between" }, { children: footerActionList.map(function (buttonProps) { return (_jsx(Button, __assign({}, buttonProps))); }) }))) : null }, modal.id)) }));
};
export default Modal;
