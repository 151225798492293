var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback, useLayoutEffect, forwardRef, useImperativeHandle, } from "react";
import { Box, Center } from "@faharmony/theme";
import { Button, Text, } from "@faharmony/components";
import { StepsBar } from "./StepsBar";
import { Step } from "./Step";
var useSteps = function (props) {
    var _a;
    var _b = __read(useState(props.currentStep | 0), 2), currentStep = _b[0], setCurrentStep = _b[1];
    var isLastStep = currentStep === ((_a = props.steps) === null || _a === void 0 ? void 0 : _a.length) - 1;
    var handleNextButtonClick = function () {
        setCurrentStep(function (currStep) { return currStep + 1; });
        if (props === null || props === void 0 ? void 0 : props.handleNextButton) {
            props === null || props === void 0 ? void 0 : props.handleNextButton();
        }
    };
    var handleBackButtonClick = function () {
        setCurrentStep(function (currStep) { return currStep - 1; });
        if (props === null || props === void 0 ? void 0 : props.handleBackButton) {
            props === null || props === void 0 ? void 0 : props.handleBackButton();
        }
    };
    return {
        currentStep: currentStep,
        isLastStep: isLastStep,
        setCurrentStep: setCurrentStep,
        handleNextButtonClick: handleNextButtonClick,
        handleBackButtonClick: handleBackButtonClick,
    };
};
var useStepper = function (props) {
    var _a;
    var _b = useSteps(__assign({}, props)), currentStep = _b.currentStep, isLastStep = _b.isLastStep, setCurrentStep = _b.setCurrentStep, handleNextButtonClick = _b.handleNextButtonClick, handleBackButtonClick = _b.handleBackButtonClick;
    var StepPanel = useCallback(function () {
        var activeStep = props.steps[currentStep];
        var stepPanelContent = activeStep === null || activeStep === void 0 ? void 0 : activeStep.content;
        /** Triggers validation for prefilled fields on load
         * ex case: when user goes back to previous step from the panel with invalid invalid inputs, preserves the inputs and errors once the user comes back to same panel
         * */
        useLayoutEffect(function () {
            var _a;
            var fieldsOnCurrentPanel = __assign({}, (_a = props.formMethods) === null || _a === void 0 ? void 0 : _a.control._fields);
            var filedNamesOnCurrentPanel = fieldsOnCurrentPanel
                ? Object.keys(fieldsOnCurrentPanel)
                : [];
            filedNamesOnCurrentPanel.forEach(function (visibleFieldName) {
                var _a, _b, _c, _d;
                var currentValue = (_c = (_b = (_a = fieldsOnCurrentPanel === null || fieldsOnCurrentPanel === void 0 ? void 0 : fieldsOnCurrentPanel[visibleFieldName]) === null || _a === void 0 ? void 0 : _a._f) === null || _b === void 0 ? void 0 : _b.ref) === null || _c === void 0 ? void 0 : _c.value;
                //Trigger validation if the field is not empty
                if (currentValue && currentValue != "") {
                    (_d = props.formMethods) === null || _d === void 0 ? void 0 : _d.trigger(visibleFieldName);
                }
            });
            /**Reset with current values so they are preserved if navigating back */
            return function () {
                var _a;
                (_a = props.formMethods) === null || _a === void 0 ? void 0 : _a.reset(props.formMethods.getValues());
            };
        }, []);
        return (_jsx("div", __assign({ style: { width: "100%", height: "100%" } }, { children: stepPanelContent || (_jsx(Center, { children: _jsx(Text, { value: "No data available for the step", color: "disabled" }) })) })));
    }, [currentStep]);
    var SubmitButton = forwardRef(function (props, ref) {
        var _a = __assign({}, props), disabled = _a.disabled, loading = _a.loading, value = _a.value, buttonProps = __rest(_a, ["disabled", "loading", "value"]);
        var _b = __read(useState(disabled), 2), isDisabled = _b[0], setDisabled = _b[1];
        var _c = __read(useState(loading), 2), isLoading = _c[0], setLoading = _c[1];
        var _d = __read(useState(value), 2), text = _d[0], setText = _d[1];
        var setSubmitting = function (submitting) {
            setDisabled(submitting);
            setLoading(submitting);
            setText(submitting ? props.savingLabel : props.value);
        };
        useImperativeHandle(ref, function () {
            return {
                setSubmitting: setSubmitting,
            };
        });
        return (_jsx(Button, __assign({}, buttonProps, { disabled: isDisabled, loading: isLoading, value: text })));
    });
    var NavButton = forwardRef(function (props, ref) {
        var _a = __assign({}, props), disabled = _a.disabled, loading = _a.loading, value = _a.value, buttonProps = __rest(_a, ["disabled", "loading", "value"]);
        var _b = __read(useState(disabled), 2), isDisabled = _b[0], setDisabled = _b[1];
        var _c = __read(useState(loading), 2), isLoading = _c[0], setLoading = _c[1];
        var _d = __read(useState(value), 2), text = _d[0], setText = _d[1];
        useImperativeHandle(ref, function () {
            return {
                setDisabled: setDisabled,
                setLoading: setLoading,
                setText: setText,
            };
        });
        return (_jsx(Button, __assign({}, buttonProps, { disabled: isDisabled, loading: isLoading, value: text })));
    });
    var StepsBarWithButtons = useCallback(function () {
        var _a, _b, _c, _d;
        var handleSave = (props === null || props === void 0 ? void 0 : props.handleSubmitButton)
            ? props === null || props === void 0 ? void 0 : props.handleSubmitButton
            : console.log("Saving");
        var onSubmit = (props === null || props === void 0 ? void 0 : props.formMethods)
            ? (_a = props === null || props === void 0 ? void 0 : props.formMethods) === null || _a === void 0 ? void 0 : _a.handleSubmit(handleSave)
            : handleSave;
        var isValidForm = props.formMethods
            ? ((_b = props.formMethods) === null || _b === void 0 ? void 0 : _b.formState.isValid)
                ? true
                : false
            : true;
        var nextButton = {
            id: "stepNextButton",
            value: "Next",
            variant: "default",
            disabled: !isValidForm,
            onClick: handleNextButtonClick,
        };
        var saveButton = {
            id: "saveButton",
            value: ((_c = props.submitButton) === null || _c === void 0 ? void 0 : _c.label) || "Save",
            variant: "primary",
            disabled: !isValidForm,
            type: "submit",
            savingLabel: ((_d = props.submitButton) === null || _d === void 0 ? void 0 : _d.submittingLabel) || "Saving",
            onClick: onSubmit,
        };
        return (_jsxs(Box, __assign({ direction: "column" }, { children: [_jsx(StepsBar, { steps: props.steps, setActiveStep: setCurrentStep, currentStep: currentStep, editMode: (props === null || props === void 0 ? void 0 : props.editMode) || false }), _jsxs(Box, __assign({ justifyContent: "space-between" }, { children: [_jsx(Box, __assign({ justifyContent: "flex-start" }, { children: _jsx(NavButton, { id: "stepBackButton", value: "Back", disabled: currentStep == 0, onClick: handleBackButtonClick, ref: props.backButtonRef }) })), _jsxs(Box, __assign({ justifyContent: "flex-end" }, { children: [isLastStep || (props === null || props === void 0 ? void 0 : props.editMode) ? (_jsx(SubmitButton, __assign({}, saveButton, { ref: props.submitButtonRef }))) : null, !isLastStep ? (_jsx(NavButton, __assign({}, nextButton, { ref: props.nextButtonRef }))) : null] }))] }))] })));
    }, [(_a = props.formMethods) === null || _a === void 0 ? void 0 : _a.formState.isValid, currentStep]);
    return {
        StepsBarWithButtons: StepsBarWithButtons,
        StepPanel: StepPanel,
        handleNextButtonClick: handleNextButtonClick,
        handleBackButtonClick: handleBackButtonClick,
        currentStep: currentStep,
    };
};
export { useStepper, Step };
