var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { InputField } from "../BaseInputField";
/**
 * TextField
 * ---
 * Component to manage single line input for text
 * @jira https://fasolutions.jira.com/browse/HAR-ticker
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @copyright FA Solutions Oy
 * @version 1.6.0
 * @example <TextField />
 */
var TextField = React.forwardRef(function (props, ref) {
    return _jsx(InputField, __assign({}, props, { ref: ref, type: props.type || "text" }));
});
export { TextField };
