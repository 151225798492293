var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, } from "@apollo/client";
import { getAuthToken } from "../keycloak";
export var createCustomApolloClient = function (uri) {
    var errorLink = onError(function (_a) {
        var networkError = _a.networkError;
        if (networkError) {
            if ("bodyText" in networkError) {
                try {
                    JSON.parse(networkError.bodyText);
                }
                catch (e) {
                    if ("statusCode" in networkError) {
                        networkError.message = "".concat(networkError.response.status, " ").concat(networkError.response.statusText);
                    }
                }
            }
        }
    });
    // Auth
    var authLink = setContext(function (_, _a) {
        var headers = _a.headers;
        // get the authentication token from local storage if it exists
        var token = getAuthToken();
        // return the headers to the context so httpLink can read them
        return {
            headers: __assign(__assign({}, headers), { authorization: token ? "Bearer ".concat(token) : "", Accept: "application/json" }),
        };
    });
    // GraphQL
    var defaultLink = createHttpLink({ uri: uri });
    // Link
    var link = ApolloLink.from([errorLink, authLink, defaultLink]);
    // Cache
    var cache = new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    loadPreference: {
                        merge: function (_existingData, incomingData) {
                            try {
                                //this is simply a helper to parse the incoming data
                                var incomingParsed = JSON.parse(incomingData);
                                return incomingParsed;
                            }
                            catch (e) {
                                return null;
                            }
                        },
                    },
                },
            },
        },
    });
    // Client
    var faGraphQlClient = new ApolloClient({ cache: cache, link: link });
    return faGraphQlClient;
};
