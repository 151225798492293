var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import Tippy from "@tippyjs/react/headless";
import { preventDefaultOnClick } from "@faharmony/helpers";
import usePopoverOptions from "./usePopoverOptions";
import MenuContent from "./content/Menu";
import ConfirmContent from "./content/Confirm";
/**
 * Popover
 * ---
 * Extend the functionality by adding a floating popup near the element.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.5.0
 * @example
 * <Popover content={<div>{contentToShow}</div>}> {triggerElement} </Popover>
 */
var Popover = function (props) {
    var options = usePopoverOptions(props).options;
    var _a = props.trigger, trigger = _a === void 0 ? "click" : _a;
    var child = React.Children.only(props.children);
    if (child) {
        var tippyChildren = React.createElement(child.type, __assign(__assign({}, child.props), { onClick: trigger === "click" ? preventDefaultOnClick : child.props.onContextMenu, onContextMenu: trigger === "contextmenu"
                ? preventDefaultOnClick
                : child.props.onContextMenu }));
        return _jsx(Tippy, __assign({}, options, { children: tippyChildren }));
    }
    console.warn("Popup could not be attached to the children since it didn't pass: React.Children.only(props.children)");
    return props.children;
};
/**
 * Popover.Menu
 * ---
 * Render menu in popover
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.5.0
 * @example
 * <Popover.Menu menuItems={[{
 *  id: 'item1', label: 'Item 1'
 * }]}> {triggerElement} </Popover.Menu>
 */
Popover.Menu = function (_a) {
    var children = _a.children, menuItems = _a.menuItems, props = __rest(_a, ["children", "menuItems"]);
    return (_jsx(Popover, __assign({ content: MenuContent(menuItems) }, props, { tippyProps: { placement: "bottom" } }, { children: children })));
};
/**
 * Popover.Confirm
 * ---
 * Get a popup to confirm an action
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.5.0
 * @example
 * <Popover.Confirm> {triggerElement} </Popover.Confirm>
 */
Popover.Confirm = function (props) {
    return (_jsx(Popover, __assign({ content: ConfirmContent(props) }, props, { children: props.children })));
};
///////////////////////////////////
export { Popover, };
