var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Preloader } from "@faharmony/components";
import { Div100vh } from "@faharmony/views";
import { theme, setTheme, getCurrentDarkModePreference, } from "@faharmony/theme";
import { addModal, removeModal } from "../Modals";
// Pre loading screen
export var Loader = function () {
    setTheme({ enableDarkMode: getCurrentDarkModePreference() });
    return (_jsx(Div100vh, __assign({ id: "full-height-wrapper", style: {
            width: "100%",
            height: "100vh",
            background: theme.color.background.default,
        } }, { children: _jsx(Preloader, {}) })));
};
export default Loader;
export var addLoaderOverlay = function (props) {
    addModal({
        id: props.id || "loaderOverlay",
        content: _jsx(Preloader, { loadingText: props.loadingText }),
        backgroundColor: "transparent",
        width: props.fullScreen !== false ? "100vw" : "100%",
        height: props.fullScreen !== false ? "100vh" : "100%",
    });
};
export var removeLoaderOverlay = function (id) {
    removeModal(id || "loaderOverlay");
};
