import { useLocation } from "react-router-dom";
/**
 * Read query parameters from URL.
 * @param param The parameter to search in URL
 * @returns param value (string) or null
 */
export var useQueryParam = function (param) {
    return useLocation
        ? new URLSearchParams(useLocation().search).get(param)
        : console.error("Package \"react-router-dom@^5\" is needed for \"useQueryParam\".");
};
var getBaseUrl = function () {
    return window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        window.location.hash.split("?")[0];
};
/**
 * Insert/update a query parameters to the URL without reloading.
 * @param {string} key
 * @param {string} value
 */
export var insertQueryParam = function (key, value) {
    var baseURL = getBaseUrl();
    var encodedValue = encodeURIComponent(value);
    var queryUrl = window.location.hash.split("?")[1];
    var paramsMap = [];
    var match = false;
    if (queryUrl) {
        paramsMap = queryUrl
            .split("&")
            .map(function (query) { return [query.split("=")[0], query.split("=")[1]]; });
        paramsMap.forEach(function (query) {
            if (query[0] === key) {
                query[1] = encodedValue;
                match = true;
            }
        });
    }
    if (!match)
        paramsMap.push([key, encodedValue]);
    var newQueryUrl = paramsMap
        .map(function (query) { return query.join("="); })
        .join("&");
    if (window.history.replaceState) {
        var newUrl = baseURL + "?" + newQueryUrl;
        window.history.replaceState({ path: newUrl }, "", newUrl);
    }
};
/** Remove all query params from URL without reloading */
export var clearQueryParam = function () {
    var url = getBaseUrl();
    if (window.history.replaceState) {
        window.history.replaceState({ path: url }, "", url);
    }
};
