import axios from "axios";
import { getAuthToken } from "../keycloak";
import { useAxios } from "./useAxios";
export var faKeycloakApiInstance = axios.create({
    baseURL: "".concat(process.env.REACT_APP_KEYCLOAK_URL || "/auth", "/admin/realms/fa/"),
});
faKeycloakApiInstance.interceptors.request.use(function (config) {
    config.headers.Authorization = "Bearer ".concat(getAuthToken());
    return config;
}, function (error) { return Promise.reject(error); });
/**
 * useKeycloakApi
 * ---
 * Use FA's Keycloak api available at `/auth` endpoint.
 * @param  options
 * @param  lazy [false] - true: to prevent execution onMount.
 * @example
 * const [state, execute, cancel] = useKeycloakApi<DataType>({url: "users", method: "GET"});
 * const { isLoading, isCancelled, error, success, data} = state;
 */
export var useKeycloakApi = function (options, lazy) {
    if (lazy === void 0) { lazy = false; }
    return useAxios(options, faKeycloakApiInstance, lazy);
};
