var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createModuleState, createUseModuleStateHook, useDispatch, } from "@faharmony/state";
/** Initial state of Module */
export var initialState = [];
// -------------------
// Edit reducers below
var removeDialogById = function (dialogs, id) {
    return dialogs.filter(function (dialog) { return dialog.id !== id; });
};
/** Clear all dialogs */
var clearAllDialogs = function () { return []; };
/** Add dialog */
var addDialog = function (state, _a) {
    var payload = _a.payload;
    return __spreadArray(__spreadArray([], __read(removeDialogById(state, payload.id)), false), [
        payload,
    ], false);
};
/** Remove dialog by ID */
var removeDialog = function (state, _a) {
    var id = _a.payload;
    return removeDialogById(state, id);
};
var ModuleID = "harmony-dialogs";
/** Create Module's state using wrapper of createSlice(). */
var DialogsState = createModuleState({
    /** name should be exact same as Module ID. */
    name: ModuleID,
    /** Initial value of Module's state. */
    initialState: initialState,
    /** Object of case reducers to modify Module's state. */
    reducers: { addDialog: addDialog, removeDialog: removeDialog, clearAllDialogs: clearAllDialogs },
});
// Create hooks for accessing Module's state
var useDialogsState = createUseModuleStateHook(ModuleID).useModuleState;
// Extract `actions` from Module's state
var DialogsActions = DialogsState.actions;
export { DialogsActions, DialogsState, useDialogsState, useDispatch };
