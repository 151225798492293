var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense } from "react";
import { KeycloakContext, GraphQLProvider, } from "@faharmony/service";
import { HashRouter as Router } from "@faharmony/router";
import { StateProvider, HarmonyState } from "@faharmony/state";
import { fetchLocalePreference, setCurrentLocale } from "@faharmony/locale";
import App, { AppContext } from "../App";
import Loader from "../App/Loader";
import { ToastsState } from "../Toast";
import { ModalsState } from "../Modals";
import { ThemeProvider } from "@faharmony/mui";
import { useMuiFaTheme } from "@faharmony/theme";
import { ConfirmProvider } from "material-ui-confirm";
import { LicenseInfo } from "@mui/x-license-pro";
var getModuleSlices = function (Modules) {
    return Modules.reduce(function (acc, m) {
        if (m === null || m === void 0 ? void 0 : m.state)
            acc.push(m.state);
        return acc;
    }, []);
};
/**
 * Success
 * ---
 * Generates the react component that should be
 * rendered when authentication is successful.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 */
export var Success = function (_a) {
    var keycloakInstance = _a.keycloakInstance, args = _a.args;
    /** Setup locale */
    fetchLocalePreference().then(setCurrentLocale);
    LicenseInfo.setLicenseKey("bb9e040cc84fcd291f2350051554982cTz03NTEwMixFPTE3MjczNTY1NzgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");
    var faTheme = useMuiFaTheme();
    /** Render app */
    return (_jsx(Suspense, __assign({ fallback: _jsx(Loader, {}) }, { children: _jsx(StateProvider, __assign({ slices: __spreadArray(__spreadArray([], __read(getModuleSlices(args.Modules)), false), [
                HarmonyState,
                ToastsState,
                ModalsState,
            ], false) }, { children: _jsx(Router, { children: _jsx(AppContext.Provider, __assign({ value: args.appConfig }, { children: _jsx(KeycloakContext.Provider, __assign({ value: keycloakInstance }, { children: _jsx(GraphQLProvider, __assign({ keycloakInstance: keycloakInstance, customGraphQL: args.customGraphQL }, { children: _jsx(ThemeProvider, __assign({ theme: faTheme }, { children: _jsx(ConfirmProvider, __assign({ defaultOptions: {
                                        confirmationButtonProps: {
                                            autoFocus: true,
                                            variant: "contained",
                                            color: "primary",
                                        },
                                    } }, { children: _jsx(App, __assign({}, args)) })) })) })) })) })) }) })) })));
};
