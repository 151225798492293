import WebFont from "webfontloader";
import { themeDark, themeLight } from "../faTheme";
import { getCurrentDarkModePreference, setCurrentDarkModePreference, } from "../helpers";
import { generateCSS } from "./css";
/**
 * Function to set or update theme, and dark mode.
 * The function writes global/root CSS which then can be accessed by any component.
 */
var setTheme = function (_a) {
    var _b = _a.enableDarkMode, enableDarkMode = _b === void 0 ? getCurrentDarkModePreference() : _b, _c = _a.globalCSS, globalCSS = _c === void 0 ? "" : _c;
    var theme = enableDarkMode ? themeDark : themeLight;
    // Load fonts
    WebFont.load(theme.text.fontConfig);
    WebFont.load(theme.code.fontConfig);
    // Set DarkMode preference globally
    setCurrentDarkModePreference(enableDarkMode);
    // Add global CSS
    generateCSS(theme, enableDarkMode, globalCSS);
};
export { setTheme };
