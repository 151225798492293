var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { styled, theme, Box } from "@faharmony/theme";
import { faTimes } from "@faharmony/icons";
import { Text } from "../Text";
import { Icon } from "../Icon";
var getBackgroundColor = function (_a) {
    var _b = _a.color, color = _b === void 0 ? "default" : _b;
    if (color === "default")
        return theme.color.background.default;
    if (color === "secondary")
        return theme.color.background.secondary;
    return theme.color.background["".concat(color, "Secondary")];
};
var getTextColor = function (_a) {
    var _b = _a.color, color = _b === void 0 ? "default" : _b;
    if (color === "default")
        return theme.color.text.primary;
    if (color === "secondary")
        return theme.color.text.secondary;
    return theme.color.text["".concat(color, "Secondary")];
};
var getBorderColor = function (_a) {
    var _b = _a.color, color = _b === void 0 ? "default" : _b;
    if (color === "default")
        return theme.color.border.default;
    if (color === "secondary")
        return theme.color.border.inverse;
    return theme.color.border[color];
};
var StyledTag = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-height: ", ";\n  display: inline-block;\n  text-align: center;\n  width: max-content;\n  max-width: ", "px;\n  padding: ", " ", ";\n  vertical-align: bottom;\n  border-radius: ", ";\n  border: 1px solid ", ";\n  background-color: ", ";\n  color: ", ";\n  user-select: none;\n  svg {\n    align-self: center;\n  }\n  .fa-times {\n    cursor: pointer;\n  }\n"], ["\n  max-height: ", ";\n  display: inline-block;\n  text-align: center;\n  width: max-content;\n  max-width: ", "px;\n  padding: ", " ", ";\n  vertical-align: bottom;\n  border-radius: ", ";\n  border: 1px solid ", ";\n  background-color: ", ";\n  color: ", ";\n  user-select: none;\n  svg {\n    align-self: center;\n  }\n  .fa-times {\n    cursor: pointer;\n  }\n"])), function (p) { return (p.autoHeight ? "auto" : "20px"); }, function (p) { return (p.maxWidth ? p.maxWidth : 140); }, theme.spacing.none, theme.spacing.sm, theme.radius[100], getBorderColor, getBackgroundColor, getTextColor);
/**
 * Tag
 * ---
 * Used for purpose of displaying information or filters. The size matches h5 and p Text component.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.7.0
 *
 * @example
 * <Tag color="negative">Deprecated</Tag>
 */
var Tag = React.forwardRef(function (props, ref) { return (_jsx(StyledTag, __assign({ ref: ref, className: "Tag tag" }, props, { spacing: "xs" }, { children: _jsxs(Box, { children: [props.icon && _jsx(Icon, { icon: props.icon, size: props.iconSize ? props.iconSize : "sm" }), _jsx(Text, __assign({ truncate: true, variant: props.valueVariant ? props.valueVariant : "small" }, { children: props.value || props.children || "" })), props.onClose && (_jsx(Icon, { icon: faTimes, size: "sm", onClick: props.onClose, className: "closeIcon" }))] }) }))); });
export { Tag };
var templateObject_1;
