var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from "react";
import { localStorageKeys, getLocalStorageValue, setLocalStorageValue, } from "@faharmony/helpers";
import { useInterval, usePropState } from "@faharmony/helpers";
import { getTextDimensions } from "@faharmony/theme";
export var useMenuCollapse = function (isMenuCollapsedDefault) {
    var _a = __read(React.useState(getLocalStorageValue(localStorageKeys.menuCollapsed) === "true"), 2), isMenuCollapsed = _a[0], setMenuCollapsed = _a[1];
    // const isKeyPressed = useKeyPress(keyPressCombos.collapseNavigationMenu);
    // React.useEffect(() => {
    //   if (isKeyPressed) setMenuCollapsed((prev) => !prev);
    // }, [isKeyPressed]);
    React.useEffect(function () {
        setMenuCollapsed(isMenuCollapsedDefault ||
            getLocalStorageValue(localStorageKeys.menuCollapsed) === "true");
    }, [isMenuCollapsedDefault]);
    React.useEffect(function () {
        setLocalStorageValue(localStorageKeys.menuCollapsed, isMenuCollapsed.toString());
    }, [isMenuCollapsed]);
    var toggleMenuCollapse = React.useCallback(function () { return setMenuCollapsed(function (prev) { return !prev; }); }, []);
    return { isMenuCollapsed: isMenuCollapsed, toggleMenuCollapse: toggleMenuCollapse };
};
export var useVisibleText = function (targetRef, isMenuCollapsed, text, shortText) {
    var _a = __read(React.useState({
        isHidden: false,
        visibleText: text,
    }), 2), state = _a[0], setState = _a[1];
    var _b = __read(React.useState(0), 2), elementHeight = _b[0], setDimensions = _b[1];
    React.useLayoutEffect(function () {
        if (targetRef.current)
            setDimensions(targetRef.current.offsetHeight);
    }, [isMenuCollapsed]);
    React.useEffect(function () {
        var availableSpace = elementHeight - 60;
        var textLength = getTextDimensions(text, "h1").width;
        var shortTextLength = (shortText
            ? getTextDimensions(shortText, "h1")
            : { width: undefined }).width;
        if (isMenuCollapsed) {
            if (textLength > availableSpace) {
                if (shortText && shortTextLength) {
                    if (shortTextLength > availableSpace)
                        setState({ visibleText: shortText, isHidden: true });
                    else
                        setState({ visibleText: shortText, isHidden: false });
                }
                else
                    setState({ visibleText: text, isHidden: true });
            }
            else
                setState({ isHidden: false, visibleText: text });
        }
        else
            setState({ isHidden: false, visibleText: text });
    }, [elementHeight]);
    return state;
};
export var useCleanItems = function (items) {
    var _a = __read(React.useState(items), 2), cleanItems = _a[0], setItems = _a[1];
    React.useEffect(function () {
        setItems(function () {
            var newItems = items
                .filter(function (item) { return (item.id && item.id !== "" ? true : false); })
                .filter(function (value, index, self) {
                return self.map(function (x) { return x.id; }).indexOf(value.id) == index;
            });
            newItems = newItems.map(function (item) { return (__assign(__assign({}, item), { subItems: item.subItems
                    ? item.subItems
                        .filter(function (subItem) {
                        return subItem.id && subItem.id !== "" ? true : false;
                    })
                        .filter(function (value, index, self) {
                        return self.map(function (x) { return x.id; }).indexOf(value.id) == index;
                    })
                    : undefined })); });
            return newItems;
        });
    }, [items]);
    return cleanItems;
};
export var useFilterNavigationItems = function (items) {
    var _a = __read(usePropState(items), 2), filteredItems = _a[0], setItems = _a[1];
    var handleFiltering = React.useCallback(function (value) {
        var newItems = items.map(function (item) {
            var _a;
            return (__assign(__assign({}, item), { subItems: (_a = item.subItems) === null || _a === void 0 ? void 0 : _a.filter(function (subItem) {
                    return subItem.label.toLowerCase().includes(value.toLowerCase());
                }) }));
        });
        setItems(newItems);
    }, [items]);
    return { filteredItems: filteredItems, handleFiltering: handleFiltering };
};
export var useCheckLeadingIcon = function (items) {
    var _a = __read(React.useState(false), 2), showIcon = _a[0], setShowIcon = _a[1];
    React.useEffect(function () {
        if (items.filter(function (item) { return item.icon; }).length)
            setShowIcon(true);
        else
            setShowIcon(false);
    }, [items]);
    return showIcon;
};
var isItemSelected = function (item) {
    return item.isSelectedCallback ? item.isSelectedCallback() : false;
};
var itemSelectedId = function (item) {
    var selectedId = "";
    if (isItemSelected(item))
        selectedId = item.id;
    if (item.subItems)
        item.subItems.forEach(function (subItem) {
            if (isItemSelected(subItem))
                selectedId = subItem.id;
        });
    return selectedId;
};
var getDefaultSelectedId = function (items) {
    var selectedIds = items.map(itemSelectedId).filter(function (id) { return id !== ""; });
    if (selectedIds.length)
        return selectedIds[0];
    return "";
};
export var useSelected = function (items) {
    var _a = __read(React.useState(getDefaultSelectedId(items)), 2), selectedId = _a[0], setSelectedId = _a[1];
    var _b = __read(React.useState(window.location.hash), 2), windowLocation = _b[0], setLocation = _b[1];
    useInterval(function () { return setLocation(window.location.hash); }, 500);
    React.useEffect(function () {
        setSelectedId(getDefaultSelectedId(items));
    }, [items, windowLocation]);
    var handleItemSelect = React.useCallback(function (item) { return setSelectedId(item.id); }, []);
    return { selectedId: selectedId, handleItemSelect: handleItemSelect };
};
// Body
export var useSubItemSelected = function (item, selectedId) {
    var _a = __read(React.useState(false), 2), subItemSelected = _a[0], setSubItemSelected = _a[1];
    React.useEffect(function () {
        if (item.subItems) {
            var selectedSubItems = item.subItems.filter(function (subItem) { return subItem.id === selectedId; });
            setSubItemSelected(selectedSubItems.length ? true : false);
        }
        else
            setSubItemSelected(false);
    }, [item.subItems, selectedId]);
    return subItemSelected;
};
export var useExpandItem = function (item, subItemSelected, selectedId) {
    var _a = __read(React.useState(false), 2), canExpand = _a[0], setCanExpand = _a[1];
    var _b = __read(React.useState(item.id === selectedId && !!item.subItems), 2), isExpanded = _b[0], setIsExpanded = _b[1];
    React.useEffect(function () {
        setIsExpanded((subItemSelected || item.id === selectedId) && !!item.subItems);
    }, [subItemSelected, selectedId]);
    React.useEffect(function () {
        if (item.subItems && item.subItems.length)
            setCanExpand(true);
        else
            setCanExpand(false);
    }, [item.subItems]);
    var toggleExpand = React.useCallback(function () {
        setIsExpanded(function (prev) { return !prev; });
    }, []);
    return { canExpand: canExpand, isExpanded: isExpanded, toggleExpand: toggleExpand };
};
// COMMON
export var calcGridTemplateRows = function (props) {
    var isHeaderVisible = props.isHeaderVisible, isMenuCollapsed = props.isMenuCollapsed, actionsPresent = props.actionsPresent;
    var rows = "";
    if (isMenuCollapsed) {
        if (actionsPresent)
            rows += "max-content ";
        return (rows += "minmax(max-content, auto) minmax(auto,max-content)");
    }
    else {
        if (isHeaderVisible)
            rows += "max-content ";
        if (actionsPresent)
            rows += "max-content ";
        return (rows += "max-content 1fr max-content");
    }
    // if (!props.isHeaderVisible)
    //   return props.isMenuCollapsed ? "max-content 1fr" : "auto max-content";
    // else
    //   return props.isMenuCollapsed
    //     ? props.actionsPresent
    //       ? "max-content max-content auto"
    //       : "max-content auto"
    //     : "max-content auto max-content";
};
