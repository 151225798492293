var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled, theme, Box } from "@faharmony/theme";
import { Tooltip } from "@faharmony/components";
import { useRef } from "react";
export var defaultWidth = 100;
var getBackgroundColor = function (_a) {
    var _b = _a.state, state = _b === void 0 ? "inactive" : _b, _c = _a.completed, completed = _c === void 0 ? false : _c;
    var backgroundColor;
    switch (state) {
        case "active":
            backgroundColor = completed
                ? theme.color.background.positive
                : theme.color.background.inverse;
            break;
        case "disabled":
            backgroundColor = completed ? theme.color.background.disabled : "none";
            break;
        default:
            // Default inactive
            backgroundColor = "none";
    }
    return backgroundColor;
};
var getBorderColor = function (_a) {
    var _b = _a.state, state = _b === void 0 ? "inactive" : _b, _c = _a.completed, completed = _c === void 0 ? false : _c;
    var borderColor;
    switch (state) {
        case "active":
            borderColor = completed
                ? theme.color.border.positive
                : theme.color.border.selected;
            break;
        case "disabled":
            borderColor = theme.color.border.default;
            break;
        default:
            // Default inactive
            borderColor = completed
                ? theme.color.border.positive
                : theme.color.border.selected;
    }
    return borderColor;
};
var getTextColor = function (state) {
    if (state === void 0) { state = "inactive"; }
    var textColor;
    switch (state) {
        case "active":
            textColor = "primary";
            break;
        case "disabled":
            textColor = "disabled";
            break;
        default:
            // Default inactive
            textColor = "secondary";
    }
    return textColor;
};
// @ts-ignore
var StyledStepperIndicator = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: static;\n  left: 0px;\n  top: 0px;\n  display: block;\n  height: 4px;\n  border-radius: ", ";\n  background-color: ", ";\n  border: 1px solid ", ";\n  align-self: stretch;\n  margin: 4px 0px;\n  flex: none;\n  flex-grow: 0;\n  order: 0;\n"], ["\n  position: static;\n  left: 0px;\n  top: 0px;\n  display: block;\n  height: 4px;\n  border-radius: ", ";\n  background-color: ", ";\n  border: 1px solid ", ";\n  align-self: stretch;\n  margin: 4px 0px;\n  flex: none;\n  flex-grow: 0;\n  order: 0;\n"])), theme.radius[100], getBackgroundColor, getBorderColor);
// @ts-ignore
var StyledBox = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: 2.5px !important;\n  margin-right: 2.5px !important;\n  min-width: ", "px;\n  max-width: ", "px;\n  align-self: flex-start;\n"], ["\n  margin-left: 2.5px !important;\n  margin-right: 2.5px !important;\n  min-width: ", "px;\n  max-width: ", "px;\n  align-self: flex-start;\n"])), function (p) { return p.width; }, function (p) { return p.width; });
var StyledStepperLabel = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: block;\n  /* Fallback for non-webkit */\n  display: -webkit-box;\n  width: 100%;\n  color: ", ";\n  overflow: hidden !important;\n  text-align: center;\n  vertical-align: baseline;\n\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n"], ["\n  display: block;\n  /* Fallback for non-webkit */\n  display: -webkit-box;\n  width: 100%;\n  color: ", ";\n  overflow: hidden !important;\n  text-align: center;\n  vertical-align: baseline;\n\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n"])), function (p) { return theme.color.text[p.color]; });
/**
 * Step
 * ----
 */
var Step = function (props) {
    var _a, _b;
    var textRef = useRef(null);
    var scrollHeight = (_a = textRef === null || textRef === void 0 ? void 0 : textRef.current) === null || _a === void 0 ? void 0 : _a.scrollHeight;
    var offsetHeight = (_b = textRef === null || textRef === void 0 ? void 0 : textRef.current) === null || _b === void 0 ? void 0 : _b.offsetHeight;
    var isTextOverflow = scrollHeight && offsetHeight && scrollHeight > offsetHeight;
    var styledStep = (
    // @ts-ignore
    _jsxs(StyledBox, __assign({}, props, { direction: "column", spacing: "none" }, { children: [_jsx(StyledStepperIndicator, __assign({}, props, { className: "step" })), _jsx(StyledStepperLabel, __assign({ color: getTextColor(props.state), ref: textRef }, { children: props.label }))] })));
    return isTextOverflow ? (_jsx(Tooltip, __assign({ value: props.label }, { children: styledStep }))) : (styledStep);
};
export { Step };
var templateObject_1, templateObject_2, templateObject_3;
