var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useMutation, gql } from "@apollo/client";
import { addToast } from "../../Toast";
import { taskManagerActions } from "../state";
var CLAIM_PROCESS_TASK = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation claimProcessTask($taskId: String) {\n    claimProcessTask(taskId: $taskId) {\n      assignee\n      attachments\n      candidateGroups\n      candidateUsers\n      category\n      claimTime\n      createTime\n      data\n      description\n      dueDate\n      formDefinition\n      formDefinitionJson\n      formTranslationsJson\n      formKey\n      id\n      name\n      owner\n      parentTaskId\n      priority\n      processDefinitionId\n      processInstanceId\n      taskDefinitionKey\n      taskId\n      processInitiator {\n        firstName\n        lastName\n        userId\n      }\n      taskAssignee {\n        firstName\n        lastName\n        userId\n      }\n    }\n  }\n"], ["\n  mutation claimProcessTask($taskId: String) {\n    claimProcessTask(taskId: $taskId) {\n      assignee\n      attachments\n      candidateGroups\n      candidateUsers\n      category\n      claimTime\n      createTime\n      data\n      description\n      dueDate\n      formDefinition\n      formDefinitionJson\n      formTranslationsJson\n      formKey\n      id\n      name\n      owner\n      parentTaskId\n      priority\n      processDefinitionId\n      processInstanceId\n      taskDefinitionKey\n      taskId\n      processInitiator {\n        firstName\n        lastName\n        userId\n      }\n      taskAssignee {\n        firstName\n        lastName\n        userId\n      }\n    }\n  }\n"])));
function useClaimProcessTask(taskId, shouldBeAssignedTo) {
    if (shouldBeAssignedTo === void 0) { shouldBeAssignedTo = ""; }
    var _a = __read(useMutation(CLAIM_PROCESS_TASK, {
        variables: {
            taskId: taskId,
        },
        onCompleted: function (data) {
            taskManagerActions.updateTask(data.claimProcessTask);
            if (shouldBeAssignedTo &&
                data.claimProcessTask.assignee !== shouldBeAssignedTo) {
                addToast({
                    id: "claim-process-error",
                    title: "Error",
                    description: "Failed to reassign task to you.",
                    variant: "negative",
                    persist: true,
                });
            }
        },
        onError: function (error) {
            console.error(error);
            addToast({
                id: "claim-process-error",
                title: "Error",
                description: error.message,
                variant: "negative",
                persist: true,
            });
        },
    }), 1), claimProcessTask = _a[0];
    return {
        claimProcessTask: claimProcessTask,
    };
}
export default useClaimProcessTask;
var templateObject_1;
