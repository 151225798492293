var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { gql, useQuery } from "@faharmony/service";
import { addToast } from "../../Toast";
import { useSnapshot } from "valtio";
import { state } from "../state";
var GET_SINGLE_PROCESS_INSTANCES = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetSingleProcessInstances($id: String!) {\n    processInstances(\n      queryParams: { processInstanceId: $id }\n      maxCount: 1\n      category: \"\"\n    ) {\n      processInstance {\n        id\n        diagram\n        processDefinitionDescription\n        processDefinitionName\n        startTime\n      }\n    }\n  }\n"], ["\n  query GetSingleProcessInstances($id: String!) {\n    processInstances(\n      queryParams: { processInstanceId: $id }\n      maxCount: 1\n      category: \"\"\n    ) {\n      processInstance {\n        id\n        diagram\n        processDefinitionDescription\n        processDefinitionName\n        startTime\n      }\n    }\n  }\n"])));
var GET_TASK_DUE_DATE = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query GetTaskDueDate($processInstanceId: String, $taskDefinitionKey: String) {\n    processTasks(\n      queryParams: {\n        processInstanceId: $processInstanceId\n        taskDefinitionKey: $taskDefinitionKey\n      }\n    ) {\n      processTask {\n        taskId\n        dueDate\n      }\n    }\n  }\n"], ["\n  query GetTaskDueDate($processInstanceId: String, $taskDefinitionKey: String) {\n    processTasks(\n      queryParams: {\n        processInstanceId: $processInstanceId\n        taskDefinitionKey: $taskDefinitionKey\n      }\n    ) {\n      processTask {\n        taskId\n        dueDate\n      }\n    }\n  }\n"])));
export var useGetTaskAndProcessInstance = function (_a) {
    var _b;
    var processInstanceId = _a.processInstanceId, taskDefinitionKey = _a.taskDefinitionKey, taskId = _a.taskId;
    useSnapshot(state.taskManager);
    var _c = useQuery(GET_TASK_DUE_DATE, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
        variables: {
            taskDefinitionKey: taskDefinitionKey,
            processInstanceId: processInstanceId,
        },
        onCompleted: function (data) {
            var task = data === null || data === void 0 ? void 0 : data.processTasks.processTask.find(function (task) { return task.taskId === taskId; });
            if (task) {
                Object.entries(state.taskManager).some(function (_a) {
                    var _b = __read(_a, 2), key = _b[0], taskManagerState = _b[1];
                    var targetTask = taskManagerState.tasks.findIndex(function (ta) { return ta.taskId === taskId; });
                    if (targetTask >= 0) {
                        state.taskManager[key].tasks[targetTask].dueDate = task.dueDate;
                        return true;
                    }
                    return false;
                });
            }
        },
        onError: function (error) {
            addToast({
                id: "tasks-error",
                title: "Error, could not get task information",
                description: error.message,
                variant: "negative",
                persist: true,
            });
        },
    }), taskData = _c.data, taskLoading = _c.loading, taskError = _c.error;
    var _d = useQuery(GET_SINGLE_PROCESS_INSTANCES, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
        variables: {
            id: processInstanceId,
        },
        onError: function (error) {
            addToast({
                id: "process-error",
                title: "Error, could not workflow information",
                description: error.message,
                variant: "negative",
                persist: true,
            });
        },
    }), processData = _d.data, processLoading = _d.loading, processError = _d.error;
    return {
        processInstance: (processData === null || processData === void 0 ? void 0 : processData.processInstances.processInstance.length)
            ? processData.processInstances.processInstance[0]
            : undefined,
        taskDueDate: (_b = taskData === null || taskData === void 0 ? void 0 : taskData.processTasks.processTask.find(function (task) { return task.taskId === taskId; })) === null || _b === void 0 ? void 0 : _b.dueDate,
        error: processError || taskError,
        loading: processLoading || taskLoading,
    };
};
var templateObject_1, templateObject_2;
