var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Text, Divider, Toolbar } from "@faharmony/components";
import { Breadcrumbs, } from "@faharmony/navigation";
import { StyledHeader, StyledTitle } from "./style";
var getCrumbs = function (caption) {
    var crumbs = caption
        ? typeof caption === "string"
            ? [
                {
                    id: "default",
                    label: caption,
                },
            ]
            : caption
        : [];
    return crumbs;
};
var Title = function (props) {
    var caption = props.caption, heading = props.heading, actions = props.actions;
    var _a = __read(React.useState(getCrumbs(caption)), 1), crumbs = _a[0];
    return (_jsxs(StyledTitle, __assign({ isCaption: crumbs.length > 0 }, { children: [_jsxs("div", __assign({ className: "PageView-header-title" }, { children: [crumbs.length > 0 && _jsx(Breadcrumbs, { crumbs: crumbs, variant: "strong" }), _jsx(Text, { value: heading, variant: "h2", truncate: true })] })), _jsx("div", __assign({ className: "PageView-header-actions" }, { children: actions }))] })));
};
export var Header = function (props) {
    var toolbarContent = props.toolbarContent, tabs = props.tabs, TabBar = props.TabBar, tabOrientation = props.tabOrientation;
    return (_jsxs(StyledHeader, __assign({ className: "PageView-header" }, { children: [!props.mui && _jsx(Title, __assign({}, props)), !props.mui && toolbarContent && _jsxs(Toolbar, { children: [toolbarContent, " "] }), tabs && tabOrientation !== "vertical" ? _jsx(TabBar, {}) : _jsx(Divider, {})] })));
};
