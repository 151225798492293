var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useKeycloak } from "@faharmony/service";
import { i18n } from "@faharmony/locale";
// If admin, show the module
// If there are no roles mentioned, show the module.
// If roles are present, then only show to those who have roles
export var useCheckUserForRoles = function (roles) {
    var _a = useKeycloak(), isAdmin = _a.isAdmin, hasClientRoles = _a.hasClientRoles;
    return (isAdmin || (roles ? (!!roles.length ? hasClientRoles(roles) : true) : true));
};
export var useCheckUserForRolesCallback = function () {
    var _a = useKeycloak(), isAdmin = _a.isAdmin, hasClientRoles = _a.hasClientRoles;
    return function (roles) {
        return isAdmin || (roles ? (!!roles.length ? hasClientRoles(roles) : true) : true);
    };
};
export var combineModuleRoles = function (Modules) {
    var allRoles = [];
    Modules.forEach(function (Module) {
        var mRoles = Module.roles ? Module.roles : [];
        allRoles = __spreadArray(__spreadArray([], __read(allRoles), false), __read(mRoles), false);
    });
    allRoles = allRoles.filter(function (item, pos) { return allRoles.indexOf(item) === pos; });
    return allRoles;
};
export var getI18nValue = function (i18next, id, key, fallback) {
    if (fallback === void 0) { fallback = ""; }
    return (i18n === null || i18n === void 0 ? void 0 : i18n.exists("".concat(id, ":").concat(key))) && i18next
        ? i18next.t("".concat(id, ":").concat(key))
        : fallback || key;
};
