var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled, theme } from "@faharmony/theme";
import { titleHeight } from "../common";
export var StyledView = styled.main(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  margin: 0;\n  height: 100%;\n  width: 100%;\n  max-width: 100vw;\n  min-width: 300px;\n  padding: 0;\n  display: grid;\n  grid-template-rows: max-content 1fr ", ";\n  /* gap: ", "; */\n  overflow: hidden;\n  background-color: ", ";\n  box-shadow: ", ";\n  z-index: ", ";\n"], ["\n  position: relative;\n  margin: 0;\n  height: 100%;\n  width: 100%;\n  max-width: 100vw;\n  min-width: 300px;\n  padding: 0;\n  display: grid;\n  grid-template-rows: max-content 1fr ", ";\n  /* gap: ", "; */\n  overflow: hidden;\n  background-color: ", ";\n  box-shadow: ", ";\n  z-index: ", ";\n"])), function (p) { return p.hasFooter && "max-content"; }, theme.spacing.md, theme.color.background.default, theme.shadow.large, theme.zIndex.base);
export var StyledHeader = styled.header(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: fit-content;\n  padding: ", " ", ";\n"], ["\n  width: 100%;\n  height: fit-content;\n  padding: ", " ", ";\n"])), theme.spacing.md, theme.spacing.md);
export var StyledTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  overflow: inherit;\n  min-height: ", "px;\n  padding: 0 ", ";\n  display: flex;\n  flex-direction: row;\n  width:100%;\n  margin-bottom: 8px;\n  align-items: flex-start;\n  justify-content: space-between;\n}\n  .TextContainer {\n    margin-top: ", ";\n    margin-bottom: 8px;\n  }\n\n  .PageView-header-actions {\n    margin-top: 8px;\n    height: 100%;\n    display: flex;\n    align-items: center;\n  }\n"], ["\n  overflow: inherit;\n  min-height: ", "px;\n  padding: 0 ", ";\n  display: flex;\n  flex-direction: row;\n  width:100%;\n  margin-bottom: 8px;\n  align-items: flex-start;\n  justify-content: space-between;\n}\n  .TextContainer {\n    margin-top: ", ";\n    margin-bottom: 8px;\n  }\n\n  .PageView-header-actions {\n    margin-top: 8px;\n    height: 100%;\n    display: flex;\n    align-items: center;\n  }\n"])), titleHeight, theme.spacing.md, function (p) { return (p.isCaption ? 0 : theme.spacing.md); });
export var StyledBody = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  height: initial;\n  overflow: ", ";\n  padding: ", ";\n  padding-top: 0px;\n"], ["\n  position: relative;\n  width: 100%;\n  height: initial;\n  overflow: ", ";\n  padding: ", ";\n  padding-top: 0px;\n"])), function (p) { return p.overflow || "hidden"; }, theme.spacing.md);
export var StyledFooter = styled.footer(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  height: auto;\n  padding: 0 ", " ", ";\n  display: grid;\n  gap: ", ";\n  grid-template-rows: repeat(2, max-content);\n"], ["\n  width: 100%;\n  height: auto;\n  padding: 0 ", " ", ";\n  display: grid;\n  gap: ", ";\n  grid-template-rows: repeat(2, max-content);\n"])), theme.spacing.xl, theme.spacing.md, theme.spacing.md);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
