var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { styled, theme, Box } from "@faharmony/theme";
import { Text, Button } from "@faharmony/components";
import { faChevronDown, faChevronRight, faTimesCircle } from "@faharmony/icons";
var headerHeight = "calc(".concat(theme.spacing.md, " * 5)");
var StyledContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-shadow: ", ";\n  border: 1px solid ", ";\n  border-radius: ", ";\n  height: 100%;\n  max-height: 100%;\n  max-width: 100%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  background-color: ", ";\n"], ["\n  box-shadow: ", ";\n  border: 1px solid ", ";\n  border-radius: ", ";\n  height: 100%;\n  max-height: 100%;\n  max-width: 100%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  background-color: ", ";\n"])), theme.shadow.default, theme.color.border.default, theme.radius[500], theme.color.background.default);
var StyledHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-bottom: 1px solid ", ";\n  padding: ", " ", ";\n  min-height: ", ";\n  width: 100%;\n  display: grid;\n  grid-template-columns: minmax(0, auto) max-content;\n  background-color: transparent;\n  gap: ", ";\n  .ContentViewHeaderTitle {\n    margin: ", " 0;\n  }\n  .ContentViewHeaderAdditionalContent {\n    margin-top: 4px;\n  }\n"], ["\n  border-bottom: 1px solid ", ";\n  padding: ", " ", ";\n  min-height: ", ";\n  width: 100%;\n  display: grid;\n  grid-template-columns: minmax(0, auto) max-content;\n  background-color: transparent;\n  gap: ", ";\n  .ContentViewHeaderTitle {\n    margin: ", " 0;\n  }\n  .ContentViewHeaderAdditionalContent {\n    margin-top: 4px;\n  }\n"])), theme.color.border.default, theme.spacing.sm, theme.spacing.md, headerHeight, theme.spacing.xl, "calc(".concat(theme.spacing.sm, " + ").concat(theme.spacing.xs, ")"));
var StyledBody = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  padding: 0;\n  flex: auto;\n  height: initial;\n  width: 100%;\n"], ["\n  position: relative;\n  padding: 0;\n  flex: auto;\n  height: initial;\n  width: 100%;\n"])));
var StyledFooter = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-top: 1px solid ", ";\n  padding: ", " ", ";\n  width: \"100%\";\n  height: ", ";\n  min-height: ", ";\n  background-color: transparent;\n"], ["\n  border-top: 1px solid ", ";\n  padding: ", " ", ";\n  width: \"100%\";\n  height: ", ";\n  min-height: ", ";\n  background-color: transparent;\n"])), theme.color.border.default, theme.spacing.sm, theme.spacing.md, headerHeight, headerHeight);
/**
 * ContextView
 * ---
 * Content View can be placed inside other Views to encapsulate or differentiate between various content.
 * It can be also used as base for Modals. When using inside modal, pass stretchHeight={true} param if modal needs to use maximum height.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.5.0
 * @example
 * <ContentView headerTitle="Heading"> {content} </ContentView>
 */
var ContentView = function (_a) {
    var id = _a.id, headerTitle = _a.headerTitle, _b = _a.headerHidden, headerHidden = _b === void 0 ? false : _b, children = _a.children, headerActions = _a.headerActions, footerContent = _a.footerContent, _c = _a.overflow, overflow = _c === void 0 ? "auto" : _c, _d = _a.containerProps, containerProps = _d === void 0 ? {} : _d, _e = _a.style, style = _e === void 0 ? {} : _e, _f = _a.headerAdditionalContentToggleButtonEnabled, headerAdditionalContentToggleButtonEnabled = _f === void 0 ? false : _f, _g = _a.headerAdditionalContentExpandedByDefault, headerAdditionalContentExpandedByDefault = _g === void 0 ? false : _g, headerAdditionalContent = _a.headerAdditionalContent, onClose = _a.onClose;
    var _h = __read(useState(headerAdditionalContentExpandedByDefault), 2), headerAdditionalContentExpanded = _h[0], setHeaderAdditionalContentExpanded = _h[1];
    var closeButtonObject = onClose
        ? {
            id: id ? "close-summary-view-".concat(id) : "close-summary-view",
            onClick: onClose,
            icon: faTimesCircle,
            tooltip: "Close",
            spacing: "sm",
            outline: true,
        }
        : undefined;
    return (_jsxs(StyledContainer, __assign({ id: id, className: "ContentView" }, containerProps, { children: [headerHidden ? null : (_jsxs(StyledHeader, __assign({ className: "ContentViewHeader" }, { children: [_jsxs("div", __assign({ className: "ContentViewHeaderTitle" }, { children: [_jsxs(Box, { children: [_jsx("div", { children: typeof headerTitle === "string" ? (_jsx(Text, { value: headerTitle, variant: "h5", truncate: true })) : (headerTitle) }), headerAdditionalContentToggleButtonEnabled && (_jsx(Button, { icon: headerAdditionalContentExpanded
                                            ? faChevronDown
                                            : faChevronRight, spacing: "sm", id: id ? "".concat(id, "ExpandContentViewAdditionalContent") : "", onClick: function () {
                                            return setHeaderAdditionalContentExpanded(!headerAdditionalContentExpanded);
                                        } }))] }), (!headerAdditionalContentToggleButtonEnabled ||
                                headerAdditionalContentExpanded) &&
                                headerAdditionalContent ? (_jsx("div", __assign({ className: "ContentViewHeaderAdditionalContent" }, { children: headerAdditionalContent }))) : null] })), _jsxs(Box, { children: [_jsx("div", __assign({ className: "ContentViewHeaderActions" }, { children: headerActions })), closeButtonObject && _jsx(Button, __assign({}, closeButtonObject))] })] }))), _jsx(StyledBody, __assign({ className: "ContentViewBody", style: __assign({ overflow: overflow }, style) }, { children: children })), footerContent && (_jsx(StyledFooter, __assign({ className: "ContentViewFooter" }, { children: footerContent })))] })));
};
export { ContentView };
export default ContentView;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
