var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState, useCallback, useRef } from "react";
import { usePrevious } from "../state/usePrevious";
var defaultOptions = {
    cancelOnUnmount: true,
};
/**
 * An async-utility hook that accepts a callback function and a delay time (in milliseconds), then delays the
 * execution of the given function by the defined time. It persists over renders.
 * @author Antonio Rù <> (https://github.com/antonioru)
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @example
 *   const [showContent, setShowContent] = useState(false);
 *   useTimeout(() => setShowContent(true), 5000);
 */
export var useTimeout = function (fn, milliseconds, options) {
    if (options === void 0) { options = defaultOptions; }
    var opts = __assign(__assign({}, defaultOptions), (options || {}));
    var timeout = useRef();
    var callback = useRef(fn);
    var _a = __read(useState(false), 2), isCleared = _a[0], setIsCleared = _a[1];
    // the clear method
    var clear = useCallback(function () {
        if (timeout.current) {
            clearTimeout(timeout.current);
            setIsCleared(true);
        }
    }, []);
    // if the provided function changes, change its reference
    useEffect(function () {
        if (typeof fn === "function") {
            callback.current = fn;
        }
    }, [fn]);
    // when the milliseconds change, reset the timeout
    useEffect(function () {
        if (typeof milliseconds === "number") {
            timeout.current = setTimeout(function () {
                callback.current();
            }, milliseconds);
        }
    }, [milliseconds]);
    // when component unmount clear the timeout
    useEffect(function () { return function () {
        if (opts.cancelOnUnmount) {
            clear();
        }
    }; }, []);
    return [isCleared, clear];
};
var defaultOptionsConditionally = {
    cancelOnUnmount: true,
    cancelOnConditionChange: true,
};
/**
 * An async-utility hook that accepts a callback function and a delay time (in milliseconds), then delays the
 * execution of the given function by the defined time from when the condition verifies. It persists over renders.
 * @author Antonio Rù <> (https://github.com/antonioru)
 * @author Lindsay89 <> (https://github.com/Lindsay89)
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @example
 *   const [showContent, setShowContent] = useState(false);
 *   const [condition,setCondition] = useState(false);
 *   useTimeoutConditionally(() => setShowContent(true), 5000, condition);
 *   return <Button onClick={()=>setCondition(true)}>Click</Button> ;
 */
export var useTimeoutConditionally = function (fn, milliseconds, condition, options) {
    if (options === void 0) { options = defaultOptionsConditionally; }
    var opts = __assign(__assign({}, defaultOptionsConditionally), (options || {}));
    var timeout = useRef();
    var callback = useRef(fn);
    var _a = __read(useState(false), 2), isCleared = _a[0], setIsCleared = _a[1];
    var prevCondition = usePrevious(condition);
    // the clear method
    var clear = useCallback(function () {
        if (timeout.current) {
            clearTimeout(timeout.current);
            setIsCleared(true);
        }
    }, []);
    // if the provided function changes, change its reference
    useEffect(function () {
        if (typeof fn === "function") {
            callback.current = fn;
        }
    }, [fn]);
    // when the milliseconds change, reset the timeout
    useEffect(function () {
        if (condition && typeof milliseconds === "number") {
            timeout.current = setTimeout(function () {
                callback.current();
            }, milliseconds);
        }
    }, [condition, milliseconds]);
    // when the condition change, clear the timeout
    useEffect(function () {
        if (prevCondition &&
            condition !== prevCondition &&
            opts.cancelOnConditionChange) {
            clear();
        }
    }, [condition, options]);
    // when component unmount clear the timeout
    useEffect(function () { return function () {
        if (opts.cancelOnUnmount) {
            clear();
        }
    }; }, []);
    return [isCleared, clear];
};
