var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useCallback, useState, useEffect, useRef, } from "react";
import BaseDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCurrentLocale, formatDate } from "@faharmony/locale";
import { faCalendarAlt } from "@faharmony/icons";
import { Button } from "@faharmony/components";
import { useFormContext } from "../hook-form";
import { InputField } from "../BaseInputField";
import { renderCustomHeader, renderDayContents, calendarContainer, checkIsTodayDisabled, popperContainer, } from "./utilities";
import { TimeInput } from "./CustomTimePicker";
export * from "./DateRangePicker";
export var dateFormats = {
    "en-GB": "dd/MM/yyyy",
    "en-US": "MM/dd/yyyy",
    "fi-FI": "dd.MM.yyyy",
    "sv-SE": "yyyy-MM-dd",
};
export var dateTimeFormats = {
    "en-GB": "dd/MM/yyyy HH:mm",
    "en-US": "MM/dd/yyyy hh:mm a",
    "fi-FI": "dd.MM.yyyy HH:mm",
    "sv-SE": "yyyy-MM-dd HH:mm",
};
/**
 * DatePicker
 * ---
 * Form input to pick date.
 * @jira https://fasolutions.jira.com/browse/HAR-126
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @copyright FA Solutions Oy
 * @version 1.8.0
 * @example <DatePicker selected={new Date()} onDateChange={console.log} />
 */
var DatePicker = forwardRef(function (props, ref) {
    var locale = getCurrentLocale();
    var defaultDateFormat = dateFormats[locale] || dateFormats["en-GB"];
    var defaultDateTimeFormat = dateTimeFormats[locale] || dateTimeFormats["en-GB"];
    var _a = props.selected, selected = _a === void 0 ? new Date() : _a, onDateChange = props.onDateChange, onChange = props.onChange, _b = props.dateFormat, dateFormat = _b === void 0 ? props.showCustomTimeSelector
        ? defaultDateTimeFormat
        : defaultDateFormat : _b, includeDates = props.includeDates, excludeDates = props.excludeDates, 
    // InputField
    spacing = props.spacing, onInputClear = props.onInputClear, prefixIcon = props.prefixIcon, prefixText = props.prefixText, success = props.success, error = props.error, isLoading = props.isLoading, textAlign = props.textAlign, _c = props.hideTodayButton, hideTodayButton = _c === void 0 ? props.hideTodayButton : _c, 
    // Rest
    rest = __rest(props, ["selected", "onDateChange", "onChange", "dateFormat", "includeDates", "excludeDates", "spacing", "onInputClear", "prefixIcon", "prefixText", "success", "error", "isLoading", "textAlign", "hideTodayButton"]);
    var _d = __read(useState(selected), 2), selectedDate = _d[0], setSelectedDate = _d[1];
    var handleChange = useCallback(function (date, event) {
        if (date instanceof Date && !props.showCustomTimeSelector) {
            date = new Date("".concat(date.getFullYear(), "-").concat(date.getMonth() <= 8
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1, "-").concat(date.getDate() <= 9 ? "0" + date.getDate() : date.getDate()));
        }
        if (onChange) {
            onChange(date, event);
        }
        if (onDateChange && date && "getMonth" in date) {
            setSelectedDate(date);
            onDateChange(date);
        }
    }, [onDateChange, onChange]);
    var todayButton = (_jsx(Button, { value: "Today", spacing: "xs", disabled: checkIsTodayDisabled(includeDates, excludeDates) }));
    var inputField = (_jsx(InputField, __assign({}, {
        spacing: spacing,
        onInputClear: onInputClear,
        prefixIcon: prefixIcon,
        prefixText: prefixText,
        success: success,
        error: error,
        isLoading: isLoading,
        textAlign: textAlign,
    }, { suffixIcon: faCalendarAlt })));
    var datePickerRef = ref !== null && ref !== void 0 ? ref : useRef(null);
    var onKeyDown = useCallback(function (e) {
        var _a;
        if (e && e.key === "Tab") {
            {
                /* @ts-ignore */
            }
            (_a = datePickerRef === null || datePickerRef === void 0 ? void 0 : datePickerRef.current) === null || _a === void 0 ? void 0 : _a.setOpen(false);
        }
    }, []);
    return (_jsx(BaseDatePicker, __assign({ showWeekNumbers: true, selected: props.selectsStart || props.selectsEnd ? props.selected : selectedDate, onChange: onChange || handleChange, dateFormat: dateFormat, placeholderText: dateFormat.toUpperCase(), strictParsing: true, showPopperArrow: false, includeDates: includeDates, excludeDates: excludeDates }, rest, { todayButton: hideTodayButton ? undefined : todayButton, customInput: props.customInput ? props.customInput : inputField, renderCustomHeader: renderCustomHeader, shouldCloseOnSelect: !props.showCustomTimeSelector && props.shouldCloseOnSelect, renderDayContents: renderDayContents, calendarContainer: !props.showPopperArrow ? calendarContainer : undefined, popperContainer: props.showPopperArrow ? popperContainer : undefined, ref: datePickerRef, portalId: "react-datepicker-portal", popperModifiers: props.popperModifiers
            ? props.popperModifiers
            : [{ name: "offset", enabled: false }], showTimeInput: props.showCustomTimeSelector || props.showTimeInput, customTimeInput: props.showCustomTimeSelector && (_jsx(TimeInput, { maxDate: props.maxDate, minDate: props.minDate })), onKeyDown: onKeyDown })));
});
export { DatePicker };
/**
 * Hook to generate props for DatePicker component used inside a Form.
 * @param name Field name
 * @param defaultDate Default date if any
 * @returns Props for DatePicker component
 * @example
 * ```tsx
 * const formDatePickerProps = useFormDatePickerProps("dob");
 * return <DatePicker {...formDatePickerProps} />
 * ```
 */
export function useFormDatePickerProps(name, defaultDate) {
    var context = useFormContext();
    if (!context) {
        throw new Error("The hook 'useFormDatePickerProps' can only be inside a Form component.");
    }
    var register = context.register, setValue = context.setValue;
    var _a = __read(useState(defaultDate ? new Date(defaultDate) : undefined), 2), selectedDate = _a[0], setSelectedDate = _a[1];
    useEffect(function () {
        register(name);
    }, [name, register]);
    useEffect(function () {
        if (selectedDate)
            setValue(name, formatDate(selectedDate));
    }, [name, selectedDate, setValue]);
    return { name: name, selected: selectedDate, onDateChange: setSelectedDate };
}
