var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useCallback, useRef } from "react";
import { createPortal } from "react-dom";
import { styled } from "@faharmony/theme";
import { theme } from "@faharmony/theme";
import { useOnClickOutside } from "@faharmony/helpers";
var StyledDiv = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: ", ";\n  background-color: ", ";\n\n  .modalContainer {\n    position: relative;\n    z-index: ", ";\n    overflow: auto;\n    min-width: 320px;\n    max-width: calc(100vw - ", ");\n    max-height: calc(100vh - ", ");\n    background-color: ", ";\n    border-radius: ", ";\n    box-shadow: ", ";\n  }\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: ", ";\n  background-color: ", ";\n\n  .modalContainer {\n    position: relative;\n    z-index: ", ";\n    overflow: auto;\n    min-width: 320px;\n    max-width: calc(100vw - ", ");\n    max-height: calc(100vh - ", ");\n    background-color: ", ";\n    border-radius: ", ";\n    box-shadow: ", ";\n  }\n"])), theme.zIndex.overlay, theme.color.background.overlay, theme.zIndex.modal, theme.spacing.xl, theme.spacing.xl, theme.color.background.primary, theme.radius[500], theme.shadow.float);
// eslint-disable-next-line react/display-name
export var Modal = React.forwardRef(function (props, ref) {
    var children = props.children, _a = props.isOpen, isOpen = _a === void 0 ? false : _a, _b = props.style, style = _b === void 0 ? {} : _b;
    if (isOpen === false) {
        return null;
    }
    return createPortal(_jsx(StyledDiv, __assign({ className: "modalWrapper" }, { children: _jsx("div", __assign({ className: "modalContainer", ref: ref, style: style }, { children: children })) })), document.getElementsByTagName("body")[0]);
});
/**
 * useModal
 * ---
 * Hook to create FA style Modals
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.5.0
 * @param closeOnClickOutside Pass false if modal should not close when clicked outside. By default, it is true.
 * @return [ModalWrapper, openModalFn, closeModalFn, isModalOpen]
 * - ModalWrapper - Wrap the Modal content with this element
 * - openModalFn - Callback to open the modal
 * - closeModalFn - Callback to close the modal
 * - isModalOpen - Boolean value to tell if modal is currently open
 *
 * ---
 *
 * @example
 * const [ModalWrapper, openModalFn, closeModalFn, isModalOpen] = useModal();
 */
export var useModal = function (closeOnClickOutside) {
    if (closeOnClickOutside === void 0) { closeOnClickOutside = true; }
    var ref = useRef(null);
    var _a = __read(useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    var openModalFn = useCallback(function () { return setOpen(true); }, [setOpen]);
    var closeModalFn = useCallback(function () { return setOpen(false); }, [setOpen]);
    useOnClickOutside(ref, function () { return closeOnClickOutside && setOpen(false); });
    var ModalWrapper = useCallback(function (props) {
        return (_jsx(Modal, __assign({ isOpen: isOpen, ref: ref, style: props.style }, { children: props.children })));
    }, [isOpen]);
    return [ModalWrapper, openModalFn, closeModalFn, isOpen];
};
var templateObject_1;
