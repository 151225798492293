var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled, theme } from "@faharmony/theme";
export var StyledView = styled.aside(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0;\n  height: 100%;\n  max-height: 100vh;\n  width: 100%;\n  max-width: 100vw;\n  min-width: 240px;\n  padding: ", ";\n  display: grid;\n  gap: ", ";\n  grid-template-rows: max-content 1fr ", ";\n  overflow: hidden;\n  background-color: ", ";\n  box-shadow: ", ";\n  z-index: ", ";\n"], ["\n  margin: 0;\n  height: 100%;\n  max-height: 100vh;\n  width: 100%;\n  max-width: 100vw;\n  min-width: 240px;\n  padding: ", ";\n  display: grid;\n  gap: ", ";\n  grid-template-rows: max-content 1fr ", ";\n  overflow: hidden;\n  background-color: ", ";\n  box-shadow: ", ";\n  z-index: ", ";\n"])), theme.spacing.md, theme.spacing.md, function (p) { return p.hasFooter && "max-content"; }, theme.color.background.primary, theme.shadow.default, theme.zIndex.docked);
export var StyledHead = styled.header(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 8px;\n  height: fit-content;\n\n  .SummaryView-header-text {\n    overflow: hidden;\n    padding: 0 ", ";\n    margin-bottom: 4px;\n\n    .strong {\n      margin-right: 4px;\n      width: auto;\n    }\n    .h3 {\n      word-break: break-word;\n      width: auto;\n    }\n  }\n\n  .SummaryView-header-additional-content {\n    padding-left: 8px;\n  }\n\n  .SummaryView-header-actions {\n    margin-right: ", ";\n  }\n\n  .headerDivider {\n    /* padding: 0 ", "; */\n    /* margin: 0 calc(-1 * ", "); */\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 8px;\n  height: fit-content;\n\n  .SummaryView-header-text {\n    overflow: hidden;\n    padding: 0 ", ";\n    margin-bottom: 4px;\n\n    .strong {\n      margin-right: 4px;\n      width: auto;\n    }\n    .h3 {\n      word-break: break-word;\n      width: auto;\n    }\n  }\n\n  .SummaryView-header-additional-content {\n    padding-left: 8px;\n  }\n\n  .SummaryView-header-actions {\n    margin-right: ", ";\n  }\n\n  .headerDivider {\n    /* padding: 0 ", "; */\n    /* margin: 0 calc(-1 * ", "); */\n  }\n"])), theme.spacing.md, theme.spacing.md, theme.spacing.md, theme.spacing.md);
export var StyledBody = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  max-height: 100%;\n  overflow: auto;\n"], ["\n  position: relative;\n  width: 100%;\n  max-height: 100%;\n  overflow: auto;\n"])));
export var StyledFooter = styled.footer(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: auto;\n  padding: 0 ", ";\n  display: grid;\n  gap: ", ";\n  overflow: hidden;\n  grid-template-rows: repeat(2, max-content);\n"], ["\n  width: 100%;\n  height: auto;\n  padding: 0 ", ";\n  display: grid;\n  gap: ", ";\n  overflow: hidden;\n  grid-template-rows: repeat(2, max-content);\n"])), theme.spacing.md, theme.spacing.md);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
