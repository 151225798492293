/**
 * Localization of datetime
 * with ECMAScript Internationalization API (Intl)
 *
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @since 1.7.0
 *
 * Expected output format for default settings
 * for `en-GB` locale: "DD/MM/YYYY, hh:mm:ss",
 * for `fi-FI` locale: "DD.MM.YYYY hh:mm:ss",
 * for `sv-SE` locale: "YYYY-MM-DD hh:mm:ss"
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { cleanupLocale, defaultLocale } from "./utilities";
var TwoDigit = "2-digit";
var timeOptions = {
    hour: TwoDigit,
    minute: TwoDigit,
    second: TwoDigit,
    hour12: false,
};
var dateOptions = {
    year: "numeric",
    month: TwoDigit,
    day: TwoDigit,
};
/**
 * Format a date-time to a local string
 * @param value Date to format
 * @param options Options to customize formatting of datetime
 * @example
 * const formattedString = formatDateTime(new Date());
 */
export function formatDateTime(value, _a) {
    if (_a === void 0) { _a = {}; }
    var locale = _a.locale, options = __rest(_a, ["locale"]);
    return (new Intl.DateTimeFormat([cleanupLocale(locale), defaultLocale], __assign(__assign(__assign({}, dateOptions), timeOptions), options))
        .format(new Date(value))
        // Remove Finnish separated of dateTime ("klo" = "at")
        .replace("klo", ""));
}
/**
 * Format a date to a local string
 * @param value Date to format
 * @param options Options to customize formatting of date
 * @example
 * const formattedString = formatDate(new Date());
 */
export function formatDate(value, _a) {
    if (_a === void 0) { _a = {}; }
    var locale = _a.locale, options = __rest(_a, ["locale"]);
    return new Intl.DateTimeFormat([cleanupLocale(locale), defaultLocale], __assign(__assign({}, dateOptions), options)).format(new Date(value));
}
/**
 * Format time to a local string
 * @param value Date to format
 * @param options Options to customize formatting of time (optional)
 * @example
 * const formattedString = formatTime(new Date());
 */
export function formatTime(value, _a) {
    if (_a === void 0) { _a = {}; }
    var locale = _a.locale, options = __rest(_a, ["locale"]);
    return new Intl.DateTimeFormat([cleanupLocale(locale), defaultLocale], __assign(__assign({}, timeOptions), options)).format(new Date(value));
}
