var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { theme, styled, getTextDimensions } from "@faharmony/theme";
import { Text } from "@faharmony/components";
var headerHeight = 58;
var StyledHeader = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: ", ";\n  width: inherit;\n  background: transparent;\n  height: ", ";\n  max-height: ", ";\n  overflow: hidden;\n  padding: ", "\n    ", " 0;\n  grid-template-rows: auto max-content;\n\n  .heading {\n    margin: 0 ", ";\n    width: calc(inherit - 2 * ", ");\n    overflow: hidden;\n  }\n\n  > .navHeader-actions {\n    margin-bottom: ", ";\n  }\n"], ["\n  display: ", ";\n  width: inherit;\n  background: transparent;\n  height: ", ";\n  max-height: ", ";\n  overflow: hidden;\n  padding: ", "\n    ", " 0;\n  grid-template-rows: auto max-content;\n\n  .heading {\n    margin: 0 ", ";\n    width: calc(inherit - 2 * ", ");\n    overflow: hidden;\n  }\n\n  > .navHeader-actions {\n    margin-bottom: ", ";\n  }\n"])), function (props) { return (props.isMenuCollapsed ? "block" : "grid"); }, function (props) { return (props.isMenuCollapsed ? "unset" : "".concat(headerHeight, "px")); }, function (props) {
    return props.isMenuCollapsed ? "unset" : "".concat(headerHeight, "px");
}, function (props) {
    return props.isMenuCollapsed ? theme.spacing.md : theme.spacing.lg;
}, theme.spacing.md, theme.spacing.md, theme.spacing.md, theme.spacing.md);
/**
 * Navigation Header
 * ---
 * Builds the navigation Header containing heading
 * and actions.
 *
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 */
var Header = function (props) {
    var heading = props.heading, shortHeading = props.shortHeading, isMenuCollapsed = props.isMenuCollapsed;
    // Check whether to use short or normal heading
    var showShortHeading = getTextDimensions(heading, "h1").width > 210 && !!shortHeading;
    return isMenuCollapsed ? null : (_jsx(StyledHeader, __assign({}, props, { children: _jsx("div", __assign({ className: "heading" }, { children: _jsx(Text, { truncate: true, variant: "h1", value: showShortHeading ? shortHeading || "" : heading }) })) })));
};
export default Header;
var templateObject_1;
