var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCloudUploadAlt, faFileImport } from "@faharmony/icons";
import React from "react";
import { Button } from "../Button";
/**
 * ButtonUpload
 * ---
 * Description of component
 * @author Amrit Regmi <amrit.regmi@fasolutions.com>
 * @copyright FA Solutions Oy
 * @version 1.11.0
 */
var ButtonUpload = React.forwardRef(function (props, ref) {
    var _a = props.multiple, multiple = _a === void 0 ? false : _a, accept = props.accept, onFilesSelect = props.onFilesSelect, _b = props.uploadIcon, uploadIcon = _b === void 0 ? true : _b, buttonProps = __rest(props, ["multiple", "accept", "onFilesSelect", "uploadIcon"]);
    var hiddenFileInput = React.useRef(null);
    var handleClick = function () {
        if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.value = "";
            hiddenFileInput.current.click();
        }
    };
    var handleChange = function (event) {
        var filesUploaded = event.target.files;
        onFilesSelect(filesUploaded);
    };
    return (_jsxs(React.Fragment, { children: [_jsx(Button, __assign({}, buttonProps, { icon: uploadIcon ? faCloudUploadAlt : faFileImport, ref: ref, onClick: handleClick })), _jsx("input", { type: "file", ref: hiddenFileInput, onChange: handleChange, style: { display: "none" }, multiple: multiple, accept: accept })] }));
});
export { ButtonUpload };
