var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from "@faharmony/theme";
var defColor = "grey";
var Styled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  position: relative;\n  width: ", ";\n  height: ", ";\n\n  div {\n    box-sizing: border-box;\n    display: block;\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    border: 2px solid ", ";\n    border-radius: 50%;\n    animation: rcs-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n    border-color: ", " transparent transparent\n      transparent;\n    :nth-of-type(1) {\n      animation-delay: -0.45s;\n    }\n    :nth-of-type(2) {\n      animation-delay: -0.3s;\n    }\n    :nth-of-type(3) {\n      animation-delay: -0.15s;\n    }\n  }\n  @keyframes rcs-ring {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n"], ["\n  display: inline-block;\n  position: relative;\n  width: ", ";\n  height: ", ";\n\n  div {\n    box-sizing: border-box;\n    display: block;\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    border: 2px solid ", ";\n    border-radius: 50%;\n    animation: rcs-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n    border-color: ", " transparent transparent\n      transparent;\n    :nth-of-type(1) {\n      animation-delay: -0.45s;\n    }\n    :nth-of-type(2) {\n      animation-delay: -0.3s;\n    }\n    :nth-of-type(3) {\n      animation-delay: -0.15s;\n    }\n  }\n  @keyframes rcs-ring {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n"])), function (p) { return p.size || "1rem"; }, function (p) { return p.size || "1rem"; }, function (p) { return p.color || defColor; }, function (p) { return p.color || defColor; });
export var RingLoader = function (props) { return (_jsxs(Styled, __assign({ className: "RingLoader" }, props, { children: [_jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {})] }))); };
var templateObject_1;
