var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { motion } from "framer-motion";
var AnimatedTabIndicator = function (_a) {
    var refs = _a.refs, activeTab = _a.activeTab, finishAnimating = _a.finishAnimating, orientation = _a.orientation;
    // We keep the x offset and width in state
    var _b = __read(React.useState({
        left: 0,
        top: 0,
        width: 2,
        height: 2,
    }), 2), _c = _b[0], left = _c.left, top = _c.top, width = _c.width, height = _c.height, setAttributes = _b[1];
    // A memoised function to update the state using the active element
    var updateAttributes = React.useCallback(function () {
        var _a;
        if (refs && refs[activeTab]) {
            var currentTab = (_a = refs[activeTab]) === null || _a === void 0 ? void 0 : _a.current;
            var width_1 = (currentTab === null || currentTab === void 0 ? void 0 : currentTab.getBoundingClientRect().width) || 0;
            var height_1 = (currentTab === null || currentTab === void 0 ? void 0 : currentTab.getBoundingClientRect().height) || 0;
            if (orientation === "vertical") {
                setAttributes({
                    top: (currentTab === null || currentTab === void 0 ? void 0 : currentTab.offsetTop) || 0,
                    left: ((currentTab === null || currentTab === void 0 ? void 0 : currentTab.offsetLeft) || 0) + width_1,
                    width: 2,
                    height: height_1,
                });
            }
            else {
                setAttributes({
                    top: ((currentTab === null || currentTab === void 0 ? void 0 : currentTab.offsetTop) || 0) + height_1 - 2,
                    left: (currentTab === null || currentTab === void 0 ? void 0 : currentTab.offsetLeft) || 0,
                    width: width_1,
                    height: 2,
                });
            }
        }
    }, [activeTab, refs[activeTab], orientation]);
    // If active route changes (or refs change), recalculate attributes
    React.useEffect(function () {
        updateAttributes();
    }, [activeTab, refs[activeTab], updateAttributes]);
    // After window resize, recalculate attributes
    React.useEffect(function () {
        window.addEventListener("resize", updateAttributes);
        return function () {
            window.removeEventListener("resize", updateAttributes);
        };
    });
    return (_jsx(motion.div, { className: "tabs-list__underline", 
        // Animate the x offset and width
        animate: orientation === "horizontal"
            ? {
                left: left,
                width: width,
                transition: { duration: 0.75 },
            }
            : {
                top: top,
                height: height,
                transition: { duration: 0.75 },
            }, style: {
            top: top,
            left: left - width,
            width: width,
        }, 
        // Let the parent (Tabs) know when animation completes
        onAnimationComplete: finishAnimating }));
};
export default AnimatedTabIndicator;
