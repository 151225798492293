var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion, Button, InfoBox, Popover, Preloader, useModal, } from "@faharmony/components";
import { Combobox } from "@faharmony/form";
import { Box } from "@faharmony/theme";
import { SummaryView } from "@faharmony/views";
import { useEffect, useMemo, useState } from "react";
import { useSnapshot } from "valtio";
import { state } from "../state";
import { faDiagramProject, faPlay } from "@faharmony/icons";
import { useKeycloak } from "@faharmony/service";
import { formatDate } from "@faharmony/locale";
import { MenuDrilldown } from "@faharmony/shared";
import { useGetProcessInstances } from "../hook/useGetProcessInstances";
import useClaimProcessTask from "../hook/useClaimProcessTask";
import { getUserNameFromTask } from "../utils";
import { useGetCurrentUser } from "./../hook/useGetCurrentUser";
import { MuiPageView, Box as MUIBox, Tooltip, IconButton, } from "@faharmony/mui";
import { Sync } from "@faharmony/mui/icons-material";
import { useTaskManager } from "./context";
import { WorkflowDetails } from "./WorkflowDetails";
var loggedInUserUsername = "";
var isTaskInaccessible = function (task) {
    return !task.formKey || (!!task.assignee && task.assignee !== loggedInUserUsername);
};
export var TaskCardView = function (_a) {
    var _b;
    var task = _a.task;
    var _c = __read(useModal(), 3), ModalWrapperImageViewer = _c[0], openModalImageViewer = _c[1], closeModalImageViewer = _c[2];
    var claimProcessTask = useClaimProcessTask(task.taskId, loggedInUserUsername).claimProcessTask;
    return (_jsxs("div", __assign({ style: { width: 350 } }, { children: [_jsx(ModalWrapperImageViewer, __assign({ style: { width: "100%", height: "100%" } }, { children: _jsx(WorkflowDetails, { task: task, onClose: function () {
                        closeModalImageViewer();
                    } }) })), _jsx(SummaryView, { caption: task.workflowDetails.processDefinitionName, heading: task.name, actionsInHeader: true, actions: [
                    {
                        id: "view",
                        name: "View",
                        icon: faDiagramProject,
                        onClick: function () {
                            openModalImageViewer();
                        },
                    },
                    {
                        id: "complete",
                        name: "Complete",
                        icon: faPlay,
                        disabled: isTaskInaccessible(task),
                        onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var taskData;
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0: return [4 /*yield*/, claimProcessTask()];
                                    case 1:
                                        taskData = (_a = (_b.sent()).data) === null || _a === void 0 ? void 0 : _a.claimProcessTask;
                                        state.activeTask = taskData;
                                        state.modalMode = "open";
                                        return [2 /*return*/];
                                }
                            });
                        }); },
                    },
                ], children: _jsxs(Box, __assign({ alignItems: "start", style: { padding: 8 }, spacing: "xl" }, { children: [_jsx(InfoBox, { field: task.dueDate ? "Due date" : "Creation date", value: formatDate(new Date((_b = task.dueDate) !== null && _b !== void 0 ? _b : task.createTime)) }), _jsx("div", __assign({ style: {
                                position: "relative",
                                width: "100%",
                            } }, { children: _jsx(InfoBox, __assign({ field: "Assignee" }, { children: _jsx(Popover, __assign({ disabled: isTaskInaccessible(task) ||
                                        task.assignee === loggedInUserUsername, placement: "bottom-end", content: function (_a) {
                                        var closePopover = _a.closePopover;
                                        return (_jsx("div", __assign({ style: { width: 180 } }, { children: _jsx(MenuDrilldown, { optionsTree: {
                                                    label: "",
                                                    options: task.assignee &&
                                                        task.assignee === loggedInUserUsername
                                                        ? [{ label: "Unassign", value: "unassign" }]
                                                        : [
                                                            {
                                                                label: "Assign to me",
                                                                value: "assignToMe",
                                                            },
                                                        ],
                                                }, onOptionSelected: function () { return __awaiter(void 0, void 0, void 0, function () {
                                                    return __generator(this, function (_a) {
                                                        switch (_a.label) {
                                                            case 0:
                                                                closePopover();
                                                                return [4 /*yield*/, claimProcessTask()];
                                                            case 1:
                                                                _a.sent();
                                                                return [2 /*return*/];
                                                        }
                                                    });
                                                }); } }) })));
                                    } }, { children: _jsx(Button, { disabled: isTaskInaccessible(task) ||
                                            task.assignee === loggedInUserUsername, value: getUserNameFromTask(task, "taskAssignee") ||
                                            (isTaskInaccessible(task) && "Not assignable") ||
                                            "Unassigned", variant: "secondary" }) })) })) }))] })) })] })));
};
export var TaskManagerContent = function (_a) {
    var _b, _c, _d;
    var _e = _a.stateAccessorKey, stateAccessorKey = _e === void 0 ? "global" : _e;
    useSnapshot(state.taskManager);
    var tasks = ((_b = state.taskManager[stateAccessorKey]) !== null && _b !== void 0 ? _b : {}).tasks;
    var _f = __read(useState({
        label: "Assignee",
        value: "assignee",
    }), 2), grouping = _f[0], setGrouping = _f[1];
    useGetProcessInstances(stateAccessorKey);
    var loading = (_c = state.taskManager[stateAccessorKey]) === null || _c === void 0 ? void 0 : _c.loading;
    var groupedTasks = useMemo(function () {
        if (!(tasks === null || tasks === void 0 ? void 0 : tasks.length))
            return [];
        if (grouping.value === "assignee") {
            if (!loggedInUserUsername)
                return [];
            var myTasks = tasks.filter(function (task) { return task.assignee === loggedInUserUsername; });
            var unassignedTasks = tasks.filter(function (task) { return !task.assignee; });
            var assignedToOthersTasks = tasks.filter(function (task) { return task.assignee && task.assignee !== loggedInUserUsername; });
            return [
                {
                    id: "myTasks",
                    sectionName: "My Tasks",
                    tasks: myTasks,
                },
                {
                    id: "unassignedTasks",
                    sectionName: "Unassigned Tasks",
                    tasks: unassignedTasks,
                },
                {
                    id: "assignedToOthersTasks",
                    sectionName: "Assigned to Others",
                    tasks: assignedToOthersTasks,
                },
            ];
        }
        else if (grouping.value === "workflow") {
            var tasksGroupedByWorkflow_1 = tasks.reduce(function (acc, task) {
                var workflowId = task.workflowDetails.processDefinitionId;
                if (!acc[workflowId]) {
                    acc[workflowId] = [];
                }
                acc[workflowId].push(task);
                return acc;
            }, {});
            return Object.keys(tasksGroupedByWorkflow_1).map(function (workflowId) {
                var _a;
                return ({
                    id: workflowId,
                    sectionName: (_a = tasksGroupedByWorkflow_1[workflowId][0]) === null || _a === void 0 ? void 0 : _a.workflowDetails.processDefinitionName,
                    tasks: tasksGroupedByWorkflow_1[workflowId],
                });
            });
        }
        else
            return [];
    }, [tasks, grouping]);
    return (_jsxs(Box, __assign({ direction: "column", spacing: "xl", height: loading ? "100%" : "auto" }, { children: [_jsx("div", __assign({ style: { width: 260 } }, { children: _jsx(InfoBox, __assign({ field: "Group by" }, { children: _jsx(Combobox, { name: "grouping", isClearable: false, value: grouping, onChange: function (value) { return setGrouping(value); }, options: [
                            { label: "Assignee", value: "assignee" },
                            { label: "Workflow", value: "workflow" },
                        ] }) })) })), loading ? (_jsx(Box, __assign({ width: "100%", height: "100%" }, { children: _jsx(Preloader, {}) }))) : (_jsx(Accordion, { fullHeight: true, independent: true, panels: (_d = groupedTasks === null || groupedTasks === void 0 ? void 0 : groupedTasks.map(function (group) {
                    var _a;
                    return ({
                        id: group.id,
                        heading: group.sectionName,
                        defaultActive: true,
                        content: (_jsx(Box, __assign({ wrap: "wrap" }, { children: (_a = group === null || group === void 0 ? void 0 : group.tasks) === null || _a === void 0 ? void 0 : _a.map(function (task) { return (_jsx(TaskCardView, { task: task }, task.id)); }) }))),
                    });
                })) !== null && _d !== void 0 ? _d : [] }))] })));
};
export var TaskManager = function (_a) {
    var _b, _c;
    var modal = _a.modal, options = _a.options;
    var _d = __assign({}, options), _e = _d.taskManagerTitle, taskManagerTitle = _e === void 0 ? "" : _e, _f = _d.customQueryParams, customQueryParams = _f === void 0 ? undefined : _f, _g = _d.category, category = _g === void 0 ? "" : _g, maxTasks = _d.maxTasks;
    var keycloakData = useKeycloak();
    loggedInUserUsername =
        //@ts-ignore extract username from keycloak token
        (_c = (_b = keycloakData === null || keycloakData === void 0 ? void 0 : keycloakData.keycloakInstance) === null || _b === void 0 ? void 0 : _b.tokenParsed) === null || _c === void 0 ? void 0 : _c.preferred_username;
    var stateAccessorKey = useMemo(function () {
        return customQueryParams || category || maxTasks
            ? JSON.stringify({
                customQueryParams: customQueryParams,
                category: category,
                maxTasks: maxTasks,
            })
            : "global";
    }, [customQueryParams, category, maxTasks]);
    useEffect(function () {
        state.taskManager[stateAccessorKey] = __assign(__assign({}, state.taskManager[stateAccessorKey]), {
            query: {
                customQueryParams: customQueryParams,
                category: category,
                maxCount: maxTasks,
            },
        });
    }, [customQueryParams, category, maxTasks, stateAccessorKey]);
    var skip = modal && !(modal === null || modal === void 0 ? void 0 : modal.isOpen);
    useGetProcessInstances(stateAccessorKey, skip);
    useGetCurrentUser(skip);
    var title = "Tasks and workflows".concat(taskManagerTitle && typeof taskManagerTitle === "string"
        ? ": ".concat(taskManagerTitle)
        : "");
    var open = (modal && modal.isOpen) || !modal;
    return open ? (_jsx(MuiPageView, __assign({ id: "taskManager_pageView", heading: title, actions: _jsx(Tooltip, __assign({ title: "Refresh" }, { children: _jsx(IconButton, __assign({ size: "small", "aria-label": "refresh", onClick: function () { return state.taskManager[stateAccessorKey].tasksRefetcher(); } }, { children: _jsx(Sync, {}) })) })), backButton: !!modal
            ? {
                backButtonAdditionalActions: function () {
                    modal && modal.onClose();
                    return false;
                },
            }
            : undefined }, { children: _jsx(MUIBox, __assign({ sx: { pt: 2, pl: 1, pr: 2, width: "100%", height: "100%" } }, { children: _jsx(TaskManagerContent, { stateAccessorKey: stateAccessorKey }) })) }))) : null;
};
/**Renders task manager as overlay */
export var TaskManagerModal = function () {
    var _a = useTaskManager(), close = _a.close, taskManager = _a.taskManager;
    var _b = __assign({}, taskManager), options = _b.options, isOpen = _b.isOpen;
    return (_jsx(TaskManager, { options: options, modal: { isOpen: isOpen, onClose: close } }));
};
/** Renders the TaskManagerView */
export var TaskManagerView = function (props) {
    return _jsx(TaskManager, __assign({}, props));
};
