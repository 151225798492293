var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { styled } from "@faharmony/theme";
import { theme } from "@faharmony/theme";
var StyledText = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: ", " !important;\n  vertical-align: baseline;\n  color: ", ";\n  cursor: ", ";\n  text-align: ", ";\n  :hover {\n    text-decoration-line: ", ";\n  }\n"], ["\n  width: 100%;\n  display: ", " !important;\n  vertical-align: baseline;\n  color: ", ";\n  cursor: ", ";\n  text-align: ", ";\n  :hover {\n    text-decoration-line: ", ";\n  }\n"])), function (p) {
    return p.block === undefined || p.block === true ? "block" : "inline";
}, function (props) {
    return props.tint
        ? theme.color.text[props.tint]
        : props.onClick
            ? theme.color.text.info
            : "inherit";
}, function (props) { return (props.onClick ? "pointer" : "inherit"); }, function (props) { return props.align; }, function (props) { return (props.onClick ? "underline" : "none"); });
var StyledContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: ", ";\n"], ["\n  width: 100%;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: ", ";\n"])), function (p) { return p.align; });
/**
 * Text
 * ---
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.5.0
 * @example
 * <Text>Hello World!</Text>
 * <Text variant="h1">Hello World!</Text>
 * <Text code>() => Hello World!</Text>
 */
var Text = React.forwardRef(function (props, ref) {
    var value = props.value, children = props.children, onClick = props.onClick, code = props.code, variant = props.variant, color = props.color, truncate = props.truncate, _a = props.align, align = _a === void 0 ? "left" : _a, verticalAlign = props.verticalAlign, _b = props.block, block = _b === void 0 ? false : _b;
    var className = code ? "code" : variant || "p";
    // const StyledTag = StyledText.withComponent(className);
    var text = (_jsx(StyledText, __assign({ ref: ref, className: "Text text " + className, tint: color, onClick: onClick, align: align, verticalAlign: verticalAlign || "baseline", block: block }, { children: value || (children === null || children === void 0 ? void 0 : children.toString()) || "" })));
    return truncate ? (_jsx(StyledContainer, __assign({ className: "TextContainer", align: align }, { children: text }))) : (text);
});
export { Text };
var templateObject_1, templateObject_2;
