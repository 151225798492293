var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Text, Icon } from "@faharmony/components";
import { StyledNavSecondaryItem } from "./Style";
var commonId = "navSecondaryItem";
var NavSecondaryItem = React.memo(function (props) {
    var item = props.item, showLeadingIcon = props.showLeadingIcon;
    var label = item.label, icon = item.icon, trailingIcon = item.trailingIcon, trailingIconTint = item.trailingIconTint, id = item.id;
    var handleClick = function (e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        item.handleClick && item.handleClick(e);
        props.handleItemSelect(item);
    };
    return (_jsxs(StyledNavSecondaryItem, __assign({}, props, { onClick: handleClick, id: "".concat(commonId, "-").concat(id) }, { children: [showLeadingIcon && (_jsx("div", __assign({ className: "iconWrapper" }, { children: icon && _jsx(Icon, { icon: icon, color: "disabled" }) }))), _jsx(Text, { value: label }), trailingIcon && (_jsx("div", __assign({ className: "iconWrapper" }, { children: _jsx(Icon, { icon: trailingIcon, color: trailingIconTint }) })))] })));
});
NavSecondaryItem.displayName = "NavSecondaryItem";
export { NavSecondaryItem };
export default NavSecondaryItem;
