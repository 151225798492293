var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useContext } from "react";
var initialState = {
    isOpen: false,
    options: {},
};
var TaskManagerContext = React.createContext([initialState, function () { return null; }]);
/**
 * Hook to get / update task manager context
 * This hook dynamically displays the task manager and provides method to open or close
 * Additional options can be passed to open function to get the filtered results
 * ex:
 * import {useTaskManager} from "@faharmony/core"
 * const ExampleComponent = () => {
 * `const {open, close, taskManager } = useTaskManager()
 *  return <>
 *    <Button onClick = { () => {
 *        console.log(taskManager)
 *        open({maxTasks: 10, category: "funds"})
 *        }
 *     } />
 *    <Button onClick = { () => close()} />
 * </>
 * }
 * `
 */
var useTaskManager = function () {
    var _a = __read(useContext(TaskManagerContext), 2), state = _a[0], dispatch = _a[1];
    return {
        open: function (options) {
            dispatch({ type: "open", options: options });
        },
        close: function () { return dispatch({ type: "close" }); },
        taskManager: state,
    };
};
var taskManagerReducer = function (state, action) {
    switch (action.type) {
        case "open":
            return __assign(__assign({}, state), { isOpen: true, options: __assign({}, action.options) });
        case "close":
            return __assign(__assign({}, state), { isOpen: false });
        default:
            throw new Error("unexpected action type");
    }
};
export { TaskManagerContext, useTaskManager, taskManagerReducer };
