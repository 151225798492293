var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { theme, Grid } from "@faharmony/theme";
/**
 * PrimaryWithSidebarLayout
 * ---
 * It is used to split the available space in two. The primary part of layout is supposedly always visible. The secondary part of layout is expected to be temporary.
 * @example
 * <PrimaryWithSidebarLayout primaryNode={<div />} secondaryNode={<div />} />
 */
export var PrimaryWithSidebarLayout = function (props) { return (_jsxs(Grid, __assign({ width: "100%", height: "100%", gridTemplate: "100% / 1fr max-content", spacing: "none", overflow: "visible" }, { children: [props.primaryNode, _jsx("div", __assign({ style: {
                zIndex: theme.zIndex.docked,
                boxShadow: theme.shadow.float,
                overflow: "hidden",
                height: "calc(100% + ".concat(theme.spacing.xl, ")"),
                maxWidth: props.secondaryNodeWidth || "300px",
                transform: "translateX(".concat(theme.spacing.md, ") translateY(calc(-1 * ").concat(theme.spacing.md, "))"),
                backgroundColor: theme.color.background.primary,
            } }, { children: props.secondaryNode }))] }))); };
