var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from "axios";
import { useAxios } from "./useAxios";
export var faLegacyApiInstance = axios.create({
    baseURL: "/rs/secure/fa/api/",
});
faLegacyApiInstance.interceptors.request.use(function (config) {
    config.headers.set("Accept", "application/json");
    //config.headers!.Accept = "application/json";
    return config;
}, function (error) { return Promise.reject(error); });
/**
 * useFaApi
 * ---
 * Use FA's main api available at `/rs/` endpoint.
 * @param  options
 * @param  lazy - true: to prevent execution onMount, default: false
 * @param  version - Version of API to use, default: 1.0
 * @example
 * const [state, execute, cancel] = useFaApi<DataType>({url: "contact", method: "POST"}, {tokenName: ''; tokenValue: ''}, false, "1.1");
 * const { isLoading, isCancelled, error, success, data} = state;
 */
export var useFaLegacyApi = function (options, credentials, lazy, version) {
    if (lazy === void 0) { lazy = false; }
    if (version === void 0) { version = "1.0"; }
    return useAxios(__assign(__assign({}, options), { url: "v".concat(version, "/").concat(options.url), headers: __assign(__assign({}, options.headers), { "fa-token-value": credentials.tokenValue, "fa-token-name": credentials.tokenName }) }), faLegacyApiInstance, lazy);
};
