var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from "react";
import { Subject } from "rxjs";
/**
 * Trigger
 * ---
 * Pub/Sub Implementation of RxJS Subject
 * Create a trigger which can be used in other parts of app.
 *
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @example
 * // TS
 * // PayloadType is the type of data that is sent and received by the trigger.
 * type PayloadType = string;
 * export const myTrigger = new Trigger<PayloadType>();
 *
 * // JS
 * export const myTrigger = new Trigger();
 */
var Trigger = /** @class */ (function () {
    /** Initiate the subject */
    function Trigger() {
        var _this = this;
        /**
         * Publish (set) new payload to the trigger
         * @param {object, T} payload
         * @example
         * myTrigger.publish("Item");
         */
        this.publish = function (payload) {
            _this.$.next(payload);
        };
        /**
         * Subscribe and listen to changes to a trigger and execute a function.
         * Needs to be unsubscribed. Otherwise use useSubscribe.
         * @param {Function} execute Function to execute (receives payload as argument)
         */
        this.subscribe = function (execute) { return _this.$.subscribe(execute); };
        /**
         * Hook to Subscribe and listen to changes and execute a callback function.
         * It manages subscriptions and un-subscription.
         * Can only be used inside component or other Hook.
         * @param {Function} execute Function to execute (receives payload as argument)
         * @example
         * myTrigger.useCallback((payload) => console.log(payload));
         */
        this.useCallback = function (callback) {
            return useEffect(function () {
                var subscription = _this.subscribe(callback);
                return function () {
                    subscription.unsubscribe();
                };
            }, []);
        };
        /**
         * @deprecated in favor of useCallback
         * Hook to Subscribe and listen to changes and execute a function.
         * It manages subscriptions and un-subscription.
         * Can only be used inside component or other Hook.
         * @param {Function} execute Function to execute (receives payload as argument)
         * @example
         * myTrigger.useSubscribe((payload) => console.log(payload));
         */
        this.useSubscribe = this.useCallback;
        /**
         * Hook to subscribe to the trigger and get the latest value.
         * Takes an optional default value.
         * Can only be used inside component or other Hook.
         * @param {T!} defaultValue
         * @example const [value, setValue] = myTrigger.useValue();
         */
        this.useValue = function (defaultValue) {
            if (defaultValue === void 0) { defaultValue = undefined; }
            var _a = __read(useState(defaultValue), 2), state = _a[0], setState = _a[1];
            _this.useCallback(setState);
            return [state, setState];
        };
        this.$ = new Subject();
    }
    return Trigger;
}());
export { Trigger };
export default Trigger;
