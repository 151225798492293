var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState } from "react";
import { createTheme } from "@mui/material";
import { darkModeLocalStorageKey, getLocalStorageValue, setLocalStorageValue, useResponsive, useInterval, } from "@faharmony/helpers";
import { useReducedMotion } from "framer-motion";
import { baseFontFamily } from "./faTheme/font";
import { themeDark, themeLight } from ".";
import { CustomInputHelper } from "./components/CustomInputHelper";
/** List of semantic names for spacing */
export var SMLArray = ["xs", "sm", "md", "lg", "xl", "none"];
/** Extract font name from the Theme text/code style */
export var getFontName = function (text) {
    var config = __assign({}, text.fontConfig);
    if (config.google)
        return config.google.families
            .map(function (family) { return family.split(":")[0]; })
            .join(", ");
    else if (config.typekit)
        return config.typekit.id || "";
    else if (config.fontdeck)
        return config.fontdeck.id || "";
    else if (config.monotype)
        return config.monotype.projectId || "";
    else if (config.custom && config.custom.families)
        return config.custom.families.join(", ") || "";
    return baseFontFamily;
};
/** Check if object is a TextStyle */
export var isTextStyle = function (obj) {
    return obj.fontSize !== undefined;
};
/** Get dimensions of Text, when rendered on DOM. */
export function getTextDimensions(value, type) {
    if (type === void 0) { type = "p"; }
    var container = document.createElement(type);
    document.body.appendChild(container);
    container.style.height = "auto";
    container.style.width = "auto";
    container.style.position = "absolute";
    container.style.whiteSpace = "no-wrap";
    container.innerHTML = value;
    var width = Math.ceil(container.clientWidth);
    var height = Math.ceil(container.clientHeight);
    document.body.removeChild(container);
    return { width: width, height: height };
}
/** Get the current dark-mode preference. */
export var getCurrentDarkModePreference = function () {
    var darkMode = false;
    // Check local storage
    if (getLocalStorageValue(darkModeLocalStorageKey)) {
        darkMode = getLocalStorageValue(darkModeLocalStorageKey) === "true";
    }
    else {
        // Check CSS color preference
        darkMode =
            window.matchMedia &&
                window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
    return darkMode;
};
/** Set the current dark-mode preference. */
export var setCurrentDarkModePreference = function (darkMode) {
    return setLocalStorageValue(darkModeLocalStorageKey, darkMode.toString());
};
/** Hook to check is animation should be disabled or not. */
export var useDisableAnimation = function () {
    var shouldReduceMotion = useReducedMotion();
    var isDesktop = useResponsive().isDesktop;
    return !isDesktop || !!shouldReduceMotion;
};
/** Hook to actively check for Dark mode */
export var useDarkModeEnabled = function () {
    // Theme
    var _a = __read(useState(getCurrentDarkModePreference()), 2), darkMode = _a[0], setDarkMode = _a[1];
    useInterval(function () { return setDarkMode(getCurrentDarkModePreference()); }, 1000);
    return darkMode;
};
export var useMuiFaTheme = function () {
    /** Track dark mode changes */
    var darkmodeEnabled = useDarkModeEnabled();
    var activeTheme = darkmodeEnabled ? themeDark : themeLight;
    var muiFaTheme = createTheme({
        palette: {
            mode: darkmodeEnabled ? "dark" : "light",
            primary: {
                main: activeTheme.color.text.primary,
            },
            secondary: {
                main: activeTheme.color.text.secondary,
            },
            error: {
                main: activeTheme.color.text.negative,
            },
            warning: {
                main: activeTheme.color.text.warning,
            },
            info: {
                main: activeTheme.color.text.info,
            },
            success: {
                main: activeTheme.color.text.positive,
            },
            background: __assign({}, activeTheme.color.background),
        },
        typography: {
            fontFamily: "Mulish, sans-serif",
            h4: {
                fontWeight: 800,
            },
            h5: {
                fontWeight: 800,
            },
            h6: {
                fontWeight: 700,
            },
            body2: {
                fontSize: "1rem",
                fontWeight: 700,
            },
        },
        components: {
            MuiButton: {
                defaultProps: {
                    variant: "contained",
                    color: "primary",
                },
                styleOverrides: {
                    root: {
                        label: {
                            textTransform: "uppercase", // Enforce uppercase text for all buttons
                        },
                    },
                },
            },
            // data grids
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        //Default colors for row and headers
                        "& .MuiDataGrid-columnHeaders": {
                            fontWeight: 700,
                            backgroundColor: activeTheme.color.background.secondary,
                            borderRadius: 0,
                        },
                        ".MuiDataGrid-columnHeaderTitle": {
                            fontWeight: 700,
                            backgroundColor: activeTheme.color.background.secondary,
                            lineHeight: "normal",
                        },
                        "& .even": {
                            backgroundColor: activeTheme.color.background.rowAlt,
                        },
                        "& .MuiDataGrid-pinnedColumnHeaders": {
                            backgroundColor: activeTheme.color.background.secondary,
                        },
                        //Toolbar buttons to match the current theme
                        "& .MuiDataGrid-toolbarContainer .MuiButtonBase-root[aria-label='Select columns'],.MuiButtonBase-root[aria-label='Export'],.MuiButtonBase-root[aria-label='Show filters'],.MuiButtonBase-root[aria-label='Density'] ": {
                            color: activeTheme.color.text.primary,
                            backgroundColor: "transparent",
                            border: "none",
                            boxShadow: "none",
                        },
                    },
                    cell: {
                        fontWeight: "normal",
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        fontWeight: 700,
                        backgroundColor: activeTheme.color.background.secondary,
                    },
                    body: {
                        fontWeight: 400,
                    },
                },
            },
            // tooltip
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        fontSize: "14px",
                    },
                },
            },
            MuiTextField: {
                defaultProps: {
                    FormHelperTextProps: {
                        component: CustomInputHelper,
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    shrink: function (props) { return ({
                        fontSize: props.ownerState.size === "small" ? "1.1rem" : "1.3rem",
                        fontWeight: "bold",
                    }); },
                    asterisk: {
                        color: activeTheme.color.text.negative,
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: function (props) { return ({
                        fontSize: props.ownerState.size === "small" ? "1.1rem" : "1.3rem",
                        fontWeight: "bold",
                    }); },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        fontSize: "0.95rem",
                        marginTop: "4px",
                        lineHeight: 1,
                        marginBottom: "8px",
                    },
                },
            },
        },
    });
    return muiFaTheme;
};
