var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { gql, useQuery } from "@faharmony/service";
import { useState } from "react";
import { addToast } from "../../Toast";
import { state } from "../state";
var GET_CURRENT_USER = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getCurrentUserDetails {\n    currentUser {\n      firstName\n      lastName\n      userId\n    }\n  }\n"], ["\n  query getCurrentUserDetails {\n    currentUser {\n      firstName\n      lastName\n      userId\n    }\n  }\n"])));
export var useGetCurrentUser = function (skip) {
    var _a = __read(useState(), 2), currentUser = _a[0], setCurrentUser = _a[1];
    var _b = useQuery(GET_CURRENT_USER, {
        notifyOnNetworkStatusChange: true,
        onError: function (error) {
            addToast({
                id: "get-current-user-error",
                title: "Error, could not get current user",
                description: error.message,
                variant: "negative",
                persist: false,
            });
        },
        onCompleted: function (_a) {
            var currentUser = _a.currentUser;
            setCurrentUser(currentUser);
            state.currentUser = currentUser;
        },
        skip: skip,
    }), data = _b.data, loading = _b.loading, error = _b.error;
    return {
        currentUser: currentUser,
        data: data,
        error: error,
        loading: loading,
    };
};
var templateObject_1;
