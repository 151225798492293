var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect } from "react";
import { useKeycloak } from "./useKeycloak";
/**
 * Check if user is an employee of FA
 */
export var isFAEmployee = function (user) {
    var _a;
    var faGroups = (_a = user.attributes) === null || _a === void 0 ? void 0 : _a.fa_groups;
    return faGroups && faGroups.length > 0;
};
/**
 * Custom hook to fetch Keycloak authenticated user profile.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 */
export var useKeycloakUser = function () {
    var keycloakInstance = useKeycloak().keycloakInstance;
    var _a = __read(useState(), 2), userProfile = _a[0], setUserProfile = _a[1];
    useEffect(function () {
        keycloakInstance.loadUserProfile().then(function (profile) {
            setUserProfile(__assign(__assign({}, profile), { id: profile.id || "", username: profile.username || "", enabled: profile.enabled || false, email: profile.email || "", emailVerified: profile.emailVerified || false, createdTimestamp: profile.createdTimestamp || 0 }));
        });
    }, [keycloakInstance]);
    return userProfile;
};
export var getLogoutUrl = function (keycloakInstance) {
    var _a;
    return (((_a = process.env.REACT_APP_KEYCLOAK_URL) !== null && _a !== void 0 ? _a : "/auth") +
        "/realms/".concat(keycloakInstance === null || keycloakInstance === void 0 ? void 0 : keycloakInstance.realm, "/protocol/openid-connect/logout?id_token_hint=").concat(keycloakInstance === null || keycloakInstance === void 0 ? void 0 : keycloakInstance.idToken, "&post_logout_redirect_uri=").concat(encodeURI(window.location.href)));
};
/** Utility function to parse the authToken string
 * @param {string} authToken
 * @returns {ParsedToken}
 * "aaa.bbb.ccc"
 */
export var getParsedToken = function (authToken) {
    return JSON.parse(btoa(authToken.split(".")[1]));
};
