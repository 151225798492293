/**
 * Default theme of Harmony
 * ---
 * @author Mahima Bhutani (Design)
 * @author Siddhant Gupta (Development)
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { colorLight, colorDark } from "./color";
import { shadowLight, shadowDark } from "./shadow";
import { code, text } from "./font";
import { radius, size, transition, zIndex, spacing } from "./misc";
var themeCommon = { radius: radius, size: size, code: code, text: text, zIndex: zIndex, transition: transition, spacing: spacing };
export var themeLight = __assign({ color: colorLight, shadow: shadowLight }, themeCommon);
export var themeDark = __assign({ color: colorDark, shadow: shadowDark }, themeCommon);
