var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, Tooltip, Help } from "@faharmony/components";
import { theme, Box } from "@faharmony/theme";
import { uuid } from "@faharmony/helpers";
/**
 * FormControl
 * ---
 * Wrapper around other form components tp provide label, help-text, errors, etc.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @copyright FA Solutions Oy
 * @jira https://fasolutions.jira.com/browse/HAR-48
 * @version 1.6.0
 * @example <FormControl><TextField /></FormControl>
 */
var FormControl = function (props) {
    var label = props.label, helpText = props.helpText, errorText = props.errorText, _a = props.placement, placement = _a === void 0 ? "column" : _a, required = props.required, children = props.children, id = props.id;
    return (_jsxs(Box, __assign({ id: "FormControl-" + (id || label || uuid()), spacing: "sm", alignItems: "start", direction: placement === "left" ? "row" : "column" }, { children: [(label || helpText) && (_jsx(Box, { children: _jsxs(Box, __assign({ justifyContent: "flex-start", spacing: "sm" }, { children: [_jsx("div", { children: _jsx(Text, { value: label || "", variant: "h6" }) }), required && (_jsx("div", __assign({ style: { color: theme.color.text.negative, cursor: "default" } }, { children: _jsx(Tooltip, __assign({ value: "Required" }, { children: _jsx(Text, { value: "*", variant: "h6" }) })) }))), helpText && _jsx(Help, { text: helpText })] })) })), children, errorText && (_jsx("span", __assign({ style: {
                    color: theme.color.text.negative,
                } }, { children: _jsx(Text, { value: errorText, variant: "small" }) })))] })));
};
export { FormControl };
