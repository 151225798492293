var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
export function DialogMUI(_a) {
    var open = _a.open, dialog = __rest(_a, ["open"]);
    var handleClose = function (dialog) {
        var _a;
        (_a = dialog.onClosed) === null || _a === void 0 ? void 0 : _a.call(dialog, dialog);
    };
    var handleConfirm = function (dialog) {
        var _a;
        (_a = dialog.onConfirm) === null || _a === void 0 ? void 0 : _a.call(dialog, dialog);
    };
    return (_jsxs(Dialog, __assign({ open: open, onClose: handleClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", sx: { "& .MuiDialog-paper": { width: "100%" } } }, { children: [_jsx(DialogTitle, __assign({ id: "alert-dialog-title" }, { children: dialog.title })), _jsx(DialogContent, { children: typeof dialog.content === "string" ? (_jsx(DialogContentText, __assign({ id: "alert-dialog-description" }, { children: dialog.content }))) : dialog.content }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: function () { return handleClose(dialog); } }, { children: dialog.cancelText || "Cancel" })), _jsx(Button, __assign({ onClick: function () { return handleConfirm(dialog); }, autoFocus: true }, { children: dialog.confirmText || "OK" }))] })] }), dialog.id || "MUIDialog"));
}
