var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from "react";
import { Trigger } from "@faharmony/helpers";
import { useQueryParam, insertQueryParam } from "@faharmony/router";
export var tabIdTrigger = new Trigger();
export var useActiveTabId = function (uid, tabs) {
    var _a;
    var tabParamId = "tab-" + encodeURIComponent(uid.replace(" ", "_"));
    var param = useQueryParam(tabParamId);
    var defaultActiveId = param || ((_a = tabs.find(function (tab) { return tab.defaultActive; })) === null || _a === void 0 ? void 0 : _a.id) || "";
    var _b = __read(React.useState(defaultActiveId), 2), activeId = _b[0], setActiveId = _b[1];
    React.useEffect(function () {
        tabIdTrigger.publish(activeId);
        tabs.length > 1 && insertQueryParam(tabParamId, activeId);
        return function () {
            tabs.length > 1 && insertQueryParam(tabParamId, "");
        };
    });
    return { activeId: activeId, setActiveId: setActiveId };
};
