export var NOOP = function () { return null; };
export var preventDefaultOnClick = function (e) {
    e === null || e === void 0 ? void 0 : e.preventDefault();
    e === null || e === void 0 ? void 0 : e.stopPropagation();
};
export var padStart = function (num, pad) {
    if (pad === void 0) { pad = 2; }
    return num.toString().padStart(pad, "0");
};
export var padEnd = function (num, pad) {
    if (pad === void 0) { pad = 2; }
    return num.toString().padEnd(pad, "0");
};
/**
 * Returns a valid HTML tag which can be used for building components.
 * @param tagName
 */
export var getValidTagName = function (tagName) {
    var Tag = "div";
    if (tagName !== undefined &&
        tagName !== "dir" &&
        tagName !== "font" &&
        tagName !== "frame" &&
        tagName !== "frameset" &&
        tagName !== "marquee" &&
        tagName !== "slot") {
        Tag = tagName;
    }
    return Tag;
};
/** Create range of numbers */
export var range = function (from, to, step) {
    if (step === void 0) { step = 1; }
    return Array.from({ length: (to - from) / step + 1 }, function (_, i) { return from + i * step; });
};
/** Merge multiple refs */
export var mergeRefs = function (refs) {
    return function (value) {
        refs.forEach(function (ref) {
            if (typeof ref === "function")
                ref(value);
            else if (ref != null)
                ref.current = value;
        });
    };
};
var isProduction = process.env.NODE_ENV === 'production';
var prefix = 'Invariant failed';
// > Not providing an inline default argument for message as the result is smaller
/**
 * Throw an error if the condition fails. Strip out error messages for production.
 */
export default function tsInvariant(condition, 
// Can provide a string, or a function that returns a string for cases where
// the message takes a fair amount of effort to compute
message) {
    if (condition) {
        return;
    }
    // Condition not passed
    // In production we strip the message but still throw
    if (isProduction) {
        throw new Error(prefix);
    }
    // When not in production we allow the message to pass through
    // *This block will be removed in production builds*
    var provided = typeof message === 'function' ? message() : message;
    // Options:
    // 1. message provided: `${prefix}: ${provided}`
    // 2. message not provided: prefix
    var value = provided ? "".concat(prefix, ": ").concat(provided) : prefix;
    throw new Error(value);
}
/**
 * Returns a unique name given the list of existing names.
 * @param name
 * @param existingNames
 * @returns a unique name by appending a number to the name if it already exists in the list of existing names
 * @example
 * uniqueName('name', ['name', 'name (1)', 'name (2)']) // 'name (3)'
 */
export var uniqueName = function (name, existingNames) {
    var index = 0;
    var uniqueName = name;
    // Check if the name or name with index exists
    while (existingNames.includes(uniqueName)) {
        index += 1;
        uniqueName = "".concat(name, " (").concat(index, ")");
    }
    return uniqueName;
};
