var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { faChevronDown, faChevronRight, faTimesCircle } from "@faharmony/icons";
import { Text, Toolbar, Divider, ButtonPopover, Button, } from "@faharmony/components";
import { theme, Grid, Box } from "@faharmony/theme";
import { StyledHead } from "./style";
var getHeaderActions = function (_a) {
    var _b = _a.actions, actions = _b === void 0 ? [] : _b, actionsInHeader = _a.actionsInHeader, onClose = _a.onClose, actionsContent = _a.actionsContent;
    var headerActions = [];
    if (actionsInHeader && !actionsContent)
        headerActions = __spreadArray([], __read(actions), false);
    if (onClose) {
        var closeButtonObject = {
            id: "close-summary-view",
            onClick: onClose,
            icon: faTimesCircle,
            tooltip: "Close",
        };
        headerActions.push(closeButtonObject);
    }
    return __spreadArray([], __read(headerActions), false);
};
var Title = function (props) {
    var id = props.id, caption = props.caption, captionSuffix = props.captionSuffix, heading = props.heading, headingSuffix = props.headingSuffix, actionsContent = props.actionsContent, additionalContent = props.additionalContent, _a = props.additionalContentExpandedByDefault, additionalContentExpandedByDefault = _a === void 0 ? false : _a, _b = props.additionalContentToggleButtonEnabled, additionalContentToggleButtonEnabled = _b === void 0 ? false : _b;
    var hasSuffix = !!headingSuffix;
    var headerActions = getHeaderActions(props);
    var _c = __read(useState(additionalContentExpandedByDefault), 2), additionalContentExpanded = _c[0], setAdditionalContentExpanded = _c[1];
    return (_jsxs(Grid, __assign({ gridTemplate: "auto / auto max-content", overflow: "inherit" }, { children: [_jsxs("div", __assign({ className: "SummaryView-header-text" }, { children: [_jsxs(Box, { children: [_jsx(Text, { value: caption.toString(), variant: "strong", color: "secondary", truncate: !captionSuffix }), captionSuffix] }), _jsxs(Box, { children: [_jsx("div", { children: _jsx(Text, { value: heading, variant: "h3", truncate: !hasSuffix }) }), headingSuffix, additionalContentToggleButtonEnabled && (_jsx("div", { children: _jsx(Button, { icon: additionalContentExpanded ? faChevronDown : faChevronRight, spacing: "xs", id: id ? "".concat(id, "ExpandContentViewAdditionalContent") : "", onClick: function () {
                                        return setAdditionalContentExpanded(!additionalContentExpanded);
                                    } }) }))] })] })), _jsxs(Box, __assign({ className: "SummaryView-header-actions", height: "100%", alignItems: "unset", style: { paddingTop: theme.spacing.md } }, { children: [actionsContent, headerActions.map(function (action) { return (_jsx(ButtonPopover, __assign({}, action, { spacing: "sm", outline: true }), action.id)); })] })), (!additionalContentToggleButtonEnabled || additionalContentExpanded) &&
                additionalContent ? (_jsx(Box, __assign({ className: "SummaryView-header-additional-content", style: { paddingBottom: theme.spacing.lg } }, { children: additionalContent }))) : null] })));
};
export var Header = function (props) {
    var actionsInHeader = props.actionsInHeader, _a = props.actions, actions = _a === void 0 ? [] : _a, toolbarContent = props.toolbarContent, tabs = props.tabs, TabBar = props.TabBar, tabOrientation = props.tabOrientation;
    return (_jsxs(StyledHead, __assign({ className: "SummaryView-header" }, { children: [_jsx(Title, __assign({}, props)), toolbarContent ? (_jsx(Toolbar, { children: toolbarContent })) : (!!actions.length && !actionsInHeader && _jsx(Toolbar, { actions: actions })), tabs && tabOrientation !== "vertical" ? _jsx(TabBar, {}) : _jsx(Divider, {})] })));
};
