var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Drawer, Grid, IconButton, Tooltip, Typography, } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useMuiFaTheme, getCurrentDarkModePreference } from "@faharmony/theme";
import { useState, useEffect } from "react";
export var SideView = function (props) {
    var _a, _b, _c, _d;
    var _e = __read(useState(false), 2), open = _e[0], setOpen = _e[1];
    useEffect(function () {
        setOpen(props.open);
    }, [props.open]);
    var _f = __assign({}, props), _g = _f.showCloseIcon, showCloseIcon = _g === void 0 ? true : _g, _h = _f.toolBarPosition, toolBarPosition = _h === void 0 ? "bottom" : _h, _j = _f.closeOnClickOutside, closeOnClickOutside = _j === void 0 ? true : _j, _k = _f.closeOnEsc, closeOnEsc = _k === void 0 ? true : _k;
    var theme = useMuiFaTheme();
    var _l = __assign({}, props.drawerProps), drawerSx = _l.sx, drawerProps = __rest(_l, ["sx"]);
    return (_jsx(Drawer, __assign({ variant: "temporary", PaperProps: { id: props.id }, open: open, onClose: function (_event, reason) {
            var _a;
            if ((reason === "backdropClick" && closeOnClickOutside) ||
                (reason === "escapeKeyDown" && closeOnEsc)) {
                (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props);
            }
        }, anchor: "right", sx: __assign(__assign({}, drawerSx), { width: (_b = (_a = props.width) !== null && _a !== void 0 ? _a : drawerSx === null || drawerSx === void 0 ? void 0 : drawerSx.width) !== null && _b !== void 0 ? _b : "50%", "&.MuiDrawer-root": __assign({}, __assign({}, (drawerProps.variant === "persistent"
                ? {
                    width: !open ? "0" : undefined,
                    transition: theme.transitions.create(["width"], {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                    zIndex: 0,
                }
                : {}))), "& .MuiDrawer-paper": __assign({ width: (_d = (_c = props.width) !== null && _c !== void 0 ? _c : drawerSx === null || drawerSx === void 0 ? void 0 : drawerSx.width) !== null && _d !== void 0 ? _d : "50%", boxSizing: "border-box" }, __assign({}, (drawerProps.variant === "persistent"
                ? {
                    display: "".concat(!open ? "none" : "box"),
                    border: "1px solid ".concat(getCurrentDarkModePreference() ? "#515151" : "#e0e0e0"),
                    width: "100%",
                    borderRadius: 1,
                    position: "relative",
                }
                : {}))) }) }, drawerProps, { children: _jsxs(Grid, __assign({ container: true, id: props.id + "_container", direction: "column", sx: {
                display: "flex",
                height: "100%",
                background: theme.palette.background.primary,
                flexWrap: "nowrap",
            } }, { children: [_jsx(Grid, __assign({ id: props.id + "_header_root", item: true, xs: 12, boxShadow: theme.shadows[1], sx: {
                        flexBasis: "auto !important",
                    } }, { children: _jsxs(Grid, __assign({ id: props.id + "_header", sx: {
                            pl: 2,
                            pr: 1,
                            py: 0.5,
                            mb: 0,
                            width: "100%",
                            zIndex: 2,
                        } }, { children: [_jsxs(Grid, __assign({ container: true, flexDirection: "row", alignItems: "center", flexWrap: "nowrap" }, { children: [_jsxs(Grid, __assign({ container: true, direction: "column", item: true, sx: { width: "100%" } }, { children: [_jsx(Typography, __assign({ variant: "subtitle1", fontWeight: 600, id: props.id + "_header_subTitle" }, { children: props.subTitle })), _jsxs(Grid, __assign({ item: true, flexDirection: "row" }, { children: [_jsx(Typography, __assign({ variant: "h5", id: props.id + "_header_title", sx: { mr: 1 } }, { children: props.title })), props.additionalTitleContent] }))] })), props.toolbar && toolBarPosition === "left" && (_jsx(Grid, __assign({ item: true, id: props.id + "_header_toolbar" }, { children: props.toolbar }))), showCloseIcon && (_jsx(Grid, { children: _jsx(Tooltip, __assign({ title: "Close" }, { children: _jsx(IconButton, __assign({ id: props.id + "_header_btn_close", "aria-label": "close", onClick: function () {
                                                    if (props.onClose) {
                                                        props.onClose();
                                                    }
                                                    else
                                                        setOpen(false);
                                                } }, { children: _jsx(Close, {}) })) })) }))] })), props.toolbar && toolBarPosition === "bottom" && (_jsx(Grid, __assign({ item: true, mt: 1, id: props.id + "_header_toolbar" }, { children: props.toolbar })))] })) })), _jsx(Grid, __assign({ item: true, sx: __assign({ height: "100%", overflow: "auto", mt: "1px" }, props.containerSxProps), id: props.id + "_content_root", xs: 12 }, { children: props.children }))] })) })));
};
