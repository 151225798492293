var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Provider } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
var convertArrayToObject = function (array) {
    var initialValue = {};
    return array.reduce(function (obj, item) {
        var _a;
        return __assign(__assign({}, obj), (_a = {}, _a[item["name"]] = item.reducer, _a));
    }, initialValue);
};
/** Wrapper to provide global state management to app */
var StateProvider = function (_a) {
    var children = _a.children, slices = _a.slices;
    if (slices.length > 0) {
        var rootReducer = combineReducers(convertArrayToObject(slices));
        var store = configureStore({
            middleware: function (getDefaultMiddleware) {
                return getDefaultMiddleware({
                    serializableCheck: false,
                });
            },
            reducer: rootReducer,
        });
        return _jsx(Provider, __assign({ store: store }, { children: children }));
    }
    else {
        return _jsx(React.Fragment, { children: children });
    }
};
export { StateProvider };
