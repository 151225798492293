var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState, useMemo, useCallback, } from "react";
import _ from "lodash";
import { GET_USER_PREFERENCES_QUERY, usePreferences, baseApolloClient } from "@faharmony/service";
import { uniqueName } from "@faharmony/helpers";
import { addToastMUI } from "@faharmony/core";
import { useModuleTranslation } from "@faharmony/module";
var DEFAULT_GRID_PREFERENCES = {
    preferences: {},
    defaultPreference: null,
};
var applyGridPreferences = function (gridApiRef, preferences, selectedGridPreference) {
    var _a;
    var preferencesToApply = preferences.preferences[selectedGridPreference];
    gridApiRef.current.restoreState(preferencesToApply);
    //handle density separately (not supported by export/restore state)
    gridApiRef.current.setDensity(preferencesToApply.density || "standard");
    //handle column visibility separately (not supported by export/restore state)
    if ((_a = preferencesToApply === null || preferencesToApply === void 0 ? void 0 : preferencesToApply.columns) === null || _a === void 0 ? void 0 : _a.columnVisibilityModel) {
        gridApiRef.current.setColumnVisibilityModel(preferencesToApply.columns.columnVisibilityModel);
    }
};
var setInitialStatesToStorage = function (preferences, prefKey) {
    if (preferences) {
        sessionStorage.setItem("initialStates-".concat(prefKey), JSON.stringify(preferences));
    }
};
var getInitialStatesFromStorage = function (prefKey) {
    var initialStates = sessionStorage.getItem("initialStates-".concat(prefKey));
    return initialStates ? JSON.parse(initialStates) : undefined;
};
//only write to cache every 500ms
var CACHE_WRITE_INTERVAL = 500;
/**
 * Gets and maintains grid preferences for a grid. Use with the GridPreferenceSelector component.
 * It writes changes to the cache when the user changes  the grid settings and to the api when the user saves/deletes/renames.
 * Cache writes happen on every grid state change, which is spammed. It is debounced to avoid performance issues.
 * @todo replace the cleanState with a native method to reset the grid to a 'default' state.
 * @param prefKey the id of the grid you want to maintain preferences for. This needs to be UNIQUE accross applications.
 * @param gridApiRef the gridApiRef of the datagrid you want to maintain preferences for.
 * @param skip whether to skip the query to get preferences from the api.
 */
export var useGridPreferenceSelector = function (prefKey, gridApiRef, skip) {
    var _a, _b, _c;
    if (skip === void 0) { skip = false; }
    var _d = __read(useState(false), 2), cacheLock = _d[0], setCacheLock = _d[1];
    var t = useModuleTranslation();
    //Record the very first state of the grid
    //and use it as the 'clean state' to reset to
    //as there is no method to reset a grid to a 'default' state
    var _e = __read(useState((_b = (_a = gridApiRef === null || gridApiRef === void 0 ? void 0 : gridApiRef.current) === null || _a === void 0 ? void 0 : _a.exportState) === null || _b === void 0 ? void 0 : _b.call(_a)), 2), cleanState = _e[0], setCleanState = _e[1];
    useEffect(function () {
        var _a, _b;
        setCleanState((_b = (_a = gridApiRef === null || gridApiRef === void 0 ? void 0 : gridApiRef.current) === null || _a === void 0 ? void 0 : _a.exportState) === null || _b === void 0 ? void 0 : _b.call(_a));
    }, []);
    //Record whether the grid has been initialized with preferences from API
    var _f = __read(useState(false), 2), hasInitialized = _f[0], setHasInitialized = _f[1];
    var _g = usePreferences(prefKey, skip), preferenceData = _g.preferenceData, setPreferences = _g.setPreferences, preferencesLoading = _g.preferencesLoading, setPreferencesLoading = _g.setPreferencesLoading;
    //selectable grid preferences
    var selectablePreferences = useMemo(function () {
        return ((preferenceData === null || preferenceData === void 0 ? void 0 : preferenceData.preferences)
            ? Object.keys(preferenceData === null || preferenceData === void 0 ? void 0 : preferenceData.preferences)
            : []).sort(function (a, b) { return a.localeCompare(b); });
    }, [preferenceData, prefKey]);
    var _h = __read(useState(null), 2), selectedGridPreference = _h[0], setSelectedGridPreference = _h[1];
    /**
     * INITIALIZE SELECTED GRID PREFERENCE
     * Set the selected grid preference if the user has not selected one yet.
     */
    var _j = __read(useState(false), 2), hasDefaultedSelectedPreference = _j[0], setHasDefaultedSelectedPreference = _j[1];
    useEffect(function () {
        var _a, _b, _c;
        if (preferenceData && !hasDefaultedSelectedPreference) {
            var lastSelected = sessionStorage.getItem("".concat(prefKey, "-selectedGridPreference"));
            setSelectedGridPreference(lastSelected && selectablePreferences.includes(lastSelected)
                ? lastSelected
                : (_c = (_b = (_a = preferenceData.defaultPreference) !== null && _a !== void 0 ? _a : selectablePreferences[0]) !== null && _b !== void 0 ? _b : DEFAULT_GRID_PREFERENCES.defaultPreference) !== null && _c !== void 0 ? _c : null);
            setHasDefaultedSelectedPreference(true);
        }
    }, [preferenceData, selectablePreferences, prefKey]);
    /**
     * RECORD DIFF FROM INITIAL STATES OF PREFERENCES
     * This is used to determine whether the user has changes to save.
     * @todo: This can trigger false positives if new columns have been added to the grid.
     */
    var _k = __read(useState(getInitialStatesFromStorage(prefKey) || (preferenceData === null || preferenceData === void 0 ? void 0 : preferenceData.preferences) || {}), 2), initialStates = _k[0], setInitialStates = _k[1];
    var _l = __read(useState(false), 2), hasModifiedSelectedPreference = _l[0], setHasModifiedSelectedPreference = _l[1];
    useEffect(function () {
        var _a;
        var currPreference = selectedGridPreference ?
            (_a = preferenceData === null || preferenceData === void 0 ? void 0 : preferenceData.preferences) === null || _a === void 0 ? void 0 : _a[selectedGridPreference] : undefined;
        var currPreferenceAsString = currPreference
            ? JSON.stringify(currPreference)
            : undefined;
        var preferenceInitially = selectedGridPreference ? initialStates === null || initialStates === void 0 ? void 0 : initialStates[selectedGridPreference] : undefined;
        var preferenceInitiallyAsString = preferenceInitially
            ? JSON.stringify(preferenceInitially)
            : undefined;
        if (currPreferenceAsString === preferenceInitiallyAsString) {
            setHasModifiedSelectedPreference(false);
        }
        else {
            setHasModifiedSelectedPreference(true);
        }
    }, [preferenceData, initialStates, selectedGridPreference]);
    /**
     * PERSIST SELECTED GRID PREFERENCE TO LOCAL STORAGE
     */
    useEffect(function () {
        if (selectedGridPreference) {
            sessionStorage.setItem("".concat(prefKey, "-selectedGridPreference"), selectedGridPreference);
        }
    }, [selectedGridPreference]);
    /**
     * APPLY PREFERENCES WHEN USER CHANGES SELECTED GRID PREFERENCE
     * This will apply the preferences to the grid when the user changes the selected grid preference.
     */
    useEffect(function () {
        var _a, _b, _c;
        var canApply = selectedGridPreference &&
            ((_a = preferenceData === null || preferenceData === void 0 ? void 0 : preferenceData.preferences) === null || _a === void 0 ? void 0 : _a[selectedGridPreference]);
        if (canApply) {
            //store the initial state of the grid
            //before it has been modified by the user
            if (!hasInitialized) {
                var newInitialStates = getInitialStatesFromStorage(prefKey) ||
                    preferenceData.preferences ||
                    {};
                setInitialStates(newInitialStates);
                setInitialStatesToStorage(newInitialStates, prefKey);
                setHasInitialized(true);
            }
            //apply the preferences
            setCacheLock(true);
            applyGridPreferences(gridApiRef, preferenceData, selectedGridPreference);
            setCacheLock(false);
        }
        //reset the grid to a 'clean' state
        if (!selectedGridPreference && hasDefaultedSelectedPreference) {
            (_c = (_b = gridApiRef === null || gridApiRef === void 0 ? void 0 : gridApiRef.current) === null || _b === void 0 ? void 0 : _b.restoreState) === null || _c === void 0 ? void 0 : _c.call(_b, cleanState);
        }
    }, [preferenceData, selectedGridPreference, hasDefaultedSelectedPreference]);
    /**
     * SAVE PREFERENCES TO API
     * This will save the preferences to the api.
     */
    var savePreferencesInBackend = function (newState, onComplete) { return __awaiter(void 0, void 0, void 0, function () {
        var newStateAsString, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setCacheLock(true);
                    newStateAsString = newState ? JSON.stringify(newState) : "";
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, setPreferences({
                            variables: { prefValue: newStateAsString },
                            /* optimisticResponse: {
                              savePreference: true,
                            }, */
                            update: function (_cache, _a) {
                                var data = _a.data;
                                if (data === null || data === void 0 ? void 0 : data.savePreference) {
                                    //only update the cache if the request is the latest
                                    savePreferencesInCache(newState);
                                }
                            },
                            onError: function () {
                                //revert any state that were optimistically updated
                                addToastMUI(t("messages.updatePreferenceError"), {
                                    variant: "error",
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "right",
                                    },
                                    autoHideDuration: 5000,
                                });
                            },
                            onCompleted: function () {
                                onComplete === null || onComplete === void 0 ? void 0 : onComplete();
                            }
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    console.error("Failed to save grid preferences to api.");
                    return [3 /*break*/, 4];
                case 4:
                    setCacheLock(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var savePreferencesInCache = useCallback(function (newState) {
        //save the state to the api
        baseApolloClient.writeQuery({
            query: GET_USER_PREFERENCES_QUERY,
            variables: { prefKey: prefKey },
            data: {
                loadPreference: JSON.stringify(newState),
            },
        });
    }, [prefKey]);
    var savePreferencesInCacheDebounced = useCallback(_.debounce(function (newState) {
        //save the state to the api
        baseApolloClient.writeQuery({
            query: GET_USER_PREFERENCES_QUERY,
            variables: { prefKey: prefKey },
            data: {
                loadPreference: JSON.stringify(newState),
            },
        });
    }, CACHE_WRITE_INTERVAL), [prefKey]);
    /**
     * Apply to onChange of the combobox where the user selects the grid preference.
     * This will set the selected grid preference.
     */
    var onSetSelectedPreference = function (newPreference) {
        //set the selected preference
        setSelectedGridPreference(newPreference);
    };
    /**
     * Saves the currently selected preference to backend and
     * resets the initial state of the grid to the current state.
     */
    var onSavePreferences = function () { return __awaiter(void 0, void 0, void 0, function () {
        var preferences, newState, newInitialState_1;
        var _a, _b;
        var _c, _d, _e, _f, _g, _h;
        return __generator(this, function (_j) {
            switch (_j.label) {
                case 0:
                    if (!selectedGridPreference) return [3 /*break*/, 2];
                    preferences = preferenceData !== null && preferenceData !== void 0 ? preferenceData : DEFAULT_GRID_PREFERENCES;
                    newState = __assign(__assign({}, preferences), { preferences: __assign(__assign({}, preferences.preferences), (_a = {}, _a[selectedGridPreference] = __assign({ density: (_c = gridApiRef === null || gridApiRef === void 0 ? void 0 : gridApiRef.current) === null || _c === void 0 ? void 0 : _c.state.density.value }, (_e = (_d = gridApiRef === null || gridApiRef === void 0 ? void 0 : gridApiRef.current) === null || _d === void 0 ? void 0 : _d.exportState) === null || _e === void 0 ? void 0 : _e.call(_d)), _a)) });
                    newInitialState_1 = __assign(__assign({}, initialStates), (_b = {}, _b[selectedGridPreference] = __assign({ density: (_f = gridApiRef === null || gridApiRef === void 0 ? void 0 : gridApiRef.current) === null || _f === void 0 ? void 0 : _f.state.density.value }, (_h = (_g = gridApiRef === null || gridApiRef === void 0 ? void 0 : gridApiRef.current) === null || _g === void 0 ? void 0 : _g.exportState) === null || _h === void 0 ? void 0 : _h.call(_g)), _b));
                    return [4 /*yield*/, savePreferencesInBackend(newState, function () {
                            //on success
                            setInitialStates(newInitialState_1);
                            setInitialStatesToStorage(newInitialState_1, prefKey);
                        })];
                case 1:
                    _j.sent();
                    _j.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    /**
     * Apply to onStateChange of the grid.
     * Saves the grid state to the cache when the grid state changes.
     * If not used, changes to the grid will not stick locally.
     */
    var onGridStateChange = function () {
        var _a;
        var _b, _c, _d;
        if (cacheLock)
            return;
        if (!preferencesLoading && selectedGridPreference && hasInitialized) {
            //start listening for changes only when the grid has initilized
            //with default preferences
            var preferences = preferenceData !== null && preferenceData !== void 0 ? preferenceData : DEFAULT_GRID_PREFERENCES;
            var newState = __assign(__assign({}, preferences), { preferences: __assign(__assign({}, preferences.preferences), (_a = {}, _a[selectedGridPreference] = __assign({ density: (_b = gridApiRef === null || gridApiRef === void 0 ? void 0 : gridApiRef.current) === null || _b === void 0 ? void 0 : _b.state.density.value }, (_d = (_c = gridApiRef === null || gridApiRef === void 0 ? void 0 : gridApiRef.current) === null || _c === void 0 ? void 0 : _c.exportState) === null || _d === void 0 ? void 0 : _d.call(_c)), _a)) });
            //the grid state changes very frequently
            //so we debounce the save to the cache
            //otherwise there are performance issues
            savePreferencesInCacheDebounced(newState);
        }
    };
    /**
     * Sets the given preference as the default preference
     * and stores the change in the backend.
     */
    var onSetDefaultPreference = function (newPreference) { return __awaiter(void 0, void 0, void 0, function () {
        var preferences, newState;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    preferences = preferenceData !== null && preferenceData !== void 0 ? preferenceData : DEFAULT_GRID_PREFERENCES;
                    if (!newPreference || newPreference === (preferenceData === null || preferenceData === void 0 ? void 0 : preferenceData.defaultPreference)) {
                        //there is nothing to update
                        return [2 /*return*/];
                    }
                    newState = _.cloneDeep(preferences);
                    newState.defaultPreference = newPreference;
                    //persist the new default to the backend
                    return [4 /*yield*/, savePreferencesInBackend(newState)];
                case 1:
                    //persist the new default to the backend
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    /**
     * Deletes the given preference and stores the change in backend.
     * If the deleted preference was the default preference,
     * the default preference will be set to the first preference.
     */
    var onDeletePreference = function (preferenceName) { return __awaiter(void 0, void 0, void 0, function () {
        var preferences, newState;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(preferenceName &&
                        preferenceName !== DEFAULT_GRID_PREFERENCES.defaultPreference)) return [3 /*break*/, 2];
                    preferences = preferenceData !== null && preferenceData !== void 0 ? preferenceData : DEFAULT_GRID_PREFERENCES;
                    newState = _.cloneDeep(preferences);
                    //delete the preference
                    delete newState.preferences[preferenceName];
                    if (preferenceName === newState.defaultPreference) {
                        //if the deleted preference was the default preference
                        //set the default to the first preference or default
                        newState.defaultPreference =
                            ((_a = Object.keys(newState.preferences)) === null || _a === void 0 ? void 0 : _a[0]) ||
                                DEFAULT_GRID_PREFERENCES.defaultPreference;
                    }
                    //persist the delete to the backend
                    return [4 /*yield*/, savePreferencesInBackend(newState, function () {
                            //revert on success
                            if (selectedGridPreference === preferenceName) {
                                setSelectedGridPreference(null);
                            }
                        })];
                case 1:
                    //persist the delete to the backend
                    _b.sent();
                    _b.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    /**
     * Adds a new preference with the given name and stores it in the backend.
     * If there is already a preference with the same name, a number will be appended to the end.
     */
    var onAddNewPreference = function (newPreferenceName) { return __awaiter(void 0, void 0, void 0, function () {
        var newPreferenceNameTrimmed, preferences, newState, existingNames, newName, newInitialStates;
        var _a;
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    newPreferenceNameTrimmed = newPreferenceName.trim();
                    if (!newPreferenceNameTrimmed)
                        return [2 /*return*/];
                    preferences = preferenceData !== null && preferenceData !== void 0 ? preferenceData : DEFAULT_GRID_PREFERENCES;
                    newState = _.cloneDeep(preferences);
                    existingNames = Object.keys(newState.preferences);
                    newName = uniqueName(newPreferenceNameTrimmed, existingNames);
                    //set the new preferences
                    newState.preferences[newName] = __assign({ density: (_b = gridApiRef === null || gridApiRef === void 0 ? void 0 : gridApiRef.current) === null || _b === void 0 ? void 0 : _b.state.density.value }, (_d = (_c = gridApiRef === null || gridApiRef === void 0 ? void 0 : gridApiRef.current) === null || _c === void 0 ? void 0 : _c.exportState) === null || _d === void 0 ? void 0 : _d.call(_c));
                    //set as default if there is no default
                    if (!newState.defaultPreference) {
                        newState.defaultPreference = newName;
                    }
                    newInitialStates = __assign(__assign({}, initialStates), (_a = {}, _a[newName] = newState.preferences[newName], _a));
                    return [4 /*yield*/, savePreferencesInBackend(newState, function () {
                            //on success
                            setInitialStates(newInitialStates);
                            setInitialStatesToStorage(newInitialStates, prefKey);
                            setSelectedGridPreference(newName);
                        })];
                case 1:
                    _e.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    /**
     * Adds a new preference with the given name and stores it in the backend.
     * If there is already a preference with the same name, a number will be appended to the end.
     */
    var onSaveAsPreference = function (preferenceName) { return __awaiter(void 0, void 0, void 0, function () {
        var preferenceNameTrimmed, preferences, newState, existingNames, newName, newInitialStates;
        var _a;
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    preferenceNameTrimmed = preferenceName.trim();
                    if (!preferenceNameTrimmed)
                        return [2 /*return*/];
                    preferences = preferenceData !== null && preferenceData !== void 0 ? preferenceData : DEFAULT_GRID_PREFERENCES;
                    newState = _.cloneDeep(preferences);
                    existingNames = Object.keys(newState.preferences);
                    newName = preferenceNameTrimmed === selectedGridPreference
                        ? preferenceNameTrimmed
                        : uniqueName(preferenceNameTrimmed, existingNames);
                    //set the new preferences
                    newState.preferences[newName] = __assign({ density: (_b = gridApiRef === null || gridApiRef === void 0 ? void 0 : gridApiRef.current) === null || _b === void 0 ? void 0 : _b.state.density.value }, (_d = (_c = gridApiRef === null || gridApiRef === void 0 ? void 0 : gridApiRef.current) === null || _c === void 0 ? void 0 : _c.exportState) === null || _d === void 0 ? void 0 : _d.call(_c));
                    //set as default if there is no default
                    if (!newState.defaultPreference) {
                        newState.defaultPreference = newName;
                    }
                    newInitialStates = __assign(__assign({}, initialStates), (_a = {}, _a[newName] = newState.preferences[newName], _a));
                    //persist the new preference to the backend
                    return [4 /*yield*/, savePreferencesInBackend(newState, function () {
                            //on api success
                            setInitialStates(newInitialStates);
                            setInitialStatesToStorage(newInitialStates, prefKey);
                            //set the new preference as the selected preference
                            if (newName !== selectedGridPreference) {
                                setSelectedGridPreference(newName);
                            }
                        })];
                case 1:
                    //persist the new preference to the backend
                    _e.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onRestorePreference = function (preferenceName) {
        var _a;
        if (preferenceName) {
            var preferences = preferenceData !== null && preferenceData !== void 0 ? preferenceData : DEFAULT_GRID_PREFERENCES;
            var newState = __assign(__assign({}, preferences), { preferences: __assign(__assign({}, preferences.preferences), (_a = {}, _a[preferenceName] = initialStates[preferenceName], _a)) });
            setCacheLock(true);
            //persist the new state locally
            savePreferencesInCache(newState);
            setCacheLock(false);
        }
    };
    var onRenamePreference = function (oldName, newName) { return __awaiter(void 0, void 0, void 0, function () {
        var newNameTrimmed, preferences, newState, existingNames, finalName, newInitialState, newInitialStates;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    newNameTrimmed = newName.trim();
                    if (!oldName || !newNameTrimmed || oldName === newNameTrimmed)
                        return [2 /*return*/];
                    preferences = preferenceData !== null && preferenceData !== void 0 ? preferenceData : DEFAULT_GRID_PREFERENCES;
                    newState = __assign(__assign({}, preferences), { preferences: __assign({}, preferences.preferences) });
                    existingNames = Object.keys(newState.preferences);
                    finalName = uniqueName(newNameTrimmed, existingNames);
                    //set the new preferences
                    newState.preferences[finalName] = preferences.preferences[oldName];
                    delete newState.preferences[oldName];
                    newInitialState = _.cloneDeep(initialStates);
                    delete newInitialState[oldName];
                    newInitialStates = __assign(__assign({}, newInitialState), (_a = {}, _a[finalName] = newState.preferences[finalName], _a));
                    //update the default preference if it was the one that was edited
                    if (newState.defaultPreference === oldName) {
                        newState.defaultPreference = finalName;
                    }
                    return [4 /*yield*/, savePreferencesInBackend(newState, function () {
                            //on success
                            setInitialStates(newInitialStates);
                            setInitialStatesToStorage(newInitialStates, prefKey);
                            //update the selected preference if it was the one that was edited
                            if (selectedGridPreference === oldName) {
                                setSelectedGridPreference(finalName);
                            }
                        })];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return {
        loading: preferencesLoading,
        submitting: setPreferencesLoading,
        selectedGridPreference: selectedGridPreference,
        selectablePreferences: selectablePreferences,
        defaultPreference: (_c = preferenceData === null || preferenceData === void 0 ? void 0 : preferenceData.defaultPreference) !== null && _c !== void 0 ? _c : null,
        hasModifiedSelectedPreference: hasModifiedSelectedPreference,
        onAddNewPreference: onAddNewPreference,
        onDeletePreference: onDeletePreference,
        onSavePreferences: onSavePreferences,
        onSaveAsPreference: onSaveAsPreference,
        onRestorePreference: onRestorePreference,
        onRenamePreference: onRenamePreference,
        onGridStateChange: onGridStateChange,
        onSetSelectedPreference: onSetSelectedPreference,
        onSetDefaultPreference: onSetDefaultPreference,
    };
};
