var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useCallback, useState } from "react";
import { theme, styled, motion, useSpring } from "@faharmony/theme";
import { NOOP } from "@faharmony/helpers";
var StyledPopover = styled(motion.div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  box-shadow: ", ";\n  border-radius: ", ";\n  background: ", ";\n\n  padding: 0;\n  overflow: ", ";\n  /* \n  #arrow,\n  #arrow::before {\n    position: absolute;\n    width: 8px;\n    height: 8px;\n  }\n\n  #arrow::before {\n    content: \"\";\n    transform: rotate(45deg);\n    background: ", ";\n  }\n\n  &[data-placement^=\"top\"] > #arrow {\n    bottom: -4px;\n  }\n\n  &[data-placement^=\"bottom\"] > #arrow {\n    top: -4px;\n  }\n\n  &[data-placement^=\"left\"] > #arrow {\n    right: -4px;\n  }\n\n  &[data-placement^=\"right\"] > #arrow {\n    left: -4px;\n  } */\n"], ["\n  border: 1px solid ", ";\n  box-shadow: ", ";\n  border-radius: ", ";\n  background: ", ";\n\n  padding: 0;\n  overflow: ", ";\n  /* \n  #arrow,\n  #arrow::before {\n    position: absolute;\n    width: 8px;\n    height: 8px;\n  }\n\n  #arrow::before {\n    content: \"\";\n    transform: rotate(45deg);\n    background: ", ";\n  }\n\n  &[data-placement^=\"top\"] > #arrow {\n    bottom: -4px;\n  }\n\n  &[data-placement^=\"bottom\"] > #arrow {\n    top: -4px;\n  }\n\n  &[data-placement^=\"left\"] > #arrow {\n    right: -4px;\n  }\n\n  &[data-placement^=\"right\"] > #arrow {\n    left: -4px;\n  } */\n"])), theme.color.border.default, theme.shadow.float, theme.radius[300], function (p) {
    return p.transparent ? "transparent" : theme.color.background.primary;
}, function (p) { return p.overflow || "auto"; }, theme.color.background.primary);
var usePopoverOptions = function (_a) {
    var _b = _a.onOpen, onOpen = _b === void 0 ? NOOP : _b, _c = _a.onClose, onClose = _c === void 0 ? NOOP : _c, _d = _a.placement, placement = _d === void 0 ? "auto" : _d, _e = _a.persistent, persistent = _e === void 0 ? false : _e, _f = _a.trigger, trigger = _f === void 0 ? "click" : _f, _g = _a.disabled, disabled = _g === void 0 ? false : _g, content = _a.content, tippyProps = _a.tippyProps, overflow = _a.overflow;
    var _h = __read(useState(), 2), instance = _h[0], setInstance = _h[1];
    var initialScale = 0.9;
    var opacity = useSpring(0, theme.transition.spring);
    var scale = useSpring(initialScale, theme.transition.spring);
    var onCreate = function (tippyInstance) {
        setInstance({
            closePopover: tippyInstance.hide,
            id: tippyInstance.id.toString(),
            openPopover: tippyInstance.show,
        });
    };
    var onMount = useCallback(function () {
        scale.set(1);
        opacity.set(1);
        onOpen();
    }, []);
    var onHide = useCallback(function (_a) {
        var unmount = _a.unmount;
        var cleanup = scale.onChange(function (value) {
            if (value <= initialScale) {
                cleanup();
                unmount();
            }
        });
        scale.set(initialScale);
        opacity.set(0);
        onClose();
    }, []);
    var render = useCallback(function (attrs) {
        return instance ? (_jsx(StyledPopover, __assign({ className: "popover", overflow: overflow, tabIndex: -1, role: "popover", style: { scale: scale, opacity: opacity } }, attrs, { children: content(instance) }))) : null;
    }, [instance, scale, opacity, content]);
    var isContextMenu = trigger === "contextmenu";
    var options = useMemo(function () { return (__assign({ allowHTML: true, appendTo: function () { return document.body; }, arrow: true, placement: placement, render: render, hideOnClick: persistent ? "toggle" : true, interactive: true, offset: [0, 4], onCreate: onCreate, onMount: onMount, onHide: onHide, disabled: disabled, touch: isContextMenu ? "hold" : true, trigger: trigger, zIndex: theme.zIndex.popover }, tippyProps)); }, [render, tippyProps, isContextMenu, trigger, onMount, onHide]);
    return { options: options, instance: instance };
};
export default usePopoverOptions;
var templateObject_1;
