var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { onError } from "@apollo/client/link/error";
import { ApolloProvider, ApolloClient, ApolloLink, createHttpLink, InMemoryCache, } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getAuthToken } from "../keycloak";
var errorLink = onError(function (_a) {
    var networkError = _a.networkError;
    if (networkError) {
        if ("bodyText" in networkError) {
            try {
                JSON.parse(networkError.bodyText);
            }
            catch (e) {
                if ("statusCode" in networkError) {
                    networkError.message = "".concat(networkError.response.status, " ").concat(networkError.response.statusText);
                }
            }
        }
    }
});
var GraphQLProvider = function (props) {
    var keycloakInstance = props.keycloakInstance, customGraphQL = props.customGraphQL, children = props.children;
    // Auth
    var authLink = setContext(function (_, _a) {
        var headers = _a.headers;
        // get the authentication token from local storage if it exists
        var token = (keycloakInstance === null || keycloakInstance === void 0 ? void 0 : keycloakInstance.token) || getAuthToken();
        // return the headers to the context so httpLink can read them
        return {
            headers: __assign(__assign({}, headers), { authorization: token ? "Bearer ".concat(token) : "", Accept: "application/json" }),
        };
    });
    // GraphQL
    var defaultLink = createHttpLink({ uri: "/graphql" });
    // Custom
    var customLink = customGraphQL
        ? typeof customGraphQL.link === "string"
            ? customGraphQL.link.length > 0
                ? createHttpLink({ uri: customGraphQL.link })
                : undefined
            : customGraphQL.link
        : undefined;
    // Link
    var link = customLink
        ? (customGraphQL === null || customGraphQL === void 0 ? void 0 : customGraphQL.split)
            ? authLink.split(function (operation) { return operation.getContext().custom === true; }, customLink, defaultLink)
            : ApolloLink.from([errorLink, authLink, customLink])
        : ApolloLink.from([errorLink, authLink, defaultLink]);
    // Cache
    var cache = new InMemoryCache();
    // Client
    var faGraphQlClient = new ApolloClient({ cache: cache, link: link });
    return _jsx(ApolloProvider, __assign({ client: faGraphQlClient }, { children: children }));
};
export { GraphQLProvider };
