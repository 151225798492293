import { useRef, useEffect } from "react";
/**
 * Hook to manage listeners.
 * @author Gabe Ragland <> (https://github.com/gragland)
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @param eventName https://developer.mozilla.org/en-US/docs/Web/Events
 * @param handler Function to perform on event. Should be wrapped in useCallback()
 * @param element Element to attach listener to (default=window)
 * @example
 *  useEventListener('mousemove', handler);
 */
export function useEventListener(eventName, handler, element) {
    if (element === void 0) { element = window; }
    // Create a ref that stores handler
    var savedHandler = useRef();
    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(function () {
        savedHandler.current = handler;
    }, [handler]);
    useEffect(function () {
        // Make sure element supports addEventListener
        // On
        var isSupported = element && element.addEventListener;
        if (!isSupported)
            return;
        // window.addEventListener();
        // Create event listener that calls handler function stored in ref
        var eventListener = function (event) {
            return savedHandler.current && savedHandler.current(event);
        };
        // Add event listener
        element.addEventListener(eventName, eventListener);
        // Remove event listener on cleanup
        return function () {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element] // Re-run if eventName or element changes
    );
}
