var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// FA FUND MANAGEMENT ICON
export var FAFundManagementAppSVGPath = "M68,392 C101.137085,392 128,418.862915 128,452 C128,485.137085 101.137085,512 68,512 C34.862915,512 8,485.137085 8,452 C8,418.862915 34.862915,392 68,392 Z M256,392 C289.137085,392 316,418.862915 316,452 C316,485.137085 289.137085,512 256,512 C222.862915,512 196,485.137085 196,452 C196,418.862915 222.862915,392 256,392 Z M444,392 C477.137085,392 504,418.862915 504,452 C504,485.137085 477.137085,512 444,512 C410.862915,512 384,485.137085 384,452 C384,418.862915 410.862915,392 444,392 Z M88,380 L48,380 L48,340 C48,328.954305 56.954305,320 68,320 L236,320 L236,297 L25.6,297 C11.6054219,297 0.229548438,285.771387 0,271.839659 L0,25.5833333 C0,11.5978663 11.2359277,0.229398992 25.1767322,0 L486.4,0 C500.394578,0 511.770452,11.2286126 512,25.1603411 L512,271.416667 C512,285.402134 500.764072,296.770601 486.823268,296.996572 L486.4,297 L276,297 L276,320 L444,320 C455.045695,320 464,328.954305 464,340 L464,380 L424,380 L424,360 L276,360 L276,380 L236,380 L236,360 L88,360 L88,380 Z M423.705882,37 L90.2941176,37 C90.2941176,65.0697992 67.7886249,87.873196 39.8483218,88.3264569 L39,88.3333333 L39,206.666667 C67.0483555,206.666667 89.8343318,229.189365 90.2872465,257.15103 L90.2941176,258 L423.705882,258 C423.705882,229.930201 446.211375,207.126804 474.151678,206.673543 L475,206.666667 L475,88.3333333 C446.951645,88.3333333 424.165668,65.8106345 423.712753,37.8489704 L423.705882,37 Z M256.5,71 C292.119873,71 321,105.473918 321,148 C321,190.534698 292.112,225 256.5,225 C220.871466,225 192,190.51825 192,148 C192,105.473918 220.880127,71 256.5,71 Z";
export var FAFundManagementAppIcon = function () { return (_jsx("svg", __assign({ viewBox: "512, 512" }, { children: _jsx("path", { d: FAFundManagementAppSVGPath }) }))); };
export var FAFundManagementAppIconDefinition = {
    prefix: "far",
    iconName: "hands-usd",
    icon: [512, 512, [], "f4c5", FAFundManagementAppSVGPath],
};
