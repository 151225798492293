var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { gql, useQuery } from "@faharmony/service";
import { useState, useEffect } from "react";
import { addToast } from "../../Toast";
import { state } from "../state";
// query for querying context aware processes
var GET_PROCESS_DEFINITIONS = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getProcessDefinitions($category: String!, $maxCount: Int) {\n    processDefinitions(maxCount: $maxCount, category: $category) {\n      processDefinition {\n        id\n        key\n        name\n        category\n        description\n        diagram\n      }\n      total\n    }\n  }\n"], ["\n  query getProcessDefinitions($category: String!, $maxCount: Int) {\n    processDefinitions(maxCount: $maxCount, category: $category) {\n      processDefinition {\n        id\n        key\n        name\n        category\n        description\n        diagram\n      }\n      total\n    }\n  }\n"])));
export var useGetProcessDefinitions = function (_a) {
    var _b = _a.category, category = _b === void 0 ? "" : _b, _c = _a.maxCount, maxCount = _c === void 0 ? 10000 : _c;
    var _d = __read(useState([]), 2), processes = _d[0], setProcesses = _d[1];
    useEffect(function () {
        state.processDefinitions = processes;
    }, [processes]);
    var _e = useQuery(GET_PROCESS_DEFINITIONS, {
        notifyOnNetworkStatusChange: true,
        variables: {
            category: category,
            maxCount: maxCount,
        },
        onError: function (error) {
            addToast({
                id: "process-definitions-error",
                title: "Error, could not get workflows definitions",
                description: error.message,
                variant: "negative",
                persist: true,
            });
        },
        onCompleted: function (data) {
            try {
                setProcesses(data.processDefinitions.processDefinition);
            }
            catch (error) {
                console.error(error);
                addToast({
                    id: "failed-parsing-process-definitions",
                    title: "Error, failed to parse workflows definitions",
                    variant: "negative",
                    persist: true,
                });
            }
        },
    }), data = _e.data, loading = _e.loading, error = _e.error;
    return {
        processes: processes,
        data: data,
        error: error,
        loading: loading,
    };
};
var templateObject_1;
