var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from "react";
import { faServicesApiInstance } from "@faharmony/service";
import { FAHarmonySVGPath } from "@faharmony/icons";
/** Dummy app for devMode */
var harmonyApp = {
    position: 0,
    id: "fa-harmony",
    appName: "FA Harmony",
    iconPath: FAHarmonySVGPath,
    url: "/",
    openInNewWindow: false,
};
/** Fetch appList from navigator micro-service */
export default (function (devMode) {
    if (devMode === void 0) { devMode = false; }
    var _a = __read(React.useState([]), 2), apps = _a[0], setApps = _a[1];
    React.useEffect(function () {
        devMode
            ? // Set dummy app in devMode
                setApps([harmonyApp])
            : // Fetch if not in devMode
                faServicesApiInstance
                    .get("navigator/")
                    .then(function (_a) {
                    var data = _a.data;
                    return data && Array.isArray(data) && setApps(data);
                });
    }, [devMode]);
    return apps.sort(function (_a, _b) {
        var a = _a.position;
        var b = _b.position;
        return a - b;
    });
});
