var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { uuid, usePropState } from "@faharmony/helpers";
import { Icon, Help } from "@faharmony/components";
import { faSquare } from "@faharmony/icons";
import { faMinusSquare, faCheckSquare } from "@faharmony/icons/solid";
import { InputPropError, StyledInputLabel, } from "../common";
import { Box } from "@faharmony/theme";
/**
 * Checkbox
 * ---
 * Simple checkbox component
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @copyright FA Solutions Oy
 * @jira https://fasolutions.jira.com/browse/HAR-48
 * @version 1.6.0
 * @example <Checkbox onChange={console.log} />
 */
var Checkbox = React.forwardRef(function (props, ref) {
    var indeterminate = props.indeterminate, label = props.label, defaultChecked = props.defaultChecked, checked = props.checked, onChange = props.onChange, name = props.name, helpText = props.helpText, registerRef = props.registerRef, otherProps = __rest(props, ["indeterminate", "label", "defaultChecked", "checked", "onChange", "name", "helpText", "registerRef"]);
    var _a = __read(usePropState(checked), 2), isChecked = _a[0], setChecked = _a[1];
    var _b = __assign({}, registerRef), onChangeForm = _b.onChange, formRef = _b.ref, formProps = __rest(_b, ["onChange", "ref"]);
    // Return
    if (checked !== undefined && defaultChecked !== undefined)
        return _jsx(InputPropError, { value: "checked" });
    var ID = name || label || uuid();
    var handleChange = function (event) {
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        onChangeForm === null || onChangeForm === void 0 ? void 0 : onChangeForm(event);
        setChecked(event === null || event === void 0 ? void 0 : event.target.checked);
        onChange === null || onChange === void 0 ? void 0 : onChange(event);
    };
    var inputProps = React.useMemo(function () {
        return checked !== undefined
            ? { checked: isChecked, onChange: handleChange }
            : { defaultChecked: defaultChecked, onChange: handleChange };
    }, [isChecked, handleChange, defaultChecked, onChange]);
    return (_jsxs(StyledInputLabel, __assign({ className: "CheckboxField", id: ID, disabled: props.disabled }, { children: [_jsx("input", __assign({}, formProps, { type: "checkbox", ref: formRef !== null && formRef !== void 0 ? formRef : ref, name: ID }, inputProps, otherProps)), indeterminate ? (_jsx(Icon, { icon: faMinusSquare, className: "indeterminate" })) : (_jsxs(React.Fragment, { children: [_jsx(Icon, { icon: faCheckSquare, className: "checked" }), _jsx(Icon, { icon: faSquare, className: "unchecked" })] })), (label || helpText) && (_jsx(Box, { children: _jsx(Box, __assign({ width: "fit-content" }, { children: _jsxs("span", { children: [label, helpText && _jsx(Help, { text: helpText })] }) })) }))] })));
});
export { Checkbox };
