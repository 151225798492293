var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// FA WEALTH MANAGEMENT ICON
export var FAWealthManagementAppSVGPath = "M558.2 333.6c-9.5-8.619-22-13.37-35.12-13.37c-12.5 0-24.88 4.248-34.62 12.12l-61.45 49.21c-1.928 1.545-4.324 2.385-6.795 2.385h-41.62c4.625-9.619 6.5-20.74 4.75-32.23C379.4 323.7 353.7 304 325.6 304H181.2c-20.75 0-40.1 6.746-57.5 19.24l-38.5 28.73H16c-8.801 0-16 7.195-16 15.99S7.198 383.1 15.1 383.1l80.01 .0114l46.86-35.29c11.12-8.244 24.62-12.74 38.37-12.74h146.7c13.25 0 24 10.74 24 23.99s-10.75 23.98-24 23.98H239.1c-8.75 0-16 7.246-16 15.99c0 8.746 7.25 15.99 16 15.99h180.2c9.625 0 19-3.248 26.62-9.246l61.62-49.22c4.25-3.373 9.5-5.248 14.62-5.248c5 0 9.875 1.75 13.75 5.248c10.12 9.119 9.375 24.48-.875 32.61l-100.7 80.58c-7.584 6.064-17.01 9.369-26.72 9.369H16c-8.801 0-16 7.189-16 15.98S7.198 512 15.1 512h392.5c17 0 33.38-5.871 46.62-16.37l100.9-80.7c12.25-9.744 19.5-24.36 19.1-39.98C576.5 359.3 569.1 344.2 558.2 333.6zM275.1 190.8C268.2 189.7 262.6 188.1 237.1 179.5C228.8 176.6 219.7 181.2 216.8 189.6C214 197.9 218.5 207 226.9 209.8c29.34 9.971 36.58 11.45 45.09 12.67V240c0 8.844 7.175 16 16.02 16s15.98-7.156 15.98-16V223.2c29.72-3.51 50.59-19.29 55.03-43.72C367.5 133 322.1 120.7 292.1 112.5L284.9 110.5C250.8 100.1 246.2 94.38 248.5 82.23c3-16.64 28.03-20.52 51.47-17.05c6.562 .9687 14.53 3.047 25.06 6.516c8.375 2.766 17.47-1.812 20.19-10.2c2.781-8.391-1.781-17.44-10.19-20.2c-12.31-4.047-22.59-6.57-31-7.82V16c0-8.844-7.138-16-15.98-16S271.1 7.156 271.1 16v16.8C242.2 36.32 221.4 52.09 216.1 76.52C208.6 122.4 254.3 135.2 276.2 141.3L283.7 143.4c38.22 10.41 46.41 16.08 43.81 30.38C324.5 190.4 299.6 194.4 275.1 190.8z";
export var FAWealthManagementAppIcon = function () { return (_jsx("svg", __assign({ viewBox: "576, 512" }, { children: _jsx("path", { d: FAWealthManagementAppSVGPath }) }))); };
export var FAWealthManagementAppIconDefinition = {
    prefix: "far",
    iconName: "hand-holding-usd",
    icon: [576, 512, [], "f4c5", FAWealthManagementAppSVGPath],
};
