import { useEffect, useRef } from "react";
/**
 * A custom hook that uses the useRef hook internally for storing the previous value
 * @author Gabe Ragland <> (https://github.com/gragland)
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @param {any} value - Derived from useState
 * @example
 *  const [count, setCount] = useState(0);
 *  const prevCount = usePrevious(count);
 */
export function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    var ref = useRef();
    // Store current value in ref
    useEffect(function () {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}
