var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { MenuList } from "./MenuList";
import { AnimatePresence, motion, styled } from "@faharmony/theme";
export var MenuDrilldown = function (_a) {
    var optionsTree = _a.optionsTree, onOptionSelected = _a.onOptionSelected, _b = _a.headless, headless = _b === void 0 ? false : _b, _c = _a.enableFiltering, enableFiltering = _c === void 0 ? false : _c;
    var _d = __read(useState([optionsTree]), 2), renderedLists = _d[0], setRenderedLists = _d[1];
    var _e = __read(useState([]), 2), selectedOptions = _e[0], setSelectedOptions = _e[1];
    return (_jsx(MenuDrilldownContainer, { children: _jsx(AnimatePresence, { children: renderedLists.map(function (_a, i) {
                var _b = _a.label, label = _b === void 0 ? "" : _b, options = _a.options;
                return (_jsx(RenderedListsContainer, __assign({ transition: { duration: 0.2, ease: "easeOut" }, initial: { opacity: 0, x: 0 }, animate: { opacity: 1, x: 0 }, exit: { opacity: 0, x: 0 } }, { children: _jsx(MenuList, { header: label, options: options, headless: headless, enableFiltering: enableFiltering, onBack: renderedLists.length > 1
                            ? function () {
                                setRenderedLists(renderedLists.slice(0, -1));
                                setSelectedOptions(selectedOptions.slice(0, -1));
                            }
                            : undefined, onOptionSelected: function (option) {
                            var newSelectedOptions = __spreadArray(__spreadArray([], __read(selectedOptions), false), [option], false);
                            setSelectedOptions(newSelectedOptions);
                            if (!option.options) {
                                setRenderedLists([optionsTree]);
                                setSelectedOptions([]);
                                return onOptionSelected(newSelectedOptions);
                            }
                            setRenderedLists(__spreadArray(__spreadArray([], __read(renderedLists), false), [option], false));
                        } }) }), i));
            }) }) }));
};
var MenuDrilldownContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  position: relative;\n  min-width: 80px;\n  min-height: 56px;\n"], ["\n  width: 100%;\n  height: 100%;\n  position: relative;\n  min-width: 80px;\n  min-height: 56px;\n"])));
var RenderedListsContainer = styled(motion.div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  position: absolute;\n"], ["\n  width: 100%;\n  height: 100%;\n  position: absolute;\n"])));
var templateObject_1, templateObject_2;
