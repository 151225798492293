var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { faChevronDown, faChevronRight } from "@faharmony/icons";
import { Text, Icon, Button, Popover, } from "@faharmony/components";
import { useExpandItem, useSubItemSelected } from "../helpers";
import { StyledNavPrimaryItem } from "./Style";
import SecondaryItem from "./SecondaryItem";
var CollapsedMenuNavButton = function (props) {
    var _a;
    var item = props.item, isSelected = props.isSelected, selectedId = props.selectedId, handleItemSelect = props.handleItemSelect;
    var subItemSelected = useSubItemSelected(item, selectedId);
    var buttonLabel = item.icon ? undefined : item.label[0].toUpperCase();
    var buttonVariant = isSelected || subItemSelected ? "primary" : "secondary";
    var handleClick = function (e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        item.handleClick && item.handleClick(e);
        handleItemSelect(item);
    };
    var button = (_jsx(Button, { onClick: handleClick, icon: item.icon, tooltip: item.label, value: buttonLabel, variant: buttonVariant }));
    return ((_a = item.subItems) === null || _a === void 0 ? void 0 : _a.length) ? (_jsx(Popover.Menu, __assign({ placement: "right", trigger: "contextmenu", menuItems: __spreadArray([
            {
                id: item.id,
                label: item.label,
                onClick: item.handleClick,
                icon: item.icon,
                color: item.isSelectedCallback && item.isSelectedCallback()
                    ? "accent"
                    : undefined,
            },
            {
                id: "module",
                divider: true,
            }
        ], __read(item.subItems.map(function (subItem) { return ({
            id: subItem.id,
            label: subItem.label,
            icon: subItem.icon,
            onClick: subItem.handleClick,
            color: subItem.isSelectedCallback && subItem.isSelectedCallback()
                ? "accent"
                : undefined,
        }); })), false) }, { children: _jsx("div", { children: button }) }))) : (button);
};
var commonId = "navPrimaryItem";
var NavPrimaryItem = React.memo(function (props) {
    var item = props.item, showLeadingIcon = props.showLeadingIcon, handleItemSelect = props.handleItemSelect;
    var label = item.label, icon = item.icon, subItems = item.subItems, id = item.id;
    var subItemSelected = useSubItemSelected(item, props.selectedId);
    var _a = useExpandItem(item, subItemSelected, props.selectedId), canExpand = _a.canExpand, isExpanded = _a.isExpanded, toggleExpand = _a.toggleExpand;
    var handleClick = function (e) {
        e.preventDefault();
        e.stopPropagation();
        item.handleClick && item.handleClick(e);
        canExpand && !isExpanded && toggleExpand();
        handleItemSelect(item);
    };
    if (props.isMenuCollapsed)
        return _jsx(CollapsedMenuNavButton, __assign({}, props));
    else
        return (_jsxs(StyledNavPrimaryItem, __assign({}, props, { onClick: handleClick, isExpanded: isExpanded, id: commonId + "-" + id }, { children: [_jsxs("div", __assign({ className: commonId }, { children: [showLeadingIcon && (_jsx("div", __assign({ className: "iconWrapper" }, { children: icon && _jsx(Icon, { icon: icon }) }))), _jsx(Text, { value: label, variant: "h5", color: subItemSelected ? "primary" : undefined }), canExpand && (_jsx("div", __assign({ id: commonId + "-" + id + "-collapseButton", onClick: function (e) {
                                if (subItemSelected) {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }
                            } }, { children: _jsx(Button, { icon: isExpanded ? faChevronDown : faChevronRight, spacing: "xs", outline: true, disabled: isExpanded && subItemSelected, tooltip: !subItemSelected
                                    ? isExpanded
                                        ? "Collapse"
                                        : "Expand"
                                    : undefined, onClick: function (e) {
                                    e === null || e === void 0 ? void 0 : e.preventDefault();
                                    e === null || e === void 0 ? void 0 : e.stopPropagation();
                                    toggleExpand();
                                } }) })))] })), isExpanded && subItems && (_jsx("ul", { children: subItems.map(function (subItem) { return (_jsx(SecondaryItem, { item: subItem, handleItemSelect: handleItemSelect, showLeadingIcon: showLeadingIcon, isSelected: props.selectedId === subItem.id }, subItem.id)); }) }))] })));
});
NavPrimaryItem.displayName = "NavPrimaryItem";
export { NavPrimaryItem };
export default NavPrimaryItem;
