var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, theme, styled, globalBarWidth } from "@faharmony/theme";
import { Icon, Tooltip, Popover, } from "@faharmony/components";
import { openExternalLink } from "@faharmony/router";
import { faExternalLinkSquare, getIconDefinition } from "@faharmony/icons";
import { isAppActive, ActionButton } from "./common";
import FALogo from "./FALogo";
var barWidth = globalBarWidth;
var appButtonHeight = "36px";
var color = theme.color, shadow = theme.shadow, spacing = theme.spacing, zIndex = theme.zIndex, radius = theme.radius;
var StyledBar = styled.aside(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  z-index: ", ";\n  margin: 0;\n  box-shadow: ", ";\n  width: ", ";\n  max-width: ", ";\n  height: 100%;\n  max-height: 100vh;\n  background-color: ", ";\n  display: grid;\n  grid-template-rows: ", " minmax(auto, 1fr) max-content;\n  gap: ", ";\n\n  #FA-Bar-apps {\n    margin-top: 16px;\n    padding-top: 1px;\n  }\n\n  #FA-Bar-actions {\n    padding-bottom: ", ";\n  }\n"], ["\n  position: relative;\n  z-index: ", ";\n  margin: 0;\n  box-shadow: ", ";\n  width: ", ";\n  max-width: ", ";\n  height: 100%;\n  max-height: 100vh;\n  background-color: ", ";\n  display: grid;\n  grid-template-rows: ", " minmax(auto, 1fr) max-content;\n  gap: ", ";\n\n  #FA-Bar-apps {\n    margin-top: 16px;\n    padding-top: 1px;\n  }\n\n  #FA-Bar-actions {\n    padding-bottom: ", ";\n  }\n"])), zIndex.docked, shadow.large, barWidth, barWidth, color.background.default, barWidth, spacing.sm, spacing.md);
var StyledAppButton = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  font-size: 1.75rem;\n  cursor: pointer;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  font-size: 1.75rem;\n  cursor: pointer;\n"])));
var StyledAppButtonInactive = styled(StyledAppButton)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: ", ";\n  width: ", ";\n  box-shadow: ", ";\n  border-radius: ", ";\n  background: ", ";\n  color: ", ";\n  :hover {\n    color: ", ";\n    box-shadow: ", ";\n  }\n"], ["\n  height: ", ";\n  width: ", ";\n  box-shadow: ", ";\n  border-radius: ", ";\n  background: ", ";\n  color: ", ";\n  :hover {\n    color: ", ";\n    box-shadow: ", ";\n  }\n"])), appButtonHeight, appButtonHeight, shadow.small, radius[300], color.background.primary, color.text.secondary, color.text.primary, shadow.default);
var StyledAppButtonActive = styled(StyledAppButton)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: calc(", " + ", ");\n  width: calc(", " - ", ");\n  border-radius: ", " 0 0 ", ";\n  background: ", ";\n  margin-left: ", ";\n  padding-right: ", ";\n  color: ", ";\n  box-shadow: ", ";\n"], ["\n  height: calc(", " + ", ");\n  width: calc(", " - ", ");\n  border-radius: ", " 0 0 ", ";\n  background: ", ";\n  margin-left: ", ";\n  padding-right: ", ";\n  color: ", ";\n  box-shadow: ", ";\n"])), appButtonHeight, spacing.md, barWidth, spacing.xs, radius[500], radius[500], color.background.secondary, spacing.xs, spacing.xs, color.static.accent, shadow.inner);
var AppButton = function (app) {
    var appName = app.appName, iconPath = app.iconPath, url = app.url, openInNewWindow = app.openInNewWindow, id = app.id;
    var ButtonWrapper = isAppActive(app)
        ? StyledAppButtonActive
        : StyledAppButtonInactive;
    var appButtonMenuItems = [
        {
            id: "openSameWindow",
            label: "Open app",
            onClick: function () { return openExternalLink(url, "_self"); },
        },
        {
            id: "openNewWindow",
            label: "Open app in new tab",
            onClick: function () { return openExternalLink(url, "_blank"); },
            icon: faExternalLinkSquare,
        },
    ];
    return (_jsx(Popover.Menu, __assign({ menuItems: appButtonMenuItems, trigger: "contextmenu" }, { children: _jsx("span", __assign({ tabIndex: 0 }, { children: _jsx(Tooltip, __assign({ value: appName, placement: "right" }, { children: _jsx(ButtonWrapper, __assign({ onClick: function () {
                        return openExternalLink(url, openInNewWindow ? "_blank" : "_self");
                    }, id: "faGlobalBarApp-" + id }, { children: _jsx(Icon, { icon: getIconDefinition(iconPath), size: "1x" }) })) })) })) })));
};
var VerticalBar = function (_a) {
    var actions = _a.actions, apps = _a.apps;
    return (_jsxs(StyledBar, __assign({ id: "faGlobalBar" }, { children: [_jsx(FALogo, {}), _jsx(Box, __assign({ id: "FA-Bar-apps", direction: "column", justifyContent: "flex-start", alignItems: "center", style: { overflowX: "hidden", overflowY: "auto" } }, { children: apps.map(function (app) { return (_jsx(AppButton, __assign({}, app), app.id)); }) })), _jsx(Box, __assign({ id: "FA-Bar-actions", direction: "column", justifyContent: "flex-end", alignItems: "center" }, { children: actions.map(function (action) { return (_jsx(ActionButton, __assign({}, action), action.id)); }) }))] })));
};
export default VerticalBar;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
