var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect } from "react";
var alphabets = "abcdefghijklmnopqrstuvwxyz";
var alphabetLowerCase = alphabets.split("");
var alphabetUpperCase = alphabets.toUpperCase().split("");
/**
 * This hook makes it easy to detect when the user is pressing a specific (single) key on their keyboard.
 * @author Gabe Ragland <> (https://github.com/gragland)
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @param {string} targetKey https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
 * @example const isPressedShift = useKeyPress('Shift');
 */
function useSingleKeyPress(targetKey) {
    // State for keeping track of whether key is pressed
    var _a = __read(useState(false), 2), keyPressed = _a[0], setKeyPressed = _a[1];
    var alphaTarget = alphabetLowerCase.includes(targetKey) ||
        alphabetUpperCase.includes(targetKey);
    // If pressed key is our target key then set to true
    function downHandler(_a) {
        var key = _a.key;
        if (alphaTarget
            ? key.toUpperCase() === targetKey.toUpperCase()
            : key === targetKey) {
            setKeyPressed(true);
        }
    }
    // If released key is our target key then set to false
    var upHandler = function (_a) {
        var key = _a.key;
        if (alphaTarget
            ? key.toUpperCase() === targetKey.toUpperCase()
            : key === targetKey) {
            setKeyPressed(false);
        }
    };
    // Add event listeners
    useEffect(function () {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        // Remove event listeners on cleanup
        return function () {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    }, []); // Empty array ensures that effect is only run on mount and un-mount
    return keyPressed;
}
/**
 * This hook checks if the set of keys are pressed.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @param {Array} keys[] - list of keys @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
 * @example const isPressed = useKeyPress(['Control', 'S']);
 */
export function useKeyPress(keys) {
    var _a = __read(useState(false), 2), isComboPressed = _a[0], setComboPressed = _a[1];
    var keysPressed = keys.map(function (key) { return useSingleKeyPress(key); });
    useEffect(function () {
        if (keysPressed.every(function (keyPressed) { return keyPressed; }))
            setComboPressed(true);
        else
            setComboPressed(false);
        return function () {
            setComboPressed(false);
        };
    }, [keysPressed]);
    return isComboPressed;
}
