var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Icon, Tooltip } from "@faharmony/components";
import { StyledInputField, commonClass } from "./style";
import { faTimesCircle } from "@faharmony/icons";
/**
 * InputField
 * ---
 * Base Input component.
 * @jira https://fasolutions.jira.com/browse/HAR-ticker
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @copyright FA Solutions Oy
 * @version 1.6.0
 * @example <InputField />
 */
var InputField = React.forwardRef(function (props, ref) {
    var _a = props.isClearable, isClearable = _a === void 0 ? false : _a, _b = props.disabled, disabled = _b === void 0 ? false : _b, _c = props.readOnly, readOnly = _c === void 0 ? false : _c, _d = props.spacing, spacing = _d === void 0 ? "md" : _d, _e = props.className, className = _e === void 0 ? commonClass : _e, _f = props.placeholder, placeholder = _f === void 0 ? "Enter value..." : _f, prefixIcon = props.prefixIcon, prefixText = props.prefixText, suffixIcon = props.suffixIcon, suffixText = props.suffixText, _g = props.error, error = _g === void 0 ? false : _g, _h = props.success, success = _h === void 0 ? false : _h, _j = props.autoComplete, autoComplete = _j === void 0 ? "off" : _j, _k = props.textAlign, textAlign = _k === void 0 ? "left" : _k, inputComponent = props.inputComponent, onClick = props.onClick, onInputClear = props.onInputClear, isLoading = props.isLoading, registerRef = props.registerRef, onBlur = props.onBlur, onChange = props.onChange, inputProps = __rest(props, ["isClearable", "disabled", "readOnly", "spacing", "className", "placeholder", "prefixIcon", "prefixText", "suffixIcon", "suffixText", "error", "success", "autoComplete", "textAlign", "inputComponent", "onClick", "onInputClear", "isLoading", "registerRef", "onBlur", "onChange"]);
    var _l = __assign({}, registerRef), onBlurForm = _l.onBlur, onChangeForm = _l.onChange, formRef = _l.ref, formProps = __rest(_l, ["onBlur", "onChange", "ref"]);
    var inputField = inputComponent || (_jsx("input", __assign({}, formProps, inputProps, { onBlur: function (e) {
            onBlurForm === null || onBlurForm === void 0 ? void 0 : onBlurForm(e);
            onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
        }, onChange: function (e) {
            onChangeForm === null || onChangeForm === void 0 ? void 0 : onChangeForm(e);
            onChange === null || onChange === void 0 ? void 0 : onChange(e);
        }, className: className + " input", disabled: disabled, ref: formRef !== null && formRef !== void 0 ? formRef : ref, readOnly: readOnly, placeholder: placeholder, autoComplete: autoComplete, style: { textAlign: textAlign }, onClick: onClick })));
    // Field prefix
    var prefixComponent = (prefixIcon || prefixText) && (_jsx("div", __assign({ className: className + " prefix", onClick: onClick, style: { cursor: onClick ? "pointer" : "inherit" } }, { children: prefixIcon ? _jsx(Icon, { icon: prefixIcon }) : prefixText || "" })));
    // Field suffix
    var suffixComponent = (suffixIcon || suffixText) && (_jsx("div", __assign({ className: className + " suffix", onClick: onClick, style: { cursor: onClick ? "pointer" : "inherit" } }, { children: suffixIcon ? _jsx(Icon, { icon: suffixIcon }) : suffixText || "" })));
    var showClearButton = isClearable && !disabled && !readOnly;
    // Clear input
    var clearButton = showClearButton && (_jsx(Tooltip, __assign({ value: "Clear" }, { children: _jsx(Icon, { icon: faTimesCircle, className: className + " clearButton", onClick: onInputClear }) })));
    return (_jsxs(StyledInputField, __assign({ className: className + " inputContainer", isClearable: showClearButton, disabled: disabled, spacing: spacing, readonly: readOnly, prefixIcon: prefixIcon, prefixText: prefixText, suffixIcon: suffixIcon, suffixText: suffixText, textAlign: textAlign, error: error, success: success, isLoading: isLoading }, { children: [inputField, prefixComponent, suffixComponent, clearButton] })));
});
export { InputField };
