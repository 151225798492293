import { useEffect } from "react";
/**
 * This hook allows you to detect clicks outside of a specified element.
 * @author Gabe Ragland <> (https://github.com/gragland)
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @param {Function} handler - to optimize, wrap handler in useCallback()
 * @example
 *  const [isModalOpen, setModalOpen] = useState(true);
 *  useOnClickOutside(ref, () => setModalOpen(false));
 */
export function useOnClickOutside(targetRef, handler) {
    useEffect(function () {
        var listener = function (event) {
            // Do nothing if clicking ref's element or descendent elements
            if (!targetRef.current || targetRef.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return function () {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, 
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [targetRef, handler]);
}
