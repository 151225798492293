var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@faharmony/theme";
import { theme, color } from "@faharmony/theme";
var minHeight = 20;
var baseHeight = 32;
var calcHeight = function (_a) {
    var _b = _a.spacing, spacing = _b === void 0 ? "md" : _b;
    return ["lg"].includes(spacing)
        ? baseHeight + 4
        : ["xl"].includes(spacing)
            ? baseHeight + 8
            : ["sm"].includes(spacing)
                ? baseHeight - 4
                : ["xs"].includes(spacing)
                    ? baseHeight - 8
                    : ["none"].includes(spacing)
                        ? minHeight
                        : baseHeight;
};
var calcPadding = function (props) {
    return Math.max(calcHeight(props) - minHeight - 2, 0) / 2;
};
var calcBackgroundColor = function (_a) {
    var variant = _a.variant, isOutline = _a.isOutline, disabled = _a.disabled;
    if (isOutline)
        return "transparent";
    if (disabled)
        return color.background.disabled;
    switch (variant) {
        case "primary":
            return color.background.inverse;
        case "secondary":
            return color.background.primary;
        default:
            return color.background[variant || "default"];
    }
};
var calcOutlineColor = function (_a) {
    var variant = _a.variant, disabled = _a.disabled;
    return disabled
        ? color.background.disabled
        : color.border[variant === "primary" ? "inverse" : variant || "default"];
};
var calcTextColor = function (_a) {
    var variant = _a.variant, isOutline = _a.isOutline, disabled = _a.disabled;
    if (disabled)
        return color.text.disabled;
    if (isOutline) {
        switch (variant) {
            case "default":
                return color.text.primary;
            case "secondary":
                return color.text.secondary;
            default:
                return color.text[variant || "primary"];
        }
    }
    switch (variant) {
        case "primary":
            return color.text.inverse;
        case "secondary":
            return color.text.secondary;
        case "default":
            return color.text.primary;
        case "warning":
            return color.text.primary;
        default:
            return color.static.white;
    }
};
var StyledBaseButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0px;\n\n  overflow: hidden;\n  padding: ", "px;\n  height: ", "px;\n  width: ", ";\n  min-width: ", "px;\n\n  background-color: transparent;\n  background: ", ";\n  border-radius: ", ";\n  box-shadow: ", ";\n  border: 1px solid\n    ", ";\n\n  cursor: ", ";\n  user-select: none;\n  text-align: center;\n  color: ", ";\n  filter: none;\n  :hover {\n    box-shadow: ", ";\n  }\n  :active {\n    box-shadow: ", ";\n  }\n  :focus,\n  :focus-within {\n    box-shadow: ", ";\n  }\n"], ["\n  margin: 0px;\n\n  overflow: hidden;\n  padding: ", "px;\n  height: ", "px;\n  width: ", ";\n  min-width: ", "px;\n\n  background-color: transparent;\n  background: ", ";\n  border-radius: ", ";\n  box-shadow: ", ";\n  border: 1px solid\n    ", ";\n\n  cursor: ", ";\n  user-select: none;\n  text-align: center;\n  color: ", ";\n  filter: none;\n  :hover {\n    box-shadow: ", ";\n  }\n  :active {\n    box-shadow: ", ";\n  }\n  :focus,\n  :focus-within {\n    box-shadow: ", ";\n  }\n"])), calcPadding, calcHeight, function (props) { return (props.fullWidth ? "fill-available" : "fit-content"); }, calcHeight, calcBackgroundColor, theme.radius[300], function (props) { return (props.isOutline ? "none" : theme.shadow.default); }, function (props) {
    return props.isOutline ? calcOutlineColor(props) : calcBackgroundColor(props);
}, function (props) {
    return props.disabled
        ? "not-allowed"
        : props.isLoading === true
            ? "wait"
            : "pointer";
}, calcTextColor, function (props) {
    return props.disabled || props.isLoading ? "" : theme.shadow.hover;
}, function (props) {
    return props.disabled || props.isLoading ? "" : theme.shadow.pressed;
}, function (props) {
    return props.disabled || props.isLoading ? "" : theme.shadow.focus;
});
export var StyledButton = styled(StyledBaseButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .p,\n  .h5 {\n    text-align: center;\n    vertical-align: middle;\n    height: 20px;\n    padding: 0 4px;\n  }\n"], ["\n  .p,\n  .h5 {\n    text-align: center;\n    vertical-align: middle;\n    height: 20px;\n    padding: 0 4px;\n  }\n"])));
export var StyledIconButton = styled(StyledBaseButton)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-width: ", "px;\n"], ["\n  max-width: ", "px;\n"])), calcHeight);
var templateObject_1, templateObject_2, templateObject_3;
