var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Box } from "@faharmony/theme";
import { useCheckLeadingIcon, useSelected } from "../helpers";
import { StyledNavigationMenu } from "./Style";
import PrimaryItem from "./PrimaryItem";
/**
 * NavigationMenu
 * ---
 * Builds a menu with collapsible items.
 * Used primarily in MenuView for showing App menu.
 *
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 *
 * ---
 *
 * @example
 * <NavigationMenu navItems={[
 *  {
 *    id: 'item1',
 *    label: 'Item 1',
 *    icon: faSquare,
 *    handleClick: (e) => console.log(e),
 *    isSelectedCallback: () => true,
 *  },
 * ]} />
 */
var NavigationMenu = function (props) {
    var items = props.navItems;
    var showLeadingIcon = useCheckLeadingIcon(items);
    var _a = useSelected(items), selectedId = _a.selectedId, handleItemSelect = _a.handleItemSelect;
    var ref = React.useRef(null);
    var isMenuCollapsed = props.isMenuCollapsed;
    var renderItem = function (item) { return (_jsx(PrimaryItem, { item: item, showLeadingIcon: showLeadingIcon, isSelected: selectedId === item.id, handleItemSelect: handleItemSelect, 
        // Don't expand by default
        isExpanded: false, selectedId: selectedId, isMenuCollapsed: isMenuCollapsed }, item.id)); };
    var displayItems = items.map(renderItem);
    return (_jsx(StyledNavigationMenu, __assign({ isMenuCollapsed: isMenuCollapsed, isHeaderShown: props.isHeaderShown, ref: ref }, { children: displayItems.length ? (isMenuCollapsed ? (_jsx(Box, __assign({ direction: "column", alignItems: "center" }, { children: displayItems }))) : (_jsx("ul", { children: displayItems }))) : (!isMenuCollapsed && _jsx("div", __assign({ className: "noContent" }, { children: "No content" }))) })));
};
export { NavigationMenu, };
export default NavigationMenu;
