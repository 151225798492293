var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from "@faharmony/components";
import { faCalendarAlt } from "@faharmony/icons";
import { dateFns, getCurrentLocale } from "@faharmony/locale";
import React, { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { dateFormats, DatePicker, dateTimeFormats } from "..";
import { InputField } from "../../BaseInputField";
import { StyledDateIconContainer, StyledDateRangeInput, StyledDateSeperator, } from "./StyledComponents";
/**
 * DateRangeicker
 * ---
 * Form input to pick a date range.
 * This is the custom implementation of Date Picker with range from react-date-picker libray to add support for time range selection.
 * @jira https://fasolutions.jira.com/browse/HAR-218
 * @author Amrit Regmi <amrit.regmi@fasolutions.com>
 * @copyright FA Solutions Oy
 * @version 1.14.0
 * @example <DateRangePicker startDate={new Date("2021/05/04 22:21"),}  endDate={new Date("2021/05/09 21:00"),} setStartDate, setEndDate, />
 */
export var DateRangePicker = function (props) {
    var _a, _b;
    var locale = getCurrentLocale();
    var defaultDateFormat = dateFormats[locale] || dateFormats["en-GB"];
    var defaultDateTimeFormat = dateTimeFormats[locale] || dateTimeFormats["en-GB"];
    var _c = __assign({}, props), startDate = _c.startDate, endDate = _c.endDate, setStartDate = _c.setStartDate, setEndDate = _c.setEndDate, startDateFieldName = _c.startDateFieldName, endDateFieldName = _c.endDateFieldName, _d = _c.dateFormat, dateFormat = _d === void 0 ? props.showCustomTimeSelector
        ? defaultDateTimeFormat
        : defaultDateFormat : _d, 
    // InputField
    spacing = _c.spacing, prefixIcon = _c.prefixIcon, prefixText = _c.prefixText, success = _c.success, error = _c.error, isLoading = _c.isLoading, textAlign = _c.textAlign, onCalendarClose = _c.onCalendarClose, rest = __rest(_c, ["startDate", "endDate", "setStartDate", "setEndDate", "startDateFieldName", "endDateFieldName", "dateFormat", "spacing", "prefixIcon", "prefixText", "success", "error", "isLoading", "textAlign", "onCalendarClose"]);
    var startDateRef = useRef(null);
    var endDateRef = useRef(null);
    var _e = __read(React.useState((_a = startDateRef.current) === null || _a === void 0 ? void 0 : _a.isCalendarOpen()), 2), startDateCalenderIsOpen = _e[0], setStartDateCalenderIsOpen = _e[1];
    var _f = __read(React.useState((_b = endDateRef.current) === null || _b === void 0 ? void 0 : _b.isCalendarOpen()), 2), endDateCalenderIsOpen = _f[0], setEndDateCalenderIsOpen = _f[1];
    var inputField = (_jsx(InputField, __assign({}, {
        spacing: spacing,
        prefixIcon: prefixIcon,
        prefixText: prefixText,
        success: success,
        error: error,
        isLoading: isLoading,
        textAlign: textAlign,
    }, { size: dateFormat === null || dateFormat === void 0 ? void 0 : dateFormat.length })));
    useEffect(function () {
        if (startDate)
            setStartDate(startDate);
        if (endDate)
            setEndDate(endDate);
    }, [startDate, endDate]);
    return (_jsxs(StyledDateRangeInput, __assign({ direction: "row", className: "dateRange inputContainer", endDateCalenderOpen: endDateCalenderIsOpen, startDateCalenderOpen: startDateCalenderIsOpen }, { children: [_jsx(DatePicker, __assign({ name: startDateFieldName, showPopperArrow: true, className: "startDate", dateFormat: dateFormat, customInput: inputField }, rest, { ref: startDateRef, selectsStart: true, selected: startDate ? startDate : undefined, startDate: startDate, onChange: function (date) {
                    if (date && startDate && !dateFns.isSameDay(date, startDate)) {
                        date === null || date === void 0 ? void 0 : date.setHours(0);
                        date === null || date === void 0 ? void 0 : date.setMinutes(0);
                    }
                    setStartDate(date);
                }, endDate: endDate && startDate && endDate < startDate ? undefined : endDate, onCalendarOpen: function () { return setStartDateCalenderIsOpen(true); }, onCalendarClose: function () {
                    var _a;
                    if (rest.onStartCalenderClose) {
                        rest.onStartCalenderClose();
                    }
                    (_a = endDateRef.current) === null || _a === void 0 ? void 0 : _a.setOpen(true);
                    setStartDateCalenderIsOpen(false);
                } })), _jsx(StyledDateSeperator, { className: "dateSeperator" }), _jsx(DatePicker, __assign({ showPopperArrow: true, name: endDateFieldName, className: "endDate", selectsEnd: true, dateFormat: dateFormat }, rest, { ref: endDateRef, selected: endDate && startDate && endDate < startDate
                    ? startDate
                    : endDate || undefined, startDate: startDate, customInput: inputField, endDate: endDate, minDate: startDate, onCalendarOpen: function () { return setEndDateCalenderIsOpen(true); }, onCalendarClose: function () {
                    (rest === null || rest === void 0 ? void 0 : rest.onEndCalenderClose) && rest.onEndCalenderClose();
                    setEndDateCalenderIsOpen(false);
                }, onChange: function (date) {
                    if (date && endDate) {
                        if (!dateFns.isSameDay(date, endDate)) {
                            date.setHours(23);
                            date.setMinutes(59);
                        }
                        var now = new Date();
                        /* This is to check if the selected date is same day as today and time value has not been manually updated by user. If both true, then set the time to
                      current time. */
                        if (dateFns.isSameDay(date, now) &&
                            date.getHours() === endDate.getHours() &&
                            date.getMinutes() === endDate.getMinutes() &&
                            now < date) {
                            setEndDate(now);
                            return;
                        }
                    }
                    setEndDate(date);
                } })), _jsx(StyledDateIconContainer, __assign({ onClick: function () {
                    var _a, _b;
                    if (!((_a = endDateRef.current) === null || _a === void 0 ? void 0 : _a.isCalendarOpen()))
                        (_b = startDateRef.current) === null || _b === void 0 ? void 0 : _b.setOpen(true);
                } }, { children: _jsx(Icon, { icon: faCalendarAlt }) }))] })));
};
/**
 * Hook to register startDate and endDate of DatePicker component used inside a Form.
 * @param  startDateFieldname  Field name for startDate
 * @param  endDateFieldname Field name for endDate
 * @returns DateRangePickerProp
 * @example
 * ```tsx
 * const formDateRangePickerProps = useFormDateRangeProps("dob");
 * return  <DateRangePicker
        {...formDateRangePickerProps}
        dateFormat="dd.MM.yyyy hh:mm a"
        rangeFunctions={useDefaultCustomRanges()}
        showCustomTimeSelector
      ></DateRangePicker>
 * ```
 */
export function useFormDateRangeProps(startDateFieldName, endDateFieldName, selectedStartDate, selectedEndDate) {
    var context = useFormContext();
    if (!context) {
        throw new Error("The hook 'useFormDatePickerProps' can only be inside a Form component.");
    }
    var register = context.register, setValue = context.setValue;
    var _a = __read(React.useState(selectedStartDate), 2), startDate = _a[0], setStartDate = _a[1];
    var _b = __read(React.useState(selectedEndDate), 2), endDate = _b[0], setEndDate = _b[1];
    useEffect(function () {
        register(startDateFieldName);
    }, [startDateFieldName, register]);
    useEffect(function () {
        register(endDateFieldName);
    }, [endDateFieldName, register]);
    useEffect(function () {
        setValue(startDateFieldName, startDate);
        setValue(endDateFieldName, endDate);
    }, [startDate, endDate]);
    return {
        startDate: startDate,
        endDate: endDate,
        setStartDate: setStartDate,
        setEndDate: setEndDate,
    };
}
