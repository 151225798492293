var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect, useCallback, useReducer } from "react";
import { useCompleteTask } from "./useCompleteTask";
import useStartProcess from "./useStartProcess";
import { actions, state } from "../state";
var processInitialState = {
    executorState: "LOADING",
    formIds: undefined,
};
var processStateReducer = function (state, action) {
    var _a, _b;
    switch (action.type) {
        case "UPDATE_DATA": {
            var payload = action.payload;
            var newExecutorState = (_a = payload.executorState) !== null && _a !== void 0 ? _a : state.executorState;
            var formIds = (_b = state.formIds) !== null && _b !== void 0 ? _b : [];
            if (action.payload.formKey &&
                !formIds.includes(Number(action.payload.formKey))) {
                state.formIds = __spreadArray(__spreadArray([], __read(formIds), false), [Number(action.payload.formKey)], false);
            }
            if (newExecutorState === "READY" ||
                newExecutorState === "SUBMITTING" ||
                newExecutorState === "SUBMIT_ERROR") {
                return __assign(__assign({}, state), payload);
            }
            return { executorState: newExecutorState };
        }
        case "SET_DATA":
            return action.payload;
        case "RESET_SUBMIT_ERROR":
            if (state.executorState === "READY" ||
                state.executorState === "SUBMITTING" ||
                state.executorState === "SUBMIT_ERROR") {
                return __assign(__assign({}, state), { executorState: "READY" });
            }
            return state;
        default:
            throw new Error();
    }
};
export var eventNames = {
    // ! TODO remove on Formio react implementation
    submit: "formSubmitEvent",
    setTaskLanguage: "setTaskLanguage",
};
var attachmentsObjectToList = function (attachments) {
    if (attachments) {
        var attachmentList = Object.entries(attachments).map(function (_a) {
            var _b = __read(_a, 2), attachmentName = _b[0], attachment = _b[1];
            if (typeof attachment === "object") {
                return attachment;
            }
            else {
                return {
                    name: attachmentName,
                    url: attachment,
                };
            }
        });
        return attachmentList;
    }
    return [];
};
export var useProcessExecutor = function (OnProcessFinished) {
    var _a = __read(useReducer(processStateReducer, processInitialState), 2), processState = _a[0], dispatchProcessStateAction = _a[1];
    useInitializeForm(dispatchProcessStateAction);
    var completeTask = useCompleteTask(processState.executorState === "READY" ? processState.taskId : undefined).completeTask;
    var handleSubmit = useCallback(function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var submissionData, completeFormResponse, processFinished, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    submissionData = e.detail;
                    if (processState.executorState !== "READY" || !submissionData) {
                        return [2 /*return*/];
                    }
                    dispatchProcessStateAction({
                        type: "UPDATE_DATA",
                        payload: {
                            executorState: "SUBMITTING",
                        },
                    });
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, completeTask({
                            variables: {
                                data: replaceNullWithEmptyString(submissionData.data),
                                taskId: processState.taskId,
                                processInstanceId: processState.processInstanceId,
                                skipRequiredFieldValidation: submissionData.data.goBack,
                            },
                        })];
                case 2:
                    completeFormResponse = _b.sent();
                    processFinished = finishCurrentTask((_a = completeFormResponse.data) === null || _a === void 0 ? void 0 : _a.completeProcessTask, dispatchProcessStateAction, function (nextTask) { return (state.activeTask = nextTask); }).processFinished;
                    if (processFinished) {
                        actions.closeModal();
                        OnProcessFinished && OnProcessFinished();
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    console.error("handleSubmit error", error_1);
                    dispatchProcessStateAction({
                        type: "UPDATE_DATA",
                        payload: {
                            executorState: "SUBMIT_ERROR",
                        },
                    });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [completeTask, dispatchProcessStateAction, processState, OnProcessFinished]);
    // event communication with iframe setup
    useEffect(function () {
        window.document.addEventListener(eventNames.submit, handleSubmit, false);
        return function () {
            return window.document.removeEventListener(eventNames.submit, handleSubmit, false);
        };
    }, [handleSubmit]);
    var setGlobalProcessLanguage = useCallback(function (e) {
        var _a;
        localStorage.setItem("globalProcessLang", (_a = e.detail) !== null && _a !== void 0 ? _a : "");
    }, []);
    useEffect(function () {
        window.document.addEventListener(eventNames.setTaskLanguage, setGlobalProcessLanguage, false);
        return function () {
            return window.document.removeEventListener(eventNames.setTaskLanguage, setGlobalProcessLanguage, false);
        };
    }, [setGlobalProcessLanguage]);
    if (processState.executorState === "READY" ||
        processState.executorState === "SUBMITTING") {
        return {
            executorState: processState.executorState,
            formDefinition: processState.formDefinition,
            initialData: processState.initialData,
            attachments: processState.attachments,
            translations: processState.formTranslationsJson,
            formKey: processState.formKey,
            name: processState.name,
            formIds: processState.formIds,
        };
    }
    return {
        executorState: processState.executorState,
    };
};
var useInitializeForm = function (dispatchProcessStateAction) {
    var _a;
    var startProcess = useStartProcess((_a = state.selectedProcess) === null || _a === void 0 ? void 0 : _a.key, state.processPayload).startProcess;
    var initForm = useCallback(function (initFormData) {
        var _a;
        if (!initFormData ||
            (initFormData && !initFormData.processInstanceId) ||
            !initFormData.formDefinition) {
            dispatchProcessStateAction({
                type: "SET_DATA",
                payload: {
                    executorState: "PROCESS_ERROR",
                },
            });
            return;
        }
        dispatchProcessStateAction({
            type: "UPDATE_DATA",
            payload: {
                formDefinition: JSON.parse(initFormData.formDefinition),
                formTranslationsJson: initFormData.formTranslationsJson,
                initialData: initFormData.data || {},
                attachments: attachmentsObjectToList((_a = initFormData.data) === null || _a === void 0 ? void 0 : _a.attachments),
                taskId: initFormData.taskId,
                formKey: initFormData.formKey,
                processInstanceId: initFormData.processInstanceId,
                executorState: "READY",
            },
        });
    }, [dispatchProcessStateAction]);
    useEffect(function () {
        if (!state.selectedProcess && !state.activeTask)
            return;
        var hookRunning = true;
        var initialize = function () { return __awaiter(void 0, void 0, void 0, function () {
            var responseData;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!hookRunning)
                            return [2 /*return*/];
                        if (!((_a = state.activeTask) === null || _a === void 0 ? void 0 : _a.formKey)) return [3 /*break*/, 1];
                        initForm(state.activeTask);
                        return [3 /*break*/, 3];
                    case 1:
                        if (!state.selectedProcess) return [3 /*break*/, 3];
                        return [4 /*yield*/, startProcess()];
                    case 2:
                        responseData = (_b = (_c.sent()).data) === null || _b === void 0 ? void 0 : _b.startProcess;
                        if (responseData === null || responseData === void 0 ? void 0 : responseData.formKey) {
                            state.activeTask = responseData;
                            state.modalMode = "open";
                            initForm(responseData);
                        }
                        _c.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        initialize();
        return function () {
            hookRunning = false;
        };
    }, [startProcess, initForm, state.selectedProcess, state.activeTask]);
};
var finishCurrentTask = function (nextTask, dispatchProcessStateAction, 
// @ts-ignore
onNextTask) {
    var _a;
    if (onNextTask === void 0) { onNextTask = function (nextTask) { }; }
    // process not ended we have next task
    if (nextTask === null || nextTask === void 0 ? void 0 : nextTask.processInstanceId) {
        onNextTask(nextTask);
        var formDefinition = (nextTask.formDefinition && JSON.parse(nextTask.formDefinition)) ||
            undefined;
        dispatchProcessStateAction({
            type: "UPDATE_DATA",
            payload: {
                formDefinition: formDefinition,
                formTranslationsJson: nextTask.formTranslationsJson,
                initialData: nextTask.data || {},
                attachments: attachmentsObjectToList((_a = nextTask.data) === null || _a === void 0 ? void 0 : _a.attachments),
                taskId: nextTask.taskId,
                processInstanceId: nextTask.processInstanceId,
                executorState: "READY",
                formKey: nextTask.formKey,
                name: nextTask.name,
            },
        });
    }
    return {
        processFinished: !(nextTask === null || nextTask === void 0 ? void 0 : nextTask.formDefinition),
    };
};
var replaceNullWithEmptyString = function (object) {
    Object.entries(object).forEach(function (_a) {
        var _b = __read(_a, 2), k = _b[0], v = _b[1];
        if (v && typeof v === "object")
            replaceNullWithEmptyString(v);
        if (v === null || v === undefined) {
            object[k] = "";
        }
    });
    return object;
};
