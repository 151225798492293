/**
 * keyPressCombos object used to manage all available
 * keyboard key press combos.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 */
export var keyPressCombos = {
    switchDarkMode: ["Control", "Shift", "D"],
    collapseNavigationMenu: ["Control", "Shift", "M"],
};
export var getKeyPressComboString = function (combo) {
    return combo
        .map(function (key) {
        if (key === "Control")
            key = "Ctrl";
        if (key.length === 1 && key.match(/[a-z]/i))
            key = key.toUpperCase();
        return key;
    })
        .join(" ");
};
