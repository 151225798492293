var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled, theme } from "@faharmony/theme";
import { Icon } from "../../Icon";
import { Divider } from "../../Divider";
import { Text } from "../../Text";
var StyledItem = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: transparent;\n  padding: ", " ", ";\n  display: grid;\n  gap: ", ";\n  grid-template-columns: auto max-content;\n  width: inherit;\n  cursor: pointer;\n  pointer-events: ", ";\n  color: ", ";\n  &:hover {\n    background-color: ", ";\n  }\n\n  .contextMenuItem-label {\n    padding: ", " 0;\n  }\n  .contextMenuItem-icon {\n    padding-top: ", ";\n  }\n"], ["\n  background-color: transparent;\n  padding: ", " ", ";\n  display: grid;\n  gap: ", ";\n  grid-template-columns: auto max-content;\n  width: inherit;\n  cursor: pointer;\n  pointer-events: ", ";\n  color: ", ";\n  &:hover {\n    background-color: ", ";\n  }\n\n  .contextMenuItem-label {\n    padding: ", " 0;\n  }\n  .contextMenuItem-icon {\n    padding-top: ", ";\n  }\n"])), theme.spacing.xs, theme.spacing.md, theme.spacing.md, function (p) { return (p.disabled ? "none" : "auto"); }, function (p) {
    return theme.color.text[p.disabled ? "disabled" : p.color || "primary"];
}, function (p) {
    return p.disabled ? "initial" : theme.color.background.hover;
}, theme.spacing.sm, theme.spacing.sm);
var MenuItem = function (_a) {
    var item = _a.item, instance = _a.instance;
    var id = item.id, label = item.label, subLabel = item.subLabel, disabled = item.disabled, divider = item.divider, icon = item.icon, color = item.color, onClick = item.onClick;
    //   Create divider
    if (divider)
        return label ? (_jsx(Divider, __assign({ spacing: "lg" }, { children: label }))) : (_jsx(Divider, { spacing: "sm" }));
    var handleClick = function (e) {
        onClick && onClick(e);
        instance.closePopover();
    };
    return (_jsxs(StyledItem, __assign({ className: "contextMenuItem", onClick: handleClick, id: "contextMenuItem-" + id, disabled: disabled, color: color }, { children: [_jsxs("div", __assign({ className: "contextMenuItem-label" }, { children: [_jsx(Text, { value: label, truncate: true }), subLabel && (_jsx(Text, { value: subLabel, variant: "small", color: disabled ? "disabled" : "secondary", truncate: true }))] })), icon && (_jsx("div", __assign({ className: "contextMenuItem-icon" }, { children: _jsx(Icon, { icon: icon }) })))] })));
};
var StyledList = styled.menu(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0;\n  padding: ", " 0;\n  list-style: none;\n  min-width: 200px;\n"], ["\n  margin: 0;\n  padding: ", " 0;\n  list-style: none;\n  min-width: 200px;\n"])), theme.spacing.sm);
export default (function (menuItems) { return function (instance) { return (_jsx(StyledList, __assign({ className: "noselect" }, { children: menuItems.map(function (item) { return (_jsx(MenuItem, { item: item, instance: instance }, item.id)); }) }))); }; });
var templateObject_1, templateObject_2;
