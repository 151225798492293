var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var FALogoSVGPath = "M513.002048,256.997568 C513.002048,513.002048 513.002048,513.002048 256.997568,513.002048 C3.55313268,513.002048 1.01868833,513.002048 0.993343884,264.601157 L0.993087879,256.997568 C0.993087879,3.55313268 0.993087879,1.01868833 249.393979,0.993343884 L256.997568,0.993087879 C513.002048,0.993087879 513.002048,0.993087879 513.002048,256.997568 Z M344.637953,134.115418 L287.718106,134.115418 L369.639539,379.879718 L427.113304,379.879718 C428.21394,379.879718 429.307378,379.702278 430.351533,379.354226 C435.716806,377.565802 438.616415,371.766584 436.827991,366.40131 L436.827991,366.40131 L364.067327,148.119318 C361.279662,139.756325 353.453322,134.115418 344.637953,134.115418 L344.637953,134.115418 Z M277.477926,164.835955 L205.796672,379.879718 L259.857044,379.879718 C264.264728,379.879718 268.177898,377.059265 269.571731,372.877768 L269.571731,372.877768 L308.198464,256.997568 L277.477926,164.835955 Z M256.997568,134.115418 L93.1547007,134.115418 C81.8437111,134.115418 72.6743423,143.284786 72.6743423,154.595776 L72.6743423,154.595776 L72.6743423,359.39936 C72.6743423,370.71035 81.8437111,379.879718 93.1547007,379.879718 L93.1547007,379.879718 L144.355597,379.879718 L144.355597,297.958285 L195.027366,297.958285 C199.439575,297.958285 203.355696,295.132132 204.745933,290.94467 L204.745933,290.94467 L219.41611,246.757389 L144.355597,246.757389 L144.355597,195.556493 L229.136506,195.556493 C233.54419,195.556493 237.457361,192.736039 238.851193,188.554543 L238.851193,188.554543 L256.997568,134.115418 Z";
export var FALogoOpaqueSVGPath = [
    "M256,1.8189894e-14 C512,1.8189894e-14 512,1.8189894e-14 512,256 C512,512 512,512 256,512 C2.36468622e-13,512 2.36468622e-13,512 2.36468622e-13,256 C2.36468622e-13,1.8189894e-14 2.36468622e-13,1.8189894e-14 256,1.8189894e-14 Z",
    "M280.4224,163.84 L311.1424,256 L272.516343,371.878172 C271.122535,376.059596 267.209433,378.88 262.801826,378.88 L208.7424,378.88 L280.4224,163.84 Z M347.581251,133.12 C356.396466,133.12 364.222669,138.760808 367.010285,147.123655 L439.769676,365.401828 C441.558069,370.767007 438.658511,376.566124 433.293331,378.354517 C432.249195,378.702563 431.155776,378.88 430.055159,378.88 L372.5824,378.88 L290.6624,133.12 L347.581251,133.12 Z M259.9424,133.12 L241.796343,187.558172 C240.402535,191.739596 236.489433,194.56 232.081826,194.56 L147.3024,194.56 L147.3024,245.76 L222.3616,245.76 L207.691679,289.946508 C206.301466,294.133896 202.385415,296.96 197.973282,296.96 L147.3024,296.96 L147.3024,378.88 L96.1024,378.88 C84.7916083,378.88 75.6224,369.710792 75.6224,358.4 L75.6224,153.6 C75.6224,142.289208 84.7916083,133.12 96.1024,133.12 L259.9424,133.12 Z",
];
export var FALogo = function () { return (_jsx("svg", __assign({ viewBox: "512, 512" }, { children: _jsx("path", { d: FALogoSVGPath }) }))); };
export var FALogoOpaque = function () { return (_jsxs("svg", __assign({ viewBox: "512, 512" }, { children: [_jsx("path", { d: FALogoOpaqueSVGPath[0], fill: "#AE001A" }), _jsx("path", { d: FALogoOpaqueSVGPath[0], fill: "#FFFFFF" })] }))); };
export var FALogoIconDefinition = {
    prefix: "fab",
    iconName: "gulp",
    icon: [512, 512, [], "f3ae", FALogoSVGPath],
};
export var FALogoOpaqueIconDefinition = {
    prefix: "fab",
    iconName: "gulp",
    icon: [512, 512, [], "f3ae", FALogoOpaqueSVGPath],
};
export default FALogoIconDefinition;
