var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect, useMemo } from "react";
import { useDebounceFunction } from "../sideEffects/useDebounce";
/**
 * A really common need is to get the current size of the browser window.
 * This hook returns an object containing the window's width and height.
 * The default delay for debounce is (250ms)
 *
 * @author Gabe Ragland <> (https://github.com/gragland)
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @param {number} delay Delay time in ms.
 */
export function useWindowSize(delay) {
    if (delay === void 0) { delay = 250; }
    function getSize() {
        return {
            width: window.innerWidth || 0,
            height: window.innerHeight || 0,
        };
    }
    var _a = __read(useState(getSize), 2), windowSize = _a[0], setWindowSize = _a[1];
    var orientation = useMemo(function () { return (windowSize.width >= windowSize.height ? "landscape" : "portrait"); }, [windowSize]);
    var _b = __read(useDebounceFunction(function () { return setWindowSize(getSize()); }, delay), 1), debouncedFunction = _b[0];
    useEffect(function () {
        window.addEventListener("resize", debouncedFunction);
        return function () { return window.removeEventListener("resize", debouncedFunction); };
    }, []); // Empty array ensures that effect is only run on mount and un-mount
    return { width: windowSize.width, height: windowSize.height, orientation: orientation };
}
