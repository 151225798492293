var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Text, Button, Icon } from "@faharmony/components";
import { faArrowLeft as backIcon } from "@faharmony/icons/solid";
import { faChevronRight } from "@faharmony/icons";
import { ContentView } from "@faharmony/views";
import { AnimatePresence, motion, styled, theme } from "@faharmony/theme";
import { TextField } from "@faharmony/form";
export var MenuList = function (_a) {
    var header = _a.header, options = _a.options, onOptionSelected = _a.onOptionSelected, _b = _a.onBack, onBack = _b === void 0 ? undefined : _b, _c = _a.headless, headless = _c === void 0 ? false : _c, _d = _a.enableFiltering, enableFiltering = _d === void 0 ? false : _d;
    var _e = __read(useState(""), 2), filterInput = _e[0], setFilterInput = _e[1];
    return (_jsxs(ContentView, __assign({ style: { overflowX: "hidden" }, headerHidden: headless || !header, headerTitle: !headless &&
            header && (_jsx(HeaderContainer, { children: _jsxs(_Fragment, { children: [onBack && (_jsx("div", __assign({ style: { marginTop: -4 } }, { children: _jsx(Button, { icon: backIcon, spacing: "sm", outline: true, onClick: onBack }) }))), typeof header === "string" ? (_jsx(Text, __assign({ variant: "h5", truncate: true }, { children: header }))) : ({ header: header })] }) })) }, { children: [enableFiltering && (_jsx(FilterInput, { onChange: function (e) {
                    setFilterInput(e.target.value);
                }, value: filterInput, autoFocus: true, placeholder: "Filter" })), _jsx("div", __assign({ style: { paddingTop: 8, paddingBottom: 8 } }, { children: _jsx(AnimatePresence, { children: options
                        .filter(function (option) {
                        return option.label.toLowerCase().includes(filterInput.toLowerCase());
                    })
                        .map(function (option) { return (_jsxs(Item, __assign({ transition: { duration: 0.15, ease: "easeOut" }, initial: { opacity: 0, x: 24 }, animate: { opacity: 1, x: 0 }, exit: { opacity: 0, x: 24 }, onClick: function () {
                            onOptionSelected(option);
                            setFilterInput("");
                        } }, { children: [_jsx("div", __assign({ style: { flexGrow: 1 } }, { children: option.label })), option.options && _jsx(Icon, { icon: faChevronRight })] }), option.key || option.label)); }) }) }))] })));
};
var color = theme.color, spacing = theme.spacing;
var Item = styled(motion.div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  padding: 8px;\n  &:hover {\n    cursor: pointer;\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  padding: 8px;\n  &:hover {\n    cursor: pointer;\n    background-color: ", ";\n  }\n"])), color.background.hover);
var FilterInput = styled(TextField)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-top: ", ";\n  padding-bottom: ", ";\n  padding-left: ", ";\n  padding-right: ", ";\n"], ["\n  padding-top: ", ";\n  padding-bottom: ", ";\n  padding-left: ", ";\n  padding-right: ", ";\n"])), spacing.sm, spacing.sm, spacing.sm, spacing.sm);
var HeaderContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n  align-items: flex-start;\n"], ["\n  display: flex;\n  gap: 8px;\n  align-items: flex-start;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
