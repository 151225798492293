var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Trigger } from "@faharmony/helpers";
import { useDispatch, ModalsActions } from "./state";
export var addModalTrigger = new Trigger();
export var addDialogTrigger = new Trigger();
export var removeModalTrigger = new Trigger();
export var removeDialogTrigger = new Trigger();
/** Add a new modal to the modal-notification stack
 * @example addModal({id: "uid", title: "Notification" }) */
export var addModal = addModalTrigger.publish;
export var addDialog = addDialogTrigger.publish;
export var removeDialog = removeDialogTrigger.publish;
/** Remove a ID-specific modal from the modal-notification stack
 * @example removeModal("uid") */
export var removeModal = removeModalTrigger.publish;
var uniqueDialogId = 0;
var uniqueModalId = 0;
/** Get Modal actions */
export var useModals = function () {
    var dispatch = useDispatch();
    var addModal = function (modal) {
        return dispatch(ModalsActions.addModal(__assign(__assign({}, modal), { id: modal.id || "modal_" + uniqueModalId++ })));
    };
    var removeModal = function (id) { return dispatch(ModalsActions.removeModal(id)); };
    var clearAllModals = function () { return dispatch(ModalsActions.clearAllModals()); };
    var addDialog = function (dialog) {
        return dispatch(ModalsActions.addModal(__assign(__assign({}, dialog), { id: dialog.id || "dialog_" + uniqueDialogId++, title: dialog.title, cancelText: dialog.cancelText || "Cancel", confirmText: dialog.confirmText || "OK", content: dialog.content, isConfirm: true, footerButtonPlacement: "flex-end", showCloseIcon: true, onClosed: function () {
                var _a;
                (_a = dialog.onClosed) === null || _a === void 0 ? void 0 : _a.call(dialog, dialog);
            }, onConfirm: function () { return dialog.onConfirm(dialog); }, width: dialog.width, height: dialog.height })));
    };
    var removeDialog = function (id) { return dispatch(ModalsActions.removeModal(id)); };
    return { addModal: addModal, removeModal: removeModal, clearAllModals: clearAllModals, addDialog: addDialog, removeDialog: removeDialog };
};
