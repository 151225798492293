var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { styled, theme } from "@faharmony/theme";
import { Icon } from "../Icon";
var StyledCard = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-shadow: ", ";\n  border-radius: ", ";\n  width: 100%;\n  height: auto;\n  background-color: ", ";\n  padding: ", ";\n  display: grid;\n  grid-template-columns: max-content auto;\n  gap: ", ";\n  align-items: flex-start;\n\n  .Card-text {\n    .Card-heading {\n      display: block;\n      color: ", ";\n    }\n    .Card-body {\n      color: ", ";\n    }\n  }\n"], ["\n  box-shadow: ", ";\n  border-radius: ", ";\n  width: 100%;\n  height: auto;\n  background-color: ", ";\n  padding: ", ";\n  display: grid;\n  grid-template-columns: max-content auto;\n  gap: ", ";\n  align-items: flex-start;\n\n  .Card-text {\n    .Card-heading {\n      display: block;\n      color: ", ";\n    }\n    .Card-body {\n      color: ", ";\n    }\n  }\n"])), theme.shadow.default, theme.radius[300], theme.color.background.primary, theme.spacing.md, theme.spacing.md, theme.color.text.primary, theme.color.text.secondary);
/**
 * Card
 * ---
 * Component to display information as a bound card.
 * @jira https://fasolutions.jira.com/browse/HAR-102
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @copyright FA Solutions Oy
 * @version 1.7.0
 * @example <Card />
 */
var Card = React.forwardRef(function (props, ref) {
    var heading = props.heading, icon = props.icon, body = props.body, iconColor = props.iconColor, rest = __rest(props, ["heading", "icon", "body", "iconColor"]);
    return (_jsxs(StyledCard, __assign({ ref: ref }, rest, { children: [_jsx("div", __assign({ className: "Card-icon" }, { children: icon && _jsx(Icon, { icon: icon, color: iconColor }) })), _jsxs("div", __assign({ className: "Card-text" }, { children: [_jsx("p", __assign({ className: "Card-heading" }, { children: heading })), body && _jsx("small", __assign({ className: "Card-body" }, { children: body }))] }))] })));
});
export { Card };
var templateObject_1;
