var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@faharmony/theme";
import { useDialogs } from "./helpers";
import { ContentView } from "@faharmony/views";
import { Button, Text } from "@faharmony/components";
var Dialog = function (dialog) {
    var removeDialog = useDialogs().removeDialog;
    var handleClose = function (dialog) {
        var _a;
        removeDialog(dialog.id);
        (_a = dialog.onClosed) === null || _a === void 0 ? void 0 : _a.call(dialog, dialog);
    };
    var handleConfirm = function (dialog) {
        var _a;
        removeDialog(dialog.id);
        (_a = dialog.onConfirm) === null || _a === void 0 ? void 0 : _a.call(dialog, dialog);
    };
    return (_jsx(ContentView, { headerTitle: dialog.title, onClose: function () { return handleClose(dialog); }, children: _jsx(Box, __assign({ direction: "column", style: { padding: 8, minHeight: 120 } }, { children: typeof dialog.content === "string" ? (_jsx(Text, { value: dialog.content, variant: "p" })) : dialog.content })), footerContent: _jsxs(Box, __assign({ justifyContent: "flex-end" }, { children: [_jsx(Button, { id: "DialogueCancelButton", value: dialog.cancelText, onClick: function () { return handleClose(dialog); }, variant: "default" }), _jsx(Button, { id: "DialogueConfirmButton", value: dialog.confirmText, onClick: function () { return handleConfirm(dialog); }, variant: "primary" })] })) }, dialog.id));
};
export default Dialog;
