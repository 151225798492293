var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
/** @author Mikhail Vasin <> (https://github.com/mvasin/react-div-100vh) */
import React from "react";
import { useEventListener } from "@faharmony/helpers";
var rvhRegex = /(\d+(\.\d*)?)rvh(?!\w)/;
// const containsRvh = (propertyValue: string) => rvhRegex.test(propertyValue);
var replaceRvhWithPx = function (propertyStringValue, windowHeight) {
    return propertyStringValue.replace(rvhRegex, function (_, rvh) { return "".concat((windowHeight * parseFloat(rvh)) / 100, "px"); });
};
var throwOnBadArgs = function (givenStyle, windowHeight) {
    if ((typeof givenStyle !== "object" && givenStyle !== undefined) ||
        Array.isArray(givenStyle))
        throw Error("style (the first argument) must be an object or undefined");
    if (typeof windowHeight !== "number" || windowHeight < 0)
        throw Error("Second argument (windowHeight) must be a non-negative number");
};
var convertStyle = function (givenStyle, windowHeight) {
    throwOnBadArgs(givenStyle, windowHeight);
    // If style is not passed, implicit {height: '100rvh'} style is used.
    var defaultStyle = { height: "100rvh" };
    var usedStyle = givenStyle === undefined ? defaultStyle : givenStyle;
    var convertedStyle = {};
    Object.keys(usedStyle).forEach(function (key) {
        // if a value contains no rvh unit, it's used as is, otherwise converted to px; 1rvh = (window.innerHeight / 100)px
        convertedStyle[key] =
            typeof usedStyle[key] === "string"
                ? replaceRvhWithPx(usedStyle[key], windowHeight)
                : usedStyle[key];
    });
    return convertedStyle;
};
var getWindowHeight = function () {
    return (document &&
        document.documentElement &&
        document.documentElement.clientHeight) ||
        window.innerHeight;
};
var Div100vh = function (props) {
    var _a = __read(React.useState({}), 2), style = _a[0], setStyle = _a[1];
    var updateStyle = function () {
        return setStyle(convertStyle(props.style, getWindowHeight()));
    };
    React.useLayoutEffect(function () {
        updateStyle();
    }, []);
    useEventListener("resize", updateStyle);
    return _jsx("div", __assign({}, props, { style: style }));
};
export { Div100vh };
