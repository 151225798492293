var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, useRouteMatch } from "@faharmony/router";
import { ModuleProvider } from "@faharmony/module";
import { useCheckUserForRolesCallback, getI18nValue } from "./helpers";
import LandingPage from "./LandingPage";
import Loader from "./Loader";
import { ModalContainer } from "../Modals";
var ModuleRouter = function (Module) {
    var path = useRouteMatch().path;
    var label = Module.label, _a = Module.subModules, subModules = _a === void 0 ? [] : _a, i18next = Module.i18next;
    var moduleLabel = getI18nValue(i18next, Module.id, "moduleName", label);
    return (_jsxs(ModuleProvider, __assign({ Module: Module }, { children: [_jsx(ModalContainer, {}), _jsx(Helmet, { children: _jsx("title", { children: moduleLabel }) }), _jsx(Suspense, __assign({ fallback: _jsx(Loader, {}) }, { children: _jsxs(Switch, { children: [subModules.map(function (subModule) {
                            var subModuleLabel = getI18nValue(i18next, Module.id, subModule.id, subModule.label);
                            return (_jsxs(Route, __assign({ path: path + "/" + subModule.id }, { children: [_jsx(Helmet, { children: _jsx("title", { children: moduleLabel + " - " + subModuleLabel }) }), _jsx(subModule.component, {})] }), subModule.id));
                        }), _jsx(Route, __assign({ path: path }, { children: _jsx(Module.component, {}) }))] }) }))] })));
};
export var MainView = function (_a) {
    var Modules = _a.Modules, appConfig = _a.appConfig, multilingual = _a.multilingual;
    var checkRolesForUser = useCheckUserForRolesCallback();
    var modules = Modules.filter(function (m) { return !m.stateOnly; });
    return (_jsxs(React.Fragment, { children: [_jsx(Helmet, __assign({ titleTemplate: "FA " + appConfig.name + " - %s", defaultTitle: "FA " + appConfig.name }, { children: _jsx("meta", { name: "description", content: appConfig.description || appConfig.name }) }), "helmetApp"), _jsxs(Switch, { children: [modules.map(function (Module) {
                        return checkRolesForUser(Module.roles) ? (_jsx(Route, __assign({ path: "/".concat(Module.id) }, { children: _jsx(ModuleRouter, __assign({}, Module, { i18next: multilingual })) }), Module.id)) : null;
                    }), _jsx(Route, __assign({ path: "/" }, { children: _jsx(LandingPage, { appConfig: appConfig, Modules: modules }) }))] }, "switch")] }));
};
export default MainView;
