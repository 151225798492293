var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { theme, styled, Box } from "@faharmony/theme";
import { Button } from "../../Button";
import { Text } from "../../Text";
var Styled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0;\n  padding: ", ";\n  min-width: 200px;\n  max-width: 320px;\n"], ["\n  margin: 0;\n  padding: ", ";\n  min-width: 200px;\n  max-width: 320px;\n"])), theme.spacing.md);
export default (function (props) { return function (instance) {
    var _a = props.message, message = _a === void 0 ? "Are you sure?" : _a, confirmButtonIcon = props.confirmButtonIcon, _b = props.cancelButtonText, cancelButtonText = _b === void 0 ? "No" : _b, _c = props.confirmButtonText, confirmButtonText = _c === void 0 ? "Yes" : _c, _d = props.confirmButtonVariant, confirmButtonVariant = _d === void 0 ? "primary" : _d, _e = props.onCancel, onCancel = _e === void 0 ? function () { } : _e, onConfirm = props.onConfirm;
    var handleConfirm = function () {
        onConfirm();
        instance.closePopover();
    };
    var handleCancel = function () {
        onCancel();
        instance.closePopover();
    };
    return (_jsxs(Styled, { children: [_jsx(Text, { value: message }), _jsxs(Box, __assign({ justifyContent: "flex-end", style: { marginTop: theme.spacing.md } }, { children: [_jsx(Button, { value: cancelButtonText, onClick: handleCancel, outline: true, spacing: "sm", variant: "secondary" }), _jsx(Button, { value: confirmButtonText, icon: confirmButtonIcon, variant: confirmButtonVariant, onClick: handleConfirm, spacing: "sm" })] }))] }));
}; });
var templateObject_1;
