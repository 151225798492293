var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InfoBox } from "@faharmony/components";
import { Box } from "@faharmony/theme";
import { SummaryView } from "@faharmony/views";
import { faMagnifyingGlassMinus, faMagnifyingGlassPlus, } from "@faharmony/icons";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { formatDate } from "@faharmony/locale";
import { getUserNameFromTask } from "../utils";
import { useGetTaskAndProcessInstance } from "../hook/useGetTaskDueDateAndProcessInstance";
import { Skeleton } from "@faharmony/mui";
import Loader from "../../App/Loader";
export var WorkflowDetails = function (props) {
    var _a = useGetTaskAndProcessInstance({
        processInstanceId: props.task.processInstanceId,
        taskDefinitionKey: props.task.taskDefinitionKey,
        taskId: props.task.id,
    }), taskDueDate = _a.taskDueDate, processInstance = _a.processInstance, loading = _a.loading;
    return (_jsx(TransformWrapper, __assign({ centerOnInit: true }, { children: function (_a) {
            var _b, _c, _d, _e;
            var zoomIn = _a.zoomIn, zoomOut = _a.zoomOut;
            return (_jsx(SummaryView, { caption: "WORKFLOW", heading: (_b = processInstance === null || processInstance === void 0 ? void 0 : processInstance.processDefinitionName) !== null && _b !== void 0 ? _b : props.task.workflowDetails.processDefinitionName, onClose: props.onClose, additionalContentToggleButtonEnabled: true, additionalContentExpandedByDefault: true, additionalContent: _jsx(Box, __assign({ direction: "column" }, { children: !loading ? (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ direction: "row", spacing: "xl" }, { children: [_jsx("div", { children: _jsx(InfoBox, { field: "Creation date", value: formatDate(new Date((_c = processInstance === null || processInstance === void 0 ? void 0 : processInstance.startTime) !== null && _c !== void 0 ? _c : props.task.workflowDetails.startTime)) }) }), _jsx("div", { children: _jsx(InfoBox, { field: "Created by", value: getUserNameFromTask(props.task) }) }), _jsx("div", { children: _jsx(InfoBox, { field: "Due date", value: props.task.dueDate || taskDueDate
                                                ? formatDate(taskDueDate !== null && taskDueDate !== void 0 ? taskDueDate : props.task.dueDate)
                                                : "-" }) })] })), _jsx(Box, __assign({ direction: "row" }, { children: _jsx(InfoBox, { field: "Workflow description", value: (_d = processInstance === null || processInstance === void 0 ? void 0 : processInstance.processDefinitionDescription) !== null && _d !== void 0 ? _d : props.task.workflowDetails.processDefinitionDescription }) }))] })) : (_jsx(Skeleton, { width: "30%" })) })), children: _jsx(TransformComponent, __assign({ wrapperStyle: {
                        width: "100%",
                        height: "100%",
                    } }, { children: !loading ? (_jsx("img", { id: "workflow-diagram", style: { width: "100%", height: "100%" }, src: "data:image/png;base64, ".concat((_e = processInstance === null || processInstance === void 0 ? void 0 : processInstance.diagram) !== null && _e !== void 0 ? _e : props.task.workflowDetails.diagram), alt: "workflow diagram" })) : (_jsx(Loader, {})) }), loading ? 1 : 0), actionsInHeader: true, actions: !loading
                    ? [
                        {
                            id: "zoomIn",
                            name: "Zoom In",
                            tooltip: "Zoom in",
                            icon: faMagnifyingGlassPlus,
                            onClick: function () { return zoomIn(); },
                        },
                        {
                            id: "zoomOut",
                            name: "zoomOut",
                            tooltip: "Zoom out",
                            icon: faMagnifyingGlassMinus,
                            onClick: function () { return zoomOut(); },
                        },
                    ]
                    : [] }));
        } })));
};
