var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/** Export `theme` object */
import { themeLight as faTheme, themeLight, themeDark } from "./faTheme";
/**Global bar width */
export var globalBarWidth = "52px";
/** Variable based color object */
var color = Object.keys(faTheme.color).reduce(function (colors, type) {
    var _a;
    return (__assign(__assign({}, colors), (_a = {}, _a[type] = Object.keys(faTheme.color[type]).reduce(function (result, item) {
        var _a;
        return (__assign(__assign({}, result), (_a = {}, _a[item] = "var(--color-".concat(type, "-").concat(item, ")"), _a)));
    }, {}), _a)));
}, {});
/** Variable based shadow object */
var shadow = Object.keys(faTheme.shadow).reduce(function (result, item) {
    result[item] = "var(--shadow-".concat(item, ")");
    return result;
}, {});
/** Theme object usable in code */
export var theme = __assign(__assign({}, faTheme), { color: color, shadow: shadow, themeLight: themeLight, themeDark: themeDark });
