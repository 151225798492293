var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from "react";
import ReactSelect from "react-select";
import ReactSelectAsync from "react-select/async";
import { WindowedMenuList } from "react-windowed-select";
import { useFormContext, Controller } from "../hook-form";
import { allComponents as components } from "./components";
import { selectStyles, selectTheme, filterSelectStyles } from "./style";
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";
var Selector = forwardRef(function (props, ref) {
    var onFocus = props.onFocus, isLoading = props.isLoading, isDisabled = props.isDisabled, _a = props.isClearable, isClearable = _a === void 0 ? true : _a, options = props.options, loadOptions = props.loadOptions, defaultOptions = props.defaultOptions, cacheOptions = props.cacheOptions, _b = props.readonly, readonly = _b === void 0 ? false : _b, _c = props.isFilter, isFilter = _c === void 0 ? false : _c, isCreatable = props.isCreatable, selectProps = __rest(props, ["onFocus", "isLoading", "isDisabled", "isClearable", "options", "loadOptions", "defaultOptions", "cacheOptions", "readonly", "isFilter", "isCreatable"]);
    var postSelectOptions = {
        className: isFilter ? "FilterCombobox" : "Combobox",
        styles: isFilter ? filterSelectStyles : selectStyles,
        closeMenuOnSelect: !props.isMulti,
        hideSelectedOptions: false,
        components: components,
        theme: function (t) { return selectTheme(t, isFilter, props.spacing); },
        options: loadOptions ? undefined : options,
    };
    var preSelectOptions = {
        isClearable: isClearable,
        isSearchable: true,
        isLoading: isLoading,
        isDisabled: isDisabled || readonly || isLoading,
        readonly: readonly,
    };
    /** render in react-window */
    if ((options === null || options === void 0 ? void 0 : options.length) > 0 && !props.isMulti) {
        postSelectOptions.components = __assign(__assign({}, postSelectOptions.components), { MenuList: WindowedMenuList });
    }
    var commonProps = __assign(__assign(__assign({}, preSelectOptions), selectProps), postSelectOptions);
    if (isCreatable) {
        return loadOptions ? (_jsx(AsyncCreatableSelect, __assign({}, commonProps, { loadOptions: loadOptions, defaultOptions: defaultOptions, cacheOptions: cacheOptions, ref: ref }))) : (_jsx(CreatableSelect, __assign({}, commonProps, { ref: ref })));
    }
    return loadOptions ? (_jsx(ReactSelectAsync, __assign({}, commonProps, { loadOptions: loadOptions, defaultOptions: defaultOptions, cacheOptions: cacheOptions, ref: ref }))) : (_jsx(ReactSelect, __assign({}, commonProps, { ref: ref })));
});
/**
 * Combobox
 * ---
 * Component to search and select options.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @copyright FA Solutions Oy
 * @jira https://fasolutions.jira.com/browse/HAR-69
 * @version 1.6.0
 * @example <Combobox options={[{label: "Opt1", value: 'op1'}]} />
 */
var Combobox = forwardRef(function (props, ref) {
    var name = props.name, rules = props.rules, onFocus = props.onFocus;
    var reactSelect = (_jsx(Selector, __assign({}, props, { ref: ref })));
    // Check if component is placed in a react-hook-form.
    try {
        var control = useFormContext().control;
        return (_jsx(Controller, { control: control, rules: rules, render: function (_a) {
                var field = _a.field;
                return (_jsx(Selector, __assign({}, field, props, { ref: ref, onFocus: onFocus })));
            }, name: name || "combobox", defaultValue: props.defaultValue }));
    }
    catch (_a) {
        return reactSelect;
    }
});
export { Combobox };
