var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import tsInvariant from "@faharmony/helpers/lib/misc";
var withNewItemIds = function (list, itemIds) { return ({
    id: list.id,
    title: list.title,
    itemIds: itemIds,
    sortType: list.sortType,
}); };
var reorderSingleDrag = function (_a) {
    var _b, _c;
    var entities = _a.entities, selectedItemsIds = _a.selectedItemsIds, source = _a.source, destination = _a.destination;
    // moving in the same list
    if (source.droppableId === destination.droppableId) {
        var list = entities.lists[source.droppableId];
        var reordered = reorder(list.itemIds, source.index, destination.index);
        var updated_1 = __assign(__assign({}, entities), { lists: __assign(__assign({}, entities.lists), (_b = {}, _b[list.id] = withNewItemIds(list, reordered), _b)) });
        return {
            entities: updated_1,
            selectedItemsIds: selectedItemsIds,
        };
    }
    // moving to a new list
    var home = entities.lists[source.droppableId];
    var foreign = entities.lists[destination.droppableId];
    // the id of the item to be moved
    var itemId = home.itemIds[source.index];
    // remove from home list
    var newHomeItemIds = __spreadArray([], __read(home.itemIds), false);
    newHomeItemIds.splice(source.index, 1);
    // add to foreign list
    var newForeignItemIds = __spreadArray([], __read(foreign.itemIds), false);
    newForeignItemIds.splice(destination.index, 0, itemId);
    var updated = __assign(__assign({}, entities), { lists: __assign(__assign({}, entities.lists), (_c = {}, _c[home.id] = withNewItemIds(home, newHomeItemIds), _c[foreign.id] = withNewItemIds(foreign, newForeignItemIds), _c)) });
    return {
        entities: updated,
        selectedItemsIds: selectedItemsIds,
    };
};
export var getHomeList = function (entities, itemId) {
    var listId = entities.listOrder.find(function (id) {
        var list = entities.lists[id];
        return list.itemIds.includes(itemId);
    });
    tsInvariant(listId, "Count not find list for item");
    return entities.lists[listId];
};
var reorderMultiDrag = function (_a) {
    var _b;
    var entities = _a.entities, selectedItemsIds = _a.selectedItemsIds, source = _a.source, destination = _a.destination;
    var start = entities.lists[source.droppableId];
    var dragged = start.itemIds[source.index];
    var insertAtIndex = (function () {
        var destinationIndexOffset = selectedItemsIds.reduce(function (previous, current) {
            if (current === dragged) {
                return previous;
            }
            var final = entities.lists[destination.droppableId];
            var list = getHomeList(entities, current);
            if (list !== final) {
                return previous;
            }
            var index = list.itemIds.indexOf(current);
            if (index >= destination.index) {
                return previous;
            }
            // the selected item is before the destination index
            // we need to account for this when inserting into the new location
            return previous + 1;
        }, 0);
        var result = destination.index - destinationIndexOffset;
        return result;
    })();
    // doing the ordering now as we are required to look up lists
    // and know original ordering
    var orderedSelectedItemIds = __spreadArray([], __read(selectedItemsIds), false);
    orderedSelectedItemIds.sort(function (a, b) {
        // moving the dragged item to the top of the list
        if (a === dragged) {
            return -1;
        }
        if (b === dragged) {
            return 1;
        }
        // sorting by their natural indexes
        var listForA = getHomeList(entities, a);
        var indexOfA = listForA.itemIds.indexOf(a);
        var listForB = getHomeList(entities, b);
        var indexOfB = listForB.itemIds.indexOf(b);
        if (indexOfA !== indexOfB) {
            return indexOfA - indexOfB;
        }
        // sorting by their order in the selectedItemsIds list
        return -1;
    });
    // we need to remove all of the selected items from their lists
    var withRemovedItems = entities.listOrder.reduce(function (previous, listId) {
        var list = entities.lists[listId];
        // remove the id's of the items that are selected
        var remainingItemIds = list.itemIds.filter(function (id) { return !selectedItemsIds.includes(id); });
        previous[list.id] = withNewItemIds(list, remainingItemIds);
        return previous;
    }, entities.lists);
    var final = withRemovedItems[destination.droppableId];
    var withInserted = (function () {
        var base = __spreadArray([], __read(final.itemIds), false);
        base.splice.apply(base, __spreadArray([insertAtIndex, 0], __read(orderedSelectedItemIds), false));
        return base;
    })();
    // insert all selected items into final list
    var withAddedItems = __assign(__assign({}, withRemovedItems), (_b = {}, _b[final.id] = withNewItemIds(final, withInserted), _b));
    var updated = __assign(__assign({}, entities), { lists: withAddedItems });
    return {
        entities: updated,
        selectedItemsIds: orderedSelectedItemIds,
    };
};
export var multiDragAwareReorder = function (args) {
    if (args.selectedItemsIds.length > 1) {
        return reorderMultiDrag(args);
    }
    return reorderSingleDrag(args);
};
export var multiSelectTo = function (entities, selectedItemsIds, newItemId) {
    // Nothing already selected
    if (!selectedItemsIds.length) {
        return [newItemId];
    }
    var listOfNew = getHomeList(entities, newItemId);
    var indexOfNew = listOfNew.itemIds.indexOf(newItemId);
    var lastSelected = selectedItemsIds[selectedItemsIds.length - 1];
    var listOfLast = getHomeList(entities, lastSelected);
    var indexOfLast = listOfLast.itemIds.indexOf(lastSelected);
    // multi selecting to another list
    // select everything up to the index of the current item
    if (listOfNew !== listOfLast) {
        return listOfNew.itemIds.slice(0, indexOfNew + 1);
    }
    // multi selecting in the same list
    // need to select everything between the last index and the current index inclusive
    // nothing to do here
    if (indexOfNew === indexOfLast) {
        return null;
    }
    var isSelectingForwards = indexOfNew > indexOfLast;
    var start = isSelectingForwards ? indexOfLast : indexOfNew;
    var end = isSelectingForwards ? indexOfNew : indexOfLast;
    var inBetween = listOfNew.itemIds.slice(start, end + 1);
    // everything in-between needs to have it's selection toggled.
    // with the exception of the start and end values which will always be selected
    var toAdd = inBetween.filter(function (itemId) {
        // if already selected: then no need to select it again
        if (selectedItemsIds.includes(itemId)) {
            return false;
        }
        return true;
    });
    var sorted = isSelectingForwards ? toAdd : __spreadArray([], __read(toAdd), false).reverse();
    var combined = __spreadArray(__spreadArray([], __read(selectedItemsIds), false), __read(sorted), false);
    return combined;
};
export var reorder = function (list, startIndex, endIndex) {
    var result = Array.from(list);
    var _a = __read(result.splice(startIndex, 1), 1), removed = _a[0];
    result.splice(endIndex, 0, removed);
    return result;
};
export var sortItemsByContentAsc = function (items) { return items.sort(function (a, b) { return a === null || a === void 0 ? void 0 : a.content.localeCompare(b === null || b === void 0 ? void 0 : b.content); }); };
export var sortItemIdsAsc = function (entities, listId) {
    var items = entities.lists[listId].itemIds.map(function (itemId) { return entities.items[itemId]; });
    var sortedItems = sortItemsByContentAsc(items);
    return sortedItems.map(function (each) { return each.id; });
};
