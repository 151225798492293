var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useLayoutEffect } from "react";
import { useWindowSize } from "./useWindowSize";
/**
 * This hook returns the dimensions of the target element.
 * Return {width, height} : number
 * @param targetRef
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 */
export var useElementDimensions = function (targetRef) {
    var _a = __read(useState({ width: 0, height: 0 }), 2), dimensions = _a[0], setDimensions = _a[1];
    var windowWidth = useWindowSize().width;
    useLayoutEffect(function () {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight,
            });
        }
    }, [windowWidth]);
    return { width: dimensions.width, height: dimensions.height };
};
// function getSize(el: HTMLElement) {
//   if (!el) {
//     return {
//       width: 0,
//       height: 0,
//     };
//   }
//   return {
//     width: el.offsetWidth,
//     height: el.offsetHeight,
//   };
// }
// function useComponentSize(ref: React.RefObject<any>) {
//   const _useState = useState(getSize(ref ? ref.current : {}));
//   const ComponentSize = _useState[0];
//   const setComponentSize = _useState[1];
//   const handleResize = useCallback(
//     function handleResize() {
//       if (ref.current) {
//         setComponentSize(getSize(ref.current));
//       }
//     },
//     [ref],
//   );
//   useLayoutEffect(
//     function() {
//       if (!ref.current) {
//         return;
//       }
//       handleResize();
//       if (typeof ResizeObserver === 'function') {
//         var resizeObserver = new ResizeObserver(function() {
//           handleResize();
//         });
//         resizeObserver.observe(ref.current);
//         return function() {
//           resizeObserver.disconnect(ref.current);
//           resizeObserver = null;
//         };
//       } else {
//         window.addEventListener('resize', handleResize);
//         return function() {
//           window.removeEventListener('resize', handleResize);
//         };
//       }
//     },
//     [ref.current],
//   );
//   return ComponentSize;
// }
