import React, { useContext } from "react";
import Keycloak from "keycloak-js";
import { adminRealmRole } from "./common";
export var KeycloakContext = React.createContext(new Keycloak());
/**
 * Get keycloak initiated instance among other properties.
 * - keycloakInstance
 * - getCurrentToken()
 * - isAdmin
 * - userId
 * - hasClientRoles(roles)
 */
export var useKeycloak = function () {
    var keycloakInstance = useContext(KeycloakContext);
    return {
        keycloakInstance: keycloakInstance,
        getCurrentToken: function () { return keycloakInstance.token || ""; },
        isAdmin: keycloakInstance.hasRealmRole(adminRealmRole),
        userId: keycloakInstance.subject || "",
        hasClientRoles: function (roles, client) {
            return roles.some(function (role) { return keycloakInstance.hasResourceRole(role, client); });
        },
    };
};
