var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled, theme } from "@faharmony/theme";
export var StyledNavigationMenu = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: inherit;\n  height: inherit;\n  background: transparent;\n  padding-top: ", ";\n  padding-bottom: ", ";\n  overflow: auto;\n  ::-webkit-scrollbar,\n  ::-webkit-scrollbar:horizontal,\n  ::-webkit-scrollbar:vertical {\n    width: ", ";\n    height: ", ";\n  }\n\n  ul {\n    list-style-type: none;\n    padding: 0;\n    margin: 0;\n  }\n\n  .noContent {\n    color: ", ";\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n"], ["\n  position: relative;\n  width: inherit;\n  height: inherit;\n  background: transparent;\n  padding-top: ", ";\n  padding-bottom: ", ";\n  overflow: auto;\n  ::-webkit-scrollbar,\n  ::-webkit-scrollbar:horizontal,\n  ::-webkit-scrollbar:vertical {\n    width: ", ";\n    height: ", ";\n  }\n\n  ul {\n    list-style-type: none;\n    padding: 0;\n    margin: 0;\n  }\n\n  .noContent {\n    color: ", ";\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n"])), theme.spacing.md, theme.spacing.md, theme.spacing.xs, theme.spacing.xs, function () { return theme.color.text.disabled; });
var iconWidth = "24px";
var NavItemCommonStyle = function (props) { return "     \n  display: grid;\n  grid-gap: ".concat(theme.spacing.md, ";\n  padding: 0 ").concat(theme.spacing.md, ";\n  align-items: center;\n  background: ").concat(props.isSelected ? theme.color.background.selected : "transparent", ";\n  color: ").concat(props.isSelected ? theme.color.text.primary : theme.color.text.secondary, ";\n  grid-template-columns: ").concat(props.showLeadingIcon ? "".concat(iconWidth, " auto max-content") : "auto max-content", ";\n  cursor: pointer;\n  border-left: 2px solid ").concat(props.isSelected ? theme.color.static.accent : "transparent", ";\n\n  .Text {\n    cursor: pointer;\n    user-select: none;\n  }\n"); };
var IconWrapperStyle = function () { return ".iconWrapper {\n  width: ".concat(iconWidth, ";\n  .Icon {\n    margin-top: calc(-1 * ").concat(function () { return theme.spacing.xs; }, ");\n  }\n}"); };
var HoverStyle = function (props) { return "\n  :hover {\n    background: ".concat(props.isSelected
    ? theme.color.background.selected
    : theme.color.background.hover, ";\n    color: ").concat(theme.color.text.primary, ";\n  }\n"); };
export var StyledNavPrimaryItem = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  min-height: 40px;\n  margin-bottom: ", ";\n  background: ", ";\n  padding-bottom: ", ";\n\n  .navPrimaryItem {\n    min-height: 40px;\n    ", ";\n    ", ";\n    ", ";\n  }\n"], ["\n  min-height: 40px;\n  margin-bottom: ", ";\n  background: ", ";\n  padding-bottom: ", ";\n\n  .navPrimaryItem {\n    min-height: 40px;\n    ", ";\n    ", ";\n    ", ";\n  }\n"])), theme.spacing.md, function (props) {
    return props.isExpanded ? theme.color.background.default : "transparent";
}, function (props) { return (props.isExpanded ? theme.spacing.sm : 0); }, NavItemCommonStyle, IconWrapperStyle, HoverStyle);
export var StyledNavSecondaryItem = styled.li(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  min-height: 32px;\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  min-height: 32px;\n  ", ";\n  ", ";\n  ", ";\n"])), NavItemCommonStyle, IconWrapperStyle, HoverStyle);
export default StyledNavigationMenu;
var templateObject_1, templateObject_2, templateObject_3;
