var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { theme, motion, AnimatePresence, useDisableAnimation, } from "@faharmony/theme";
import { Trigger } from "@faharmony/helpers";
import { SummaryView } from "@faharmony/views";
var SideDrawerTrigger = new Trigger();
var width = 300;
var SideDrawer = function () {
    var _a = __read(useState(), 2), sideView = _a[0], setSideView = _a[1];
    SideDrawerTrigger.useSubscribe(setSideView);
    var disableAnimation = useDisableAnimation();
    var drawerStyle = {
        zIndex: theme.zIndex.docked,
        boxShadow: theme.shadow.float,
        backgroundColor: theme.color.background.default,
    };
    var closeSideView = function () { return setSideView(undefined); };
    if (disableAnimation)
        return sideView ? (_jsx("div", __assign({ style: __assign(__assign({}, drawerStyle), { width: width }) }, { children: _jsx(SummaryView, __assign({}, sideView, { onClose: closeSideView })) }))) : null;
    else
        return (_jsx(AnimatePresence, { children: sideView && (_jsx(motion.div, __assign({ style: drawerStyle, initial: { width: 0 }, animate: { width: width }, exit: { width: 0 }, transition: theme.transition.spring }, { children: _jsx(SummaryView, __assign({}, sideView, { onClose: closeSideView })) }))) }));
};
/**
 * setAppSideDrawer
 * ---
 * Use this callback to set content is App's side drawer. The drawer uses a summaryView to display content.
 * The information passed should be an object of type - `IAppSideDrawerProps`.
 *
 * The drawer has it's own close mechanism.
 */
export var setAppSideDrawer = SideDrawerTrigger.publish;
export var resetAppSideDrawer = function () { return SideDrawerTrigger.publish(undefined); };
export default SideDrawer;
