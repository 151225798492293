var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { theme, getTextDimensions, styled, Grid } from "@faharmony/theme";
import { Icon, Text, Tooltip } from "@faharmony/components";
var maxCrumbWidth = 300;
var StyledCrumb = styled(Grid)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  cursor: ", ";\n  max-width: ", "px;\n  :hover {\n    color: ", ";\n  }\n"], ["\n  color: ", ";\n  cursor: ", ";\n  max-width: ", "px;\n  :hover {\n    color: ", ";\n  }\n"])), function (p) {
    return p.crumb.disabled
        ? theme.color.text.disabled
        : p.crumb.onClick
            ? theme.color.text.secondary
            : p.lastCrumb
                ? theme.color.text.secondary
                : theme.color.text.disabled;
}, function (p) {
    return p.crumb.disabled ? "not-allowed" : p.crumb.onClick ? "pointer" : "initial";
}, maxCrumbWidth, function (p) {
    return p.crumb.disabled
        ? theme.color.text.disabled
        : p.crumb.onClick
            ? theme.color.text.primary
            : p.lastCrumb
                ? theme.color.text.secondary
                : theme.color.text.disabled;
});
var Crumb = function (_a) {
    var crumb = _a.crumb, _b = _a.lastCrumb, lastCrumb = _b === void 0 ? false : _b, _c = _a.variant, variant = _c === void 0 ? "p" : _c, _d = _a.separator, separator = _d === void 0 ? "/" : _d;
    var id = crumb.id, icon = crumb.icon, label = crumb.label, onClick = crumb.onClick, tooltip = crumb.tooltip;
    var showTooltip = tooltip
        ? true
        : label
            ? getTextDimensions(label, variant).width > maxCrumbWidth - 20
            : false;
    if (icon || label) {
        var item = (_jsxs(StyledCrumb, __assign({ onClick: onClick, width: "auto", role: "breadcrumb", crumb: crumb, lastCrumb: lastCrumb, spacing: "sm", gridTemplate: "auto / repeat(3, max-content)" }, { children: [icon && _jsx(Icon, { icon: icon }), label && _jsx(Text, { value: label, variant: variant, truncate: true }), (!lastCrumb || onClick) && (_jsx(Text, { value: " ".concat(separator, " "), variant: variant, color: "disabled" }))] })));
        if (showTooltip)
            return _jsx(Tooltip, __assign({ value: tooltip || label || "" }, { children: item }));
        else
            return item;
    }
    console.warn("Crumb ".concat(id, " could not be rendered since there is no label or icon."));
    return null;
};
export default Crumb;
var templateObject_1;
