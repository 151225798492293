var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { InfoBox, useModal } from "@faharmony/components";
import { SummaryView } from "@faharmony/views";
import { actions, state } from "../state";
import { FormDisplayer } from "./FormDisplayer";
import { useProcessExecutor } from "../hook/useProcessExecutor";
import { Box } from "@faharmony/theme";
import { formatDate } from "@faharmony/locale";
import { addToast } from "../../Toast";
import { useSnapshot } from "valtio";
import { getUserNameFromTask } from "../utils";
export var ProcessModal = function () {
    var _a, _b, _c;
    var _d = __read(useModal(false), 3), ModalWrapper = _d[0], openModal = _d[1], closeModal = _d[2];
    useSnapshot(state);
    var processState = useProcessExecutor();
    var activeTask = state.activeTask;
    useEffect(function () {
        if (state.modalMode === "closed")
            closeModal();
        else
            openModal();
        return function () { return closeModal(); };
    }, [openModal, closeModal, state.modalMode]);
    return (_jsx(ModalWrapper, __assign({ style: { width: "98vw", height: "98vh" } }, { children: _jsx(SummaryView, __assign({ caption: ((_a = state.selectedProcess) === null || _a === void 0 ? void 0 : _a.name) || "", heading: ((_b = state.activeTask) === null || _b === void 0 ? void 0 : _b.name) || "", footerContent: undefined, additionalContent: _jsx(Box, __assign({ direction: "column" }, { children: _jsxs(Box, __assign({ spacing: "xl", wrap: "wrap" }, { children: [_jsx(Box, __assign({ width: "auto", direction: "column" }, { children: _jsx(InfoBox, { field: "Assignee", value: getUserNameFromTask(activeTask, "taskAssignee") }) })), _jsx(Box, __assign({ width: "auto", direction: "column" }, { children: _jsx(InfoBox, { field: "Creation date", value: (activeTask === null || activeTask === void 0 ? void 0 : activeTask.createTime) &&
                                    formatDate(new Date(activeTask === null || activeTask === void 0 ? void 0 : activeTask.createTime)) }) })), _jsx(Box, __assign({ width: "auto", direction: "column" }, { children: _jsx(InfoBox, { field: "Created by", value: getUserNameFromTask(activeTask) }) }))] })) })), additionalContentToggleButtonEnabled: true, onClose: function () {
                addToast({
                    id: "process-task-closed",
                    title: "You can continue your task later from Workflows and tasks.",
                    variant: "note",
                    persist: false,
                });
                actions.closeModal();
            } }, { children: (state.selectedProcess || state.activeTask) && processState && (_jsx(FormDisplayer, { processState: processState, formKey: (_c = state.activeTask) === null || _c === void 0 ? void 0 : _c.formKey })) })) })));
};
export default ProcessModal;
