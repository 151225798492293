var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useRef, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { FormHelperText } from "@mui/material";
/**
 * CustomInputHelper component provides additional features for handling overflow
 * and displaying tooltips for FormHelperText in case of overflow.
 *
 * @component
 * @example
 * // Example usage of CustomInputHelper
 * <CustomInputHelper>
 *   This is a helper text that may overflow and show a tooltip.
 * </CustomInputHelper>
 *
 * @param {FormHelperTextProps} props - Props for the CustomInputHelper component.
 * @returns {JSX.Element|null} The rendered CustomInputHelper component.
 */
export var CustomInputHelper = function (_a) {
    var children = _a.children, otherProps = __rest(_a, ["children"]);
    var _b = __read(useState(false), 2), showTooltip = _b[0], setShowTooltip = _b[1];
    var _c = __read(useState(false), 2), isMouseOver = _c[0], setIsMouseOver = _c[1];
    var textRef = useRef(null);
    var timeoutId;
    useEffect(function () {
        var handleResize = function () {
            if (textRef.current) {
                var isOverflowing = textRef.current.scrollWidth > textRef.current.clientWidth;
                setShowTooltip(isOverflowing && isMouseOver);
            }
        };
        handleResize(); // Check initially
        window.addEventListener("resize", handleResize);
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, [isMouseOver]);
    var handleMouseEnter = function () {
        timeoutId = setTimeout(function () {
            setIsMouseOver(true);
        }, 500);
    };
    var handleMouseLeave = function () {
        setIsMouseOver(false);
        clearTimeout(timeoutId);
        setShowTooltip(false);
    };
    var HelperText = (_jsx(FormHelperText, __assign({ ref: textRef, onMouseEnter: handleMouseEnter, onTouchStart: handleMouseEnter, onTouchEnd: handleMouseLeave, onMouseLeave: handleMouseLeave, sx: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            lineHeight: 1.5,
        } }, otherProps, { children: children })));
    if (!children)
        return null;
    if (!showTooltip)
        return HelperText;
    return (_jsx(Tooltip, __assign({ title: children, open: showTooltip, onClose: handleMouseLeave }, { children: HelperText })));
};
