var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { getTextDimensions } from "@faharmony/theme";
import { Text, Tooltip } from "@faharmony/components";
import { maxWidth, StyledTab } from "./style";
import { InView } from "react-intersection-observer";
export var Tab = React.forwardRef(function (_a, ref) {
    var _b = _a.tab, id = _b.id, label = _b.label, _c = _b.disabled, disabled = _c === void 0 ? false : _c, formMethods = _a.formMethods, fieldNames = _a.fieldNames, orientation = _a.orientation, active = _a.active, setHiddenItems = _a.setHiddenItems, hiddenItems = _a.hiddenItems, handleClick = _a.handleClick;
    var errorFields = ((formMethods === null || formMethods === void 0 ? void 0 : formMethods.formState.errors) &&
        Object.keys(formMethods === null || formMethods === void 0 ? void 0 : formMethods.formState.errors)) ||
        [];
    var error = (errorFields === null || errorFields === void 0 ? void 0 : errorFields.length)
        ? !!(fieldNames === null || fieldNames === void 0 ? void 0 : fieldNames.find(function (fieldName) { return errorFields.includes(fieldName); }))
        : false;
    var isTextOverflow = getTextDimensions(label).width > maxWidth - 16;
    var onTabSelect = function (id) {
        handleClick(id);
    };
    var tab = (_jsx(InView, __assign({ className: "noMargin " + (isTextOverflow ? "" : "tab"), threshold: 0.7, style: {
            width: orientation === "vertical" ? "100%" : "fit-content",
            marginTop: "0px !important",
        }, onChange: function (inView, _entry) {
            var _a;
            return setHiddenItems(__assign(__assign({}, hiddenItems), (_a = {}, _a[id] = inView, _a)));
        } }, { children: _jsxs(StyledTab, __assign({ style: { width: "maxContent", marginTop: "0px !important" }, id: id, ref: ref, orientation: orientation, error: error, active: active, onClick: function () { return (disabled ? {} : onTabSelect(id)); }, disabled: disabled }, { children: [orientation === "horizontal" && _jsx("div", { className: "sperator-left" }), _jsx(Text, { value: label, variant: "p", truncate: true })] }), id) })));
    return isTextOverflow ? (_jsx(Tooltip, __assign({ value: label, placement: "top", interactive: true }, { children: _jsx("span", __assign({ className: "noMargin tab" }, { children: tab })) }))) : (tab);
});
