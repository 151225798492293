var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { components, } from "react-select";
import { theme, styled } from "@faharmony/theme";
import { useHover } from "@faharmony/helpers";
import { Icon, RingLoader } from "@faharmony/components";
import { faTimesCircle, faAngleDown } from "@faharmony/icons";
import { calcHeight } from "../common";
import { Checkbox } from "../Checkbox";
var ClearIndicator = function (props) {
    var _a = __read(useHover(), 2), hoverRef = _a[0], isHovered = _a[1];
    var clearButton = _jsx(Icon, { icon: faTimesCircle, ref: hoverRef });
    var innerProps = props.innerProps, _b = props.children, children = _b === void 0 ? clearButton : _b, getStyles = props.getStyles;
    return (_jsx("div", __assign({}, innerProps, { style: __assign(__assign({}, getStyles("clearIndicator", props)), { cursor: "pointer", transition: "color 0.5s", color: theme.color.text[isHovered ? "primary" : "disabled"] }) }, { children: children })));
};
var DropdownIndicator = function (props) {
    var spacing = props.selectProps.spacing;
    var downButton = (_jsx(Icon, { icon: faAngleDown, color: theme.color.text.secondary }));
    var innerProps = props.innerProps, _a = props.children, children = _a === void 0 ? downButton : _a, getStyles = props.getStyles;
    return (_jsx("div", __assign({}, innerProps, { style: __assign(__assign({}, getStyles("clearIndicator", props)), { width: calcHeight({ spacing: spacing }) - 1 + "px", padding: 0, justifyContent: "center" }) }, { children: children })));
};
var StyledOption = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n\n  :hover {\n    background-color: ", " !important;\n  }\n"], ["\n  ", ";\n\n  :hover {\n    background-color: ", " !important;\n  }\n"])), function (p) {
    return p.isSelected &&
        "background-color: ".concat(theme.color.background.selected, " !important");
}, function (p) {
    return p.isDisabled ? "none" : theme.color.background.hover;
});
var Option = function (props) {
    var _a = props.isMulti, isMulti = _a === void 0 ? true : _a, isSelected = props.isSelected, label = props.label, getStyles = props.getStyles, innerProps = props.innerProps, innerRef = props.innerRef, className = props.className, isDisabled = props.isDisabled;
    if (isMulti === false)
        return _jsx(components.Option, __assign({}, props));
    return (_jsx(StyledOption, __assign({ isSelected: isSelected, isDisabled: isDisabled, ref: innerRef, style: getStyles("option", props), className: className }, { children: _jsx(Checkbox, __assign({}, innerProps, { disabled: isDisabled, checked: isSelected, label: label })) })));
};
var allSelectedOption = {
    label: "All selected",
    value: "*",
    isFixed: true,
};
var getSelected = function (options, getValue) {
    var getValues = function (fn) {
        return Array.isArray(fn()) ? fn() : [];
    };
    var flatOptions = options
        .reduce(function (acc, o) {
        if (o.options)
            o.options.forEach(function (o2) { return acc.push(o2); });
        else
            acc.push(o);
        return acc;
    }, [])
        .filter(function (o) { return !o.isDisabled; });
    var values = getValues(getValue);
    var isAllSelected = values.length === flatOptions.length;
    var isNoneSelected = values.length === 0;
    var isSomeSelected = !isAllSelected && !isNoneSelected;
    return { isSomeSelected: isSomeSelected, isAllSelected: isAllSelected, isNoneSelected: isNoneSelected, flatOptions: flatOptions };
};
var ValueContainer = function (_a) {
    var _b;
    var children = _a.children, props = __rest(_a, ["children"]);
    var className = (_b = props === null || props === void 0 ? void 0 : props.selectProps) === null || _b === void 0 ? void 0 : _b.className;
    var isAllSelected = getSelected(props.options, props.getValue).isAllSelected;
    var toBeRendered = children;
    var firstEl = children && children[0] && children[0][0];
    if (firstEl) {
        // Show only count of selected elements, if combobox is used as filter in table
        if (className === "FilterCombobox") {
            var selectedElements = __assign(__assign({}, firstEl), { key: "*", props: __assign(__assign({}, firstEl.props), { children: "Selected (".concat(children[0].length, ")"), data: {
                        label: "Selected *",
                        value: "*",
                        isFixed: true,
                    } }) });
            toBeRendered = children && [[selectedElements], children[1]];
        }
        else if (isAllSelected) {
            // Create All selected tag
            var newAllEl = __assign(__assign({}, firstEl), { key: allSelectedOption.value, props: __assign(__assign({}, firstEl.props), { children: allSelectedOption.label, data: allSelectedOption }) });
            toBeRendered = children && [[newAllEl], children[1]];
        }
    }
    return _jsx(components.ValueContainer, __assign({}, props, { children: toBeRendered }));
};
var Menu = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var _b = props.isMulti, isMulti = _b === void 0 ? true : _b, setValue = props.setValue, getValue = props.getValue, clearValue = props.clearValue, options = props.options, selectProps = props.selectProps;
    var disableSelectAll = selectProps["disableSelectAll"] || false;
    var _c = getSelected(options, getValue), flatOptions = _c.flatOptions, isAllSelected = _c.isAllSelected, isSomeSelected = _c.isSomeSelected;
    // Create Select all checkbox
    if (isMulti !== false && !disableSelectAll)
        return (_jsx(components.Menu, __assign({}, props, { children: _jsxs("div", { children: [_jsx("div", __assign({ style: {
                            padding: "6px",
                            borderBottom: "1px solid ".concat(theme.color.border.default),
                        } }, { children: _jsx(Checkbox, { label: "Select all (" + flatOptions.length + ")", checked: isAllSelected, indeterminate: isSomeSelected, onClick: function () {
                                if (isAllSelected)
                                    clearValue();
                                else
                                    setValue(flatOptions, { action: "select-option" });
                            } }) })), children] }) })));
    else
        return _jsx(components.Menu, __assign({}, props, { children: children }));
};
var IndicatorSeparator = function (props) {
    return props.selectProps.isSearchable || props.selectProps.isClearable ? (_jsx(components.IndicatorSeparator, __assign({}, props))) : null;
};
var LoadingIndicator = function () { return _jsx(RingLoader, {}); };
// const MultiValue = ({
//   children,
//   clearValue,
//   ...props
// }: MultiValueProps<ComboboxOptionType>) => {
//   console.dir(props);
//   return (
//     <Tag value={children?.toString()} onClose={clearValue} />
//     //   <components.MultiValue {...props}>
//     // </components.MultiValue>
//   );
// };
export var allComponents = {
    ClearIndicator: ClearIndicator,
    DropdownIndicator: DropdownIndicator,
    Option: Option,
    ValueContainer: ValueContainer,
    Menu: Menu,
    IndicatorSeparator: IndicatorSeparator,
    LoadingIndicator: LoadingIndicator,
    // MultiValue,
};
var templateObject_1;
