var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect, useCallback } from "react";
import { Box } from "@faharmony/theme";
import { Panel } from "./Panel";
var useAccordion = function (props) {
    var _a = __read(useState([]), 2), expandedIds = _a[0], setExpandedIds = _a[1];
    useEffect(function () {
        var _a = props.independent, independent = _a === void 0 ? false : _a, _b = props.panels, panels = _b === void 0 ? [] : _b;
        var activePanelIds = panels
            .filter(function (panel) { return (panel.defaultActive ? true : false); })
            .map(function (panel) { return panel.id; });
        setExpandedIds(independent || activePanelIds.length <= 1
            ? activePanelIds
            : [activePanelIds[0]]);
    }, [props]);
    var handleExpand = useCallback(function (panelId) {
        if (props.independent) {
            setExpandedIds(function (prevIds) {
                return prevIds.indexOf(panelId) > -1 ? prevIds : __spreadArray(__spreadArray([], __read(prevIds), false), [panelId], false);
            });
        }
        else
            setExpandedIds([panelId]);
    }, [props.independent]);
    var handleCollapse = useCallback(function (panelId) {
        setExpandedIds(function (prevIds) { return prevIds.filter(function (id) { return id !== panelId; }); });
    }, []);
    return { expandedIds: expandedIds, handleExpand: handleExpand, handleCollapse: handleCollapse };
};
/**
 * Accordion
 * ---
 * A set of collapsible panels set in vertical orientation.
 * Can be used for displaying multiple set of information.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.5.0
 * @example
 * <Accordion
 *  panels={[
 *    {
 *      id: "panel1",
 *      heading: "Panel heading",
 *      content: <Panel1 />
 *    },
 *  ]}
 * />
 */
var Accordion = function (props) {
    var accordionOptions = useAccordion(props);
    return (_jsx(Box, __assign({ direction: "column", spacing: "md", height: props.fullHeight ? "100%" : "auto", className: "accordion" }, { children: props.panels.map(function (panel) { return (_jsx(Panel, __assign({}, panel, accordionOptions, { fullHeight: props.fullHeight, disableHeaderColor: props.disableHeaderColor, headerIconPosition: props.headerIconPosition, headerUppercase: props.headerUppercase }), panel.id)); }) })));
};
export { Accordion };
