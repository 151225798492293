var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
var ModuleContext = React.createContext({});
var ModuleProvider = function (props) {
    return (_jsx(ModuleContext.Provider, __assign({ value: props.Module }, { children: props.children })));
};
/** Hook to get Module information inside the module
 * with locale T function. */
var useModule = function () {
    var Module = useContext(ModuleContext);
    var t = useTranslation(Module.id).t;
    return __assign(__assign({}, Module), { t: t });
};
/** Custom hook to get i18n's T-function for selected or current Module */
var useModuleTranslation = function (moduleId) {
    var id = useModule().id;
    return useTranslation(moduleId !== null && moduleId !== void 0 ? moduleId : id).t;
};
export { ModuleProvider, useModule, useModuleTranslation };
