/**
 * Opens an external link.
 * This function will setup a virtual anchor element and
 * fire click handler to open new URL in the same room.
 * It works better than location.href=something or location.reload()
 * @param targetUrl Url to open
 * @param targetName default "_self" - Opens in same tab
 */
export var openExternalLink = function (targetURL, targetName) {
    if (targetName === void 0) { targetName = "_self"; }
    var a = document.createElement("a");
    a.href = targetURL;
    a.target = targetName;
    a.click();
};
/**
 * Downloads a blob object as a file to machine.
 * This function emulates clicking a button-link
 * to download an attached file/blob.
 * @param blob https://developer.mozilla.org/en-US/docs/Web/API/Blob
 * @param filename Name of the file to be downloaded. Default: "file"
 */
export var downloadFileFromBlob = function (blob, filename) {
    if (filename === void 0) { filename = "file"; }
    try {
        var link = document.createElement("a");
        link.setAttribute("download", filename);
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true;
    }
    catch (e) {
        console.error(e);
        return false;
    }
};
