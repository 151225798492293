var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { FormProvider } from "./hook-form";
import { styled, theme, Box } from "@faharmony/theme";
var StyledForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  /* Styles for all forms */\n  padding: ", ";\n"], ["\n  /* Styles for all forms */\n  padding: ", ";\n"])), theme.spacing.md);
/**
 * Form
 * ---
 * Styled wrapper for HTML form
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @copyright FA Solutions Oy
 * @version 1.6.0
 */
var Form = React.forwardRef(function (_a, ref) {
    var formMethods = _a.formMethods, children = _a.children, _b = _a.direction, direction = _b === void 0 ? "column" : _b, props = __rest(_a, ["formMethods", "children", "direction"]);
    return (_jsx(FormProvider, __assign({}, formMethods, { children: _jsx(StyledForm, __assign({}, props, { ref: ref }, { children: _jsx(Box, __assign({ direction: direction, style: { width: "100%", height: "100%" }, alignItems: "start" }, { children: children })) })) })));
});
export { Form };
var templateObject_1;
