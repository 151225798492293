var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/** @see https://github.com/dvtng/react-loading-skeleton */
import { theme, styled, keyframes } from "@faharmony/theme";
var defaultBaseColor = theme.color.background.disabled;
var defaultHighlightColor = theme.color.background.highlight;
var skeletonKeyframes = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    background-position: -200px 0;\n  }\n  100% {\n    background-position: calc(200px + 100%) 0;\n  }\n"], ["\n  0% {\n    background-position: -200px 0;\n  }\n  100% {\n    background-position: calc(200px + 100%) 0;\n  }\n"])));
/**
 * Skeleton
 * ---
 * Display a placeholder preview of your content before
 * the data gets loaded to reduce load-time frustration.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.6.0
 *
 * @example
 * <Skeleton width="200px" />
 */
var Skeleton = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: ", ";\n  width: ", ";\n  background-color: ", ";\n  background-image: linear-gradient(\n    90deg,\n    ", ",\n    ", ",\n    ", "\n  );\n  background-size: 200px 100%;\n  background-repeat: no-repeat;\n  border-radius: ", ";\n  display: inline-block;\n  line-height: 1;\n  animation: ", " ", "s ease-in-out\n    infinite;\n"], ["\n  height: ", ";\n  width: ", ";\n  background-color: ", ";\n  background-image: linear-gradient(\n    90deg,\n    ", ",\n    ", ",\n    ", "\n  );\n  background-size: 200px 100%;\n  background-repeat: no-repeat;\n  border-radius: ", ";\n  display: inline-block;\n  line-height: 1;\n  animation: ", " ", "s ease-in-out\n    infinite;\n"])), function (p) { return p.height || "20px"; }, function (p) { return p.width || "100%"; }, defaultBaseColor, defaultBaseColor, defaultHighlightColor, defaultBaseColor, function (p) { return (p.circle ? "50%" : theme.radius[300]); }, skeletonKeyframes, function (p) { return p.duration || 1.2; });
export { Skeleton };
var templateObject_1, templateObject_2;
