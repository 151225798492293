var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useMutation, gql } from "@apollo/client";
import { addToast } from "../../Toast";
import { taskManagerActions } from "../state";
var COMPLETE_PROCESS_TASK = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation (\n    $taskId: String\n    $data: Json\n    $skipRequiredFieldValidation: Boolean\n  ) {\n    completeProcessTask(\n      taskId: $taskId\n      data: $data\n      skipRequiredFieldValidation: $skipRequiredFieldValidation\n    ) {\n      formKey\n      formDefinition\n      formTranslationsJson\n      taskId\n      id\n      createTime\n      name\n      taskDefinitionKey\n      processInstanceId\n      data\n      processInitiator {\n        firstName\n        lastName\n        userId\n      }\n      assignee\n      taskAssignee {\n        firstName\n        lastName\n        userId\n      }\n    }\n  }\n"], ["\n  mutation (\n    $taskId: String\n    $data: Json\n    $skipRequiredFieldValidation: Boolean\n  ) {\n    completeProcessTask(\n      taskId: $taskId\n      data: $data\n      skipRequiredFieldValidation: $skipRequiredFieldValidation\n    ) {\n      formKey\n      formDefinition\n      formTranslationsJson\n      taskId\n      id\n      createTime\n      name\n      taskDefinitionKey\n      processInstanceId\n      data\n      processInitiator {\n        firstName\n        lastName\n        userId\n      }\n      assignee\n      taskAssignee {\n        firstName\n        lastName\n        userId\n      }\n    }\n  }\n"])));
var lastSavedTaskId;
export var useCompleteTask = function (taskId) {
    if (taskId) {
        lastSavedTaskId = taskId;
    }
    var _a = __read(useMutation(COMPLETE_PROCESS_TASK, {
        onCompleted: function (data) {
            if (data.completeProcessTask || lastSavedTaskId) {
                taskManagerActions.completeTask(data.completeProcessTask, lastSavedTaskId);
            }
            else {
                throw new Error("taskId is undefined");
            }
        },
        onError: function (error) {
            console.error(error);
            addToast({
                id: "start-process-error",
                title: "Error",
                description: error.message,
                variant: "negative",
                persist: true,
            });
        },
    }), 2), completeTask = _a[0], _b = _a[1], data = _b.data, loading = _b.loading, error = _b.error, reset = _b.reset;
    return {
        loading: loading,
        error: error,
        reset: reset,
        data: data,
        completeTask: completeTask,
    };
};
var templateObject_1;
