var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useQuery, useMutation, gql } from "@apollo/client/";
import { baseApolloClient } from "./apolloClients";
export var GET_USER_PREFERENCES_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query loadUserPreferences($prefKey: String!) {\n    loadPreference(prefKey: $prefKey)\n  }\n"], ["\n  query loadUserPreferences($prefKey: String!) {\n    loadPreference(prefKey: $prefKey)\n  }\n"])));
export var SET_USER_PREFERENCES_QUERY = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation setUserPreferences($prefKey: String!, $prefValue: String!) {\n    savePreference(prefKey: $prefKey, prefValue: $prefValue)\n  }\n"], ["\n  mutation setUserPreferences($prefKey: String!, $prefValue: String!) {\n    savePreference(prefKey: $prefKey, prefValue: $prefValue)\n  }\n"])));
/**
 * Gets the FA Back USER preference entry corresponding to the prefKey.
 * Pass it the type declaration of the preferences you are expecting.
 * @param prefKey the unique key of the preference.
 */
export var usePreferences = function (prefKey, skip) {
    var _a = useQuery(GET_USER_PREFERENCES_QUERY, {
        client: baseApolloClient,
        variables: { prefKey: prefKey },
        skip: skip,
    }), preferenceData = _a.data, preferencesLoading = _a.loading, preferencesError = _a.error, refetchPreferences = _a.refetch;
    var _b = __read(useMutation(SET_USER_PREFERENCES_QUERY, {
        variables: { prefKey: prefKey },
        client: baseApolloClient,
    }), 2), setPreferences = _b[0], _c = _b[1], setPreferencesLoading = _c.loading, setPreferencesData = _c.data, setPreferencesError = _c.error;
    var typedPreferences = preferenceData === null || preferenceData === void 0 ? void 0 : preferenceData.loadPreference;
    var typedSetPreferences = setPreferencesData === null || setPreferencesData === void 0 ? void 0 : setPreferencesData.savePreference;
    return {
        preferenceData: typedPreferences,
        preferencesLoading: preferencesLoading,
        preferencesError: preferencesError,
        setPreferences: setPreferences,
        setPreferencesLoading: setPreferencesLoading,
        setPreferencesData: typedSetPreferences,
        setPreferencesError: setPreferencesError,
        refetchPreferences: refetchPreferences,
    };
};
var templateObject_1, templateObject_2;
