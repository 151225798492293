import { useMemo } from "react";
import { useMediaQuery } from "../sensors/useMediaQuery";
/**
 * Hook to check device-type (size).
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @example
 * const {deviceType, minWidth, maxWidth, isMobile, isTablet, isDesktop} = useResponsive();
 */
export var useResponsive = function () {
    var deviceType = useMediaQuery(["(min-width: 1024px)", "(min-width: 600px)", "(min-width: 0px)"], ["desktop", "tablet", "mobile"], "desktop");
    var minWidth = useMemo(function () { return (deviceType === "mobile" ? 0 : deviceType === "tablet" ? 600 : 1024); }, [deviceType]);
    var maxWidth = useMemo(function () {
        return deviceType === "mobile" ? 599 : deviceType === "tablet" ? 1023 : Infinity;
    }, [deviceType]);
    var isMobile = useMemo(function () { return deviceType === "mobile"; }, [
        deviceType,
    ]);
    var isTablet = useMemo(function () { return deviceType === "tablet"; }, [
        deviceType,
    ]);
    var isDesktop = useMemo(function () { return deviceType === "desktop"; }, [
        deviceType,
    ]);
    return { deviceType: deviceType, minWidth: minWidth, maxWidth: maxWidth, isMobile: isMobile, isTablet: isTablet, isDesktop: isDesktop };
};
export default useResponsive;
