var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "../emotion";
import { theme } from "../theme";
/**
 * Grid
 * ---
 * Grid layout
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.5.0
 * @example
 * <Grid>{Other stuff...}</Grid>
 */
var Grid = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: grid;\n  gap: ", ";\n  width: ", ";\n  height: ", ";\n  overflow: ", ";\n  grid-template: ", ";\n  grid-auto-flow: ", ";\n  grid-auto-rows: ", ";\n  grid-auto-columns: ", ";\n"], ["\n  position: relative;\n  display: grid;\n  gap: ", ";\n  width: ", ";\n  height: ", ";\n  overflow: ", ";\n  grid-template: ", ";\n  grid-auto-flow: ", ";\n  grid-auto-rows: ", ";\n  grid-auto-columns: ", ";\n"])), function (props) { return theme.spacing[props.spacing || "md"]; }, function (props) { return props.width || "100%"; }, function (props) { return props.height || "100%"; }, function (props) { return props.overflow || "auto"; }, function (props) { return props.gridTemplate || "none"; }, function (props) { return props.direction || "row"; }, function (props) { return props.gridAutoRows || "auto"; }, function (props) { return props.gridAutoColumns || "auto"; });
export { Grid };
var templateObject_1;
