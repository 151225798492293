var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { preventDefaultOnClick } from "@faharmony/helpers";
import { Popover, } from "../Popover";
import { Button } from "../Button";
/**
 * ButtonPopover
 * ---
 * Description of component
 * @jira https://fasolutions.jira.com/browse/HAR-ticker
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @copyright FA Solutions Oy
 * @version 1.6.0
 * @example <ButtonPopover />
 */
var ButtonPopover = React.forwardRef(function (props, ref) {
    var popoverConfirmOptions = props.popoverConfirmOptions, popoverContentCallback = props.popoverContentCallback, popoverMenuItems = props.popoverMenuItems, _a = props.popoverPlacement, popoverPlacement = _a === void 0 ? "auto" : _a, _b = props.popoverTrigger, popoverTrigger = _b === void 0 ? "click" : _b, buttonProps = __rest(props, ["popoverConfirmOptions", "popoverContentCallback", "popoverMenuItems", "popoverPlacement", "popoverTrigger"]);
    var hasPopover = popoverContentCallback || popoverConfirmOptions || popoverMenuItems;
    var button = (_jsx(Button, __assign({}, buttonProps, { ref: ref, onClick: hasPopover && popoverTrigger === "click"
            ? preventDefaultOnClick
            : buttonProps.onClick, onContextMenu: hasPopover && popoverTrigger === "contextmenu"
            ? preventDefaultOnClick
            : buttonProps.onContextMenu })));
    var popoverCommonProps = {
        placement: popoverMenuItems ? "bottom-start" : popoverPlacement,
        trigger: popoverTrigger,
    };
    if (hasPopover) {
        if (popoverContentCallback)
            return (_jsx(Popover, __assign({}, popoverCommonProps, { content: popoverContentCallback }, { children: button })));
        if (popoverMenuItems)
            return (_jsx(Popover.Menu, __assign({}, popoverCommonProps, { menuItems: popoverMenuItems }, { children: button })));
        if (popoverConfirmOptions)
            return (_jsx(Popover.Confirm, __assign({}, popoverCommonProps, popoverConfirmOptions, { children: button })));
    }
    return button;
});
export { ButtonPopover, };
