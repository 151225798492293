import { useEventListener } from "./useEventListener";
/**
 * Hook to determine if the system in online or offline. Takes 2 functions as parameter which are executed when the mode changes.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 *
 * ---
 * @example
 * useNetwork({
    onOnline: (e) => console.log(e),
    onOffline: (e) => console.log(e),
  });
 */
export var useNetwork = function (_a) {
    // const [online, setOnline] = React.useState(navigator.onLine);
    var onOnline = _a.onOnline, onOffline = _a.onOffline;
    var handleOnline = function (e) {
        onOnline && onOnline(e);
    };
    var handleOffline = function (e) {
        onOffline && onOffline(e);
    };
    useEventListener("online", handleOnline);
    useEventListener("offline", handleOffline);
};
