var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "../emotion";
import { theme } from "../theme";
var calcSpacing = function (props) {
    var _a = props.wrap, wrap = _a === void 0 ? "nowrap" : _a, _b = props.spacing, spacing = _b === void 0 ? "md" : _b, _c = props.direction, direction = _c === void 0 ? "row" : _c;
    var isWrap = wrap === "wrap" || wrap === "wrap-reverse";
    var gap = theme.spacing[spacing];
    var marginDirection = direction === "column"
        ? "top"
        : direction === "column-reverse"
            ? "bottom"
            : direction === "row-reverse"
                ? "right"
                : "left";
    if (isWrap)
        return "\n    margin: calc(".concat(gap, " * -0.5);\n    & > * {\n        margin: calc(").concat(gap, " * 0.5) !important;\n    }\n  ");
    else
        return "\n  & > * + * {\n    margin-".concat(marginDirection, ": ").concat(gap, " !important;    \n  }\n  ");
};
/**
 * Box
 * ---
 * Flex Wrapper.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.5.0
 * @example
 * <Box>{Other stuff...}</Box>
 */
var Box = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: ", ";\n  height: ", ";\n  display: flex;\n  flex-wrap: ", ";\n  flex-direction: ", ";\n  justify-content: ", ";\n  align-content: ", ";\n  align-items: ", ";\n  overflow: ", ";\n  ", ";\n"], ["\n  position: relative;\n  width: ", ";\n  height: ", ";\n  display: flex;\n  flex-wrap: ", ";\n  flex-direction: ", ";\n  justify-content: ", ";\n  align-content: ", ";\n  align-items: ", ";\n  overflow: ", ";\n  ", ";\n"])), function (props) { return props.width || "100%"; }, function (props) { return props.height || "auto"; }, function (props) { return props.wrap || "nowrap"; }, function (props) { return props.direction || "row"; }, function (props) { return props.justifyContent || "auto"; }, function (props) { return props.alignContent || "initial"; }, function (props) {
    return props.alignItems ||
        (props.direction === "column" ? "flex-start" : "center");
}, function (props) { return props.overflow || "visible"; }, calcSpacing);
export { Box };
var templateObject_1;
