var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useTimeoutConditionally } from "@faharmony/helpers";
import { styled, theme, motion } from "@faharmony/theme";
import { Notification } from "@faharmony/components";
import { useToasts } from "./helpers";
var toastWidth = "280px";
var StyledToast = styled(motion.div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", ";\n"], ["\n  width: ", ";\n"])), toastWidth);
var Toast = function (toast) {
    var removeToast = useToasts().removeToast;
    var id = toast.id, _a = toast.persist, persist = _a === void 0 ? false : _a, props = __rest(toast, ["id", "persist"]);
    useTimeoutConditionally(function () { return removeToast(id); }, 5000, !persist);
    var motionProps = {
        initial: "initial",
        animate: "animate",
        exit: "exit",
        transition: theme.transition.spring,
        layout: true,
        variants: {
            initial: { right: -400 },
            animate: { right: 0 },
            exit: { right: -400 },
        },
    };
    return (_jsx(StyledToast, __assign({}, motionProps, { children: _jsx(Notification, __assign({}, props, { children: _jsx(_Fragment, { children: props.children }), className: "toast", id: id, onClose: function (p) { return removeToast(p.id || id); } })) })));
};
export default Toast;
var templateObject_1;
