var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState, useRef, useMemo, } from "react";
import ResizeObserver from "resize-observer-polyfill";
/**
 *
 * @author Viktor Hubert <contact@hubertviktor.com>
 */
export function useResizeObserver(opts) {
    if (opts === void 0) { opts = {}; }
    // `defaultRef` Has to be non-conditionally declared here whether or not it'll
    // be used as that's how hooks work.
    // @see https://reactjs.org/docs/hooks-rules.html#explanation
    var defaultRef = useRef(null);
    // Saving the callback as a ref. With this, I don't need to put onResize in the
    // effect dep array, and just passing in an anonymous function without memoising
    // will not instantiate the hook's ResizeObserver
    var onResize = opts.onResize;
    var onResizeRef = useRef(undefined);
    onResizeRef.current = onResize;
    // Using a single instance throughout the hook's lifetime
    var resizeObserverRef = useRef();
    var ref = opts.ref || defaultRef;
    var _a = __read(useState({
        width: undefined,
        height: undefined,
    }), 2), size = _a[0], setSize = _a[1];
    // Using a ref to track the previous width / height to avoid unnecessary renders
    var previous = useRef({
        width: undefined,
        height: undefined,
    });
    useEffect(function () {
        if (resizeObserverRef.current) {
            return;
        }
        resizeObserverRef.current = new ResizeObserver(function (entries) {
            if (!Array.isArray(entries)) {
                return;
            }
            // Since we only observe the one element, we don't need to loop over the
            // array
            if (!entries.length) {
                return;
            }
            var entry = entries[0];
            // `Math.round` is in line with how CSS resolves sub-pixel values
            var newWidth = Math.round(entry.contentRect.width);
            var newHeight = Math.round(entry.contentRect.height);
            if (previous.current.width !== newWidth ||
                previous.current.height !== newHeight) {
                var newSize = { width: newWidth, height: newHeight };
                if (onResizeRef.current) {
                    onResizeRef.current(newSize);
                }
                else {
                    previous.current.width = newWidth;
                    previous.current.height = newHeight;
                    setSize(newSize);
                }
            }
        });
        return function () { };
    }, []);
    useEffect(function () {
        if (typeof ref !== "object" ||
            ref === null ||
            !(ref.current instanceof Element)) {
            return;
        }
        var element = ref.current;
        resizeObserverRef.current.observe(element);
        return function () { return resizeObserverRef.current.unobserve(element); };
    }, [ref]);
    return useMemo(function () { return ({ ref: ref, width: size.width, height: size.height }); }, [
        ref,
        size ? size.width : null,
        size ? size.height : null,
    ]);
}
export default useResizeObserver;
