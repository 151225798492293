var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { useRef, useEffect } from "react";
import { Box, motion, styled, theme } from "@faharmony/theme";
import { useDialogsState, DialogsState } from "./state";
import { useDialogs, addDialogTrigger, removeDialogTrigger } from "./helpers";
import Dialog from "./Dialog";
var StyledDiv = styled(motion.div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  display: flex;\n  direction: column;\n  justify-content: center;\n  align-items: start;\n  z-index: ", ";\n  background-color: ", ";\n  overflow-y: auto;\n\n  .modalContainer {\n    position: relative;\n    top: 20vh;\n    overflow: auto;\n    min-width: min(640px, calc(100vw - ", "));\n    max-width: calc(100vw - ", ");\n    max-height: calc(100vh - ", ");\n    border-radius: ", ";\n    box-shadow: ", ";\n  }\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  display: flex;\n  direction: column;\n  justify-content: center;\n  align-items: start;\n  z-index: ", ";\n  background-color: ", ";\n  overflow-y: auto;\n\n  .modalContainer {\n    position: relative;\n    top: 20vh;\n    overflow: auto;\n    min-width: min(640px, calc(100vw - ", "));\n    max-width: calc(100vw - ", ");\n    max-height: calc(100vh - ", ");\n    border-radius: ", ";\n    box-shadow: ", ";\n  }\n"])), theme.zIndex.popover + 1, theme.color.background.overlay, theme.spacing.xl, theme.spacing.xl, theme.spacing.xl, theme.radius[500], theme.shadow.float);
var motionProps = {
    initial: "initial",
    animate: "animate",
    exit: "exit",
    transition: { duration: 0.2, ease: "easeIn", delay: 0.0 },
    layout: true,
    variants: {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
    },
};
var DialogContainer = function () {
    var containerRef = useRef(null);
    var dialogs = useDialogsState();
    var _a = useDialogs(), addDialog = _a.addDialog, removeDialog = _a.removeDialog;
    addDialogTrigger.useCallback(addDialog);
    removeDialogTrigger.useCallback(removeDialog);
    useEffect(function () {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight,
            });
        }
    }, [containerRef, dialogs]);
    return (dialogs === null || dialogs === void 0 ? void 0 : dialogs.length) > 0 ? (_jsx(StyledDiv, { children: _jsx(Box, __assign({ alignItems: "center", direction: "column" }, { children: dialogs.map(function (dialog) { return (_createElement(motion.div, __assign({}, motionProps, { key: "container-" + dialog.id, className: "modalContainer" }),
                _jsx(Dialog, __assign({}, dialog)))); }) })) })) : (_jsx(_Fragment, {}));
};
export { DialogContainer, DialogsState };
export { addDialog, removeDialog, useDialogs } from "./helpers";
export { DialogMUI } from "./DialogMUI";
var templateObject_1;
