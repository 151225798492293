var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import BaseButton from "./Button";
import { faTimesCircle } from "@faharmony/icons";
/**
 * Button.Close
 * ---
 * A purposeful button to render an close button.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.5.0
 * @example
 * <Button value="button label" icon="faBell" />
 */
export var Close = function (props) { return (_jsx(BaseButton, __assign({}, props, {
    icon: faTimesCircle,
    tooltip: "Close",
    spacing: "sm",
    outline: true,
}))); };
