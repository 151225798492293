var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, Divider } from "@faharmony/components";
import { useTabs, useStepper, } from "@faharmony/navigation";
import { Box, Center } from "@faharmony/theme";
import { Form } from "@faharmony/form";
import { StyledBody, StyledFooter, StyledView } from "./style";
import { Header } from "./Header";
/**
 * SummaryView
 * ---
 * Summary View is the secondary (temporary) view which can be used to display information.
 *
 * It can be used as base for Modal.
 *
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.7.0
 * @example
 * <SummaryView heading="Heading" caption="type"> {content} </SummaryView>
 */
var SummaryView = function (props) {
    var id = props.id, children = props.children, footerContent = props.footerContent, tabs = props.tabs, caption = props.caption, stepper = props.stepper;
    var defaultContent = children || (_jsx(Center, { children: _jsx(Text, { value: "No content available.", color: "secondary" }) }));
    var defaultTab = {
        id: "default",
        label: "default",
        disabled: true,
        defaultActive: true,
        content: defaultContent,
    };
    var defaultStep = {
        label: "Default Step",
        completed: false,
        state: "active",
        content: defaultContent,
    };
    var defaultStepper = {
        steps: [defaultStep],
        currentStep: 0,
    };
    var _a = useTabs(caption, tabs || [defaultTab], true), TabBar = _a.TabBar, TabPanel = _a.TabPanel, orientation = _a.orientation;
    var _b = useStepper(stepper || defaultStepper), StepsBarWithButtons = _b.StepsBarWithButtons, StepPanel = _b.StepPanel;
    var formMethods = stepper === null || stepper === void 0 ? void 0 : stepper.formMethods;
    var panelContent = function () {
        if (tabs) {
            if (Array.isArray(tabs)) {
                return _jsx(TabPanel, {});
            }
            else {
                if ("formMethods" in tabs) {
                    return (_jsx(Form, __assign({ formMethods: tabs.formMethods }, { children: _jsx(TabPanel, {}) })));
                }
                else {
                    return _jsx(TabPanel, {});
                }
            }
        }
        else if (stepper === null || stepper === void 0 ? void 0 : stepper.steps) {
            return formMethods ? (_jsx(Form, __assign({ formMethods: formMethods }, { children: _jsx(StepPanel, {}) }))) : (_jsx(StepPanel, {}));
        }
        return defaultContent;
    };
    return (_jsxs(StyledView, __assign({ id: id, className: "SummaryView", hasFooter: !!footerContent || !!stepper }, { children: [_jsx(Header, __assign({}, props, { TabBar: TabBar, tabOrientation: orientation })), tabs && orientation === "vertical" ? (_jsx(StyledBody, __assign({ className: "SummaryView-body", style: { marginTop: -8 } }, { children: _jsxs(Box, __assign({ direction: "row", height: "100%", width: "100%" }, { children: [_jsx(TabBar, {}), _jsx(Box, __assign({ style: {
                                display: "block ",
                                height: "100%",
                                overflowY: "auto",
                            } }, { children: panelContent() }))] })) }))) : (_jsx(StyledBody, __assign({ className: "SummaryView-body" }, { children: panelContent() }))), (footerContent || stepper) && (_jsxs(StyledFooter, { children: [_jsx(Divider, {}), _jsx("div", __assign({ className: "SummaryView-footer" }, { children: stepper && stepper.steps ? _jsx(StepsBarWithButtons, {}) : footerContent }))] }))] })));
};
export { SummaryView };
export default SummaryView;
