var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { theme, Box } from "@faharmony/theme";
import { ButtonPopover, Divider, Toolbar } from "@faharmony/components";
export var Actions = function (props) {
    var actions = props.actions, isMenuCollapsed = props.isMenuCollapsed;
    var actionButtons = function (list) { return (_jsx(Box, __assign({ justifyContent: "space-between", wrap: "nowrap", direction: "column", className: "navHeader-actions", alignItems: "center" }, { children: list === null || list === void 0 ? void 0 : list.map(function (action) { return (_createElement(ButtonPopover, __assign({}, action, { key: action.id }))); }) }))); };
    return actions && !!actions.length ? (isMenuCollapsed ? (_jsxs("div", { children: [actionButtons(actions), _jsx(Divider, { spacing: "sm" })] })) : (_jsx("div", __assign({ style: { padding: theme.spacing.md, paddingBottom: 0 } }, { children: _jsx(Toolbar, { actions: actions }) })))) : null;
};
export default Actions;
