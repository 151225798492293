import { Trigger } from "@faharmony/helpers";
import { useDispatch, DialogsActions } from "./state";
export var addDialogTrigger = new Trigger();
export var removeDialogTrigger = new Trigger();
/** Add a new dialog to the dialog-notification stack
 * @example addDialog({id: "uid", title: "Notification" }) */
export var addDialog = addDialogTrigger.publish;
/** Remove a ID-specific dialog from the dialog-notification stack
 * @example removeDialog("uid") */
export var removeDialog = removeDialogTrigger.publish;
var uniqueDialogId = 0;
/** Get Dialog actions */
export var useDialogs = function () {
    var dispatch = useDispatch();
    var addDialog = function (dialog) {
        return dispatch(DialogsActions.addDialog({
            id: dialog.id || uniqueDialogId++ + "",
            title: dialog.title,
            cancelText: dialog.cancelText || "Cancel",
            confirmText: dialog.confirmText || "OK",
            content: dialog.content || null,
            onConfirm: function () {
                var _a;
                console.info("Dialog ".concat(this.id, " confirmed"));
                (_a = dialog.onConfirm) === null || _a === void 0 ? void 0 : _a.call(dialog, dialog);
            },
            onClosed: function () {
                var _a;
                console.info("Dialog ".concat(this.id, " closed"));
                (_a = dialog.onClosed) === null || _a === void 0 ? void 0 : _a.call(dialog, dialog);
            },
            // variant: dialog.variant || "default",
        }));
    };
    var removeDialog = function (id) {
        return dispatch(DialogsActions.removeDialog(id));
    };
    var clearAllDialogs = function () { return dispatch(DialogsActions.clearAllDialogs()); };
    return { addDialog: addDialog, removeDialog: removeDialog, clearAllDialogs: clearAllDialogs };
};
