var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { theme, styled } from "@faharmony/theme";
import { Icon, Text, Tooltip } from "@faharmony/components";
import { faExclamationSquare } from "@faharmony/icons";
export var inputHeight = 20;
export var calcHeight = function (_a) {
    var spacing = _a.spacing;
    var baseHeight = 32;
    var height = (spacing &&
        (["lg"].includes(spacing)
            ? baseHeight + 4
            : ["xl"].includes(spacing)
                ? baseHeight + 8
                : ["sm"].includes(spacing)
                    ? baseHeight - 4
                    : ["xs"].includes(spacing)
                        ? baseHeight - 8
                        : ["none"].includes(spacing)
                            ? inputHeight
                            : baseHeight)) ||
        baseHeight;
    return height;
};
export var StyledInputLabel = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: ", ";\n  display: grid;\n  grid-template-columns: max-content 1fr min-content;\n  color: ", ";\n  /* hide checkbox */\n  input {\n    width: 0px;\n    height: 0px;\n    display: none;\n  }\n  input:focus ~ .Icon {\n    outline: 1px dashed ", ";\n  }\n  /* hide icons */\n  input:checked ~ .unchecked,\n  .checked {\n    display: none;\n    margin-right: ", ";\n  }\n  /* show icons */\n  input:checked ~ .checked,\n  .unchecked {\n    display: inline-block;\n    margin-right: ", ";\n  }\n"], ["\n  cursor: ", ";\n  display: grid;\n  grid-template-columns: max-content 1fr min-content;\n  color: ", ";\n  /* hide checkbox */\n  input {\n    width: 0px;\n    height: 0px;\n    display: none;\n  }\n  input:focus ~ .Icon {\n    outline: 1px dashed ", ";\n  }\n  /* hide icons */\n  input:checked ~ .unchecked,\n  .checked {\n    display: none;\n    margin-right: ", ";\n  }\n  /* show icons */\n  input:checked ~ .checked,\n  .unchecked {\n    display: inline-block;\n    margin-right: ", ";\n  }\n"])), function (p) { return (p.disabled ? "inherit" : "pointer"); }, function (p) { return (p.disabled ? theme.color.text.disabled : "inherit"); }, theme.color.static.accent, theme.spacing.sm, theme.spacing.sm);
/** Render an error when the FormItem is programmed incorrectly */
export var InputPropError = function (props) {
    var value = props.value, defaultValue = props.defaultValue;
    var text = "Input elements must be either controlled or uncontrolled \n  (provide either the \"".concat(value, "\" prop, or the \"").concat(defaultValue || "default".concat(value.replace(/^./, value[0].toUpperCase())), "\" prop, but not both).");
    return (_jsx(Tooltip, __assign({ value: text }, { children: _jsxs("span", __assign({ style: { color: theme.color.text.negative } }, { children: [_jsx(Icon, { icon: faExclamationSquare }), _jsx(Text, { value: "Input error" })] })) })));
};
var templateObject_1;
