var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@faharmony/theme";
import { Step } from "./Step";
import { useLayoutEffect, useRef, useState } from "react";
export var StepsBar = function (_a) {
    var _b;
    var steps = _a.steps, setActiveStep = _a.setActiveStep, currentStep = _a.currentStep, editMode = _a.editMode;
    var targetRef = useRef(null);
    var _c = __read(useState(0), 2), stepWidth = _c[0], setStepWidth = _c[1];
    var calculateStepWidth = function (stepNode, parentWidth) {
        var stepCss = window.getComputedStyle(stepNode);
        var totalEmptySpace = steps.length *
            (parseFloat(stepCss.getPropertyValue("margin-right")) +
                parseFloat(stepCss.getPropertyValue("margin-left")));
        var calculatedStepWidth = (parentWidth - totalEmptySpace) / steps.length;
        return calculatedStepWidth;
    };
    useLayoutEffect(function () {
        var _a;
        if (targetRef.current) {
            var parentWidth = targetRef.current.offsetWidth;
            var stepNode = (_a = targetRef === null || targetRef === void 0 ? void 0 : targetRef.current) === null || _a === void 0 ? void 0 : _a.childNodes[0];
            var calculatedStepWidth = calculateStepWidth(stepNode, parentWidth);
            setStepWidth(calculatedStepWidth);
        }
    }, [(_b = targetRef === null || targetRef === void 0 ? void 0 : targetRef.current) === null || _b === void 0 ? void 0 : _b.offsetWidth]);
    return (_jsx(Box, __assign({ justifyContent: "flex-start", ref: targetRef, width: "100%" }, { children: steps.map(function (step, index) {
            var completedState = editMode || index == currentStep ? "active" : "inactive";
            return (_jsx(Step, { id: step.id, label: step.label, onClick: function () {
                    // Only allow to go back in the form but not forward
                    if (index < currentStep) {
                        setActiveStep(index);
                    }
                }, completed: index < currentStep, state: index <= currentStep
                    ? completedState
                    : index > currentStep
                        ? "inactive"
                        : "disabled", width: stepWidth }, index));
        }) })));
};
