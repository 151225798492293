/**
 * Theme package
 * ---
 * @author Siddhant Gupta
 * @version 1.7.0
 */
import { theme, globalBarWidth } from "./theme";
/** @external Export Framer Motion components */
export * from "framer-motion";
/** @external Export Emotion components */
export * from "./emotion";
export * from "./helpers";
export * from "./types";
export * from "./components";
/**Exporting Box explicitly because framer-motion has also box component exported */
export { Box } from "./components";
export { setTheme } from "./setTheme";
var color = theme.color, spacing = theme.spacing, transition = theme.transition, shadow = theme.shadow, themeLight = theme.themeLight, themeDark = theme.themeDark;
export { theme, color, spacing, transition, shadow, themeLight, themeDark, globalBarWidth, };
