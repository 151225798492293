var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { NOOP } from "@faharmony/helpers";
import { StyledToggle } from "./style";
/**
 * Toggle
 * ---
 * Interactive toggle component
 * @jira https://fasolutions.jira.com/browse/HAR-77
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @copyright FA Solutions Oy
 * @version 1.6.0
 * @example <Toggle checked={true} onToggle={e=>console.log(e.target.checked)} />
 */
var Toggle = React.forwardRef(function (props, ref) {
    var className = props.className, name = props.name, defaultChecked = props.defaultChecked, checked = props.checked, _a = props.disabled, disabled = _a === void 0 ? false : _a, _b = props.value, value = _b === void 0 ? "" : _b, _c = props.onToggle, onToggle = _c === void 0 ? NOOP : _c, _d = props.onRight, onRight = _d === void 0 ? NOOP : _d, _e = props.onLeft, onLeft = _e === void 0 ? NOOP : _e, id = props.id, registerRef = props.registerRef;
    var checkedProp = React.useMemo(function () { return (checked !== undefined ? { checked: checked } : { defaultChecked: defaultChecked }); }, [checked, defaultChecked]);
    var cls = ["Toggle", className || ""].join(" ");
    var onChangeHandler = function (e) {
        var _a;
        !!onToggle && onToggle(e);
        (_a = registerRef === null || registerRef === void 0 ? void 0 : registerRef.onChange) === null || _a === void 0 ? void 0 : _a.call(registerRef, e);
        e.target.checked ? onRight(e) : onLeft(e);
    };
    return (_jsxs(StyledToggle, __assign({ className: cls, id: id }, { children: [_jsx("input", __assign({ ref: ref }, registerRef, { onChange: onChangeHandler, type: "checkbox", id: name, name: name, value: value, disabled: disabled }, checkedProp)), _jsx("label", { htmlFor: name })] })));
});
export { Toggle };
