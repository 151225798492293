var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { theme, styled, Box } from "@faharmony/theme";
import { getIconDefinition, faLayerGroup, faEllipsisV } from "@faharmony/icons";
import { Text, ButtonPopover } from "@faharmony/components";
import FALogo from "./FALogo";
import { isAppActive } from "./common";
import { openExternalLink } from "@faharmony/router";
var barHeight = "48px";
var StyledBar = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  z-index: ", ";\n  margin: 0;\n  box-shadow: ", ";\n  width: 100%;\n  max-width: 100vw;\n  height: ", ";\n  background-color: ", ";\n  display: grid;\n  gap: ", ";\n  grid-template-columns: 48px 1fr max-content;\n  align-items: center;\n\n  /* #FA-Bar-logo {\n    transform: translateY(-4px);\n  } */\n\n  #FA-Bar-actions {\n    padding: 0 ", ";\n  }\n"], ["\n  position: relative;\n  z-index: ", ";\n  margin: 0;\n  box-shadow: ", ";\n  width: 100%;\n  max-width: 100vw;\n  height: ", ";\n  background-color: ", ";\n  display: grid;\n  gap: ", ";\n  grid-template-columns: 48px 1fr max-content;\n  align-items: center;\n\n  /* #FA-Bar-logo {\n    transform: translateY(-4px);\n  } */\n\n  #FA-Bar-actions {\n    padding: 0 ", ";\n  }\n"])), theme.zIndex.docked, theme.shadow.large, barHeight, theme.color.background.default, theme.spacing.sm, theme.spacing.md);
var HorizontalBar = function (_a) {
    var _b;
    var actions = _a.actions, apps = _a.apps;
    var currentAppName = ((_b = apps.filter(isAppActive)[0]) === null || _b === void 0 ? void 0 : _b.appName.split("FA ")[1]) || "Platform";
    return (_jsxs(StyledBar, { children: [_jsx(FALogo, { horizontal: true }), _jsx("div", __assign({ style: { overflow: "hidden" } }, { children: _jsx(Text, { value: currentAppName, truncate: true, variant: "h1" }) })), _jsxs(Box, __assign({ id: "FA-Bar-actions", direction: "row", justifyContent: "flex-end", alignItems: "center" }, { children: [_jsx(ButtonPopover, { id: "faGlobalBarAppSwitcher", icon: faLayerGroup, tooltip: "App switcher", variant: "secondary", popoverPlacement: "bottom", popoverMenuItems: apps.map(function (app) {
                            var appName = app.appName, url = app.url, iconPath = app.iconPath, openInNewWindow = app.openInNewWindow, id = app.id;
                            var isActive = isAppActive(app);
                            return {
                                id: id,
                                label: appName,
                                icon: getIconDefinition(iconPath),
                                onClick: function () {
                                    return window.open(url, openInNewWindow ? "_blank" : "_self");
                                },
                                color: isActive ? "accent" : undefined,
                            };
                        }) }), _jsx(ButtonPopover, { id: "faGlobalBarActions", icon: faEllipsisV, tooltip: "Global actions", variant: "secondary", popoverPlacement: "bottom", popoverMenuItems: actions.map(function (action) {
                            var label = action.label, url = action.url, iconPath = action.iconPath, openInNewWindow = action.openInNewWindow, id = action.id, onClick = action.onClick;
                            return {
                                id: id,
                                label: label,
                                icon: getIconDefinition(iconPath),
                                onClick: onClick ||
                                    (function () {
                                        return openExternalLink(url || "", openInNewWindow === false ? "_self" : "_blank");
                                    }),
                            };
                        }) })] }))] }));
};
export default HorizontalBar;
var templateObject_1;
