var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var FAHarmonySVGPath = "M256,512 C397.388105,512 512,397.388105 512,256 C512,114.611895 397.388105,0 256,0 C114.611895,0 0,114.611895 0,256 C0,397.388105 114.611895,512 256,512 Z M254.359565,454.640148 L256.341333,454.656 C147.002271,454.656 58.368,365.94532 58.368,256.512 C58.368,147.07868 147.002271,58.368 256.341333,58.368 C311.010361,58.368 355.328,102.723844 355.328,157.44 C355.328,211.561415 311.968552,255.546518 258.120306,256.496322 L256.341333,256.512 C201.672306,256.512 157.354667,300.867844 157.354667,355.584 C157.354667,409.110675 199.766396,452.722589 252.790987,454.593463 L254.359565,454.640148 Z M255.488,190.464 C273.864719,190.464 288.768,175.560719 288.768,157.184 C288.768,138.807281 273.864719,123.904 255.488,123.904 C237.111281,123.904 222.208,138.807281 222.208,157.184 C222.208,175.560719 237.111281,190.464 255.488,190.464 Z M255.488,388.096 C237.111281,388.096 222.208,373.192719 222.208,354.816 C222.208,336.439281 237.111281,321.536 255.488,321.536 C273.864719,321.536 288.768,336.439281 288.768,354.816 C288.768,373.192719 273.864719,388.096 255.488,388.096 Z";
export var FAHarmonyOpaqueSVGPath = [
    "M 256 0 A 256 256 0 1 0 256 512 A 256 256 0 1 0 256 0 Z",
    "M256,512 C397.388105,512 512,397.388105 512,256 C512,114.611895 397.388105,0 256,0 C114.611895,0 0,114.611895 0,256 C0,397.388105 114.611895,512 256,512 Z M254.359565,454.640148 L256.341333,454.656 C147.002271,454.656 58.368,365.94532 58.368,256.512 C58.368,147.07868 147.002271,58.368 256.341333,58.368 C311.010361,58.368 355.328,102.723844 355.328,157.44 C355.328,211.561415 311.968552,255.546518 258.120306,256.496322 L256.341333,256.512 C201.672306,256.512 157.354667,300.867844 157.354667,355.584 C157.354667,409.110675 199.766396,452.722589 252.790987,454.593463 L254.359565,454.640148 Z M255.488,190.464 C273.864719,190.464 288.768,175.560719 288.768,157.184 C288.768,138.807281 273.864719,123.904 255.488,123.904 C237.111281,123.904 222.208,138.807281 222.208,157.184 C222.208,175.560719 237.111281,190.464 255.488,190.464 Z M255.488,388.096 C237.111281,388.096 222.208,373.192719 222.208,354.816 C222.208,336.439281 237.111281,321.536 255.488,321.536 C273.864719,321.536 288.768,336.439281 288.768,354.816 C288.768,373.192719 273.864719,388.096 255.488,388.096 Z",
];
export var FAHarmony = function () { return (_jsx("svg", __assign({ viewBox: "512, 512" }, { children: _jsx("path", { d: FAHarmonySVGPath }) }))); };
export var FAHarmonyOpaque = function () { return (_jsxs("svg", __assign({ viewBox: "512, 512" }, { children: [_jsx("path", { d: FAHarmonyOpaqueSVGPath[0], fill: "#FFFFFF" }), _jsx("path", { d: FAHarmonyOpaqueSVGPath[0], fill: "#32127A" })] }))); };
export var FAHarmonyIconDefinition = {
    prefix: "fas",
    iconName: "yin-yang",
    icon: [512, 512, [], "f6ad", FAHarmonySVGPath],
};
export var FAHarmonyOpaqueIconDefinition = {
    prefix: "fas",
    iconName: "yin-yang",
    icon: [512, 512, [], "f6ad", FAHarmonyOpaqueSVGPath],
};
export default FAHarmonyIconDefinition;
