var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Box, styled, theme } from "@faharmony/theme";
import { FALogoIconDefinition, FontAwesomeIcon, } from "@faharmony/icons";
var StyledIcon = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: inline-block;\n  width: 20px;\n  height: 20px;\n  font-size: ", ";\n\n  svg {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n"], ["\n  position: relative;\n  display: inline-block;\n  width: 20px;\n  height: 20px;\n  font-size: ", ";\n\n  svg {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n"])), theme.size[500]);
/**
 * Icon
 * ---
 * Render an icon from FontAwesome library. By default, renders FA logo icon.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.6.0
 * @example
 * <Icon icon="faFolders" />
 */
var Icon = React.forwardRef(function (props, ref) {
    var fontAwesomeIconProps = __assign(__assign({}, props), { icon: props.icon || FALogoIconDefinition, role: "icon", fixedWidth: props.size ? false : true });
    var fontAwesomeIcon = (_jsx(FontAwesomeIcon, __assign({}, fontAwesomeIconProps, { className: "iconSVG" })));
    return props.size ? (_jsx(Box, __assign({ ref: ref, width: "min-content" }, { children: fontAwesomeIcon }))) : (_jsx(StyledIcon, __assign({ ref: ref, className: "Icon " + (props.className || "") }, { children: fontAwesomeIcon })));
});
export { Icon };
var templateObject_1;
