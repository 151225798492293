var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState, useCallback, useRef } from "react";
var defaultOptions = {
    cancelOnUnmount: true,
};
/**
 * An async-utility hook that accepts a callback function and a delay time (in milliseconds), then repeats the
 * execution of the given function by the defined milliseconds. It persists over renders.
 * @author Antonio Rù <> (https://github.com/antonioru)
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @example
 *   const [seconds, setSeconds] = useState(0);
 *   useInterval(() => setSeconds(1 + seconds), 1000);
 */
export var useInterval = function (fn, milliseconds, options) {
    if (options === void 0) { options = defaultOptions; }
    var opts = __assign(__assign({}, defaultOptions), (options || {}));
    var timeout = useRef();
    var callback = useRef(fn);
    var _a = __read(useState(false), 2), isCleared = _a[0], setIsCleared = _a[1];
    // the clear method
    var clear = useCallback(function () {
        if (timeout.current) {
            clearInterval(timeout.current);
            setIsCleared(true);
        }
    }, []);
    // if the provided function changes, change its reference
    useEffect(function () {
        if (typeof fn === "function") {
            callback.current = fn;
        }
    }, [fn]);
    // when the milliseconds change, reset the timeout
    useEffect(function () {
        if (typeof milliseconds === "number") {
            timeout.current = setInterval(function () {
                callback.current();
            }, milliseconds);
        }
    }, [milliseconds]);
    // when component unmount clear the timeout
    useEffect(function () { return function () {
        if (opts.cancelOnUnmount) {
            clear();
        }
    }; }, []);
    return [isCleared, clear];
};
