var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, styled, theme } from "@faharmony/theme";
/**
 * ProgressLoader
 * ---
 * Circular progress loader to show progress in percentage.
 * @author Amrit Regmi <amrit.regmi@fasolutions.com>
 * @example
 * <ProgressLoader progress={1} } />
 */
export var ProgressLoader = function (props) {
    var _a = props.size, size = _a === void 0 ? 21 : _a, _b = props.progress, progress = _b === void 0 ? 1 : _b, _c = props.trackWidth, trackWidth = _c === void 0 ? 3 : _c, _d = props.trackColor, trackColor = _d === void 0 ? theme.color.background.disabled : _d, _e = props.indicatorWidth, indicatorWidth = _e === void 0 ? 3 : _e, _f = props.indicatorColor, indicatorColor = _f === void 0 ? theme.color.border.positive : _f, _g = props.labelColor, labelColor = _g === void 0 ? theme.color.text.primary : _g, _h = props.hideInnerLabel, hideInnerLabel = _h === void 0 ? size < 50 ? true : false : _h;
    var spinnerSpeed = 0.5;
    var center = size / 2, radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth), dashArray = 2 * Math.PI * radius, dashOffset = dashArray * ((100 - progress) / 100);
    return (_jsx(Box, __assign({ style: { width: "min-content" } }, { children: _jsxs(StyledDiv, __assign({ className: "svg-pi-wrapper", style: { width: "min-content", height: "min-content" } }, { children: [_jsxs("svg", __assign({ className: "svg-pi", style: { width: size, height: size } }, { children: [_jsx("circle", { className: "svg-pi-track", cx: center, cy: center, fill: "transparent", r: radius, stroke: trackColor, strokeWidth: trackWidth }), _jsx("circle", { className: "svg-pi-indicator", style: { animationDuration: "".concat(spinnerSpeed * 1000) }, cx: center, cy: center, fill: "transparent", r: radius, stroke: indicatorColor, strokeWidth: indicatorWidth, strokeDasharray: dashArray, strokeDashoffset: dashOffset, strokeLinecap: "butt" })] })), !hideInnerLabel && (_jsx("div", __assign({ className: "svg-pi-label", style: { color: labelColor } }, { children: _jsx("span", __assign({ className: "svg-pi-label__progress", style: { fontSize: (size - trackWidth) / 3.5 } }, { children: "".concat(progress > 100 ? 100 : progress, "%") })) })))] })) })));
};
var StyledDiv = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .svg-pi-wrapper {\n    position: relative;\n  }\n\n  .svg-pi {\n    transform: rotate(-90deg); /* Fix the orientation */\n  }\n\n  .svg-pi-label {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    text-align: center;\n  }\n\n  .svg-pi-label__progress {\n    font-size: 1.5em;\n    font-weight: bold;\n  }\n\n  .svg-pi-label__progress {\n    display: block;\n  }\n"], ["\n  .svg-pi-wrapper {\n    position: relative;\n  }\n\n  .svg-pi {\n    transform: rotate(-90deg); /* Fix the orientation */\n  }\n\n  .svg-pi-label {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    text-align: center;\n  }\n\n  .svg-pi-label__progress {\n    font-size: 1.5em;\n    font-weight: bold;\n  }\n\n  .svg-pi-label__progress {\n    display: block;\n  }\n"])));
var templateObject_1;
