var shadowLightDefault = "0 2px 4px 0 rgba(0,0,0,0.12), inset 0 0 2px 0 rgba(0,0,0,0.12)";
var shadowDarkDefault = "0 2px 4px 0 rgba(0,0,0,0.24), inset 0 0 2px 0 rgba(255,255,255,0.16)";
/** Light variant of shadows */
var shadowLight = {
    default: shadowLightDefault,
    hover: "0 2px 8px 0 rgba(0,0,0,0.20), inset 0 0 2px 0 rgba(0,0,0,0.12)",
    pressed: "0 0 2px 0 rgba(0,0,0,0.16)",
    float: "0 0 16px 2px rgba(0,0,0,0.24), inset 0 0 4px 0 rgba(0,0,0,0.16)",
    small: "0 1px 2px 0 rgba(0,0,0,0.12), inset 0 0 1px 0 rgba(0,0,0,0.08)",
    medium: shadowLightDefault,
    large: "0 4px 12px 0 rgba(0,0,0,0.12), inset 0 0 4px 0 rgba(0,0,0,0.12)",
    inner: "inset 0 0 2px 1px rgba(0,0,0,0.12)",
    focus: "0 0 2px 2px rgba(0,0,0,0.25)",
};
/** Dark variant of shadows */
var shadowDark = {
    default: shadowDarkDefault,
    hover: "0 2px 8px 0 rgba(0,0,0,0.40), inset 0 0 2px 0 rgba(0,0,0,0.12)",
    pressed: "0 0 2px 0 rgba(0,0,0,0.32), inset 0 0 1px 0 rgba(255,255,255,0.16)",
    float: "0 0 16px 2px rgba(0,0,0,0.48), inset 0 0 4px 0 rgba(255,255,255,0.16)",
    small: "0 1px 2px 0 rgba(0,0,0,0.12), inset 0 0 1px 0 rgba(255,255,255,0.16)",
    medium: shadowDarkDefault,
    large: "0 4px 12px 0 rgba(0,0,0,0.24), inset 0 0 2px 0 rgba(255,255,255,0.12)",
    inner: "inset 0 0 4px 0px rgba(255,255,255,0.16)",
    focus: "0 0 2px 2px rgba(255,255,255,0.25)",
};
export { shadowDark, shadowLight };
