var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect, useState } from "react";
import styled from "@emotion/styled";
import memoizeOne from "memoize-one";
import { Droppable } from "react-beautiful-dnd";
import { theme } from "@faharmony/theme";
import Item from "./item";
import { FormControl } from "../FormControl";
import { TextField } from "../TextField";
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 300px;\n  margin: ", ";\n  margin-left: 24px;\n  margin-right: 24px;\n  border-radius: ", "px;\n  background-color: ", ";\n\n  display: flex;\n  flex-direction: column;\n"], ["\n  width: 300px;\n  margin: ", ";\n  margin-left: 24px;\n  margin-right: 24px;\n  border-radius: ", "px;\n  background-color: ", ";\n\n  display: flex;\n  flex-direction: column;\n"])), theme.spacing.md, theme.radius[100], theme.color.background.default);
var ItemList = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  min-height: 80px;\n  flex-grow: 1;\n  transition: background-color 0.2s ease;\n  overflow: auto;\n  ", ";\n"], ["\n  min-height: 80px;\n  flex-grow: 1;\n  transition: background-color 0.2s ease;\n  overflow: auto;\n  ", ";\n"])), function (props) {
    return props.isDraggingOver
        ? "background-color: ".concat(theme.color.background.secondary)
        : "";
});
var getSelectedMap = memoizeOne(function (selectedItemsIds) {
    return selectedItemsIds.reduce(function (previous, current) {
        previous[current] = true;
        return previous;
    }, {});
});
export default (function (_a) {
    var list = _a.list, items = _a.items, draggingItemId = _a.draggingItemId, multiSelectTo = _a.multiSelectTo, selectedItemsIds = _a.selectedItemsIds, toggleSelection = _a.toggleSelection, toggleSelectionInGroup = _a.toggleSelectionInGroup, switchList = _a.switchList;
    var _b = __read(useState([]), 2), filteredItems = _b[0], setFilteredItems = _b[1];
    var _c = __read(useState(""), 2), filterInput = _c[0], setFilterInput = _c[1];
    useLayoutEffect(function () {
        if (filterInput === "")
            setFilteredItems(items);
        else
            setFilteredItems(items.filter(function (_a) {
                var content = _a.content;
                return content.toLowerCase().includes(filterInput.toLowerCase());
            }));
    }, [filterInput, items]);
    return (_jsxs(Container, { children: [_jsx(FormControl, __assign({ label: list.title }, { children: _jsx(TextField, { onChange: function (e) {
                        setFilterInput(e.target.value);
                    }, placeholder: "Filter" }) })), _jsx(Droppable, __assign({ droppableId: list.id }, { children: function (provided, snapshot) { return (_jsxs(ItemList, __assign({ ref: provided.innerRef, isDraggingOver: snapshot.isDraggingOver }, provided.droppableProps, { children: [items.map(function (item, index) {
                            var isSelected = Boolean(getSelectedMap(selectedItemsIds)[item.id]);
                            var isGhosting = isSelected &&
                                Boolean(draggingItemId) &&
                                draggingItemId !== item.id;
                            return (_jsx(Item, { item: item, index: index, keyProp: item.id, isSelected: isSelected, isGhosting: isGhosting, selectionCount: selectedItemsIds.length, toggleSelection: toggleSelection, toggleSelectionInGroup: toggleSelectionInGroup, multiSelectTo: function (id) { return multiSelectTo(id, filterInput); }, overrideProps: filteredItems.find(function (i) { return i.id === item.id; })
                                    ? {
                                        onDoubleClick: function () { return switchList(list.id, index); },
                                    }
                                    : {
                                        style: {
                                            height: 0,
                                            padding: 0,
                                            margin: 0,
                                            overflow: "hidden",
                                            transition: "0.1s ease-in-out",
                                        },
                                    } }));
                        }), provided.placeholder] }))); } }))] }, list.id));
});
var templateObject_1, templateObject_2;
