var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect } from "react";
/**
 * This hook makes it super easy to utilize media queries in your component logic.
 * Both queries and values array should be equal in length.
 * @author Gabe Ragland <> (https://github.com/gragland)
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @example
 *  const elementWidth = useMediaQuery(
    // Media queries
    ['(min-width: 1500px)', '(min-width: 1000px)', '(min-width: 600px)'],
    // Element width (relates to above media queries by array index)
    [600, 400, 300],
    // Default element width
    200
  );
 */
export function useMediaQuery(queries, values, defaultValue) {
    if (queries.length !== values.length)
        return defaultValue;
    // Array containing a media query list for each query
    var mediaQueryLists = queries.map(function (q) { return window.matchMedia(q); });
    // Function that gets value based on matching media query
    var getValue = function () {
        // Get index of first media query that matches
        var index = mediaQueryLists.findIndex(function (mql) { return mql.matches; });
        // Return related value or defaultValue if none
        return typeof values[index] !== "undefined" ? values[index] : defaultValue;
    };
    // State and setter for matched value
    var _a = __read(useState(getValue), 2), value = _a[0], setValue = _a[1];
    useEffect(function () {
        // Event listener callback
        // Note: By defining getValue outside of useEffect we ensure that it has ...
        // ... current values of hook args (as this hook callback is created once on mount).
        var handler = function () { return setValue(getValue); };
        // Set a listener for each media query with above handler as callback.
        mediaQueryLists.forEach(function (mql) { return mql.addListener(handler); });
        // Remove listeners on cleanup
        return function () {
            return mediaQueryLists.forEach(function (mql) { return mql.removeListener(handler); });
        };
    }, [] // Empty array ensures effect is only run on mount and un-mount
    );
    return value;
}
