var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Help, Icon, Text } from "@faharmony/components";
import { Box } from "@faharmony/theme";
import { faCircle } from "@faharmony/icons";
import { faDotCircle } from "@faharmony/icons/solid";
import { StyledInputLabel } from "../common";
/**
 * RadioGroup
 * ---
 * Group of radio components
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @copyright FA Solutions Oy
 * @jira https://fasolutions.jira.com/browse/HAR-48
 * @version 1.6.0
 * @example <RadioGroup name="radio" options={[{label: "Option1", value: "o1"}]} />
 */
var RadioGroup = React.forwardRef(function (_a, ref) {
    var _b = _a.options, options = _b === void 0 ? [] : _b, _c = _a.direction, direction = _c === void 0 ? "row" : _c, allDisabled = _a.disabled, defaultValue = _a.defaultValue, registerRef = _a.registerRef, inputProps = __rest(_a, ["options", "direction", "disabled", "defaultValue", "registerRef"]);
    return (_jsx(Box, __assign({ id: "RadioGroup-" + inputProps.name, direction: direction, alignItems: "start", tabIndex: 0 }, { children: options.map(function (o) { return (_jsxs(StyledInputLabel, __assign({ className: "Radio", disabled: allDisabled || o.isDisabled }, { children: [_jsx("input", __assign({ type: "radio", ref: ref }, registerRef, { value: o.value, disabled: allDisabled || o.isDisabled, defaultChecked: defaultValue === o.value }, inputProps)), _jsxs(React.Fragment, { children: [_jsx(Icon, { icon: faDotCircle, className: "checked" }), _jsx(Icon, { icon: faCircle, className: "unchecked" })] }), o.label && _jsx(Text, { children: o.label }), o.help && _jsx(Help, { text: o.help })] }), o.value)); }) })));
});
export { RadioGroup };
