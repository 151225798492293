var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box } from "@faharmony/theme";
import { Help } from "../Help";
import { Text } from "../Text";
/**
 * InfoBox
 * ---
 * Show information in simple manner
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.7.0
 * @example
 * <InfoBox field="Name" value="Siddhant Gupta" />
 * --
 * <InfoBox field="Name"><Tag value="Unavailable"/></InfoBox>
 */
var InfoBox = React.forwardRef(function (_a, ref) {
    var field = _a.field, helpText = _a.helpText, value = _a.value, children = _a.children, color = _a.color, _b = _a.horizontal, horizontal = _b === void 0 ? false : _b;
    if (children)
        horizontal = false;
    if (horizontal)
        return (_jsxs("div", __assign({ style: { width: "100%" }, ref: ref }, { children: [_jsx(Text, { variant: "h6", value: field, color: "primary" }), _jsx("span", { children: " : " }), _jsx("span", __assign({ style: { wordBreak: "break-all" } }, { children: _jsx(Text, { value: value || "-", color: color }) }))] })));
    else
        return (_jsxs(Box, __assign({ ref: ref, spacing: "sm", direction: "column", justifyContent: "flex-start", alignContent: "center" }, { children: [_jsxs(Box, __assign({ justifyContent: "flex-start", spacing: "sm" }, { children: [_jsx("div", { children: _jsx(Text, { variant: "h6", value: field, color: "primary" }) }), helpText && _jsx(Help, { text: helpText })] })), children || (_jsx("span", __assign({ style: { wordBreak: "break-all" } }, { children: _jsx(Text, { value: value || "-", color: color }) })))] })));
});
export { InfoBox };
