import BaseButton from "./Button";
import { Close } from "./purpose";
/**
 * Button
 * ---
 * A clickable button with multiple states, sizes and style variants.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.7.0
 * @example
 * <Button value="button label" icon={faBell} />
 */
var Button = BaseButton;
Button.Close = Close;
export { Button };
