var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { styled, motion, useSpring, theme } from "@faharmony/theme";
import Tippy from "@tippyjs/react/headless";
import { Text } from "../Text";
var color = theme.color, spacing = theme.spacing, radius = theme.radius, shadow = theme.shadow, spring = theme.transition.spring;
var StyledTooltip = styled(motion.div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  box-shadow: ", ";\n  border-radius: ", ";\n  background: ", ";\n  padding: ", " ", ";\n  max-width: 300px;\n  overflow-wrap: break-word; ;\n"], ["\n  border: 1px solid ", ";\n  box-shadow: ", ";\n  border-radius: ", ";\n  background: ", ";\n  padding: ", " ", ";\n  max-width: 300px;\n  overflow-wrap: break-word; ;\n"])), color.border.default, shadow.float, radius[100], color.background.primary, spacing.sm, spacing.md);
/**
 * Tooltip
 * ---
 * Wrap an element with it to add tooltip.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.5.0
 * @example
 * <Tooltip value="Tooltip text"> {element} </Tooltip>
 */
var Tooltip = React.forwardRef(function (_a, ref) {
    var children = _a.children, _b = _a.value, value = _b === void 0 ? "Tooltip" : _b, _c = _a.placement, placement = _c === void 0 ? "auto" : _c, interactive = _a.interactive;
    var initialScale = 0.75;
    var opacity = useSpring(0, spring);
    var scale = useSpring(initialScale, spring);
    function onMount() {
        scale.set(1);
        opacity.set(1);
    }
    function onHide(_a) {
        var unmount = _a.unmount;
        var cleanup = scale.onChange(function (value) {
            if (value <= initialScale) {
                cleanup();
                unmount();
            }
        });
        scale.set(initialScale);
        opacity.set(0);
    }
    var options = useMemo(function () { return ({
        arrow: true,
        render: function (attrs) { return (_jsx(StyledTooltip, __assign({ ref: ref, className: "tooltip", tabIndex: -1, role: "tooltip", style: { scale: scale, opacity: opacity } }, attrs, { children: typeof value === "string" ? (_jsx(Text, { value: value, variant: "small", color: "primary" })) : (value) }))); },
        interactive: interactive,
        offset: [0, 4],
        placement: placement,
        touch: false,
        onMount: onMount,
        onHide: onHide,
        appendTo: document.body,
        zIndex: theme.zIndex.tooltip,
    }); }, [value]);
    return _jsx(Tippy, __assign({}, options, { children: children }));
});
export { Tooltip };
var templateObject_1;
