var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faChevronDown, faChevronRight } from "@faharmony/icons";
import { theme, Grid, Box } from "@faharmony/theme";
// import { motion, AnimatePresence,useDisableAnimation} from "@faharmony/theme";
import { Text } from "../Text";
import { Icon } from "../Icon";
var PanelHeader = function (props) {
    var isExpanded = props.isExpanded, disableHeaderColor = props.disableHeaderColor, headerUppercase = props.headerUppercase, headerIconPosition = props.headerIconPosition, handleExpand = props.handleExpand, handleCollapse = props.handleCollapse, heading = props.heading, id = props.id, action = props.action, disabled = props.disabled;
    return (_jsx("header", __assign({ style: {
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            minHeight: "calc(3.5 * ".concat(theme.spacing.md, ")"),
            height: "auto",
            padding: "".concat(theme.spacing.sm, " ").concat(theme.spacing.md),
            borderRadius: theme.radius[300],
            cursor: disabled ? "initial" : "pointer",
            background: disableHeaderColor ? "none" : theme.color.background.rowAlt,
            // background: isExpanded
            //   ? Theme.colorBackground("rowAlt")
            //   : "transparent",
        }, onClick: disabled
            ? undefined
            : function () { return (isExpanded ? handleCollapse(id) : handleExpand(id)); } }, { children: headerIconPosition === "left"
            ? (_jsxs(Box, __assign({ direction: "row" }, { children: [_jsx(Box, __assign({ width: "fit-content" }, { children: _jsx(Text, { value: heading, variant: headerUppercase === false ? "h5" : "strong" }) })), _jsxs(Box, __assign({ width: "fit-content" }, { children: [action && _jsx("div", { children: action }), !disabled && (_jsx(Icon, { icon: isExpanded ? faChevronDown : faChevronRight }))] }))] }))) : (_jsxs(Grid, __assign({ gridTemplate: " auto / auto max-content" }, { children: [_jsx(Box, __assign({ justifyContent: "flex-start" }, { children: _jsx(Text, { value: heading, variant: headerUppercase === false ? "h5" : "strong" }) })), (action || !disabled) && (_jsxs(Box, __assign({ justifyContent: "flex-end", spacing: "md" }, { children: [action && _jsx("div", { children: action }), !disabled && (_jsx(Icon, { icon: isExpanded ? faChevronDown : faChevronRight }))] })))] }))) })));
};
// const AnimatedWrapper = ({
//   isExpanded,
//   fullHeight,
//   fullWidth,
//   content,
// }: IAccordionPanelProps &
//   IAccordionOptions & {
//     fullHeight?: IAccordionProps["fullHeight"];
//     isExpanded: boolean;
//   }) => {
//   return (
//     <AnimatePresence initial={false}>
//       {isExpanded && (
//         <motion.section
//           className="accordion-panel-content"
//           key="content"
//           style={{ width: "100%" }}
//           initial="collapsed"
//           animate="expanded"
//           exit="collapsed"
//           variants={{
//             expanded: {
//               opacity: 1,
//               height: fullHeight ? "100%" : "auto",
//             },
//             collapsed: {
//               opacity: 0,
//               height: 0,
//             },
//           }}
//           transition={Theme.transition()}
//         >
//           <div style={{ padding: fullWidth ? 0 : Theme.spacing.md }}>
//             {content}
//           </div>
//         </motion.section>
//       )}
//     </AnimatePresence>
//   );
// };
export var Panel = function (props) {
    var expandedIds = props.expandedIds, id = props.id, content = props.content, fullWidth = props.fullWidth, _a = props.fullHeight, fullHeight = _a === void 0 ? false : _a;
    var isExpanded = expandedIds.indexOf(id) > -1;
    // const disableAnimation = useDisableAnimation();
    return (_jsxs(Box, __assign({ id: "accordion-" + id, height: isExpanded && fullHeight ? "100%" : "auto", direction: "column", alignItems: "flex-start", className: "accordion-panel", spacing: "none" }, { children: [_jsx(PanelHeader, __assign({}, props, { isExpanded: isExpanded })), isExpanded ? (_jsx("div", { style: { width: "100%", padding: fullWidth ? 0 : theme.spacing.md }, children: content })) : null] })));
};
