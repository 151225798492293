var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { theme, Grid } from "@faharmony/theme";
import { Text, Icon } from "@faharmony/components";
import { useCheckUserForRoles, combineModuleRoles } from "./helpers";
import { useEffect } from "react";
export default function LandingPage(_a) {
    var appConfig = _a.appConfig, Modules = _a.Modules;
    var isAnythingAllowed = useCheckUserForRoles(combineModuleRoles(Modules));
    var description = !isAnythingAllowed ? (_jsx(Text, { value: "You do not have enough permissions to use this application.", color: "disabled" })) : undefined;
    /**Redirect to default module if defined and allowed */
    useEffect(function () {
        if (appConfig.defaultModule &&
            Modules.some(function (mod) { return mod.id === appConfig.defaultModule; }) &&
            isAnythingAllowed) {
            var location_1 = window.location;
            var _a = __assign({}, location_1), protocol = _a.protocol, host = _a.host, pathname = _a.pathname, search = _a.search;
            var redirect = protocol +
                "//" +
                host +
                pathname +
                "#/" +
                appConfig.defaultModule +
                search;
            window.location.replace(redirect);
        }
    });
    return (_jsx(Grid, __assign({ style: {
            position: "relative",
            background: isAnythingAllowed
                ? theme.color.background.default
                : theme.color.background.secondary,
        } }, { children: _jsx(Grid, __assign({ width: "auto", height: "auto", spacing: "xl", gridTemplate: "max-content max-content / auto", style: {
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translateX(-50%) translateY(-50%)",
                color: theme.color.text.disabled,
                maxWidth: "800px",
                minWidth: "300px",
                justifyItems: "center",
            } }, { children: _jsxs(Grid, __assign({ width: "auto", height: "auto", gridTemplate: "auto / max-content auto", spacing: "xl", style: { maxWidth: "100%", textAlign: "left" } }, { children: [_jsx(Icon, { icon: appConfig.icon, size: "5x" }), _jsxs("div", __assign({ style: { paddingTop: theme.spacing.lg } }, { children: [_jsx(Text, { value: "Welcome to", color: "disabled", variant: "h4" }), _jsx("br", {}), _jsx(Text, { value: appConfig.name, color: "disabled", variant: "h2" }), description ? (_jsxs("span", { children: [_jsx("br", {}), description] })) : null] }))] })) })) })));
}
