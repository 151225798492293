var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { theme } from "@faharmony/theme";
import { inputHeight, calcHeight } from "../common";
var color = theme.color, shadow = theme.shadow, zIndex = theme.zIndex;
/**
 * Theme default colors
danger: "#DE350B",
dangerLight: "#FFBDAD",
neutral0: "hsl(0, 0%, 100%)",
neutral10: "hsl(0, 0%, 90%)",
neutral20: "hsl(0, 0%, 80%)",
neutral30: "hsl(0, 0%, 70%)",
neutral40: "hsl(0, 0%, 60%)",
neutral5: "hsl(0, 0%, 95%)",
neutral50: "hsl(0, 0%, 50%)",
neutral60: "hsl(0, 0%, 40%)",
neutral70: "hsl(0, 0%, 30%)",
neutral80: "hsl(0, 0%, 20%)",
neutral90: "hsl(0, 0%, 10%)",
primary: "#2684FF",
primary25: "#DEEBFF",
primary50: "#B2D4FF",
primary75: "#4C9AFF"
 */
export var selectTheme = function (theme, isFilter, spacing) {
    if (spacing === void 0) { spacing = "md"; }
    return __assign(__assign({}, theme), { spacing: __assign(__assign({}, theme.spacing), { baseUnit: 2, controlHeight: isFilter ? 18 : calcHeight({ spacing: spacing }), menuGutter: 4 }), colors: __assign(__assign({}, theme.colors), { danger: color.text.negative, dangerLight: "transparent", neutral0: "hsl(0, 0%, 100%)", neutral10: "hsl(0, 0%, 90%)", neutral20: "hsl(0, 0%, 80%)", neutral30: "hsl(0, 0%, 70%)", neutral40: "hsl(0, 0%, 60%)", neutral5: "hsl(0, 0%, 95%)", neutral50: "hsl(0, 0%, 50%)", neutral60: "hsl(0, 0%, 40%)", neutral70: "hsl(0, 0%, 30%)", neutral80: "hsl(0, 0%, 20%)", neutral90: "hsl(0, 0%, 10%)", primary: color.text.primary, primary25: color.background.default, primary50: color.text.disabled, primary75: color.text.secondary }) });
};
/** b=baseStyle : CSSProperties
 * s=state : {
    clearValue: function clearValue()​
    cx: function classNames();
    getStyles: function getStyles(key, props)​
    getValue: function getValue();
    hasValue: true;
    innerProps: Object { onMouseDown: onDropdownIndicatorMouseDown(event), onTouchEnd: onDropdownIndicatorTouchEnd(event), "aria-hidden": "true" };
    isDisabled: false;
    isFocused: false;
    isMulti: false;
    isRtl: false;
    options: Array [ {…}, {…} ];
    selectOption: function selectOption(newValue)​;
    selectProps: Object { className: "Combobox", isClearable: true, isSearchable: true, … };
    setValue: function setValue(newValue, action, option)​;
    theme: Object { borderRadius: 4, colors: {…}, spacing: {…} };
 * }
 */
function getSelectedTextColor(s) {
    if (s.isSelected) {
        return "primary";
    }
    return "secondary";
}
export var selectStyles = {
    clearIndicator: function (b) { return (__assign({}, b)); },
    container: function (b) { return (__assign(__assign({}, b), { width: "100%" })); },
    control: function (b, s) { return (__assign(__assign({}, b), { backgroundColor: color.background[s.selectProps.readonly
            ? "default"
            : s.isDisabled
                ? "disabled"
                : s.selectProps.isSearchable
                    ? "input"
                    : "default"], width: "100%", boxShadow: s.isDisabled
            ? "none"
            : s.isFocused
                ? shadow.hover
                : s.selectProps.isSearchable
                    ? shadow.inner
                    : shadow.default, borderColor: color.border.default, transition: "border-color 0.5s", "&:hover": { borderColor: color.border.selected } })); },
    dropdownIndicator: function (b) { return (__assign({}, b)); },
    group: function (b) { return (__assign({}, b)); },
    groupHeading: function (b) { return (__assign({}, b)); },
    indicatorsContainer: function (b) { return (__assign(__assign({}, b), { padding: 0 })); },
    indicatorSeparator: function (b) { return (__assign(__assign({}, b), { backgroundColor: color.border.default })); },
    input: function (b) { return (__assign(__assign({}, b), { color: color.text.primary, fontStyle: "normal" })); },
    loadingIndicator: function (b) { return (__assign({}, b)); },
    loadingMessage: function (b) { return (__assign({}, b)); },
    menu: function (b) { return (__assign(__assign({}, b), { zIndex: zIndex.contextMenu, backgroundColor: color.background.primary, border: "1px solid ".concat(color.border.default), boxShadow: shadow.float })); },
    menuList: function (b) { return (__assign({}, b)); },
    menuPortal: function (b) { return (__assign(__assign({}, b), { zIndex: zIndex.contextMenu })); },
    // multiValue: (b, s) =>
    //   s.data?.isFixed ? { ...b, backgroundColor: colorBackground("inverse") } : b,
    multiValue: function (b) { return b; },
    multiValueLabel: function (b, s) { var _a; return ((_a = s.data) === null || _a === void 0 ? void 0 : _a.isFixed) ? __assign(__assign({}, b), { paddingRight: 6 }) : b; },
    multiValueRemove: function (b, s) {
        var _a;
        return ((_a = s.data) === null || _a === void 0 ? void 0 : _a.isFixed)
            ? __assign(__assign({}, b), { display: "none" }) : __assign(__assign({}, b), { color: theme.color.static.black });
    },
    noOptionsMessage: function (b) { return (__assign(__assign({}, b), { color: color.text.disabled })); },
    option: function (b, s) { return (__assign(__assign({}, b), { color: color.text[s.isDisabled ? "disabled" : getSelectedTextColor(s)], cursor: s.isDisabled ? "inherit" : "pointer", backgroundColor: s.isFocused
            ? color.background.disabled
            : s.isSelected
                ? color.background.selected
                : "transparent", "&:hover": {
            backgroundColor: s.isDisabled ? "none" : color.background.hover,
        } })); },
    placeholder: function (b) { return (__assign(__assign({}, b), { color: color.text.disabled })); },
    singleValue: function (b, s) { return (__assign(__assign({}, b), { color: color.text[s.isDisabled ? "disabled" : "primary"] })); },
    valueContainer: function (b, p) { return (__assign(__assign({}, b), { minHeight: inputHeight, height: p.isMulti ? "auto" : inputHeight, padding: p.isMulti ? "4px 4px" : "0px 4px" })); },
};
export var filterSelectStyles = __assign(__assign({}, selectStyles), { container: function (b) { return (__assign(__assign({}, b), { width: "100%", height: "28px" })); }, control: function (b) { return (__assign(__assign({}, b), { backgroundColor: color.background["default"], width: "100%", boxShadow: shadow.inner, height: "28px", border: "none", borderColor: color.border.default, borderRadius: "2px" })); }, option: function (b, s) { return (__assign(__assign({}, b), { color: color.text[s.isDisabled ? "disabled" : getSelectedTextColor(s)], cursor: s.isDisabled ? "inherit" : "pointer", "&:hover": { backgroundColor: s.isDisabled ? "none" : color.background.hover, } })); }, multiValue: function (b) { return (__assign(__assign({}, b), { position: "relative", bottom: "1px" })); }, placeholder: function (b) { return (__assign(__assign({}, b), { color: color.text.disabled, fontWeight: 400, top: "55%" })); }, valueContainer: function (b) { return (__assign(__assign({}, b), { padding: "0px 4px", backgroundColor: "none", height: "26px" })); } });
