var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { getAuthToken, } from "@faharmony/service";
import { datadogRum } from '@datadog/browser-rum';
function getEnvInfo() {
    return __awaiter(this, void 0, void 0, function () {
        var envInfoApiURL, baseURL, headers, response, envInfo, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    envInfoApiURL = "/env-info";
                    baseURL = location.href.includes("localhost")
                        ? "http://localhost:8010/proxy"
                        : "";
                    headers = new Headers({
                        Accept: "application/json",
                    });
                    return [4 /*yield*/, fetch(baseURL + envInfoApiURL, { headers: headers })];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error("Error fetching envInfo: ".concat(response.statusText));
                    }
                    return [4 /*yield*/, response.json()];
                case 2:
                    envInfo = _a.sent();
                    return [2 /*return*/, envInfo];
                case 3:
                    error_1 = _a.sent();
                    console.error("getEnvInfo:", error_1);
                    return [2 /*return*/, {}];
                case 4: return [2 /*return*/];
            }
        });
    });
}
var parseJwt = function (token) {
    try {
        var _a = __read(token.split("."), 3), _header = _a[0], base64Url = _a[1], _signature = _a[2];
        var base64 = base64Url === null || base64Url === void 0 ? void 0 : base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(atob(base64)
            .split("")
            .map(function (c) { return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2); })
            .join(""));
        return JSON.parse(jsonPayload);
    }
    catch (error) {
        console.error("Error parsing token payload:", error);
        return {};
    }
};
var isClaimMatchingRule = function (parsedJwt, claims) {
    var e_1, _a;
    var valueMatches = function (pattern, value) {
        if (pattern.startsWith('*')) {
            return value.endsWith(pattern.slice(1));
        }
        else if (pattern.endsWith('*')) {
            return value.startsWith(pattern.slice(0, -1));
        }
        else {
            return value === pattern;
        }
    };
    var _loop_1 = function (claim) {
        var e_2, _b;
        var currentObject = parsedJwt;
        var claimKeys = claim.key.split(/[.[\]]+/).filter(function (key) { return key; });
        try {
            for (var claimKeys_1 = (e_2 = void 0, __values(claimKeys)), claimKeys_1_1 = claimKeys_1.next(); !claimKeys_1_1.done; claimKeys_1_1 = claimKeys_1.next()) {
                var key = claimKeys_1_1.value;
                if (currentObject.hasOwnProperty(key)) {
                    currentObject = currentObject[key];
                }
                else {
                    break;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (claimKeys_1_1 && !claimKeys_1_1.done && (_b = claimKeys_1.return)) _b.call(claimKeys_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        if (Array.isArray(currentObject) &&
            currentObject.some(function (value) {
                return claim.value.some(function (pattern) { return valueMatches(pattern, value); });
            })) {
            return { value: true };
        }
    };
    try {
        for (var claims_1 = __values(claims), claims_1_1 = claims_1.next(); !claims_1_1.done; claims_1_1 = claims_1.next()) {
            var claim = claims_1_1.value;
            var state_1 = _loop_1(claim);
            if (typeof state_1 === "object")
                return state_1.value;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (claims_1_1 && !claims_1_1.done && (_a = claims_1.return)) _a.call(claims_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return false;
};
export function useDatadogRum(apps) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var envInfo, currentAppId, authToken, parsedJwt, serviceName;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, getEnvInfo()];
                case 1:
                    envInfo = _c.sent();
                    if (!("telemetry" in envInfo))
                        return [2 /*return*/];
                    currentAppId = (_b = (_a = apps.find(function (app) { return window.location.pathname.startsWith(app.url); })) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : "";
                    if (envInfo.telemetry === undefined ||
                        !envInfo.telemetry.appIds.includes(currentAppId)) {
                        return [2 /*return*/];
                    }
                    authToken = getAuthToken();
                    if (!authToken) {
                        return [2 /*return*/];
                    }
                    parsedJwt = parseJwt(authToken);
                    if (envInfo.telemetry.claims && !isClaimMatchingRule(parsedJwt, envInfo.telemetry.claims)) {
                        return [2 /*return*/];
                    }
                    serviceName = envInfo.telemetry.appIdToServiceName[currentAppId];
                    datadogRum.init({
                        clientToken: envInfo.telemetry.clientToken,
                        applicationId: envInfo.telemetry.applicationId,
                        site: envInfo.telemetry.site,
                        service: serviceName,
                        env: envInfo.name,
                        version: envInfo.version,
                        sessionSampleRate: envInfo.telemetry.sessionSampleRate,
                        sessionReplaySampleRate: envInfo.telemetry.sessionReplaySampleRate,
                        trackResources: envInfo.telemetry.trackResources,
                        trackLongTasks: envInfo.telemetry.trackLongTasks,
                        trackUserInteractions: envInfo.telemetry.trackUserInteractions,
                        defaultPrivacyLevel: envInfo.telemetry.defaultPrivacyLevel,
                        enableExperimentalFeatures: envInfo.telemetry.enableExperimentalFeatures,
                        trackFrustrations: envInfo.telemetry.trackFrustrations,
                    });
                    if (parsedJwt.hasOwnProperty("preferred_username")) {
                        datadogRum.setUser({
                            name: parsedJwt.preferred_username,
                        });
                    }
                    datadogRum.startSessionReplayRecording();
                    return [2 /*return*/];
            }
        });
    });
}
