var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
import { Box } from "@faharmony/theme";
import { Icon } from "../Icon";
import { Tooltip } from "../Tooltip";
import { RingLoader as Ellipsis } from "../Loaders";
import { StyledButton, StyledIconButton } from "./style";
import { ProgressLoader } from "../Loaders/ProgressLoader";
/**
 * Button
 * ---
 * A clickable button with multiple states, sizes and style variants.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @example
 * <Button value="Notifications" icon={faBell} />
 */
var Button = forwardRef(function (props, ref) {
    var id = props.id, className = props.className, value = props.value, children = props.children, tooltip = props.tooltip, onClick = props.onClick, onContextMenu = props.onContextMenu, _a = props.spacing, spacing = _a === void 0 ? "md" : _a, _b = props.disabled, disabled = _b === void 0 ? false : _b, _c = props.variant, variant = _c === void 0 ? "default" : _c, _d = props.outline, isOutline = _d === void 0 ? false : _d, _e = props.ghost, isGhost = _e === void 0 ? false : _e, _f = props.loading, isLoading = _f === void 0 ? false : _f, loadingText = props.loadingText, _g = props.type, type = _g === void 0 ? "button" : _g, onFocus = props.onFocus, onBlur = props.onBlur, icon = props.icon, progressPercentage = props.progressPercentage;
    var name = props.name || value || tooltip || "";
    if (isGhost && isOutline) {
        // WARNING: Button styled is conflicting.
        console.warn("Button (".concat(name, ") could be either Outline-styled or Ghost-styled, not both."));
        return null;
    }
    if (!value && !children && !icon) {
        // WARNING: Button is empty.
        console.warn("Button (".concat(name, ") could not be rendered since content (icon/value/children) is not provided."));
        return null;
    }
    // IconButton test
    var isIconButton = icon && !value && !children;
    var styleProps = {
        disabled: disabled,
        fullWidth: isIconButton ? false : props.fullWidth,
        isLoading: isLoading,
        spacing: spacing,
        isOutline: isOutline,
        variant: variant,
    };
    var buttonProps = {
        id: id,
        ref: ref,
        role: "button",
        type: type,
        name: name,
        className: "Button button " + className,
        onClick: disabled || isLoading ? undefined : onClick,
        onTouchEnd: disabled || isLoading
            ? undefined
            : function (e) {
                if (e && onClick) {
                    e.preventDefault();
                    onClick(e);
                }
            },
        onContextMenu: disabled || isLoading ? undefined : onContextMenu,
        onFocus: onFocus,
        onBlur: onBlur,
    };
    var loader;
    var hasProgressCircle = progressPercentage !== undefined && !isNaN(progressPercentage);
    var visibleText = isLoading && loadingText ? "".concat(loadingText, "...") : value;
    if (isLoading) {
        if (hasProgressCircle) {
            loader = _jsx(ProgressLoader, { progress: progressPercentage });
        }
        else {
            loader = _jsx(Ellipsis, {});
        }
    }
    var button = isIconButton ? (_jsx(StyledIconButton, __assign({}, buttonProps, styleProps, { children: loader ? loader : _jsx(Icon, { icon: icon }) }))) : (_jsx(StyledButton, __assign({}, buttonProps, styleProps, { children: children || (_jsxs(Box, __assign({ spacing: "sm", justifyContent: "center", alignItems: isLoading && hasProgressCircle ? "flex-start" : "center" }, { children: [loader ? loader : icon && _jsx(Icon, { icon: icon }), visibleText && (_jsx("div", __assign({ className: variant === "primary" ? "h5" : "p", style: {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    } }, { children: visibleText })))] }))) })));
    return tooltip ? (_jsx(Tooltip, __assign({ value: tooltip, disabled: disabled }, { children: button }))) : (button);
});
export default Button;
