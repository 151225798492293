import React, { useContext } from "react";
var AppContext = React.createContext({});
/**
 * Hook to get App information.
 * - appConfig
 */
var useApp = function () {
    var appConfig = useContext(AppContext);
    return { appConfig: appConfig };
};
export { AppContext, useApp };
