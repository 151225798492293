var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import Keycloak from "keycloak-js";
import { adminRealmRole } from "./common";
var authToken = undefined;
/**
 * Get current Keycloak auth token (encoded) string (if available)
 */
export var getAuthToken = function () { return authToken; };
/**
 * Authenticate the app with Keycloak.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @param config - as an object or public url to json file.
 * @param onAuthSuccess - Callback called when authentication is successful. Receives initialized keycloakInstance as an argument.
 * @param onAuthFailure - (Optional) Callback called when authentication fails. Receives no arguments.
 */
export var authenticateWithKeycloak = function (props) {
    var _a = props.keycloakConfig, keycloakConfig = _a === void 0 ? { url: "/auth", realm: "fa", clientId: "fa-admin-app" } : _a, onAuthSuccess = props.onAuthSuccess, onAuthFailure = props.onAuthFailure;
    var handleAuthSuccess = function (keycloakInstance) {
        console.info("Logged in successfully.");
        /**
         * Handle user authorization. If user has no roles in current
         * client/app, then a warning is shown in console.
         * @todo Redirect unauthorised users to Root landing page.
         * Overridden be FA ADMIN.
         */
        // Get a list of client-Ids in for which user has a role.
        var clientIds = Object.entries(keycloakInstance.resourceAccess || {}).map(function (_a) {
            var _b = __read(_a, 1), resource = _b[0];
            return resource;
        });
        var currentClientId = keycloakInstance.clientId || "fa";
        if (!clientIds.includes(currentClientId) &&
            !keycloakInstance.hasRealmRole(adminRealmRole)) {
            console.warn("User doesn't have enough permissions to access this application.");
        }
        /**
         * If user has at least one role in for current app, then
         * execute onAuthSuccess, along with refreshing the token.
         */
        onAuthSuccess(keycloakInstance);
        authToken = keycloakInstance.token;
        setInterval(function () {
            // Token refresh
            keycloakInstance
                .updateToken(10)
                .then(function (refreshed) {
                if (refreshed) {
                    authToken = keycloakInstance.token;
                    console.info("Authentication token was successfully refreshed");
                }
            })
                .catch(function () {
                console.error("Failed to refresh the token, or the session has expired");
                keycloakInstance.clearToken();
                keycloakInstance.logout();
            });
        }, 5000);
    };
    // Init
    var instance = new Keycloak(keycloakConfig);
    instance
        .init({ onLoad: "login-required", checkLoginIframe: false })
        .then(function (authenticated) {
        if (authenticated)
            handleAuthSuccess(instance);
        else
            throw new Error("Authentication failed");
    })
        .catch(function (reason) {
        if (instance.hasRealmRole(adminRealmRole)) {
            console.info("Logging in as FA ADMIN.");
            handleAuthSuccess(instance);
        }
        else {
            instance.clearToken();
            instance.logout();
            onAuthFailure ? onAuthFailure() : console.error(reason);
        }
    });
};
export default authenticateWithKeycloak;
