import { useEffect, useState } from "react";
/**
 * A custom hook that updates the state when props are changed.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @param {T} prop - Prop value
 * @example
 *  const [count, setCount] = usePropState(0);
 */
export function usePropState(prop) {
    var state = useState(prop);
    useEffect(function () {
        state[1](prop);
    }, [prop]);
    return state;
}
