var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "@emotion/styled";
import { Draggable } from "react-beautiful-dnd";
import { theme } from "@faharmony/theme";
import { Text } from "@faharmony/components";
// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
var primaryButton = 0;
var getBackgroundColor = function (_a) {
    var isSelected = _a.isSelected, isGhosting = _a.isGhosting;
    var _b = theme.color.background, secondary = _b.secondary, selected = _b.selected, defaultColor = _b.default;
    return (isGhosting && secondary) || (isSelected && selected) || defaultColor;
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  /* prettier-ignore */\n  border: solid ", " ", ";\n  background-color: ", ";\n  cursor: pointer !important;\n  padding: ", " ", ";\n  color: ", ";\n  text-align: start;\n  :hover {\n    color: ", ";\n    background-color: ", ";\n  }\n\n  /* needed for SelectionCount */\n  position: relative;\n  /* avoid default outline which looks lame with the position: absolute; */\n  &:focus {\n    outline: none;\n    border-color: ", ";\n  }\n"], ["\n  /* prettier-ignore */\n  border: solid ", " ", ";\n  background-color: ", ";\n  cursor: pointer !important;\n  padding: ", " ", ";\n  color: ", ";\n  text-align: start;\n  :hover {\n    color: ", ";\n    background-color: ", ";\n  }\n\n  /* needed for SelectionCount */\n  position: relative;\n  /* avoid default outline which looks lame with the position: absolute; */\n  &:focus {\n    outline: none;\n    border-color: ", ";\n  }\n"])), function (props) { return (props.isDragging ? "1px" : "0px"); }, theme.color
    .border.default, function (props) { return getBackgroundColor(props); }, theme.spacing.sm, theme.spacing.sm, theme.color.text.secondary, theme.color.text.primary, theme.color.background.hover, theme.color.border.selected);
/* stylelint-enable */
var size = 24;
var SelectionCount = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  right: -", ";\n  top: -", ";\n  padding: 2px;\n  color: ", ";\n  background: ", ";\n  border-radius: 50%;\n  height: ", "px;\n  width: ", "px;\n  position: absolute;\n  text-align: center;\n"], ["\n  right: -", ";\n  top: -", ";\n  padding: 2px;\n  color: ", ";\n  background: ", ";\n  border-radius: 50%;\n  height: ", "px;\n  width: ", "px;\n  position: absolute;\n  text-align: center;\n"])), theme.spacing.md, theme.spacing.md, theme.color.static.white, theme.color.background.info, size, size);
var keyCodes = {
    enter: 13,
    escape: 27,
    arrowDown: 40,
    arrowUp: 38,
    tab: 9,
};
export default (function (props) {
    //#region input setup
    var onKeyDown = function (event, 
    //@ts-ignore
    provided, snapshot) {
        if (event.defaultPrevented) {
            return;
        }
        if (snapshot.isDragging) {
            return;
        }
        if (event.keyCode !== keyCodes.enter) {
            return;
        }
        // we are using the event for selection
        event.preventDefault();
        performAction(event);
    };
    // Using onClick as it will be correctly
    // preventing if there was a drag
    var onClick = function (event) {
        if (event.defaultPrevented) {
            return;
        }
        if (event.button !== primaryButton) {
            return;
        }
        // marking the event as used
        event.preventDefault();
        performAction(event);
    };
    var onTouchEnd = function (event) {
        if (event.defaultPrevented) {
            return;
        }
        // marking the event as used
        // we would also need to add some extra logic to prevent the click
        // if this element was an anchor
        event.preventDefault();
        props.toggleSelectionInGroup(props.item.id);
    };
    // Determines if the platform specific toggle selection in group key was used
    var wasToggleInSelectionGroupKeyUsed = function (event) {
        var isUsingWindows = navigator.platform.indexOf("Win") >= 0;
        return isUsingWindows ? event.ctrlKey : event.metaKey;
    };
    // Determines if the multiSelect key was used
    var wasMultiSelectKeyUsed = function (event) { return event.shiftKey; };
    var performAction = function (event) {
        var item = props.item, toggleSelection = props.toggleSelection, toggleSelectionInGroup = props.toggleSelectionInGroup, multiSelectTo = props.multiSelectTo;
        if (wasToggleInSelectionGroupKeyUsed(event)) {
            toggleSelectionInGroup(item.id);
            return;
        }
        if (wasMultiSelectKeyUsed(event)) {
            multiSelectTo(item.id);
            return;
        }
        toggleSelection(item.id);
    };
    //#endregion
    var item = props.item, index = props.index, isSelected = props.isSelected, selectionCount = props.selectionCount, isGhosting = props.isGhosting, overrideProps = props.overrideProps, keyProp = props.keyProp;
    return (_jsx(Draggable, __assign({ draggableId: item.id, index: index }, { children: function (provided, snapshot) {
            var shouldShowSelection = snapshot.isDragging && selectionCount > 1;
            return (_jsxs(Container, __assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { onClick: onClick, onTouchEnd: onTouchEnd, onKeyDown: function (event) {
                    return onKeyDown(event, provided, snapshot);
                }, isDragging: snapshot.isDragging, isSelected: isSelected, isGhosting: isGhosting }, overrideProps, { children: [_jsx(Text, { children: item.content }), shouldShowSelection ? (_jsx(SelectionCount, { children: _jsx(Text, __assign({ variant: "small" }, { children: selectionCount })) })) : null] })));
        } }), keyProp));
});
var templateObject_1, templateObject_2;
