var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled, theme } from "@faharmony/theme";
export var StyledNotification = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  position: relative;\n  pointer-events: auto;\n  overflow: hidden;\n  margin: ", " 0 0;\n  width: 100%;\n  border-radius: ", ";\n  box-shadow: ", ";\n  border: 1px solid ", ";\n"], ["\n  background: ", ";\n  position: relative;\n  pointer-events: auto;\n  overflow: hidden;\n  margin: ", " 0 0;\n  width: 100%;\n  border-radius: ", ";\n  box-shadow: ", ";\n  border: 1px solid ", ";\n"])), theme.color.background.primary, theme.spacing.md, theme.radius[300], theme.shadow.default, function (props) { return theme.color.border[props.variant || "default"]; });
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  padding: ", ";\n  display: grid;\n  background-color: ", ";\n  grid-template-columns: max-content auto max-content;\n  align-items: center;\n  user-select: none;\n"], ["\n  height: 100%;\n  width: 100%;\n  padding: ", ";\n  display: grid;\n  background-color: ", ";\n  grid-template-columns: max-content auto max-content;\n  align-items: center;\n  user-select: none;\n"])), theme.spacing.md, function (props) {
    return props.variant
        ? theme.color.background[props.variant + "Secondary"]
        : "transparent";
});
export var IconArea = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: ", ";\n  height: ", ";\n  color: ", ";\n"], ["\n  margin-right: ", ";\n  height: ", ";\n  color: ", ";\n"])), function (props) { return (props.variant ? theme.spacing.md : 0); }, theme.spacing.xl, function (props) { return theme.color.text[props.variant || "secondary"]; });
export var IconClose = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: ", ";\n  height: ", ";\n  color: ", ";\n  cursor: pointer;\n  :hover {\n    color: ", ";\n  }\n"], ["\n  margin-left: ", ";\n  height: ", ";\n  color: ", ";\n  cursor: pointer;\n  :hover {\n    color: ", ";\n  }\n"])), theme.spacing.md, theme.spacing.xl, theme.color.text.secondary, theme.color.text.primary);
export var TextContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  .toast-title {\n    display: block;\n    color: ", ";\n  }\n  .toast-message {\n    color: ", ";\n  }\n"], ["\n  .toast-title {\n    display: block;\n    color: ", ";\n  }\n  .toast-message {\n    color: ", ";\n  }\n"])), function (props) { return theme.color.text[props.variant || "primary"]; }, theme.color.text.secondary);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
