/**
 * State
 * ---
 * @readonly No edit required in this file unless
 * "ModuleId" changes.
 *
 * This directory contains module state.
 * Harmony uses the Redux-Toolkit setup and
 * treats each Module as a Slice.
 *
 * Use other files like `types.ts`,
 * `initialState.ts` and `reducers.ts`
 * to maintain the functionality of ModuleState.
 * This file is responsible for combining them.
 *
 * There is no need for separately creating or
 * maintaining `actions` as they are generated
 * using slice's case reducers.
 */
import { createModuleState, createUseModuleStateHook } from "../utilities";
import initialState from "./initialState";
import * as reducers from "./reducers";
/** Module ID as defined in the `index.ts` of the Module */
var ModuleID = "harmony";
/** Create Module's state using wrapper of createSlice(). */
var HarmonyState = createModuleState({
    /** name should be exact same as Module ID. */
    name: ModuleID,
    /** Initial value of Module's state. */
    initialState: initialState,
    /** Object of case reducers to modify Module's state. */
    reducers: reducers,
});
// Create hooks for accessing Module's state
var _a = createUseModuleStateHook(ModuleID), useHarmonyState = _a.useModuleState, useHarmonyStateSelector = _a.useModuleStateSelector;
// Extract `actions` from Module's state
var HarmonyActions = HarmonyState.actions;
// Export state for Module config and actions for dispatch usage.
export { HarmonyState, HarmonyActions, useHarmonyState, useHarmonyStateSelector, };
