var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { theme, styled } from "@faharmony/theme";
import { Tooltip, Icon } from "@faharmony/components";
import { FALogoOpaqueIconDefinition } from "@faharmony/icons";
var StyledLogo = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0;\n  width: 100%;\n  height: 100%;\n  background-color: transparent;\n  text-align: center;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 36px;\n\n  .faLogo {\n    ", ";\n    --fa-primary-color: #ffffff;\n    --fa-primary-opacity: 1;\n    --fa-secondary-color: #ae001a;\n    --fa-secondary-opacity: 1;\n  }\n"], ["\n  margin: 0;\n  width: 100%;\n  height: 100%;\n  background-color: transparent;\n  text-align: center;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 36px;\n\n  .faLogo {\n    ", ";\n    --fa-primary-color: #ffffff;\n    --fa-primary-opacity: 1;\n    --fa-secondary-color: #ae001a;\n    --fa-secondary-opacity: 1;\n  }\n"])), theme.color.static.accent, function (p) { return !p.horizontal && "transform: translateY(".concat(theme.spacing.sm, ");"); });
var FALogo = function (_a) {
    var _b = _a.horizontal, horizontal = _b === void 0 ? false : _b;
    return (_jsx(StyledLogo, __assign({ onClick: function () { return window.location.reload(); }, horizontal: horizontal }, { children: _jsx(Tooltip, __assign({ value: "FA Platform" }, { children: _jsx("div", __assign({ className: "faLogo" }, { children: _jsx(Icon, { size: "1x", icon: FALogoOpaqueIconDefinition }) })) })) })));
};
export default FALogo;
var templateObject_1;
