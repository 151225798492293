var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { theme } from "@faharmony/theme";
import { faQuestionCircle } from "@faharmony/icons";
import { Tooltip } from "../Tooltip";
import { Icon } from "../Icon";
var Help = React.forwardRef(function (_a, ref) {
    var text = _a.text;
    return (_jsx(Tooltip, __assign({ ref: ref, value: text === null || text === void 0 ? void 0 : text.split("\n").map(function (textLine) { return (_jsxs("span", { children: [textLine, " ", _jsx("br", {})] }, textLine)); }) }, { children: _jsx("span", __assign({ style: {
                fontSize: "0.8rem",
                cursor: "help",
                marginLeft: "4px",
                display: "inline-block",
                alignSelf: "center",
            } }, { children: _jsx(Icon, { icon: faQuestionCircle, size: "1x", color: theme.color.text.secondary }) })) })));
});
export { Help };
