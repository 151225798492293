var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { theme } from "@faharmony/theme";
import { Div100vh } from "@faharmony/views";
import { useResponsive, useNetwork } from "@faharmony/helpers";
import { Grid } from "@faharmony/theme";
import { useKeycloak, GraphQLProvider } from "@faharmony/service";
import { GlobalBar } from "../GlobalBar";
import { addToast, removeToast, ToastContainer } from "../Toast";
import { DialogContainer } from "../Dialog";
import { useCheckUserForRoles, combineModuleRoles } from "./helpers";
import AppNavigation from "./Navigation";
import MainView from "./MainView";
import SideDrawer, { setAppSideDrawer, resetAppSideDrawer } from "./SideDrawer";
import { SnackbarProvider } from "notistack";
import { useConfirm } from "material-ui-confirm";
import ProcessModal from "../taskmanager/components/ProcessModal";
import { TaskManagerModal } from "../taskmanager";
import { TaskManagerContext, taskManagerReducer, } from "../taskmanager/TaskManager/context";
import { useReducer } from "react";
export var addDialogMUI;
var App = function (props) {
    var devMode = props.devMode, multilingual = props.multilingual, Modules = props.Modules, _harmony = props._harmony, appConfig = props.appConfig;
    var _a = appConfig.hideGlobalBar, hideGlobalBar = _a === void 0 ? false : _a, _b = appConfig.hideModuleNavigation, hideModuleNavigation = _b === void 0 ? false : _b;
    var keycloakInstance = useKeycloak().keycloakInstance;
    var isMobile = useResponsive().isMobile;
    var isAllowedAnything = useCheckUserForRoles(combineModuleRoles(Modules.filter(function (m) { return !m.stateOnly; })));
    var initialState = {
        isOpen: false,
        onClose: function () { },
        options: {},
    };
    var value = useReducer(taskManagerReducer, initialState);
    addDialogMUI = useConfirm();
    /** Check network connection */
    useNetwork({
        onOffline: function () {
            return addToast({
                id: "offline",
                title: "No internet connection",
                variant: "warning",
                persist: true,
            });
        },
        onOnline: function () { return removeToast("offline"); },
    });
    return (_jsx(Div100vh, __assign({ id: "full-height-wrapper" }, { children: _jsx(Grid, __assign({ spacing: "none", gridTemplate: !hideGlobalBar
                ? isMobile
                    ? "48px auto / auto"
                    : "auto / 52px auto"
                : "none", overflow: "hidden", style: { background: theme.color.background.default } }, { children: _jsxs(TaskManagerContext.Provider, __assign({ value: value }, { children: [!hideGlobalBar && (_jsx(GlobalBar, __assign({}, { devMode: devMode, multilingual: multilingual, _harmony: _harmony, keycloakInstance: keycloakInstance }))), _jsxs(GraphQLProvider, __assign({ keycloakInstance: keycloakInstance, customGraphQL: { link: "/graphql", split: false } }, { children: [_jsx(ProcessModal, {}), _jsx(TaskManagerModal, {})] })), _jsxs(Grid, __assign({ overflow: "hidden", gridTemplate: "auto / max-content auto max-content", spacing: "none" }, { children: [isAllowedAnything && !hideModuleNavigation ? (_jsx(AppNavigation, __assign({}, props))) : (_jsx("div", { id: "appNavigation" })), _jsx(MainView, __assign({}, props)), _jsx(SideDrawer, {}), _jsx(ToastContainer, {}), _jsx(SnackbarProvider, { maxSnack: 5, autoHideDuration: 5000 }), _jsx(DialogContainer, {})] }))] })) })) })));
};
export { setAppSideDrawer, resetAppSideDrawer };
export { useApp, AppContext } from "./context";
export { addLoaderOverlay, removeLoaderOverlay } from "./Loader";
export default App;
