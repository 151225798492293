var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect, useMemo } from "react";
import { AnimatePresence, styled, theme } from "@faharmony/theme";
import { faBellSlash } from "@faharmony/icons";
import { Button } from "@faharmony/components";
import Toast from "./Toast";
import { useToastsState, ToastsState } from "./state";
import { useToasts, addToastTrigger, removeToastTrigger } from "./helpers";
var StyledContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  z-index: ", ";\n  max-height: 100vh;\n  bottom: 0;\n  right: 0;\n  width: auto;\n  overflow-x: hidden;\n  overflow-y: auto;\n  padding: ", ";\n\n  #clearAllToasts {\n    position: sticky;\n    bottom: ", ";\n    margin-top: ", ";\n    display: flex;\n    justify-content: flex-end;\n  }\n"], ["\n  position: fixed;\n  z-index: ", ";\n  max-height: 100vh;\n  bottom: 0;\n  right: 0;\n  width: auto;\n  overflow-x: hidden;\n  overflow-y: auto;\n  padding: ", ";\n\n  #clearAllToasts {\n    position: sticky;\n    bottom: ", ";\n    margin-top: ", ";\n    display: flex;\n    justify-content: flex-end;\n  }\n"])), theme.zIndex.toast, theme.spacing.sm, theme.spacing.xs, theme.spacing.md);
/**
 * ToastContainer
 * ---
 * Wrap the app with this to show toast notifications
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.5.0
 * @example
 * <ToastContainer />
 */
var ToastContainer = function () {
    var containerRef = useRef(null);
    var toasts = useToastsState();
    var _a = useToasts(), clearAllToasts = _a.clearAllToasts, addToast = _a.addToast, removeToast = _a.removeToast;
    addToastTrigger.useCallback(addToast);
    removeToastTrigger.useCallback(removeToast);
    useEffect(function () {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight,
            });
        }
    }, [containerRef, toasts]);
    var toastCount = useMemo(function () { return toasts.length; }, [toasts]);
    return (_jsxs(StyledContainer, __assign({ className: "toast-container", ref: containerRef }, { children: [_jsx(AnimatePresence, { children: toasts.map(function (toast) { return (_jsx(Toast, __assign({}, toast), toast.id)); }) }), toastCount > 5 && (_jsx("div", __assign({ id: "clearAllToasts" }, { children: _jsx(Button, { onClick: clearAllToasts, icon: faBellSlash, tooltip: "Clear all notifications", variant: "secondary", spacing: "sm" }) })))] })));
};
export { ToastContainer, ToastsState };
export { addToast, removeToast, useToasts } from "./helpers";
export { addToastMUI, removeToastMUI } from "./MUIToast";
var templateObject_1;
