import { Trigger } from "@faharmony/helpers";
import { useDispatch, ToastsActions } from "./state";
export var addToastTrigger = new Trigger();
export var removeToastTrigger = new Trigger();
/** Add a new toast to the toast-notification stack
 * @example addToast({id: "uid", title: "Notification" }) */
export var addToast = addToastTrigger.publish;
/** Remove a ID-specific toast from the toast-notification stack
 * @example removeToast("uid") */
export var removeToast = removeToastTrigger.publish;
/** Get Toast actions */
export var useToasts = function () {
    var dispatch = useDispatch();
    var addToast = function (toast) {
        return dispatch(ToastsActions.addToast(toast));
    };
    var removeToast = function (id) { return dispatch(ToastsActions.removeToast(id)); };
    var clearAllToasts = function () { return dispatch(ToastsActions.clearAllToasts()); };
    return { addToast: addToast, removeToast: removeToast, clearAllToasts: clearAllToasts };
};
