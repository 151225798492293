import { jsx as _jsx } from "react/jsx-runtime";
import { useWatch, useFormContext } from "./hook-form";
/** Reset form values using the form control.
 * @example const {control} = useForm();
 * <Button value="Reset" onClick={() => resetFormValues(control)} />
 */
export var resetFormValues = function (control) {
    control._reset();
};
/** Component to render current values of the parent form. */
export var FormValuesWatcher = function () { return (_jsx("pre", { children: JSON.stringify(useWatch({ control: useFormContext().control }), null, 2) })); };
