var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createRef, useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { StyledTimeContainer, StyledTimeItem, StyledTimeItemContainer, StyledTimeSelector, } from "./StyledComponents";
import { TimeInput } from "./TimeInput";
export { TimeInput };
var TimeComponent;
(function (TimeComponent) {
    TimeComponent[TimeComponent["Hour"] = 0] = "Hour";
    TimeComponent[TimeComponent["Minute"] = 1] = "Minute";
})(TimeComponent || (TimeComponent = {}));
export var CustomTimePicker = function (props) {
    var _a, _b;
    var _c = __read(useState(Number((_a = props.value) === null || _a === void 0 ? void 0 : _a.split(":")[0])), 2), selectedHours = _c[0], setSelectedHours = _c[1];
    var _d = __read(useState(Number((_b = props.value) === null || _b === void 0 ? void 0 : _b.split(":")[1])), 2), selectedMinutes = _d[0], setSelectedMinutes = _d[1];
    var allHours = useMemo(function () {
        return Array(24)
            .fill(0)
            .map(function (_v, index) {
            return { ref: createRef(), hours: index };
        });
    }, []);
    /**shift the array and add to last by 6 items if the selected item is greater than 18 / 54 so the items can appear scrolled to top initially*/
    var shiftedHours = __spreadArray([], __read(allHours), false);
    if (selectedHours >= 18) {
        var removeFromTop = shiftedHours.slice(0, 6);
        var remaining = shiftedHours.slice(6);
        shiftedHours = __spreadArray(__spreadArray([], __read(remaining), false), __read(removeFromTop), false);
    }
    var allMinutes = useMemo(function () {
        return Array(60)
            .fill(0)
            .map(function (_v, i) {
            return { ref: createRef(), minutes: i };
        });
    }, []);
    var shiftedMins = __spreadArray([], __read(allMinutes), false);
    if (selectedMinutes >= 54) {
        var removeFromTop = shiftedMins.slice(0, 6);
        var remaining = shiftedMins.slice(6);
        shiftedMins = __spreadArray(__spreadArray([], __read(remaining), false), __read(removeFromTop), false);
    }
    var _f = __read(useState(shiftedHours), 2), optionHours = _f[0], setOptionHours = _f[1];
    var _g = __read(useState(shiftedMins), 2), optionMins = _g[0], setOptionMins = _g[1];
    useEffect(function () {
        var _a;
        var _b = __read(((_a = props.value) === null || _a === void 0 ? void 0 : _a.split(":")) || [0, 0], 2), hrs = _b[0], mins = _b[1];
        if (props.maxDate && props.date) {
            var tempDate = new Date(props.date);
            tempDate.setHours(Number(hrs));
            tempDate.setMinutes(Number(mins));
            /**If selcted time exceeds the max date then set to max date */
            if (tempDate > props.maxDate) {
                props.onChange &&
                    props.onChange(props.maxDate.getHours() + ":" + props.maxDate.getMinutes());
                return;
            }
        }
        /**If selcted time is smaller than min Date then set to min date */
        if (props.minDate && props.date) {
            var tempDate = new Date(props.date);
            tempDate.setHours(Number(hrs));
            tempDate.setMinutes(Number(mins));
            /**If selcted time exceeds the max date then set to max date */
            if (tempDate < props.minDate) {
                props.onChange &&
                    props.onChange(props.minDate.getHours() + ":" + props.minDate.getMinutes());
                return;
            }
        }
        setSelectedHours(Number(hrs));
        setSelectedMinutes(Number(mins));
    }, [props.date, props.maxDate, props.minDate, props.value]);
    var shouldDisableHours = useCallback(function (hour) {
        if (props.date) {
            var tempDate = new Date(props.date);
            tempDate.setHours(Number(hour));
            tempDate.setMinutes(0);
            if (props.maxDate) {
                if (tempDate > props.maxDate) {
                    return true;
                }
            }
            if (props.minDate) {
                tempDate.setMinutes(props.minDate.getMinutes());
                if (tempDate < props.minDate) {
                    return true;
                }
            }
        }
        return false;
    }, [props.minDate, props.maxDate, props.date]);
    var shouldDisableMinutes = useCallback(function (hour, minute) {
        if (props.date) {
            var tempDate = new Date(props.date);
            tempDate.setHours(Number(hour));
            tempDate.setMinutes(minute);
            if (props.maxDate) {
                if (tempDate > props.maxDate) {
                    return true;
                }
            }
            if (props.minDate) {
                if (tempDate < props.minDate) {
                    return true;
                }
            }
        }
        return false;
    }, [props.minDate, props.maxDate, props.date]);
    var ref = useRef(null);
    var selectorRefHour = useRef(null);
    var selectorRefMin = useRef(null);
    /**Calculates time selector popup position based on the postion of  time input on the window, by default it shows down
     * but if no space on down then shows up-.
     */
    var setTimeSelectorPostion = function () {
        var _a;
        var windowHeight = window.innerHeight;
        var currentTargetProps = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.parentElement.getBoundingClientRect();
        if (ref.current) {
            if (currentTargetProps.bottom +
                ref.current.getBoundingClientRect().height +
                5 >
                windowHeight) {
                ref.current.style.top =
                    "-" + (ref.current.getBoundingClientRect().height + 5) + "px";
            }
            else {
                ref.current.style.top = currentTargetProps.height + 5 + "px";
            }
        }
    };
    /* Using the arrow keys to navigate through the list. */
    var handleArrowKeys = function (e) {
        var _a, _b, _c, _d, _f, _g, _h, _j, _k;
        if (e.code === "ArrowUp") {
            (_b = (_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.previousSibling) === null || _b === void 0 ? void 0 : _b.focus();
        }
        if (e.code === "ArrowDown") {
            (_d = (_c = document.activeElement) === null || _c === void 0 ? void 0 : _c.nextSibling) === null || _d === void 0 ? void 0 : _d.focus();
        }
        /**Switch between hours and minutes column with arrow left/right key  */
        if (e.code === "ArrowLeft" || e.code === "ArrowRight") {
            /**Focus on the next item of the active column*/
            if (((_f = document.activeElement) === null || _f === void 0 ? void 0 : _f.parentElement) !== selectorRefMin.current) {
                (_h = (_g = optionMins[optionMins.findIndex(function (v) { return v.minutes === selectedMinutes; }) || 0].ref.current) === null || _g === void 0 ? void 0 : _g.nextElementSibling) === null || _h === void 0 ? void 0 : _h.focus();
            }
            else {
                (_k = (_j = optionHours[optionHours.findIndex(function (v) { return v.hours === selectedHours; }) || 0].ref.current) === null || _j === void 0 ? void 0 : _j.nextElementSibling) === null || _k === void 0 ? void 0 : _k.focus();
            }
        }
    };
    /**Function to enable infinite scrolling */
    var addMoreScroll = function (containerRef, timeComponent) {
        var _a, _b, _c, _d, _f, _g;
        if (containerRef.current) {
            /**If at the end of scroll */
            if (((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollHeight) - ((_b = containerRef.current) === null || _b === void 0 ? void 0 : _b.scrollTop) <=
                containerRef.current.offsetHeight) {
                var temp = timeComponent === TimeComponent.Hour
                    ? __spreadArray([], __read(optionHours), false) : __spreadArray([], __read(optionMins), false);
                var removeFromTopAndAddtoBottom = temp.slice(0, 1);
                var remain = temp.slice(1);
                var newOptions = __spreadArray(__spreadArray([], __read(remain), false), __read(removeFromTopAndAddtoBottom), false);
                if (timeComponent === TimeComponent.Hour) {
                    setOptionHours(newOptions);
                }
                else {
                    setOptionMins(newOptions);
                }
            }
            /**If at the begenning of scroll */
            if (((_c = containerRef.current) === null || _c === void 0 ? void 0 : _c.scrollTop) === 0) {
                (_d = containerRef.current) === null || _d === void 0 ? void 0 : _d.scrollTo({
                    top: ((_g = (_f = (timeComponent === TimeComponent.Hour
                        ? selectorRefHour
                        : selectorRefMin).current) === null || _f === void 0 ? void 0 : _f.firstElementChild) === null || _g === void 0 ? void 0 : _g.getBoundingClientRect().height) ||
                        0 * 2,
                });
                var temp = timeComponent === TimeComponent.Hour
                    ? __spreadArray([], __read(optionHours), false) : __spreadArray([], __read(optionMins), false);
                var removeFromBottomAndAddtoTop = temp.slice(temp.length - 1);
                var remain = temp.slice(0, temp.length - 1);
                var newOptions_1 = __spreadArray(__spreadArray([], __read(removeFromBottomAndAddtoTop), false), __read(remain), false);
                {
                    setTimeout(function () {
                        if (timeComponent === TimeComponent.Hour) {
                            setOptionHours(newOptions_1);
                        }
                        else {
                            setOptionMins(newOptions_1);
                        }
                    }, 100);
                }
            }
        }
    };
    useEffect(function () {
        var _a, _b, _c, _d, _f, _g, _h;
        setTimeSelectorPostion();
        var currentHoursRef = optionHours.find(function (v) { return v.hours === selectedHours; });
        (_b = (_a = currentHoursRef === null || currentHoursRef === void 0 ? void 0 : currentHoursRef.ref) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.scrollIntoView({
            block: "start",
        });
        var currentMinsRef = optionMins.find(function (v) { return v.minutes === selectedMinutes; });
        (_d = (_c = currentMinsRef === null || currentMinsRef === void 0 ? void 0 : currentMinsRef.ref) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.scrollIntoView({
            block: "start",
        });
        (_h = (_g = (_f = optionHours[optionHours.findIndex(function (v) { return v.hours === selectedHours; })]) === null || _f === void 0 ? void 0 : _f.ref) === null || _g === void 0 ? void 0 : _g.current) === null || _h === void 0 ? void 0 : _h.focus();
        /**Closees the time selector if clicked outside the input or the pop up */
        var handleClickOutside = function (event) {
            var _a, _b;
            if (ref.current && !((_b = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.contains(event.target))) {
                props.setVisible(false);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return function () {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);
    /**Add scroll event listener */
    useEffect(function () {
        var _a, _b;
        var addInfinteScrollTohours = function (_e) {
            return addMoreScroll(selectorRefHour, TimeComponent.Hour);
        };
        var addInfinteScrollToMins = function (_e) {
            return addMoreScroll(selectorRefMin, TimeComponent.Minute);
        };
        (_a = selectorRefHour.current) === null || _a === void 0 ? void 0 : _a.addEventListener("scroll", addInfinteScrollTohours, true);
        (_b = selectorRefMin.current) === null || _b === void 0 ? void 0 : _b.addEventListener("scroll", addInfinteScrollToMins, true);
        return function () {
            var _a, _b;
            (_a = selectorRefHour.current) === null || _a === void 0 ? void 0 : _a.removeEventListener("scroll", addInfinteScrollTohours, true);
            (_b = selectorRefMin.current) === null || _b === void 0 ? void 0 : _b.removeEventListener("scroll", addInfinteScrollToMins, true);
        };
    }, [optionHours[0], optionHours[23], optionMins[0], optionMins[59]]);
    //Listen to key presses
    useEffect(function () {
        var _a, _b;
        (_a = selectorRefHour.current) === null || _a === void 0 ? void 0 : _a.addEventListener("keydown", handleArrowKeys, true);
        (_b = selectorRefMin.current) === null || _b === void 0 ? void 0 : _b.addEventListener("keydown", handleArrowKeys, true);
        return function () {
            var _a, _b;
            (_a = selectorRefHour.current) === null || _a === void 0 ? void 0 : _a.removeEventListener("keydown", handleArrowKeys, true);
            (_b = selectorRefMin.current) === null || _b === void 0 ? void 0 : _b.removeEventListener("keydown", handleArrowKeys, true);
        };
    });
    return (_jsx(StyledTimeSelector, __assign({ ref: ref, direction: "column", className: "timeSelector_root" }, { children: _jsxs(StyledTimeContainer, __assign({ className: "container" }, { children: [_jsx(StyledTimeItemContainer, __assign({ ref: selectorRefHour, direction: "column", className: "hoursContainer" }, { children: optionHours.map(function (_a) {
                        var ref = _a.ref, hours = _a.hours;
                        return (_jsx(StyledTimeItem, __assign({ ref: ref, value: hours, disabled: shouldDisableHours(hours), onClick: function (e) {
                                e.stopPropagation();
                                props.onChange && props.onChange(hours + ":" + selectedMinutes);
                            }, selected: selectedHours === hours }, { children: hours < 10 ? 0 + "" + hours : hours }), hours));
                    }) })), _jsx(StyledTimeItemContainer, __assign({ ref: selectorRefMin, direction: "column", className: "minutesContainer" }, { children: optionMins.map(function (_a) {
                        var minutes = _a.minutes, ref = _a.ref;
                        return (_jsx(StyledTimeItem, __assign({ ref: ref, disabled: shouldDisableMinutes(selectedHours, minutes), selected: selectedMinutes === minutes, onClick: function (e) {
                                e.stopPropagation();
                                setTimeout(function () { var _a; return (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.focus(); }, 0);
                                props.onChange && props.onChange(selectedHours + ":" + minutes);
                            } }, { children: minutes < 9 ? 0 + "" + minutes : minutes }), minutes));
                    }) }))] })) })));
};
