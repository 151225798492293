var colorStatic = {
    accent: "#AE001A",
    black: "#000000",
    white: "#FFFFFF",
};
var colorTextLight = {
    primary: colorStatic.black,
    secondary: "#545454",
    disabled: "#AFAFAF",
    inverse: colorStatic.white,
    // Tint
    negative: "#E11900",
    warning: "#BC8B2C",
    positive: "#05944F",
    info: "#276EF1",
    note: "#AE27F2",
    // TintSecondary
    negativeSecondary: "#A31300",
    warningSecondary: "#734E12",
    positiveSecondary: "#035C31",
    infoSecondary: "#1B4CA8",
    noteSecondary: "#791BA8",
};
var colorTextDark = {
    primary: "#EEEEEE",
    secondary: "#AFAFAF",
    disabled: "#545454",
    inverse: "#141414",
    // Tint
    negative: "#E85C4A",
    warning: "#FFCF70",
    positive: "#06C167",
    info: "#5B91F5",
    note: "#C25BF5",
    // TintSecondary
    negativeSecondary: "#FED7D2",
    warningSecondary: "#FFE3AC",
    positiveSecondary: "#ADDEC9",
    infoSecondary: "#A0BFF8",
    noteSecondary: "#E1A6FF",
};
var colorBackgroundLight = {
    default: "#F7F7F7",
    primary: colorStatic.white,
    secondary: "#EEEEEE",
    disabled: "#E6E6E6",
    inverse: colorStatic.black,
    input: colorStatic.white,
    overlay: "#00000033",
    rowAlt: "#F2F2F2",
    hover: "#E8E8E8",
    selected: "#DEDEDE",
    // Tint
    negative: "#E11900",
    warning: "#FFC043",
    positive: "#05944F",
    info: "#276EF1",
    note: "#AE27F2",
    // TintSecondary
    negativeSecondary: "#FFEFED",
    warningSecondary: "#FFFAF0",
    positiveSecondary: "#E6F2ED",
    infoSecondary: "#EFF3FE",
    noteSecondary: "#FAF0FF",
    //Highlight
    highlight: "#F7F7F7",
};
var colorBackgroundDark = {
    default: "#1F1F1F",
    primary: "#333333",
    secondary: "#141414",
    disabled: "#1A1A1A",
    inverse: "#F6F6F6",
    input: "#0F0F0F",
    overlay: "#00000080",
    rowAlt: "#292929",
    hover: "#424242",
    selected: "#545454",
    // Tint
    negative: "#AB1300",
    warning: "#BC8B2C",
    positive: "#05944F",
    info: "#276EF1",
    note: "#AE27F2",
    // TintSecondary
    negativeSecondary: "#5A0A00",
    warningSecondary: "#5E4619",
    positiveSecondary: "#10462D",
    infoSecondary: "#102C60",
    noteSecondary: "#461061",
    //Highlight
    highlight: "#3a3a3a",
};
var colorBorderLight = {
    default: "#E2E2E2",
    selected: colorStatic.black,
    inverse: "#333333",
    // Tint
    negative: "#F1998E",
    warning: "#FFE3AC",
    positive: "#66D19E",
    info: "#A0BFF8",
    note: "#DBA0F8",
};
var colorBorderDark = {
    default: "#424242",
    selected: "#E2E2E2",
    inverse: "#AFAFAF",
    // Tint
    negative: "#870F00",
    warning: "#997328",
    positive: "#03582F",
    info: "#174291",
    note: "#681791",
};
/** Light variant of theme colors */
var colorLight = {
    background: colorBackgroundLight,
    text: colorTextLight,
    border: colorBorderLight,
    static: colorStatic,
};
/** Dark variant of theme colors */
var colorDark = {
    background: colorBackgroundDark,
    text: colorTextDark,
    border: colorBorderDark,
    static: colorStatic,
};
export { colorLight, colorDark };
