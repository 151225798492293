var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled, theme } from "@faharmony/theme";
export var className = "TransferShuttle";
export var StyledWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  height: 100%;\n  display: grid;\n  gap: ", ";\n  grid-template-columns: 1fr 1fr;\n  overflow: hidden;\n\n  #", "-buttons {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    width: max-content;\n    height: max-content;\n    transform: translateX(-50%) translateY(-50%);\n  }\n"], ["\n  position: relative;\n  width: 100%;\n  height: 100%;\n  display: grid;\n  gap: ", ";\n  grid-template-columns: 1fr 1fr;\n  overflow: hidden;\n\n  #", "-buttons {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    width: max-content;\n    height: max-content;\n    transform: translateX(-50%) translateY(-50%);\n  }\n"])), theme.spacing.md, className);
export var StyledShuttle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-shadow: ", ";\n  border-radius: ", ";\n  width: 100%;\n  height: 100%;\n  background: ", ";\n  display: grid;\n  gap: ", ";\n  grid-template-rows: max-content max-content max-content auto;\n  overflow: hidden;\n\n  .", "-heading {\n    padding: ", " ", " 0;\n  }\n\n  .", "-list {\n    overflow: auto;\n    margin: ", " 0;\n  }\n"], ["\n  box-shadow: ", ";\n  border-radius: ", ";\n  width: 100%;\n  height: 100%;\n  background: ", ";\n  display: grid;\n  gap: ", ";\n  grid-template-rows: max-content max-content max-content auto;\n  overflow: hidden;\n\n  .", "-heading {\n    padding: ", " ", " 0;\n  }\n\n  .", "-list {\n    overflow: auto;\n    margin: ", " 0;\n  }\n"])), theme.shadow.default, theme.radius[300], theme.color.background.default, theme.spacing.sm, className, theme.spacing.md, theme.spacing.xl, className, theme.spacing.md);
export var StyledItem = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  cursor: pointer;\n  padding: ", " ", ";\n  color: ", ";\n  user-select: none;\n  :hover {\n    color: ", ";\n    background-color: ", ";\n  }\n"], ["\n  width: 100%;\n  cursor: pointer;\n  padding: ", " ", ";\n  color: ", ";\n  user-select: none;\n  :hover {\n    color: ", ";\n    background-color: ", ";\n  }\n"])), theme.spacing.sm, theme.spacing.xl, theme.color.text.secondary, theme.color.text.primary, theme.color.background.hover);
var templateObject_1, templateObject_2, templateObject_3;
