var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box } from "@faharmony/theme";
import { faForward, faBackward } from "@faharmony/icons";
import { Text, Button, Divider, Notification } from "@faharmony/components";
import { TextField } from "../TextField";
import { StyledWrapper, StyledShuttle, StyledItem, className } from "./style";
var Shuttle = function (_a) {
    var heading = _a.heading, items = _a.items, shuttleId = _a.shuttleId, _b = _a.enableItemsReordering, enableItemsReordering = _b === void 0 ? false : _b, handleDoubleClick = _a.handleDoubleClick, handleChangeItemsOrder = _a.handleChangeItemsOrder;
    var _c = __read(useState(items), 2), filteredItems = _c[0], setFilteredItems = _c[1];
    var _d = __read(useState(false), 2), showNotification = _d[0], setShowNotification = _d[1];
    useEffect(function () {
        setFilteredItems(items);
    }, [items]);
    var handleOnDragEnd = function (result) {
        if (!result.destination)
            return;
        if (filteredItems.length !== items.length) {
            setShowNotification(true);
            return;
        }
        var itemsTemp = Array.from(filteredItems);
        var _a = __read(itemsTemp.splice(result.source.index, 1), 1), reorderedItem = _a[0];
        itemsTemp.splice(result.destination.index, 0, reorderedItem);
        handleChangeItemsOrder(itemsTemp);
        //setFilteredItems(itemsTemp);
    };
    return (_jsxs(StyledShuttle, { children: [_jsx("div", __assign({ className: className + "-heading" }, { children: _jsx(Text, { value: heading, variant: "h5", truncate: true }) })), _jsx("div", __assign({ className: className + "-filterInput" }, { children: _jsx(TextField, { onChange: function (e) {
                        var val = e.target.value;
                        if (val === "")
                            setFilteredItems(items);
                        else
                            setFilteredItems(function () {
                                return items.filter(function (_a) {
                                    var value = _a.value;
                                    return value.toLowerCase().includes(val.toLowerCase());
                                });
                            });
                    }, placeholder: "Filter" }) })), _jsx(Divider, {}), _jsx(DragDropContext, __assign({ onDragEnd: handleOnDragEnd, css: { overflow: "auto" } }, { children: _jsx(Droppable, __assign({ droppableId: shuttleId, isDropDisabled: !enableItemsReordering }, { children: function (provided) { return (_jsxs("div", __assign({ style: {
                            display: "flex",
                            flexDirection: "column",
                            overflow: "auto",
                        } }, provided.droppableProps, { ref: provided.innerRef }, { children: [_jsx(Box, __assign({ className: className + "-list", direction: "column", spacing: "sm" }, { children: filteredItems.length ? (filteredItems.map(function (item, index) { return (_jsx(Draggable, __assign({ isDragDisabled: !enableItemsReordering, draggableId: item.id, index: index }, { children: function (provided) { return (_jsx("div", __assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { children: _jsx(StyledItem, __assign({ index: index, id: className + "-Item-" + item.id, onDoubleClick: function () { return handleDoubleClick([item]); } }, { children: _jsx(Text, { value: item.value, truncate: true }) }), item.id) }))); } }), item.id)); })) : (_jsx(Text, { value: "No items found", variant: "small", color: "disabled" })) })), provided.placeholder] }))); } })) })), showNotification && (_jsx("div", __assign({ className: className + "-notification" }, { children: _jsx(Notification, { title: "It is not possible reorder the items if a filter has been applied.\n\nPlease, remove the filter and try to reorder the items again.", variant: "negative", onClose: function () {
                        setShowNotification(false);
                    } }) })))] }));
};
/**
 * TransferShuttle
 * ---
 * A transfer shuttle enables the user to move one or more shuttle items between shuttles.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.5.0
 * @example
 * <TransferShuttle shuttle1={} shuttle2={}>
 */
var TransferShuttle = React.forwardRef(function (_a, ref) {
    var shuttle1 = _a.shuttle1, shuttle2 = _a.shuttle2, _b = _a.enableTransferAllItemsButton, enableTransferAllItemsButton = _b === void 0 ? true : _b;
    return (_jsxs(StyledWrapper, __assign({ className: "transferShuttle", ref: ref }, { children: [_jsx(Shuttle, __assign({}, shuttle1, { handleDoubleClick: shuttle2.handleAcceptTransfer, handleChangeItemsOrder: shuttle1.handleChangeItemsOrder, shuttleId: "shuttle-left" })), _jsx(Shuttle, __assign({}, shuttle2, { handleDoubleClick: shuttle1.handleAcceptTransfer, handleChangeItemsOrder: shuttle2.handleChangeItemsOrder, shuttleId: "shuttle-right" })), enableTransferAllItemsButton && (_jsxs(Box, __assign({ id: className + "-buttons", direction: "column" }, { children: [_jsx(Button, { icon: faForward, variant: "secondary", spacing: "sm", tooltip: "Move all items to ".concat(shuttle2.heading), disabled: shuttle1.items.length === 0, onClick: function () { return shuttle2.handleAcceptTransfer(shuttle1.items); } }), _jsx(Button, { icon: faBackward, variant: "secondary", spacing: "sm", tooltip: "Move all items to ".concat(shuttle1.heading), disabled: shuttle2.items.length === 0, onClick: function () { return shuttle1.handleAcceptTransfer(shuttle2.items); } })] })))] })));
});
export { TransferShuttle };
