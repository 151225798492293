var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// FA FRONT ICON
export var FAFrontAppSVGPath = "M44,164 C50.627417,164 56,169.372583 56,176 L56,463.999729 L494,464 C500.627417,464 506,469.372583 506,476 L506,500 C506,506.627417 500.627417,512 494,512 L20,512 C13.372583,512 8,506.627417 8,500 L8,176 C8,169.372583 13.372583,164 20,164 L44,164 Z M433,192 C439.627417,192 445,197.372583 445,204 L445,420 C445,426.627417 439.627417,432 433,432 L409,432 C402.372583,432 397,426.627417 397,420 L397,204 C397,197.372583 402.372583,192 409,192 L433,192 Z M337,288 C343.627417,288 349,293.023714 349,299.220779 L349,420.779221 C349,426.976286 343.627417,432 337,432 L313,432 C306.372583,432 301,426.976286 301,420.779221 L301,299.220779 C301,293.023714 306.372583,288 313,288 L337,288 Z M241,338 C247.627417,338 253,343.372583 253,350 L253,420 C253,426.627417 247.627417,432 241,432 L217,432 C210.372583,432 205,426.627417 205,420 L205,350 C205,343.372583 210.372583,338 217,338 L241,338 Z M145,298 C151.627417,298 157,303.372583 157,310 L157,420 C157,426.627417 151.627417,432 145,432 L121,432 C114.372583,432 109,426.627417 109,420 L109,310 C109,303.372583 114.372583,298 121,298 L145,298 Z M203.65488,24 C208.162454,24 211.955919,27.816501 212.089962,32.437729 L212.093627,32.6905759 L212.093627,159.907853 L339.310958,159.907853 C344.42562,159.907853 348.633999,164.405759 347.92091,169.472251 C339.096299,231.93089 285.564339,280 220.671641,280 C219.234991,280 217.787346,279.978534 216.344937,279.930366 C149.854857,277.748168 94.2532622,222.151832 92.0710623,155.656545 C89.8783912,88.8335079 138.687313,33.0979058 202.523989,24.0806283 C202.904617,24.0267016 203.285246,24 203.65488,24 Z M252.959715,-1.09764642e-10 C316.670409,4.39592564 367.581362,55.3163163 371.981273,119.038822 C372.306293,123.81418 368.356162,127.858516 363.59278,127.996557 L363.341343,128.000198 L244,128.000198 L244,8.63673035 C244,3.94793497 247.786382,0.920382152 252.405039,0.920382152 C252.586285,0.920382152 252.773261,-0.0157406234 252.959715,-1.09764642e-10 Z";
export var FAFrontAppIcon = function () { return (_jsx("svg", __assign({ viewBox: "512, 512" }, { children: _jsx("path", { d: FAFrontAppSVGPath }) }))); };
export var FAFrontAppIconDefinition = {
    prefix: "far",
    iconName: "pie",
    icon: [512, 512, [], "f0b1", FAFrontAppSVGPath],
};
