var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from "@faharmony/components";
import { faClock } from "@faharmony/icons";
import React, { useEffect, useRef, useState } from "react";
import { CustomTimePicker } from ".";
import { InputField } from "../../BaseInputField";
import { StyledTimeIconContainer, StyledTimeInput, StyledTimeSeperator, } from "./StyledComponents";
export var TimeInput = function (props) {
    var _a = __read(React.useState(false), 2), selectorVisible = _a[0], setSelectorVisble = _a[1];
    var _b = __read(props.value.split(":"), 2), hours = _b[0], minutes = _b[1];
    return (_jsxs(StyledTimeInput, { children: [selectorVisible && (_jsx(CustomTimePicker, __assign({}, props, { setVisible: setSelectorVisble }))), _jsx(HoursInputField, { hours: hours, minutes: minutes, onChange: props.onChange }), _jsx(StyledTimeSeperator, { children: _jsx("div", { className: "seperator" }) }), _jsx(MinutesInputField, { hours: hours, minutes: minutes, onChange: props.onChange }), _jsx(StyledTimeIconContainer, __assign({ onClick: function () { return setSelectorVisble(true); } }, { children: _jsx(Icon, { icon: faClock }) }))] }));
};
var MinutesInputField = function (props) {
    var _a = __assign({}, props), hours = _a.hours, minutes = _a.minutes, onChange = _a.onChange;
    var ref = useRef(null);
    useEffect(function () {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.setSelectionRange(0, 2);
    }, [minutes]);
    var _b = __read(useState(false), 2), isFirstFocus = _b[0], setIsFirstFocus = _b[1];
    return (_jsx(InputField, { ref: ref, onFocus: function (e) {
            e.currentTarget.select();
            setIsFirstFocus(true);
        }, placeholder: "mm", className: "minutes", value: minutes, onChange: function (e) {
            if (!isNaN(Number(e.currentTarget.value))) {
                var newValue = e.currentTarget.value;
                if (!isFirstFocus && Number(minutes) < 6) {
                    newValue = minutes.toString() + newValue.toString();
                }
                onChange(hours + ":" + newValue);
                setIsFirstFocus(false);
            }
        }, onKeyDown: function (e) {
            var value = e.currentTarget.value;
            if (e.code === "ArrowUp") {
                e.preventDefault();
                if (value === "" || value === "59") {
                    onChange && onChange(hours + ":" + "0");
                }
                else {
                    var newValue = Number(value) + 1;
                    onChange && onChange(hours + ":" + newValue);
                }
            }
            if (e.code === "ArrowDown") {
                e.preventDefault();
                if (value === "" || value === "00") {
                    onChange && onChange(hours + ":" + 59);
                }
                else {
                    var newValue = Number(value) - 1;
                    onChange(hours + ":" + newValue);
                }
            }
        } }));
};
var HoursInputField = function (props) {
    var _a = __assign({}, props), hours = _a.hours, minutes = _a.minutes, onChange = _a.onChange;
    var ref = useRef(null);
    useEffect(function () {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.setSelectionRange(0, 2);
    }, [hours]);
    var _b = __read(useState(false), 2), isFirstFocus = _b[0], setIsFirstFocus = _b[1];
    return (_jsx(InputField, { value: hours, ref: ref, onFocus: function (e) {
            e.currentTarget.select();
            setIsFirstFocus(true);
        }, onClick: function () { return setIsFirstFocus(true); }, placeholder: "HH", className: "hours", onChange: function (e) {
            if (!isNaN(Number(e.currentTarget.value))) {
                var newValue = e.currentTarget.value;
                if (!isFirstFocus &&
                    ((Number(hours) < 2 && Number(newValue) < 9) ||
                        (Number(hours) === 2 && Number(newValue) < 4))) {
                    newValue = hours.toString() + newValue.toString();
                }
                onChange(newValue + ":" + minutes);
            }
            setIsFirstFocus(false);
        }, onKeyDown: function (e) {
            var value = e.currentTarget.value;
            if (e.code === "ArrowUp") {
                e.preventDefault();
                if (value === "" || value === "23") {
                    onChange("0" + ":" + minutes);
                }
                else {
                    var newValue = Number(value) + 1;
                    onChange(newValue + ":" + minutes);
                }
            }
            if (e.code === "ArrowDown") {
                e.preventDefault();
                if (value === "" || value === "00") {
                    onChange("23" + ":" + minutes);
                }
                else {
                    var newValue = Number(value) - 1;
                    onChange(newValue + ":" + minutes);
                }
            }
        } }));
};
