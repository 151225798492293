/** Variation in size that can be used for dimensions and spacing. */
var size = {
    0: "0px",
    50: "1px",
    100: "2px",
    200: "4px",
    300: "8px",
    400: "12px",
    500: "16px",
    600: "20px",
    700: "24px",
    750: "28px",
    800: "32px",
    850: "36px",
    900: "40px",
};
var spacing = {
    xs: size[100],
    sm: size[200],
    md: size[300],
    lg: size[400],
    xl: size[500],
    none: size[0],
};
/** Variation in size that can be used for dimensions and spacing. */
var radius = {
    0: "0px",
    100: "2px",
    300: "4px",
    500: "8px",
    700: "12px",
    900: "16px",
};
/**
 * The z-index CSS property sets the z-order of a positioned element and its descendants or flex items.
 * Overlapping elements with a larger z-index cover those with a smaller one.
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/z-index
 */
var zIndex = {
    hide: -1,
    menu: 0,
    base: 50,
    docked: 100,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    contextMenu: 1800,
    tooltip: 2000,
};
/** */
var transition = {
    spring: {
        type: "spring",
        damping: 50,
        stiffness: 300,
    },
};
export { zIndex, size, radius, transition, spacing };
