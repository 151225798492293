var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Typography, Button, Stack } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { useHistory, useRouteMatch } from "@faharmony/router";
import { useMuiFaTheme, globalBarWidth } from "@faharmony/theme";
import { useResponsive } from "@faharmony/helpers";
export var MuiPageView = function (props) {
    var theme = useMuiFaTheme();
    var isMobile = useResponsive().isMobile;
    var _a = __assign({}, props), _b = _a.stickyHeader, stickyHeader = _b === void 0 ? !isMobile : _b, _c = _a.fullWindow, fullWindow = _c === void 0 ? false : _c, sxProps = _a.sxProps;
    return (_jsxs(Grid, __assign({ id: props.id, container: true, direction: "column", sx: {
            flexWrap: !stickyHeader ? "nowrap" : "wrap",
            overflowY: !stickyHeader ? "scroll" : "auto",
            position: fullWindow ? "fixed" : "auto",
            left: isMobile ? 0 : globalBarWidth,
            width: fullWindow ? "fill-available" : "auto",
            marginTop: "1px",
            background: theme.palette.background.primary,
        }, boxShadow: theme.shadows[3], height: "100vh" }, { children: [_jsx(Header, __assign({}, props)), _jsx(Grid, __assign({ id: props.id + "_content_root", item: true, xs: true, sx: __assign({ pb: 1, pl: 1, pr: 1, ml: 0.15, background: theme.palette.background.primary, overflow: !stickyHeader ? "visible" : "auto", display: "flex", width: "fill-available" }, sxProps), boxShadow: theme.shadows[3], height: "auto" }, { children: props.children }))] })));
};
var Header = function (props) {
    var theme = useMuiFaTheme();
    var backButtonEnabled = !!props.backButton;
    var backButtonProps = typeof props.backButton === "boolean"
        ? { enabled: backButtonEnabled }
        : __assign(__assign({}, props.backButton), { enabled: backButtonEnabled });
    return (_jsxs(Grid, __assign({ id: props.id + "_header", sx: {
            background: props.headerVariant === "secondary"
                ? theme.palette.background.secondary
                : theme.palette.background.primary,
            ml: 0.1,
            mb: 0,
            py: 1.5,
            zIndex: 2,
        }, boxShadow: theme.shadows[2] }, { children: [_jsxs(Grid, __assign({ container: true, direction: "row", sx: { px: 1 }, justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Grid, __assign({ item: true, sx: { pl: 1 } }, { children: _jsxs(Stack, __assign({ direction: "row" }, { children: [_jsx(BackButton, __assign({}, backButtonProps)), typeof props.heading === "string" ? (_jsx(Typography, __assign({ variant: "h5", id: props.id + "_header_title" }, { children: props.heading }))) : (props.heading)] })) })), props.actions && (_jsx(Grid, __assign({ item: true, alignSelf: "flex-end", id: props.id + "_header_actions" }, { children: props.actions })))] })), props.toolbarContent && (_jsx(Grid, __assign({ item: true, id: props.id + "_header_toolbar", sx: { px: 2, pt: 2, pb: 1 } }, { children: props.toolbarContent })))] })));
};
var BackButton = function (props) {
    var history = useHistory();
    var url = useRouteMatch().url;
    if (props === null || props === void 0 ? void 0 : props.enabled) {
        return (_jsx(Button, __assign({ id: "pageView_btn_back", sx: { mr: 2 }, variant: "outlined", startIcon: _jsx(ArrowBackIos, {}), onClick: function () {
                if (props.backButtonAdditionalActions === undefined ||
                    (props === null || props === void 0 ? void 0 : props.backButtonAdditionalActions()) !== false) {
                    var splitsPath = url.split("/");
                    history.push(splitsPath.slice(0, splitsPath.length - 1).join("/"), {
                        state: props.state,
                    });
                }
            } }, { children: "Back" })));
    }
    else {
        return null;
    }
};
export default MuiPageView;
