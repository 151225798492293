var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from "@faharmony/theme";
import { theme } from "@faharmony/theme";
import { ButtonPopover } from "../ButtonPopover";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: ", ";\n  width: 100%;\n  background-color: ", ";\n  /* box-shadow: ", "; */\n  padding: ", ";\n  min-height: 44px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n"], ["\n  border-radius: ", ";\n  width: 100%;\n  background-color: ", ";\n  /* box-shadow: ", "; */\n  padding: ", ";\n  min-height: 44px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n"])), theme.radius[500], theme.color.background.secondary, theme.shadow.inner, theme.spacing.md);
var Toolbar = function (_a) {
    var actions = _a.actions, children = _a.children;
    if (actions || children)
        return (_jsx(Wrapper, { children: (actions === null || actions === void 0 ? void 0 : actions.map(function (action) { return (_jsx(ButtonPopover, __assign({ variant: "secondary" }, action, { spacing: "sm" }), action.id)); })) || children }));
    else {
        console.warn("Toolbar could not be rendered since no actions or children were passed.");
        return null;
    }
};
export { Toolbar };
var templateObject_1;
