import { languageCodeLocalStorageKey, getLocalStorageValue, setLocalStorageValue, } from "@faharmony/helpers";
import languageCodes, { languageCodeList } from "./languageCodes";
/**
 * Object of native names (label and changeLabel). Currently supported language codes are `en`, `fi` and `sv`.
 *
 * @returns object {label: string, changeLabel: string}
 */
export var languageNativeNames = {
    en: { label: "English", changeLabel: "Change language" },
    fi: { label: "Suomi", changeLabel: "Vaihda kieltä" },
    sv: { label: "Svenska", changeLabel: "Ändra språk" },
};
/** Get the current language code. */
export var getCurrentLanguageCode = function () {
    return getLocalStorageValue(languageCodeLocalStorageKey) || "en";
};
/**
 * Set the current language code.
 * @param {LanguageCode} code
 */
export var setCurrentLanguageCode = function (code) {
    // Set language code in local storage
    setLocalStorageValue(languageCodeLocalStorageKey, code);
    // set language in HTML Document
    document.documentElement.lang = code;
};
/**
 * Get a list of language codes for each language that is included in the locale (i18n).
 * @param i18n
 * @default ['en']
 */
export var getLocaleLanguageCodes = function (i18n) {
    return i18n.options.whitelist
        ? i18n.options.whitelist.filter(function (code) {
            return languageCodeList.some(function (lCode) { return code === lCode; });
        })
        : [languageCodes.English];
};
/**
 * Get all Init options required to initialize i18n in the app.
 */
export var getI18nInitOptions = function (_a) {
    var resources = _a.resources, moduleIds = _a.moduleIds, _b = _a.languageCodes, languageCodes = _b === void 0 ? ["en"] : _b;
    return {
        resources: resources,
        ns: moduleIds,
        defaultNS: moduleIds[0],
        whitelist: languageCodes,
        fallbackLng: languageCodes[0],
        keySeparator: ".",
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    };
};
