import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
/** This function wraps RTK createSlice() and expects ModuleID as name of slice-state.
 * @see [Writing the Slice Reducer](https://redux-toolkit.js.org/usage/usage-with-typescript#createslice) */
var createModuleState = createSlice;
/** A wrapper around RTK createSelectorHook() & useSelector.
 * Expects `IModuleState` as type-parameter and ModuleId as value-parameter
 * @example const { useModuleState } = createUseModuleStateHook<IModuleState>(ModuleID) */
var createUseModuleStateHook = function (ModuleID) {
    /** Hook to access complete Module state */
    var useModuleState = function () {
        return useSelector(function (state) { return state[ModuleID]; });
    };
    /** Hook to access partial (selected) Module state */
    var useModuleStateSelector = function (selector) {
        return useSelector(function (state) { return selector(state[ModuleID]); });
    };
    return { useModuleState: useModuleState, useModuleStateSelector: useModuleStateSelector };
};
export { createModuleState, createUseModuleStateHook };
