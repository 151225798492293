import { state } from "./state";
export var getUserNameFromTask = function (task, extractFrom) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    if (extractFrom === void 0) { extractFrom = "processInitiator"; }
    if (!task)
        return undefined;
    var userId = extractFrom === "processInitiator"
        ? ((_a = task === null || task === void 0 ? void 0 : task.processInitiator) === null || _a === void 0 ? void 0 : _a.userId)
            // * Do not know how reliable this data is -> || task?.data?.initiator
            || ((_b = task === null || task === void 0 ? void 0 : task.workflowDetails) === null || _b === void 0 ? void 0 : _b.startUserId)
        : ((_c = task === null || task === void 0 ? void 0 : task.taskAssignee) === null || _c === void 0 ? void 0 : _c.userId)
            || (task === null || task === void 0 ? void 0 : task.assignee);
    var userName = extractFrom === "processInitiator"
        ? "".concat((_e = (_d = task === null || task === void 0 ? void 0 : task.processInitiator) === null || _d === void 0 ? void 0 : _d.firstName) !== null && _e !== void 0 ? _e : "", " ").concat((_g = (_f = task === null || task === void 0 ? void 0 : task.processInitiator) === null || _f === void 0 ? void 0 : _f.lastName) !== null && _g !== void 0 ? _g : "").trim()
        // * Do not know how reliable this data is -> || `${task?.data?.userFirstName ?? ""} ${task?.data?.userLastName ?? ""}`.trim()
        : "".concat((_j = (_h = task === null || task === void 0 ? void 0 : task.taskAssignee) === null || _h === void 0 ? void 0 : _h.firstName) !== null && _j !== void 0 ? _j : "", " ").concat((_l = (_k = task === null || task === void 0 ? void 0 : task.taskAssignee) === null || _k === void 0 ? void 0 : _k.lastName) !== null && _l !== void 0 ? _l : "").trim();
    var isCurrentUser = userId && userId === ((_m = state.currentUser) === null || _m === void 0 ? void 0 : _m.userId);
    return "".concat(userName || userId || "").concat(isCurrentUser ? " (me)" : "") || undefined;
};
