var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useEffect } from "react";
import { Box, motion, styled, theme } from "@faharmony/theme";
import { useModalsState, ModalsState } from "./state";
import { useModals, addModalTrigger, removeModalTrigger, addDialogTrigger, removeDialogTrigger, } from "./helpers";
import Modal from "./Modal";
var StyledDiv = styled(motion.div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  display: flex;\n  justify-content: center;\n  z-index: ", ";\n  width: 100vw;\n  height: 100vh;\n  background-color: var(--color-background-overlay);\n\n  .modalContainer {\n    position: relative;\n    overflow: auto;\n    box-shadow: var(--shadow-default);\n    border: 1px solid var(--color-border-default);\n    border-radius: 8px;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    background-color: ", ";\n    min-width: min(400px, calc(100vw - ", "));\n    max-width: calc(100vw - ", ");\n    max-height: calc(100vh - ", ");\n    border-radius: ", ";\n    box-shadow: ", ";\n  }\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  display: flex;\n  justify-content: center;\n  z-index: ", ";\n  width: 100vw;\n  height: 100vh;\n  background-color: var(--color-background-overlay);\n\n  .modalContainer {\n    position: relative;\n    overflow: auto;\n    box-shadow: var(--shadow-default);\n    border: 1px solid var(--color-border-default);\n    border-radius: 8px;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    background-color: ", ";\n    min-width: min(400px, calc(100vw - ", "));\n    max-width: calc(100vw - ", ");\n    max-height: calc(100vh - ", ");\n    border-radius: ", ";\n    box-shadow: ", ";\n  }\n"])), theme.zIndex.overlay + 1, function (props) {
    return props.backgroundColor
        ? props.backgroundColor
        : "var(--color-background-default)";
}, theme.spacing.xl, theme.spacing.xl, theme.spacing.xl, theme.radius[500], theme.shadow.float);
var motionProps = {
    initial: "initial",
    animate: "animate",
    exit: "exit",
    transition: { duration: 0.2, ease: "easeIn", delay: 0.0 },
    layout: true,
    variants: {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
    },
};
var ModalContainer = function () {
    var containerRef = useRef(null);
    var modals = useModalsState();
    var _a = useModals(), addModal = _a.addModal, removeModal = _a.removeModal, addDialog = _a.addDialog, removeDialog = _a.removeDialog;
    addModalTrigger.useCallback(addModal);
    addDialogTrigger.useCallback(addDialog);
    removeDialogTrigger.useCallback(removeDialog);
    removeModalTrigger.useCallback(removeModal);
    useEffect(function () {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight,
            });
        }
    }, [containerRef, modals]);
    return (modals === null || modals === void 0 ? void 0 : modals.length) > 0 ? (_jsx(_Fragment, { children: modals.map(function (modal, index) { return (_jsx(StyledDiv, __assign({ backgroundColor: modal.backgroundColor }, { children: _jsx(Box, __assign({ alignItems: "center", direction: "column", justifyContent: "center" }, { children: _jsx(motion.div, __assign({ style: __assign({ zIndex: theme.zIndex.modal + 1 + index, width: modal.width, minWidth: modal.width, maxWidth: modal.width, height: modal.height, minHeight: modal.height, maxHeight: modal.height }, modal.containerStyle) }, motionProps, { className: "modalContainer" }, { children: _jsx(Modal, __assign({}, modal)) })) })) }), "container-" + modal.id)); }) })) : (_jsx(_Fragment, {}));
};
export { ModalContainer, ModalsState };
export { addModal, removeModal, addDialog, useModals, removeDialog, } from "./helpers";
var templateObject_1;
