var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import List from "./list";
import { multiDragAwareReorder, multiSelectTo, sortItemsByContentAsc, sortItemIdsAsc, } from "./utils";
import { DragDropContext, } from "react-beautiful-dnd";
import { theme } from "@faharmony/theme";
import tsInvariant from "@faharmony/helpers/lib/misc";
import { Button } from "@faharmony/components";
import { faBackward, faForward } from "@faharmony/icons";
var getItems = function (entities, listId) {
    var _a;
    var items = entities.lists[listId].itemIds.map(function (itemId) { return entities.items[itemId]; });
    if (((_a = entities.lists[listId]) === null || _a === void 0 ? void 0 : _a.sortType) === "content_asc") {
        entities.lists[listId].itemIds = sortItemIdsAsc(entities, listId);
        return sortItemsByContentAsc(items);
    }
    return items;
};
var TransferList = function (_a) {
    var data = _a.data, _b = _a.onChange, onChange = _b === void 0 ? function () { return null; } : _b;
    var _c = __read(useState({
        entities: data,
        selectedItemsIds: [],
        draggingItemId: null,
    }), 2), state = _c[0], setState = _c[1];
    var mergeState = function (updated) {
        return setState(function (state) { return (__assign(__assign({}, state), updated)); });
    };
    //#region input setup
    useEffect(function () {
        window.addEventListener("click", onWindowClick);
        window.addEventListener("keydown", onWindowKeyDown);
        window.addEventListener("touchend", onWindowTouchEnd);
        return function () {
            window.removeEventListener("click", onWindowClick);
            window.removeEventListener("keydown", onWindowKeyDown);
            window.removeEventListener("touchend", onWindowTouchEnd);
        };
    }, []);
    var onDragStart = function (start) {
        var id = start.draggableId;
        var selected = state.selectedItemsIds.find(function (itemId) { return itemId === id; });
        // if dragging an item that is not selected - unselect all items
        if (!selected) {
            unselectAll();
        }
        mergeState({
            draggingItemId: start.draggableId,
        });
    };
    var onDragEnd = function (result) {
        var destination = result.destination;
        var source = result.source;
        // nothing to do
        if (!destination || result.reason === "CANCEL") {
            mergeState({
                draggingItemId: null,
            });
            return;
        }
        var processed = multiDragAwareReorder({
            entities: state.entities,
            selectedItemsIds: state.selectedItemsIds,
            source: source,
            destination: destination,
        });
        onChange(processed.entities);
        mergeState(__assign(__assign({}, processed), { draggingItemId: null }));
    };
    var onWindowKeyDown = function (event) {
        if (event.defaultPrevented) {
            return;
        }
        if (event.key === "Escape") {
            unselectAll();
        }
    };
    var onWindowClick = function (event) {
        if (event.defaultPrevented) {
            return;
        }
        unselectAll();
    };
    var onWindowTouchEnd = function (event) {
        if (event.defaultPrevented) {
            return;
        }
        unselectAll();
    };
    var toggleSelection = function (itemId) {
        var selectedItemsIds = state.selectedItemsIds;
        var wasSelected = selectedItemsIds.includes(itemId);
        var newItemIds = (function () {
            // Item was not previously selected
            // now will be the only selected item
            if (!wasSelected) {
                return [itemId];
            }
            // Item was part of a selected group
            // will now become the only selected item
            if (selectedItemsIds.length > 1) {
                return [itemId];
            }
            // item was previously selected but not in a group
            // we will now clear the selection
            return [];
        })();
        mergeState({
            selectedItemsIds: newItemIds,
        });
    };
    var toggleSelectionInGroup = function (itemId) {
        var selectedItemsIds = state.selectedItemsIds;
        var index = selectedItemsIds.indexOf(itemId);
        // if not selected - add it to the selected items
        if (index === -1) {
            mergeState({
                selectedItemsIds: __spreadArray(__spreadArray([], __read(selectedItemsIds), false), [itemId], false),
            });
            return;
        }
        // it was previously selected and now needs to be removed from the group
        var shallow = __spreadArray([], __read(selectedItemsIds), false);
        shallow.splice(index, 1);
        mergeState({
            selectedItemsIds: shallow,
        });
    };
    // This behaviour matches the MacOSX finder selection
    var _multiSelectTo = function (newItemId, activeFilter) {
        if (activeFilter === void 0) { activeFilter = ""; }
        var updated = multiSelectTo(state.entities, state.selectedItemsIds, newItemId);
        if (updated == null) {
            return;
        }
        mergeState({
            selectedItemsIds: updated.filter(function (id) {
                return id.toLowerCase().includes(activeFilter.toLowerCase());
            }),
        });
    };
    //@ts-ignore
    var unselect = function () {
        unselectAll();
    };
    var unselectAll = function () {
        mergeState({
            selectedItemsIds: [],
        });
    };
    var switchList = function (listId, index) {
        var entities = state.entities;
        var destinationListId = Object.keys(entities.lists).find(function (colId) { return listId !== colId; });
        tsInvariant(destinationListId, "Count not find destinationListId");
        var destinationList = entities.lists[destinationListId];
        var result = multiDragAwareReorder({
            source: { droppableId: listId, index: index },
            destination: {
                droppableId: destinationListId,
                index: destinationList.itemIds.length,
            },
            entities: entities,
            selectedItemsIds: [],
        });
        onChange(result.entities);
        mergeState(__assign(__assign({}, result), { draggingItemId: null }));
    };
    //#endregion
    var selected = state.selectedItemsIds;
    return (_jsx(DragDropContext, __assign({ onDragStart: onDragStart, onDragEnd: onDragEnd }, { children: _jsx(Container, { children: state.entities.listOrder
                .map(function (listId) { return (_jsx(List, { list: state.entities.lists[listId], items: getItems(state.entities, listId), selectedItemsIds: selected, draggingItemId: state.draggingItemId, toggleSelection: toggleSelection, toggleSelectionInGroup: toggleSelectionInGroup, multiSelectTo: _multiSelectTo, switchList: switchList }, listId)); })
                .reduce(function (p, c, i) {
                return p.concat([
                    c,
                    _jsx(Divider, {}, "TransferList-Divider-" + i),
                    _jsx(MidButtons, { state: state, setState: setState, onChange: onChange, keyProp: "TransferList-MidButtons-" + i }),
                ]);
            }, [])
                // INFO removing last 2 elements Divider and MidButtons
                .slice(0, -2) }) })));
};
var Divider = function (props) { return (_jsx("div", __assign({ style: {
        marginTop: -8,
        marginBottom: -8,
        borderLeft: "1px solid " + theme.color.border.default,
    } }, props))); };
var MidButtons = function (_a) {
    var state = _a.state, setState = _a.setState, onChange = _a.onChange, keyProp = _a.keyProp;
    function MoveSelected(e, sourceListId, destinationListId) {
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        e === null || e === void 0 ? void 0 : e.preventDefault();
        var results = multiDragAwareReorder({
            source: {
                droppableId: sourceListId,
                index: state.entities.lists[sourceListId].itemIds.findIndex(function (i) { return state.selectedItemsIds[0] === i; }),
            },
            destination: {
                droppableId: destinationListId,
                index: state.entities.lists[destinationListId].itemIds.length,
            },
            entities: state.entities,
            selectedItemsIds: state.selectedItemsIds,
        });
        onChange(results.entities);
        setTimeout(function () {
            return setState(__assign(__assign({}, state), results));
        }, 0);
    }
    return (_jsxs("div", __assign({ style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: 0,
            gap: 8,
        } }, { children: [_jsx(Button, { icon: faForward, variant: "secondary", spacing: "sm", tooltip: "Move selected items to Visible columns", disabled: !state.selectedItemsIds.length ||
                    !(state.entities.lists["hidden_column"].itemIds.findIndex(function (i) { return state.selectedItemsIds[0] === i; }) >= 0), onClick: function (e) { return MoveSelected(e, "hidden_column", "visible_column"); } }), _jsx(Button, { icon: faBackward, variant: "secondary", spacing: "sm", tooltip: "Move selected items to Available columns", disabled: !state.selectedItemsIds.length ||
                    !(state.entities.lists["visible_column"].itemIds.findIndex(function (i) { return state.selectedItemsIds[0] === i; }) >= 0), onClick: function (e) { return MoveSelected(e, "visible_column", "hidden_column"); } })] }), keyProp));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  user-select: none;\n  height: 100%;\n"], ["\n  display: flex;\n  user-select: none;\n  height: 100%;\n"])));
export { TransferList };
export * from "./types";
var templateObject_1;
