var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**`
 * Menu components for navigation.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @copyright FA Solutions Oy
 * @version 1.6.0
 */
import { theme, styled, useDisableAnimation } from "@faharmony/theme";
import { Divider } from "@faharmony/components";
import { useMenuCollapse, useFilterNavigationItems, useCleanItems, calcGridTemplateRows, } from "./helpers";
import Header from "./Header";
import Footer from "./Footer";
import Nav from "./nav";
import Actions from "./Actions";
var maxWidth = "248px";
var minWidth = "48px";
var StyledNavigation = styled.aside(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: ", ";\n  width: ", ";\n  min-width: ", ";\n  max-width: ", ";\n  height: 100%;\n  min-height: ", ";\n  max-height: 100vh;\n  position: relative;\n  z-index: 0;\n  overflow: visible;\n  background-color: ", ";\n  transition: ", ";\n  display: grid;\n  grid-template-rows: ", ";\n"], ["\n  padding-top: ", ";\n  width: ", ";\n  min-width: ", ";\n  max-width: ", ";\n  height: 100%;\n  min-height: ", ";\n  max-height: 100vh;\n  position: relative;\n  z-index: 0;\n  overflow: visible;\n  background-color: ", ";\n  transition: ", ";\n  display: grid;\n  grid-template-rows: ", ";\n"])), function (p) { return (p.isMenuCollapsed ? theme.spacing["md"] : 0); }, function (p) { return (p.isMenuCollapsed ? minWidth : maxWidth); }, minWidth, function (p) { return (p.isMenuCollapsed ? minWidth : maxWidth); }, maxWidth, theme.color.background.secondary, function (p) {
    return p.disableAnimation ? "" : "width 300ms, max-width 300ms";
}, calcGridTemplateRows);
/**
 * Navigation
 * ---
 *
 * Navigation component for FA applications.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 *
 * @example
 * <Menu />
 */
var Menu = function (_a) {
    var _b = _a.isHeaderVisible, isHeaderVisible = _b === void 0 ? true : _b, isMenuCollapsedDefault = _a.isMenuCollapsedDefault, _c = _a.actions, actions = _c === void 0 ? [] : _c, _d = _a.heading, heading = _d === void 0 ? "" : _d, shortHeading = _a.shortHeading, descriptionText = _a.descriptionText, _e = _a.menu, menu = _e === void 0 ? [] : _e, enableMenuFilter = _a.enableMenuFilter, footerActions = _a.footerActions;
    actions = actions.slice(0, 5);
    var menuCollapseProps = useMenuCollapse(isMenuCollapsedDefault);
    var isMenuCollapsed = menuCollapseProps.isMenuCollapsed;
    var items = useCleanItems(menu);
    var _f = useFilterNavigationItems(items), handleFiltering = _f.handleFiltering, filteredItems = _f.filteredItems;
    return (_jsxs(StyledNavigation, __assign({ isHeaderVisible: isHeaderVisible, isMenuCollapsed: isMenuCollapsed, actionsPresent: actions.length > 0, disableAnimation: useDisableAnimation() }, { children: [isHeaderVisible && (_jsx(Header, { heading: heading, shortHeading: shortHeading, isMenuCollapsed: isMenuCollapsed })), actions.length > 0 && (_jsx(Actions, { actions: actions, isMenuCollapsed: isMenuCollapsed, heading: heading, shortHeading: shortHeading })), !isMenuCollapsed && (_jsx("div", __assign({ style: { margin: "8px 8px 0" } }, { children: _jsx(Divider, { spacing: "none" }) }))), _jsx(Nav, { navItems: filteredItems, isMenuCollapsed: isMenuCollapsed, isHeaderShown: isHeaderVisible && actions.length > 0 }), _jsx(Footer, __assign({}, menuCollapseProps, { heading: heading, shortHeading: shortHeading, descriptionText: descriptionText, handleFiltering: handleFiltering, enableMenuFilter: enableMenuFilter, footerActions: footerActions }))] })));
};
export { Menu };
var templateObject_1;
