var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useRef, useState, useEffect } from "react";
/**
 * This hook allows to check if the mouse if ove some element.
 * @author Gabe Ragland <> (https://github.com/gragland)
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @example
 *  const [hoverRef, isHovered] = useHover();
 *   <div ref={hoverRef}>
      {isHovered ? 'Yes' : 'No'}
     </div>
 */
export function useHover() {
    var _a = __read(useState(false), 2), value = _a[0], setValue = _a[1];
    var ref = useRef(null);
    var handleMouseOver = function () { return setValue(true); };
    var handleMouseOut = function () { return setValue(false); };
    useEffect(function () {
        var node = ref.current;
        if (node) {
            node.addEventListener("mouseover", handleMouseOver);
            node.addEventListener("mouseout", handleMouseOut);
        }
        return function () {
            if (node) {
                node.removeEventListener("mouseover", handleMouseOver);
                node.removeEventListener("mouseout", handleMouseOut);
            }
        };
    }, [ref.current] // Recall only if ref changes
    );
    return [ref, value];
}
