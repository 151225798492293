import axios from "axios";
import { getAuthToken } from "../keycloak";
import { useAxios } from "./useAxios";
export var faServicesApiInstance = axios.create({
    baseURL: "/services/",
});
faServicesApiInstance.interceptors.request.use(function (config) {
    config.headers.Authorization = "Bearer ".concat(getAuthToken());
    return config;
}, function (error) { return Promise.reject(error); });
/**
 * useServiceApi
 * ---
 * Use FA's Micro-service api available at `/services` endpoint.
 * @param options
 * @param lazy [false] - true: to prevent execution onMount.
 * @example
 * const [state, execute, cancel] = useServiceApi<DataType>({url: "filemanager/action/list", method: "POST"});
 * const { isLoading, isCancelled, error, success, data} = state;
 */
export var useServicesApi = function (options, lazy) {
    if (lazy === void 0) { lazy = false; }
    return useAxios(options, faServicesApiInstance, lazy);
};
