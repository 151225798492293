var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from "@faharmony/theme";
import { theme } from "@faharmony/theme";
import { Text } from "../Text";
var StyledHorizontalDivider = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 1px;\n  background-color: ", ";\n  margin: ", "\n    0;\n  position: relative;\n\n  .text {\n    position: absolute;\n    top: 50%;\n    width: auto;\n    left: ", ";\n    right: ", ";\n    transform: translateX(\n        ", "\n      )\n      translateY(-50%);\n    color: ", ";\n    backdrop-filter: blur(", ");\n    padding: 0 ", ";\n  }\n"], ["\n  width: 100%;\n  height: 1px;\n  background-color: ", ";\n  margin: ", "\n    0;\n  position: relative;\n\n  .text {\n    position: absolute;\n    top: 50%;\n    width: auto;\n    left: ", ";\n    right: ", ";\n    transform: translateX(\n        ", "\n      )\n      translateY(-50%);\n    color: ", ";\n    backdrop-filter: blur(", ");\n    padding: 0 ", ";\n  }\n"])), theme.color.border.default, function (props) {
    return theme.spacing[props.spacing || (props.children ? "md" : "none")];
}, function (props) {
    return props.align === "center"
        ? "50%"
        : props.align === "right"
            ? "unset"
            : theme.spacing.md;
}, function (props) { return (props.align === "right" ? theme.spacing.md : "unset"); }, function (props) { return (props.align === "center" ? "-50%" : "0"); }, theme.color.text.secondary, theme.spacing.md, theme.spacing.md);
var StyledVerticalDivider = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  width: 1px;\n  max-height: 100%;\n  min-height: ", ";\n  background-color: ", ";\n  margin: 0 ", ";\n  align-self: stretch;\n"], ["\n  display: block;\n  width: 1px;\n  max-height: 100%;\n  min-height: ", ";\n  background-color: ", ";\n  margin: 0 ", ";\n  align-self: stretch;\n"])), theme.spacing.xl, theme.color.border.default, function (props) { return theme.spacing[props.spacing || "none"]; });
/**
 * Divider
 * ---
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.5.0
 * @example
 * <Divider />
 * <Divider vertical />
 */
var Divider = function (props) {
    var vertical = props.vertical, _a = props.spacing, spacing = _a === void 0 ? "none" : _a, children = props.children, _b = props.align, align = _b === void 0 ? "left" : _b;
    var dividerTitle = children ? (_jsx(Text, __assign({ variant: "h6", align: align }, { children: children.toString() }))) : null;
    if (vertical)
        return _jsx(StyledVerticalDivider, { spacing: spacing });
    return (_jsx(StyledHorizontalDivider, __assign({}, props, { className: "divider" }, { children: dividerTitle })));
};
export { Divider };
var templateObject_1, templateObject_2;
