/**
 * localStorageKeys object used to manage all available
 * key-value pair stored in browser's local storage.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 */
export var darkModeLocalStorageKey = "darkMode";
export var languageCodeLocalStorageKey = "i18nextLng";
export var localeLocalStorageKey = "locale";
var localStorageKeysList = [
    darkModeLocalStorageKey,
    "menuCollapsed",
    languageCodeLocalStorageKey,
    localeLocalStorageKey,
];
var asLocalStorageKey = function (arg) { return arg; };
export var localStorageKeys = asLocalStorageKey({
    darkMode: darkModeLocalStorageKey,
    languageCode: languageCodeLocalStorageKey,
    menuCollapsed: "menuCollapsed",
    locale: localeLocalStorageKey,
});
export var getLocalStorageValue = function (key) {
    return localStorage.getItem(key);
};
export var setLocalStorageValue = function (key, value) {
    return localStorage.setItem(key, value);
};
export var removeLocalStorageValue = function (key) {
    return localStorage.removeItem(key);
};
export var clearLocalStorage = function () { return localStorage.clear(); };
