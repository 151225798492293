var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
import { faTimes, faCheckCircle, faExclamationCircle, faInfoCircle, faTimesCircle, } from "@faharmony/icons";
import { Icon } from "../Icon";
import { StyledNotification, Content, TextContent, IconArea, IconClose, } from "./style";
/**
 * Notification
 * ---
 * Display inline or toast notification
 * @jira https://fasolutions.jira.com/browse/HAR-131
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @copyright FA Solutions Oy
 * @version 1.8.0
 * @example <Notification />
 */
var Notification = forwardRef(function (props, ref) {
    var id = props.id, className = props.className, title = props.title, description = props.description, variant = props.variant, icon = props.icon, children = props.children, onClose = props.onClose;
    var notificationIcon = function (variant) {
        if (variant === void 0) { variant = "negative"; }
        if (variant === "positive")
            return faCheckCircle;
        if (variant === "warning" || variant === "negative")
            return faExclamationCircle;
        if (variant === "info")
            return faInfoCircle;
        return icon || faTimesCircle;
    };
    return (_jsx(StyledNotification, __assign({ ref: ref, variant: variant, id: id, className: className }, { children: _jsxs(Content, __assign({ variant: variant }, { children: [_jsx(IconArea, __assign({ variant: variant }, { children: variant && _jsx(Icon, { icon: notificationIcon(variant), size: "1x" }) })), _jsxs(TextContent, __assign({ variant: variant }, { children: [_jsx("p", __assign({ className: "toast-title" }, { children: title })), description && (_jsx("small", __assign({ className: "toast-message" }, { children: description }))), children && _jsx("div", { children: children })] })), onClose && (_jsx(IconClose, __assign({ onClick: function () { return onClose(props); }, variant: variant }, { children: _jsx(Icon, { icon: faTimes, size: "1x" }) })))] })) })));
});
export { Notification };
