var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { faEllipsisH, faArrowLeft } from "@faharmony/icons";
import { usePropState } from "@faharmony/helpers";
import { Box } from "@faharmony/theme";
import { Button } from "@faharmony/components";
import Crumb from "./Crumb";
var useBreadcrumbsCollapse = function (crumbList, collapseThreshold) {
    if (collapseThreshold === void 0) { collapseThreshold = 4; }
    var _a = __read(usePropState(crumbList), 1), crumbs = _a[0];
    var _b = __read(usePropState(crumbs.length > collapseThreshold), 2), isCollapsed = _b[0], setCollapsed = _b[1];
    var breadcrumbs = useMemo(function () {
        return isCollapsed
            ? [
                crumbs[0],
                {
                    id: "collapsed",
                    icon: faEllipsisH,
                    tooltip: "View ".concat(crumbs.length - 2, " collapsed crumbs"),
                    onClick: function () { return setCollapsed(false); },
                },
                crumbs[crumbs.length - 1],
            ]
            : crumbs;
    }, [crumbs, isCollapsed]);
    return breadcrumbs;
};
/**
 * Breadcrumbs
 * ---
 * Renders a breadcrumb list with a possibility of back button.
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.6.0
 * @example
 * <Breadcrumbs crumbs={[{
 *    id: 'crumb1'
 *    label: 'Crumb 1',
 *    icon: 'faLink'
 *    onClick: () => {},
 *    tooltip: "This is a crumb"
 * }]} />
 */
var Breadcrumbs = function (props) {
    var crumbs = useBreadcrumbsCollapse(props.crumbs, props.collapseThreshold);
    var renderedCrumbs = React.useMemo(function () { return (_jsx(Box, __assign({ wrap: crumbs.length > 4 ? "wrap" : "nowrap", spacing: "sm" }, { children: crumbs.map(function (crumb, index) { return (_jsx(Crumb, { crumb: crumb, variant: props.variant || "h5", separator: props.separator || "/", lastCrumb: index === crumbs.length - 1 }, crumb.id)); }) }))); }, [crumbs]);
    if (crumbs.length > 0)
        if (props.onClickBackButton) {
            return (_jsxs(Box, __assign({ justifyContent: "start" }, { children: [_jsx(Button, { icon: faArrowLeft, tooltip: "Back", spacing: "xs", variant: "secondary", disabled: crumbs.length === 1, onClick: props.onClickBackButton }), renderedCrumbs] })));
        }
        else
            return renderedCrumbs;
    else {
        console.warn("Breadcrumbs could not be rendered since no crumbs were passed.");
        return null;
    }
};
export { Breadcrumbs };
