var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from "@mui/material/styles";
import { addDialogMUI } from "@faharmony/core";
import { useModuleTranslation } from "@faharmony/module";
import { Autocomplete, Divider, IconButton, TextField, Tooltip, Typography, Stack, Badge, ClickAwayListener, Drawer, CircularProgress, } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import RestoreIcon from "@mui/icons-material/Restore";
import { useRef, useState, useMemo } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/system";
import { DataGridPro } from "@mui/x-data-grid-pro";
var StyledTextField = styled(TextField)({
    "& .MuiInputBase-input": {
        fontWeight: 800,
    },
});
var DeletePreferenceButton = function (_a) {
    var onDeletePreference = _a.onDeletePreference, preferenceName = _a.preferenceName, t = _a.t;
    return (_jsx(Tooltip, __assign({ title: t("components.gridPreferenceSelector.deleteButton.tooltip") }, { children: _jsx("span", { children: _jsx(IconButton, __assign({ onClick: function (e) {
                    e.stopPropagation();
                    addDialogMUI({
                        title: t("components.gridPreferenceSelector.deleteDialog.title"),
                        content: (_jsxs(Typography, { children: [t("components.gridPreferenceSelector.deleteDialog.content"), _jsx(Typography, __assign({ component: "span", fontWeight: "bold" }, { children: " ".concat(preferenceName) })), "?"] })),
                        confirmationText: t("components.gridPreferenceSelector.deleteDialog.confirmButton.label"),
                        cancellationText: t("components.gridPreferenceSelector.deleteDialog.cancelButton.label"),
                        confirmationButtonProps: {
                            variant: "contained",
                            color: "error",
                        },
                        cancellationButtonProps: {
                            variant: "outlined",
                        },
                    })
                        .then(function () {
                        if (preferenceName) {
                            onDeletePreference(preferenceName);
                        }
                    })
                        .catch(function () { });
                } }, { children: _jsx(DeleteIcon, {}) })) }) })));
};
var DefaultPreferenceButton = function (_a) {
    var onSetDefaultPreference = _a.onSetDefaultPreference, isDefault = _a.isDefault, t = _a.t, _b = _a.displayTooltip, displayTooltip = _b === void 0 ? true : _b;
    var tooltipTitle;
    if (!displayTooltip) {
        tooltipTitle = undefined;
    }
    else if (isDefault) {
        tooltipTitle = t("components.gridPreferenceSelector.defaultButton.tooltipActive");
    }
    else {
        tooltipTitle = t("components.gridPreferenceSelector.defaultButton.tooltip");
    }
    return (_jsx(Tooltip, __assign({ title: tooltipTitle }, { children: _jsx("span", { children: _jsx(IconButton, __assign({ onClick: function (e) {
                    e.stopPropagation();
                    onSetDefaultPreference();
                } }, { children: isDefault ? _jsx(StarIcon, {}) : _jsx(StarBorderIcon, {}) })) }) })));
};
var SaveAsButton = function (_a) {
    var hasModifiedSelectedPreference = _a.hasModifiedSelectedPreference, onSaveAsPreference = _a.onSaveAsPreference, preferenceName = _a.preferenceName, inputRef = _a.inputRef, t = _a.t, _b = _a.loading, loading = _b === void 0 ? false : _b;
    var title = preferenceName
        ? t("components.gridPreferenceSelector.saveDialog.title1")
        : t("components.gridPreferenceSelector.saveDialog.title");
    return (_jsx(Tooltip, __assign({ title: t("components.gridPreferenceSelector.saveButton.tooltip") }, { children: _jsx(IconButton, __assign({ disabled: loading, onClick: function (e) {
                e.stopPropagation();
                addDialogMUI({
                    title: title,
                    content: (_jsx(Stack, __assign({ justifyContent: "center", flexGrow: 1, p: 2 }, { children: _jsx(TextField, { inputRef: inputRef, label: t("components.gridPreferenceSelector.saveDialog.textFieldLabel"), defaultValue: preferenceName !== null && preferenceName !== void 0 ? preferenceName : "", helperText: preferenceName
                                ? t("components.gridPreferenceSelector.saveDialog.textFieldHelperText")
                                : undefined }) }))),
                    confirmationText: t("components.gridPreferenceSelector.saveDialog.confirmButton.label"),
                    cancellationText: t("components.gridPreferenceSelector.saveDialog.cancelButton.label"),
                    confirmationButtonProps: {
                        variant: "contained",
                        color: "primary",
                    },
                    cancellationButtonProps: {
                        variant: "outlined",
                    },
                })
                    .then(function () { return __awaiter(void 0, void 0, void 0, function () {
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                if (!((_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.value)) return [3 /*break*/, 2];
                                return [4 /*yield*/, onSaveAsPreference(inputRef.current.value)];
                            case 1:
                                _b.sent();
                                _b.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); })
                    .catch(function () { });
            } }, { children: _jsx(Badge, __assign({ color: "secondary", variant: "dot", invisible: !hasModifiedSelectedPreference }, { children: loading ? _jsx(CircularProgress, { size: 19 }) : _jsx(SaveIcon, {}) })) })) })));
};
var InlineRenamePreference = function (_a) {
    var onRenamePreference = _a.onRenamePreference, preferenceName = _a.preferenceName, t = _a.t;
    var _b = __read(useState(false), 2), isEditing = _b[0], setIsEditing = _b[1];
    var _c = __read(useState(preferenceName), 2), newName = _c[0], setNewName = _c[1];
    var handleRename = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.stopPropagation();
                    if (!(preferenceName && newName)) return [3 /*break*/, 2];
                    setIsEditing(false);
                    return [4 /*yield*/, onRenamePreference(preferenceName, newName)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var handleClose = function (event) {
        event.stopPropagation();
        setIsEditing(false);
        setNewName(preferenceName);
    };
    var handleEdit = function (event) {
        event.stopPropagation();
        setIsEditing(true);
    };
    return isEditing ? (_jsx(ClickAwayListener, __assign({ onClickAway: handleClose }, { children: _jsxs(Box, __assign({ sx: {
                display: "flex",
                alignItems: "center",
            } }, { children: [_jsx(TextField, { variant: "standard", value: newName, onChange: function (e) { return setNewName(e.target.value); }, onKeyDown: function (e) {
                        e.stopPropagation();
                        if (e.key === "Enter") {
                            handleRename(e);
                        }
                        else if (e.key === "Escape") {
                            handleClose(e);
                        }
                    }, autoFocus: true, sx: { minWidth: 50, maxWidth: 200 } }), _jsx(Tooltip, __assign({ title: t("components.gridPreferenceSelector.renameAcceptButton.tooltip") }, { children: _jsx(IconButton, __assign({ size: "small", onClick: handleRename }, { children: _jsx(CheckIcon, { fontSize: "small", color: "success" }) })) })), _jsx(Tooltip, __assign({ title: t("components.gridPreferenceSelector.renameCloseButton.tooltip") }, { children: _jsx(IconButton, __assign({ size: "small", onClick: handleClose }, { children: _jsx(CloseIcon, { fontSize: "small", color: "error" }) })) }))] })) }))) : (_jsxs(Box, __assign({ sx: {
            display: "flex",
            alignItems: "center",
            "&:hover .edit-button": { opacity: 1 },
        }, onClick: handleEdit }, { children: [_jsx("div", { children: preferenceName }), _jsx(Tooltip, __assign({ title: t("components.gridPreferenceSelector.renameButton.tooltip") }, { children: _jsx(IconButton, __assign({ size: "small", sx: { opacity: 0, ml: 1, transition: "opacity 0.3s" }, className: "edit-button", onClick: handleEdit }, { children: _jsx(EditIcon, { fontSize: "small" }) })) }))] })));
};
var RestorePreferenceButton = function (_a) {
    var onRestoreSelectedPreference = _a.onRestoreSelectedPreference, hasModifiedSelectedPreference = _a.hasModifiedSelectedPreference, t = _a.t, _b = _a.loading, loading = _b === void 0 ? false : _b;
    return (_jsx(Tooltip, __assign({ title: t("components.gridPreferenceSelector.restoreButton.tooltip") }, { children: _jsx("span", { children: _jsx(IconButton, __assign({ onClick: function () {
                    onRestoreSelectedPreference();
                }, disabled: !hasModifiedSelectedPreference || loading }, { children: _jsx(RestoreIcon, {}) })) }) })));
};
export var GridPreferenceSelector = function (_a) {
    var onSaveAsPreference = _a.onSaveAsPreference, selectablePreferences = _a.selectablePreferences, onSetSelectedPreference = _a.onSetSelectedPreference, selectedGridPreference = _a.selectedGridPreference, onDeletePreference = _a.onDeletePreference, defaultPreference = _a.defaultPreference, onSetDefaultPreference = _a.onSetDefaultPreference, onRestorePreference = _a.onRestorePreference, hasModifiedSelectedPreference = _a.hasModifiedSelectedPreference, onRenamePreference = _a.onRenamePreference, preferencesLoading = _a.preferencesLoading, submitting = _a.submitting;
    var inputRef = useRef(null);
    var _b = __read(useState(false), 2), drawerOpen = _b[0], setDrawerOpen = _b[1];
    var t = useModuleTranslation();
    return (_jsxs(Stack, __assign({ flexDirection: "row", columnGap: 2, width: "100%" }, { children: [_jsx(Autocomplete, { loading: preferencesLoading || submitting, size: "small", sx: { width: "100%", minWidth: 300, maxWidth: 400 }, id: "id", options: selectablePreferences, value: selectedGridPreference, onChange: function (_event, value) {
                    onSetSelectedPreference(value);
                }, renderInput: function (params) { return (_jsx(StyledTextField, __assign({}, params, { label: "Saved views" }))); }, renderOption: function (props, option) { return (_jsxs(Stack, __assign({ component: "li" }, props, { columnGap: 1, flexDirection: "row" }, { children: [option, _jsx(Divider, { orientation: "vertical" }), onSetDefaultPreference && (_jsx(Box, __assign({ ml: "auto" }, { children: _jsx(DefaultPreferenceButton, { onSetDefaultPreference: function () { return onSetDefaultPreference(option); }, isDefault: option === defaultPreference, t: t, displayTooltip: false }) })))] }))); } }), _jsx(SaveAsButton, { loading: submitting, hasModifiedSelectedPreference: hasModifiedSelectedPreference, onSaveAsPreference: onSaveAsPreference, preferenceName: selectedGridPreference, inputRef: inputRef, t: t }), _jsx(RestorePreferenceButton, { onRestoreSelectedPreference: function () {
                    if (selectedGridPreference) {
                        onRestorePreference(selectedGridPreference);
                    }
                }, hasModifiedSelectedPreference: hasModifiedSelectedPreference, t: t, loading: submitting }), _jsx(Tooltip, __assign({ title: "Manage views" }, { children: _jsx(IconButton, __assign({ disabled: submitting, onClick: function () {
                        setDrawerOpen(true);
                    } }, { children: _jsx(TuneIcon, {}) })) })), _jsx(GridPreferenceDrawer, { submitting: submitting, open: drawerOpen, preferences: selectablePreferences, closeDrawer: function () {
                    setDrawerOpen(false);
                }, onDeletePreference: onDeletePreference, onRenamePreference: onRenamePreference, onSetDefaultPreference: onSetDefaultPreference, defaultPreference: defaultPreference })] })));
};
var GridPreferenceDrawer = function (_a) {
    var open = _a.open, preferences = _a.preferences, closeDrawer = _a.closeDrawer, onDeletePreference = _a.onDeletePreference, onRenamePreference = _a.onRenamePreference, onSetDefaultPreference = _a.onSetDefaultPreference, defaultPreference = _a.defaultPreference, submitting = _a.submitting;
    var t = useModuleTranslation();
    // Structure the data into an array of objects
    var rows = useMemo(function () {
        return preferences === null || preferences === void 0 ? void 0 : preferences.map(function (preferenceName) { return ({
            id: preferenceName,
            viewName: preferenceName,
            actions: preferenceName,
        }); });
    }, [preferences]);
    var columns = useMemo(function () { return [
        {
            field: "viewName",
            headerName: "View name",
            minWidth: 200,
            flex: 1,
            renderCell: function (params) { return (_jsx(InlineRenamePreference, { preferenceName: params.value, onRenamePreference: onRenamePreference, t: t })); },
        },
        {
            field: "actions",
            headerName: "Actions",
            renderCell: function (params) { return (_jsxs(Stack, __assign({ direction: "row" }, { children: [_jsx(DeletePreferenceButton, { onDeletePreference: onDeletePreference, preferenceName: params.value, t: t }), _jsx(DefaultPreferenceButton, { onSetDefaultPreference: function () {
                            if (params.value) {
                                onSetDefaultPreference(params.value);
                            }
                        }, isDefault: params.value === defaultPreference, t: t })] }))); },
        },
    ]; }, [
        defaultPreference,
        onDeletePreference,
        onRenamePreference,
        onSetDefaultPreference,
    ]);
    return (_jsx(Drawer, __assign({ anchor: "right", open: open, onClose: closeDrawer, PaperProps: {
            sx: { width: "50%", maxWidth: "900px", minWidth: "400px" },
        } }, { children: _jsx("div", __assign({ style: { height: "100%", width: "100%" } }, { children: _jsx(DataGridPro, { loading: submitting, rows: rows !== null && rows !== void 0 ? rows : [], columns: columns, initialState: {
                    pinnedColumns: { right: ["actions"] },
                }, hideFooter: true }) })) })));
};
