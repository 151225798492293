var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from "react";
import { faServicesApiInstance } from "@faharmony/service";
import useThemeAction from "./theme";
// import useLanguageSwitcher from "./language";
// import { faPlay } from "@faharmony/icons";
var faSignOut = //light
 "M48 64h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-8.8 0-16 7.2-16 16v288c0 8.8 7.2 16 16 16h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48zm279 19.5l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l132 131.4H172c-6.6 0-12 5.4-12 12v10c0 6.6 5.4 12 12 12h279.9L320 404.4c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l164.5-164c4.7-4.7 4.7-12.3 0-17L344 83.5c-4.7-4.7-12.3-4.7-17 0z";
export default (function (props) {
    var devMode = props._harmony || props.devMode;
    var themeAction = useThemeAction();
    // const languageSwitchActions = useLanguageSwitcher(multilingual);
    // const { openTaskManager } = TaskManagerModal()
    // const taskManagerAction: IFABarActionButtonProps =
    // {
    //   id: "task-manager",
    //   iconPath: faPlay.icon["4"] as string,
    //   label: "Task Manager",
    //   onClick: () => openTaskManager(""),
    //   position: 0,
    // }
    var _a = __read(React.useState([]), 2), actions = _a[0], setActions = _a[1];
    React.useEffect(function () {
        devMode
            ? // Set dummy actions in devMode
                setActions([])
            : // Fetch if not in devMode
                faServicesApiInstance
                    .get("navigator/actions/")
                    .then(function (_a) {
                    var data = _a.data;
                    return data && Array.isArray(data) && setActions(data);
                });
    }, [devMode]);
    var logoutAction = devMode
        ? []
        : [
            {
                id: "logout",
                iconPath: faSignOut,
                label: "Log out",
                onClick: props.logout,
                position: 100,
            },
        ];
    return __spreadArray(__spreadArray([
        // taskManagerAction,
        themeAction
    ], __read(actions
        .filter(function (a) { return a.id !== "log-out"; })
        .sort(function (_a, _b) {
        var a = _a.position;
        var b = _b.position;
        return a - b;
    })), false), __read(logoutAction), false);
});
