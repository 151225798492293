var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/**
 * GlobalBar component for harmony.
 *
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @copyright FA Solutions Oy
 * @since v1.6.0
 */
import { FABar } from "@faharmony/navigation";
import useGlobalActions from "./actions";
import useGlobalApps from "./apps";
import { useValidKeycloak } from "./keycloak";
import { useDatadogRum } from "./datadogrum";
// import { createManifest } from "./webmanifest";
export var GlobalBar = function (props) {
    var apps = useGlobalApps(props._harmony || props.devMode);
    var actions = useGlobalActions(__assign(__assign({}, props), useValidKeycloak(props)));
    useDatadogRum(apps);
    return (_jsx(_Fragment, { children: _jsx(FABar, { apps: apps, actions: actions }) }));
};
