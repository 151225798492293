import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "@faharmony/components";
import { openExternalLink } from "@faharmony/router";
import { getIconDefinition } from "@faharmony/icons";
export var isAppActive = function (_a) {
    var url = _a.url;
    var urlWithoutTrailingSlash = url.endsWith("/")
        ? url.slice(0, url.length - 1)
        : url;
    return window.location.pathname.startsWith(urlWithoutTrailingSlash);
};
export var ActionButton = function (action) { return (_jsx(Button, { icon: getIconDefinition(action.iconPath), tooltip: action.label, variant: "secondary", id: "faGlobalBarAction-" + action.id, onClick: action.onClick ||
        (function () {
            return action.url
                ? openExternalLink(action.url, action.openInNewWindow === false ? "_self" : "_blank")
                : {};
        }) }, action.id)); };
