/**
 * Localization of numbers
 * with ECMAScript Internationalization API (Intl)
 *
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @since 1.7.0
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { cleanupLocale, defaultLocale } from "./utilities";
/** Format a number to a local string
 * @param value Number to format
 * @param options Options to customize formatting of numbers
 * @example
 *  const formattedString = formatNumber(98765.43210);
 */
export var formatNumber = function (value, options) {
    if (options === void 0) { options = {}; }
    var locale = options.locale, others = __rest(options, ["locale"]);
    var instance = new Intl.NumberFormat([cleanupLocale(locale), defaultLocale], __assign({ minimumFractionDigits: 2, maximumFractionDigits: 2 }, others));
    return instance.format(value).replaceAll("−", "-");
};
/** Format a number to a local currency string
 * @param value Number to format
 * @param currency 3-digit currency code from https://www.iban.com/currency-codes (default: EUR)
 * @param options Options to customize formatting of numbers
 * @default "EUR"
 * @example
 *  const formattedString = formatCurrency(98765.43210, "USD");
 */
export var formatCurrency = function (value, currency, options) {
    if (currency === void 0) { currency = "EUR"; }
    return formatNumber(value, __assign(__assign({}, options), { style: "currency", currency: currency }));
};
/** Format a number to a local percentage string
 * @param value Number to format
 * @param options Options to customize formatting of numbers
 * @example
 *  const formattedString = formatPercentage(0.98765);
 */
export var formatPercentage = function (value, options) { return formatNumber(value, __assign(__assign({}, options), { style: "percent" })); };
/** Format a number to a local units string
 * @param value Number to format
 * @param unit The unit to use in unit formatting,
 * Possible values are core unit identifiers, defined [here](https://tc39.es/proposal-unified-intl-numberformat/section6/locales-currencies-tz_proposed_out.html#sec-issanctionedsimpleunitidentifier).
 * Pairs of simple units can be concatenated with "-per-" to make a compound unit.
 * @param options Options to customize formatting of numbers
 * @example
 *  const formattedString = formatUnit(98765.43210, "megabyte");
 */
export var formatUnit = function (value, unit, options) { return formatNumber(value, __assign(__assign({}, options), { style: "unit", unit: unit })); };
