var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled, theme, Box } from "@faharmony/theme";
export var maxHeight = 31;
export var maxWidth = 240;
var getTabsBarBackground = function (errorCount, orientation, isSummaryView) {
    var shadowBackground = theme.color.background.overlay;
    var shadowBackgroundHorizontal = "#00000070";
    var shadowBackgroundHorizontalError = "rgb(255,0,0, 0.5)";
    var shadowBackgroundError = "rgb(255,0,0, 0.3)";
    var background = isSummaryView
        ? theme.color.background.primary
        : theme.color.background.default;
    if (orientation === "vertical") {
        return " \n    background: \n    linear-gradient( ".concat(background, ", ").concat(background, ", transparent 40px),\n    radial-gradient(farthest-side at 50% 0,   ").concat(errorCount.start > 0 ? shadowBackgroundError : shadowBackground, ",transparent),\n    linear-gradient(to top, ").concat(background, ", ").concat(background, ", transparent 40px),\n    radial-gradient(farthest-side at 50% 100%,  ").concat(errorCount.end > 0 ? shadowBackgroundError : shadowBackground, ", transparent) 0 100%;\n    background-size: 100% 100%, 100% 20px, 100% 100%, 100% 20px;\n    ");
    }
    else {
        return "\n    background: \n    linear-gradient(to right,".concat(background, ", ").concat(background, ", transparent  24px ),\n    radial-gradient(farthest-side at 0 50%,  ").concat(errorCount.start > 0
            ? shadowBackgroundHorizontalError
            : shadowBackgroundHorizontal, ", transparent),\n    linear-gradient(to left, ").concat(background, ", ").concat(background, ", transparent 24px),\n    radial-gradient(farthest-side at 100% 50%, ").concat(errorCount.end > 0
            ? shadowBackgroundHorizontalError
            : shadowBackgroundHorizontal, ", transparent) 100%;\n    background-size: 100% 100%, 12px 100%, 100% 100%, 12px 100%;\n    ");
    }
};
export var StyledTabBar = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n   {\n    align-items: end;\n    position: relative;\n    height: ", ";\n    width: ", ";\n    max-width: ", ";\n    overflow-y: scroll;\n    scroll-padding: 8px;\n    scroll-behavior: smooth;\n    ", "\n    background-repeat: no-repeat;\n    background-attachment: local, scroll, local, scroll;\n  }\n\n  .noMargin {\n    margin: 0 !important;\n  }\n\n  .tab {\n    :first-child .sperator-left {\n      border-left: none !important;\n      margin-right: 0px;\n    }\n\n    ", "\n  }\n\n  .tabs-list__underline {\n    margin-left: 0px !important;\n    margin-top: 0 !important;\n    position: absolute;\n    bottom: 0;\n    width: 2px;\n    height: 2px;\n    background-color: ", ";\n  }\n\n  & ::-webkit-scrollbar,\n  ::-webkit-scrollbar:horizontal,\n  ::-webkit-scrollbar:vertical {\n    width: 0 !important;\n    height: 0 !important;\n  }\n"], ["\n   {\n    align-items: end;\n    position: relative;\n    height: ", ";\n    width: ", ";\n    max-width: ", ";\n    overflow-y: scroll;\n    scroll-padding: 8px;\n    scroll-behavior: smooth;\n    ", "\n    background-repeat: no-repeat;\n    background-attachment: local, scroll, local, scroll;\n  }\n\n  .noMargin {\n    margin: 0 !important;\n  }\n\n  .tab {\n    :first-child .sperator-left {\n      border-left: none !important;\n      margin-right: 0px;\n    }\n\n    ", "\n  }\n\n  .tabs-list__underline {\n    margin-left: 0px !important;\n    margin-top: 0 !important;\n    position: absolute;\n    bottom: 0;\n    width: 2px;\n    height: 2px;\n    background-color: ", ";\n  }\n\n  & ::-webkit-scrollbar,\n  ::-webkit-scrollbar:horizontal,\n  ::-webkit-scrollbar:vertical {\n    width: 0 !important;\n    height: 0 !important;\n  }\n"])), function (p) {
    return p.orientation === "horizontal" ? maxHeight + "px" : "100%";
}, function (p) { return (p.orientation === "vertical" ? "fit-content" : "100%"); }, function (p) {
    return p.orientation === "horizontal" ? "100%" : maxWidth + "px";
}, function (p) {
    return p.isOverflow
        ? getTabsBarBackground(p.errorCount, p.orientation, p.isSummaryView)
        : null;
}, function (p) {
    return "\n      :hover + .tab .sperator-left, :nth-child(".concat(p.activeIndex + 2, ") .sperator-left {\n          border-color: transparent;\n        }\n    ");
}, theme.color.border.selected);
export var StyledTabBarContainer = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n   {\n    height:100%;\n    width: fit-content;\n\n    ", "\n    }\n    \n  }\n"], ["\n   {\n    height:100%;\n    width: fit-content;\n\n    ", "\n    }\n    \n  }\n"])), function (p) {
    if (p.orientation === "horizontal") {
        return "\n          width:100%;\n          display: grid;\n          grid-template-columns: 1fr min-content;\n        ";
    }
    return undefined;
});
export var StyledTab = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 0 !important;\n  border-bottom: ", ";\n\n  display: flex;\n\n  border-right: ", ";\n\n  width: ", ";\n\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n\n  height: ", ";\n  max-width: ", "px;\n \n  padding: ", ";\n\n  cursor: ", ";\n\n  background: ", ";\n\n  color: ", ";\n\n  border-color: ", ";\n\n   \n  :hover {\n    background: ", ";\n  }\n\n  .sperator-left {\n    border-left: ", ";\n    padding: 4px;\n    margin-right: 0px;\n    $}\n  }\n"], ["\n  margin-left: 0 !important;\n  border-bottom: ", ";\n\n  display: flex;\n\n  border-right: ", ";\n\n  width: ", ";\n\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n\n  height: ", ";\n  max-width: ", "px;\n \n  padding: ", ";\n\n  cursor: ", ";\n\n  background: ", ";\n\n  color: ", ";\n\n  border-color: ", ";\n\n   \n  :hover {\n    background: ", ";\n  }\n\n  .sperator-left {\n    border-left: ", ";\n    padding: 4px;\n    margin-right: 0px;\n    $}\n  }\n"])), function (p) {
    return p.orientation === "vertical" ? 0 : "1px solid";
}, function (p) {
    if (p.orientation === "horizontal")
        return 0;
    return "1px solid";
}, function (p) {
    if (p.orientation === "vertical")
        return "100% !important";
    return "max-content";
}, function (p) {
    if (p.orientation === "vertical")
        return "0";
    return theme.spacing.sm;
}, function (p) {
    if (p.orientation === "vertical")
        return "0";
    return theme.spacing.sm;
}, function (p) {
    if (p.orientation === "vertical")
        return "fit-content";
    return "".concat(maxHeight, " px");
}, maxWidth, function (p) {
    if (p.orientation === "vertical")
        return "8px ".concat(theme.spacing.md);
    return "".concat(theme.spacing.sm, " 8px ").concat(theme.spacing.sm, " 0px");
}, function (p) { return (p.disabled ? "not-allowed" : "pointer"); }, function (p) {
    if (p.active)
        return theme.color.background.selected;
    return "transparent";
}, function (p) {
    if (p.error) {
        return theme.color.text.negative;
    }
    else if (p.active) {
        return theme.color.text.primary;
    }
    else if (p.disabled) {
        return theme.color.text.disabled;
    }
    else {
        return theme.color.text.primary;
    }
}, function (p) {
    if (p.error) {
        return theme.color.border.negative;
    }
    else {
        return theme.color.border.default;
    }
}, theme.color.background.hover, function (p) {
    if (p.active) {
        return "none";
    }
    return "2px solid ".concat(theme.color.border.default);
});
export var StyledEmptyBox = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (p) {
    return p.orientation === "vertical"
        ? "width: 100%;\n     height : -webkit-fill-available;\n     border-right: 1px solid ".concat(theme.color.border.default, "; \n     ")
        : "\n    width:  -webkit-fill-available;\n    border-bottom: 1px solid ".concat(theme.color.border.default, ";\n    \n    \n    \n    ");
});
export var StyledMoreAction = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 0px !important;\n  padding: 4px;\n  margin-left: 0px !important;\n  flex-grow: 1;\n  width: ", ";\n  align-self: center;\n"], ["\n  margin: 0px !important;\n  padding: 4px;\n  margin-left: 0px !important;\n  flex-grow: 1;\n  width: ", ";\n  align-self: center;\n"])), function (p) { return (p.orientation === "horizontal" ? "fit-content" : "100%"); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
