var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useResponsive } from "@faharmony/helpers";
import { styled, theme, Box } from "@faharmony/theme";
import { Text, Button, Divider, ButtonPopover } from "@faharmony/components";
import { TextField } from "@faharmony/form";
// keyPressCombos,
// getKeyPressComboString,
import { faChevronSquareLeft, faChevronSquareRight, faFilter, faTimesCircle, } from "@faharmony/icons";
import { useVisibleText } from "./helpers";
var StyledFooter = styled.footer(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: inherit;\n  background: transparent;\n  overflow: hidden;\n  padding: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n\n  .menuCollapsedHeading {\n    height: auto;\n    overflow: hidden;\n    writing-mode: vertical-rl;\n    transform: rotate(180deg);\n    justify-self: center;\n    margin: ", " 0;\n  }\n\n  > .NavFooter-Actions {\n    margin-top: ", ";\n    padding-left: ", ";\n  }\n"], ["\n  width: inherit;\n  background: transparent;\n  overflow: hidden;\n  padding: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n\n  .menuCollapsedHeading {\n    height: auto;\n    overflow: hidden;\n    writing-mode: vertical-rl;\n    transform: rotate(180deg);\n    justify-self: center;\n    margin: ", " 0;\n  }\n\n  > .NavFooter-Actions {\n    margin-top: ", ";\n    padding-left: ", ";\n  }\n"])), function (props) {
    return "0 ".concat(theme.spacing[props.isMenuCollapsed ? "sm" : "md"], " ").concat(theme.spacing.md);
}, theme.spacing.md, theme.spacing.md, function (props) {
    return props.isMenuCollapsed
        ? "calc(".concat(theme.spacing.md, " - ").concat(theme.spacing.xs, ")")
        : 0;
});
/**
 * Navigation Footer
 * ---
 * Builds the Footer containing
 * CollapseButton and FilterButton
 *
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 */
var Footer = function (props) {
    var descriptionText = props.descriptionText, isMenuCollapsed = props.isMenuCollapsed, toggleMenuCollapse = props.toggleMenuCollapse, handleFiltering = props.handleFiltering, _a = props.enableMenuFilter, enableMenuFilter = _a === void 0 ? false : _a, _b = props.footerActions, footerActions = _b === void 0 ? [] : _b;
    var isMobile = useResponsive().isMobile;
    var ref = React.useRef(null);
    var _c = useVisibleText(ref, isMenuCollapsed, props.heading, props.shortHeading), isHidden = _c.isHidden, visibleText = _c.visibleText;
    var _d = __read(React.useState(false), 2), isFiltering = _d[0], setIsFiltering = _d[1];
    return (_jsxs(StyledFooter, __assign({}, props, { ref: ref }, { children: [(!isMenuCollapsed || isHidden) && _jsx(Divider, { spacing: "none" }), isMenuCollapsed && (_jsx("div", __assign({ className: "menuCollapsedHeading" }, { children: isMobile ? (_jsx("div", {})) : (_jsx(Text, { value: visibleText, variant: "h1", truncate: true })) }))), _jsxs(Box, __assign({ justifyContent: "space-between", wrap: "nowrap", className: "NavFooter-Actions" }, { children: [_jsx(Button, { icon: isMenuCollapsed ? faChevronSquareRight : faChevronSquareLeft, 
                        // tooltip={`${
                        //   isMenuCollapsed ? "Expand" : "Collapse"
                        // } [${getKeyPressComboString(keyPressCombos.collapseNavigationMenu)}]`}
                        tooltip: "".concat(isMenuCollapsed ? "Expand" : "Collapse"), spacing: "sm", onClick: toggleMenuCollapse }, "CollapseMenu"), !isMenuCollapsed && !isFiltering && (_jsxs(React.Fragment, { children: [footerActions.map(function (action) { return (_createElement(ButtonPopover, __assign({}, action, { key: action.id, spacing: "sm" }))); }), descriptionText && (_jsx(Text, { variant: "small", color: "disabled", value: descriptionText, align: "center" }))] })), enableMenuFilter &&
                        !isMenuCollapsed &&
                        (isFiltering ? (_jsxs(React.Fragment, { children: [_jsx(TextField, { id: "navFilterInput", spacing: "sm", onChange: function (e) {
                                        var val = e.target.value;
                                        handleFiltering(val);
                                    }, placeholder: "Filter navigation menu", autoFocus: true }), _jsx(Button, { icon: faTimesCircle, tooltip: "Close filter", spacing: "sm", onClick: function () {
                                        setIsFiltering(false);
                                        handleFiltering("");
                                    } }, "CloseFilter")] })) : (_jsx(Button, { icon: faFilter, tooltip: "Filter menu items", spacing: "sm", onClick: function () { return setIsFiltering(true); } }, "FilterMenu")))] }))] })));
};
export default Footer;
var templateObject_1;
