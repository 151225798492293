var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useResponsive } from "@faharmony/helpers";
import { Box, Center } from "@faharmony/theme";
import { Text, Divider } from "@faharmony/components";
import { useTabs, useStepper, } from "@faharmony/navigation";
import { Form } from "@faharmony/form";
import { Div100vh } from "../layouts/Div100vh";
import { StyledFooter, StyledView, StyledBody } from "./style";
import { Header } from "./Header";
import { MuiPageView } from "@faharmony/mui";
/**
 * PageView
 * ---
 * Page View is the primary view which is used as base for all pages in FA App.
 *
 * It should NOT be used as base for Modals. See SummaryView/ContentView for that.
 *
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @version 1.7.0
 * @example
 * <PageView headerTitle="Heading"> {content} </PageView>
 */
var PageView = function (props) {
    var footerContent = props.footerContent, _a = props.overflow, overflow = _a === void 0 ? "hidden" : _a, heading = props.heading, tabs = props.tabs, children = props.children, stepper = props.stepper;
    var isMobile = useResponsive().isMobile;
    var defaultContent = children || (_jsx(Center, { children: _jsx(Text, { value: "No content available.", color: "secondary" }) }));
    var defaultTab = {
        id: "default",
        label: "default",
        disabled: true,
        defaultActive: true,
        content: defaultContent,
    };
    var defaultStep = {
        label: "Default Step",
        completed: false,
        state: "active",
        content: defaultContent,
    };
    var defaultStepper = {
        steps: [defaultStep],
        currentStep: 0,
    };
    var _b = useTabs(heading, props.tabs || [defaultTab]), TabBar = _b.TabBar, TabPanel = _b.TabPanel, orientation = _b.orientation;
    var _c = useStepper(stepper || defaultStepper), StepsBarWithButtons = _c.StepsBarWithButtons, StepPanel = _c.StepPanel;
    var formMethods = stepper === null || stepper === void 0 ? void 0 : stepper.formMethods;
    var panelContent = function () {
        if (tabs) {
            if (Array.isArray(tabs)) {
                return _jsx(TabPanel, {});
            }
            else {
                if ("formMethods" in tabs) {
                    return (_jsx(Form, __assign({ formMethods: tabs.formMethods }, { children: _jsx(TabPanel, {}) })));
                }
                else {
                    return _jsx(TabPanel, {});
                }
            }
        }
        else if (stepper === null || stepper === void 0 ? void 0 : stepper.steps) {
            return formMethods ? (_jsx(Form, __assign({ formMethods: formMethods }, { children: _jsx(StepPanel, {}) }))) : (_jsx(StepPanel, {}));
        }
        return defaultContent;
    };
    var content = tabs && orientation === "vertical" ? (_jsx(StyledBody, __assign({ className: "PageView-body", style: { marginTop: -8 } }, { children: _jsxs(Box, __assign({ direction: "row", height: "100%", width: "100%" }, { children: [_jsx(TabBar, {}), _jsx(Box, __assign({ style: { display: "block ", paddingTop: 8, height: "100%" } }, { children: panelContent() }))] })) }))) : (_jsx(StyledBody, __assign({ overflow: overflow, className: "PageView-body" }, { children: panelContent() })));
    var footer = (footerContent || stepper) && (_jsxs(StyledFooter, { children: [_jsx(Divider, {}), _jsx("div", __assign({ className: "PageView-footer" }, { children: stepper && stepper.steps ? _jsx(StepsBarWithButtons, {}) : footerContent }))] }));
    if (props.mui) {
        return (_jsxs(MuiPageView, __assign({ id: props.id, backButton: props.backButton, heading: props.heading, toolbarContent: props.toolbarContent, actions: props.actions, sxProps: __assign(__assign({}, props.sxProps), { "& .PageView-body": {
                    overflow: "auto",
                    height: "100%",
                } }), headerVariant: props.headerVariant }, { children: [_jsx(Header, __assign({}, props, { TabBar: TabBar, tabOrientation: orientation })), content, footer] })));
    }
    return (_jsx(Div100vh, __assign({ style: { height: isMobile ? "calc(100rvh - 48px)" : "100rvh" } }, { children: _jsxs(StyledView, __assign({ className: "pageView", hasFooter: !!footerContent || !!stepper }, { children: [_jsx(Header, __assign({}, props, { TabBar: TabBar, tabOrientation: orientation })), content, footer] })) })));
};
export { PageView };
