var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var AdminRole = "FA_ADMIN";
var getClientsFromResourceAccess = function (resourceAccess) {
    if (resourceAccess === void 0) { resourceAccess = {}; }
    return Object.entries(resourceAccess).map(function (_a) {
        var _b = __read(_a, 1), resource = _b[0];
        return resource;
    });
};
export var useValidKeycloak = function (_a) {
    var keycloakInstance = _a.keycloakInstance, _harmony = _a._harmony, devMode = _a.devMode;
    // DevMode
    if (devMode || _harmony)
        return {
            logout: function () { },
            isAdmin: true,
            clients: [],
        };
    // Keycloak instance
    if (keycloakInstance)
        return {
            logout: function () { return keycloakInstance.logout(); },
            isAdmin: keycloakInstance.hasRealmRole(AdminRole),
            clients: getClientsFromResourceAccess(keycloakInstance.resourceAccess),
        };
    return {
        logout: function () { },
        isAdmin: false,
        clients: [],
    };
};
