var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useResponsive } from "@faharmony/helpers";
import { useHistory } from "@faharmony/router";
import { Menu } from "@faharmony/navigation";
import { faInfoCircle } from "@faharmony/icons";
import { useCheckUserForRolesCallback, getI18nValue } from "./helpers";
var useMenu = function (Modules, i18next) {
    var history = useHistory();
    var checkRoles = useCheckUserForRolesCallback();
    var menu = [];
    var menuAlt = [];
    var generateSecondaryItem = function (subModule, Module) {
        var id = subModule.id, label = subModule.label, icon = subModule.icon, trailingIcon = subModule.trailingIcon, trailingIconTint = subModule.trailingIconTint, featured = subModule.featured;
        var subModuleLocation = "/".concat(Module.id, "/").concat(id);
        var item = {
            id: id,
            label: getI18nValue(i18next, Module.id, id, label),
            icon: icon,
            trailingIcon: trailingIcon,
            trailingIconTint: trailingIconTint,
            handleClick: function () { return history.push(subModuleLocation); },
            isSelectedCallback: function () {
                var locSplit = window.location.hash.split(/\/(?=.)/);
                return locSplit.length > 2 && locSplit[2].startsWith(subModule.id);
            },
        };
        if (featured) {
            // If subModule is feature, add it as primary item.
            menuAlt.push(item);
            return undefined;
        }
        else
            return item;
    };
    Modules.filter(function (m) { return !m.stateOnly; }).forEach(function (Module) {
        var _a = Module, id = _a.id, label = _a.label, icon = _a.icon, roles = _a.roles, subModules = _a.subModules;
        var moduleLocation = "/".concat(id);
        if (checkRoles(roles))
            menu.push({
                id: id,
                label: getI18nValue(i18next, id, "moduleName", label),
                icon: icon,
                handleClick: function () { return history.push(moduleLocation); },
                isSelectedCallback: function () {
                    var locSplit = window.location.hash.split(/\/(?=.)/);
                    return (locSplit.length >= 2 && locSplit[1].startsWith(id)
                    // locSplit.length > 1 && locSplit.length < 3 && locSplit[1].startsWith(id)
                    );
                },
                subItems: subModules === null || subModules === void 0 ? void 0 : subModules.reduce(function (list, subModule) {
                    var subModuleRoles = subModule.roles;
                    var hasPermission = true; // default is always true, since roles get passed on from parent module
                    if (subModuleRoles && subModuleRoles.length > 0) {
                        hasPermission = checkRoles(subModuleRoles); //reset the permission based on provided role values
                    }
                    if (hasPermission) {
                        var item = generateSecondaryItem(subModule, Module);
                        if (item)
                            list.push(item);
                    }
                    return list;
                }, []),
            });
        // Combine modules with featured subModules
        menu = __spreadArray(__spreadArray([], __read(menu), false), __read(menuAlt), false);
        // Clean variable for next run
        menuAlt = [];
    });
    return menu;
};
export var AppNavigation = function (_a) {
    var Modules = _a.Modules, appConfig = _a.appConfig, multilingual = _a.multilingual, enableNavigationFilter = _a.enableNavigationFilter;
    var menu = useMenu(Modules, multilingual);
    var isMobile = useResponsive().isMobile;
    return (_jsx(Menu, { heading: appConfig.name, shortHeading: appConfig.shortName, menu: menu, actions: appConfig.actions, isMenuCollapsedDefault: menu.length === 0, isHeaderVisible: !isMobile, enableMenuFilter: enableNavigationFilter, footerActions: [
            {
                id: "version-info",
                tooltip: "Version " + appConfig.version,
                icon: faInfoCircle,
            },
        ] }));
};
export default AppNavigation;
