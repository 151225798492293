var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, forwardRef } from "react";
import { styled } from "@faharmony/theme";
import { uuid, mergeRefs } from "@faharmony/helpers";
var StyledFrame = styled.iframe(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  border: none;\n"], ["\n  width: 100%;\n  height: 100%;\n  border: none;\n"])));
/**
 * Frame
 * ---
 * Due to cross-origin restrictions on iFrame,
 * the changes might not be visible until app is deployed.
 * @jira https://fasolutions.jira.com/browse/HAR-127
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 * @copyright FA Solutions Oy
 * @version 1.8.0
 * @example <Frame src="/jsreport" />
 */
var Frame = forwardRef(function (_a, ref) {
    var _b = _a.id, id = _b === void 0 ? "frame-" + uuid() : _b, internalCSS = _a.internalCSS, rest = __rest(_a, ["id", "internalCSS"]);
    var frameRef = useRef(null);
    var mergedRef = mergeRefs([frameRef, ref]);
    useEffect(function () {
        var frame = frameRef.current;
        var isDev = process.env.NODE_ENV === "development";
        var timeout;
        if (frame && internalCSS && !isDev) {
            timeout = setTimeout(function () {
                var _a;
                var doc = frame.contentDocument || ((_a = frame.contentWindow) === null || _a === void 0 ? void 0 : _a.document);
                if (doc) {
                    var styleTag = doc.createElement("style");
                    styleTag.id = "style-" + id;
                    styleTag.innerHTML = internalCSS;
                    doc.head.appendChild(styleTag);
                }
            }, 500);
        }
        return function () {
            clearTimeout(timeout);
        };
    }, [internalCSS, frameRef, id]);
    return (_jsx(StyledFrame, __assign({ id: id, name: id, allowFullScreen: true, role: "application" }, rest, { ref: mergedRef })));
});
export { Frame };
var templateObject_1;
