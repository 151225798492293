/**
 * LanguageCodes object used to manage all available languages 2-digit codes
 * @author Siddhant Gupta <siddhant@fasolutions.com>
 */
/**
 * List of language codes
 */
export var languageCodeList = [
    "ab",
    "aa",
    "af",
    "sq",
    "am",
    "ar",
    "hy",
    "as",
    "ay",
    "az",
    "ba",
    "eu",
    "bn",
    "dz",
    "bh",
    "bi",
    "br",
    "bg",
    "my",
    "be",
    "km",
    "ca",
    "zh",
    "co",
    "hr",
    "cs",
    "da",
    "nl",
    "en",
    "eo",
    "et",
    "fo",
    "fj",
    "fi",
    "fr",
    "fy",
    "gd",
    "gl",
    "ka",
    "de",
    "el",
    "kl",
    "gn",
    "gu",
    "ha",
    "iw",
    "hi",
    "hu",
    "is",
    "in",
    "ia",
    "ie",
    "ik",
    "ga",
    "it",
    "ja",
    "jw",
    "kn",
    "ks",
    "kk",
    "rw",
    "ky",
    "rn",
    "ko",
    "ku",
    "lo",
    "la",
    "lv",
    "ln",
    "lt",
    "mk",
    "mg",
    "ms",
    "ml",
    "mt",
    "mi",
    "mr",
    "mo",
    "mn",
    "na",
    "ne",
    "no",
    "oc",
    "or",
    "om",
    "ps",
    "fa",
    "pl",
    "pt",
    "pa",
    "qu",
    "rm",
    "ro",
    "ru",
    "sm",
    "sg",
    "sa",
    "sr",
    "sh",
    "st",
    "tn",
    "sn",
    "sd",
    "si",
    "ss",
    "sk",
    "sl",
    "so",
    "es",
    "su",
    "sw",
    "sv",
    "tl",
    "tg",
    "ta",
    "tt",
    "te",
    "th",
    "bo",
    "ti",
    "to",
    "ts",
    "tr",
    "tk",
    "tw",
    "uk",
    "ur",
    "uz",
    "vi",
    "vo",
    "cy",
    "wo",
    "xh",
    "ji",
    "yo",
    "zu",
];
var asLanguageCode = function (arg) {
    return arg;
};
/**
 * All languages supported.
 */
export var languageCodes = asLanguageCode({
    Abkhazian: "ab",
    Afar: "aa",
    Afrikaans: "af",
    Albanian: "sq",
    Amharic: "am",
    Arabic: "ar",
    Armenian: "hy",
    Assamese: "as",
    Aymara: "ay",
    Azerbaijani: "az",
    Bashkir: "ba",
    Basque: "eu",
    Bengali: "bn",
    Bhutani: "dz",
    Bihari: "bh",
    Bislama: "bi",
    Breton: "br",
    Bulgarian: "bg",
    Burmese: "my",
    Byelorussian: "be",
    Cambodian: "km",
    Catalan: "ca",
    Chinese: "zh",
    Corsican: "co",
    Croatian: "hr",
    Czech: "cs",
    Danish: "da",
    Dutch: "nl",
    English: "en",
    Esperanto: "eo",
    Estonian: "et",
    Faeroese: "fo",
    Fiji: "fj",
    Finnish: "fi",
    French: "fr",
    Frisian: "fy",
    Gaelic: "gd",
    Galician: "gl",
    Georgian: "ka",
    German: "de",
    Greek: "el",
    Greenlandic: "kl",
    Guarani: "gn",
    Gujarati: "gu",
    Hausa: "ha",
    Hebrew: "iw",
    Hindi: "hi",
    Hungarian: "hu",
    Icelandic: "is",
    Indonesian: "in",
    Interlingua: "ia",
    Interlingue: "ie",
    Inupiak: "ik",
    Irish: "ga",
    Italian: "it",
    Japanese: "ja",
    Javanese: "jw",
    Kannada: "kn",
    Kashmiri: "ks",
    Kazakh: "kk",
    Kinyarwanda: "rw",
    Kirghiz: "ky",
    Kirundi: "rn",
    Korean: "ko",
    Kurdish: "ku",
    Laothian: "lo",
    Latin: "la",
    Latvian: "lv",
    Lingala: "ln",
    Lithuanian: "lt",
    Macedonian: "mk",
    Malagasy: "mg",
    Malay: "ms",
    Malayalam: "ml",
    Maltese: "mt",
    Maori: "mi",
    Marathi: "mr",
    Moldavian: "mo",
    Mongolian: "mn",
    Nauru: "na",
    Nepali: "ne",
    Norwegian: "no",
    Occitan: "oc",
    Oriya: "or",
    Oromo: "om",
    Pashto: "ps",
    Persian: "fa",
    Polish: "pl",
    Portuguese: "pt",
    Punjabi: "pa",
    Quechua: "qu",
    RhaetoRomance: "rm",
    Romanian: "ro",
    Russian: "ru",
    Samoan: "sm",
    Sangro: "sg",
    Sanskrit: "sa",
    Serbian: "sr",
    SerboCroatian: "sh",
    Sesotho: "st",
    Setswana: "tn",
    Shona: "sn",
    Sindhi: "sd",
    Singhalese: "si",
    Siswati: "ss",
    Slovak: "sk",
    Slovenian: "sl",
    Somali: "so",
    Spanish: "es",
    Sudanese: "su",
    Swahili: "sw",
    Swedish: "sv",
    Tagalog: "tl",
    Tajik: "tg",
    Tamil: "ta",
    Tatar: "tt",
    Tegulu: "te",
    Thai: "th",
    Tibetan: "bo",
    Tigrinya: "ti",
    Tonga: "to",
    Tsonga: "ts",
    Turkish: "tr",
    Turkmen: "tk",
    Twi: "tw",
    Ukrainian: "uk",
    Urdu: "ur",
    Uzbek: "uz",
    Vietnamese: "vi",
    Volapuk: "vo",
    Welsh: "cy",
    Wolof: "wo",
    Xhosa: "xh",
    Yiddish: "ji",
    Yoruba: "yo",
    Zulu: "zu",
});
export default languageCodes;
