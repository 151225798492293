var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// FA OPERATIONS ICON
export var FAOperationsSVGPath = "M256 130.444V30H5V482H507V130.444H256ZM105.4 431.778H55.2V381.556H105.4V431.778ZM105.4 331.333H55.2V281.111H105.4V331.333ZM105.4 230.889H55.2V180.667H105.4V230.889ZM105.4 130.444H55.2V80.2222H105.4V130.444ZM205.8 431.778H155.6V381.556H205.8V431.778ZM205.8 331.333H155.6V281.111H205.8V331.333ZM205.8 230.889H155.6V180.667H205.8V230.889ZM205.8 130.444H155.6V80.2222H205.8V130.444ZM456.8 431.778H256V381.556H306.2V331.333H256V281.111H306.2V230.889H256V180.667H456.8V431.778ZM406.6 230.889H356.4V281.111H406.6V230.889ZM406.6 331.333H356.4V381.556H406.6V331.333Z";
export var FAOperationsAppIcon = function () { return (_jsx("svg", __assign({ viewBox: "640, 512" }, { children: _jsx("path", { d: FAOperationsSVGPath }) }))); };
export var FAOperationsAppIconDefinition = {
    prefix: "far",
    iconName: "timeline-arrow",
    icon: [640, 512, [], "f4c5", FAOperationsSVGPath],
};
